<div class="p-3">
    <div style="display: flex; margin-bottom: 1rem; justify-content: space-between; align-items: center;">

        <trv-select [formControl]="nvdbNavigationService.savedViewsSortBy"
                    placeholder="Sortera efter"
                    [source]="sortByOptions"
                    valueKey="strategy"
                    sortKey="strategy"
                    [disableClear]="true"
                    [displayMethod]="displaySortByName"></trv-select>

        <button class="btn btn-primary" style="min-width: 100px;" type="button" [trvPopover]="popoverComponent"
                [position]="popoverPosition"
                (shown)="onShowComponent($event)">
            Spara vy
        </button>
    </div>

    <app-saved-view-item [savedView]="SWEDEN_VIEW" [title]="SWEDEN_VIEW.name" [defaultView]="isDefaultView(SWEDEN_VIEW)"
                         [swedenView]="true"></app-saved-view-item>

    <hr>

    @for (view of sortedSavedViews; track view) {
        <app-saved-view-item [savedView]="view"
                             [title]="view.name" [defaultView]="isDefaultView(view)"></app-saved-view-item>
    }

    <span style="font-size: 11px">Sparar zoomläge, kartinställningar, och valda dataprodukter</span>
</div>

<div id='reportAvikelseContainer' class='main_container' [trv-loading]='loading'>
    <div class='header'>
        <span class='title'>
            @if (geometryType == GeometryType.POINT) {
                <fa-icon class='geom-icon' [icon]='faMapMarkerAlt'></fa-icon>
            }
            @if (geometryType == GeometryType.LINE_STRING || geometryType == GeometryType.MULTI_LINE_STRING) {
                <fa-icon class='geom-icon' [icon]='faRoute'></fa-icon>
            }
            @if (geometryType == GeometryType.POLYGON || geometryType == GeometryType.MULTI_POLYGON) {
                <fa-icon class='geom-icon' [icon]='faDrawPolygon'></fa-icon>
            }

            @if (currentStatus === 'CREATING_ITEM_FROM_QUE') {
                Skapa avvikelsen {{ reportAvikelseService.totalQueLengthFromBeginning! - reportAvikelseService.queOfItemsToCreate.length }} av {{ reportAvikelseService.totalQueLengthFromBeginning! }}
            } @else if (currentStatus === 'CREATING_ITEM') {
                Skapa avvikelsen
            } @else if (currentStatus === 'EDITING_ITEM') {
                Redigera avvikelsen
            }
        </span>

        <button type='button' aria-label='Stäng' class='btn btn-link' (click)='close()'>
            <svg _ngcontent-app-c19='' xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor'
                 class='bi bi-x-lg' viewBox='0 0 16 16'>
                <path _ngcontent-app-c19=''
                      d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z'
                      fill-rule='' class='ng-star-inserted'></path>
            </svg>
        </button>
    </div>
    <div class='top_container'>
        <app-report-item-avikelse></app-report-item-avikelse>
    </div>
    <div class='bottom_container'>
        <button class='btn btn-outline-primary' (click)='close()'>Avbryt</button>
        <button class='btn btn-primary' (click)='submit(reportItemComponent)'>
            @if (currentStatus === 'CREATING_ITEM' || currentStatus === 'CREATING_ITEM_FROM_QUE'){
                Skapa
            } @else if (currentStatus === 'EDITING_ITEM') {
                Uppdatera
            }
        </button>
    </div>
</div>

import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {MapPageComponent} from "./pages/map-page/map-page.component";
import {HomePageComponent} from "./pages/home-page/home-page.component";
import {ReportsPageComponent} from "./pages/reports-page/reports-page.component";
import {ContactPageComponent} from "./pages/contact-page/contact-page.component";
import {ActorReportsPageComponent} from "./pages/actor-reports-page/actor-reports-page.component";
import {LoginPageComponent} from "./pages/login-page/login-page.component";
import {UnauthorizedPageComponent} from "./pages/unauthorized-page/unauthorized-page.component";
import {NotFoundPageComponent} from "./pages/not-found-page/not-found-page.component";
import {authenticationGuard, authenticationGuardMinAjorhallare} from "./guards/login-guard";
import {AppRedirectComponent} from "./pages/app-redirect/app-redirect.component";
import {environment} from "src/environments/environment";
import {HandleReportsPageComponent} from "./pages/handle-reports-page/handle-reports-page.component";
import {HandleSingleReportPageComponent} from "./pages/handle-single-report-page/handle-single-report-page.component";
import {LogoutPageComponent} from "./pages/logout-page/logout-page.component";
import {SingleReportPageComponent} from "./pages/single-report-page/single-report-page.component";
import {PaKartaReportPageComponent} from '@pages/pa-karta-report-page/pa-karta-report-page.component'
import {ViewReportsPageComponent} from "@pages/view-reports-page/view-reports-page.component";
import { StatisticsPageComponent } from "./pages/statistics-page/statistics-page.component";

var routes: Routes = [];

if (environment.application == "NvdbDataleverans") {
    routes = [
        {
            path: "",
            canActivate: [authenticationGuard()],
            children: [
                {
                    path: "home",
                    component: HomePageComponent,
                    title: "NVDB - Dataleverans",
                },
                {
                    path: "reports/:reportId",
                    component: SingleReportPageComponent,
                    title: "Ärende",
                },
                {
                    path: "reports",
                    pathMatch: "full",
                    component: ReportsPageComponent,
                    title: "Ärenden",
                },
                {
                    path: "map",
                    component: MapPageComponent,
                    title: "NVDB - Dataleverans",
                },
                {
                    path: "stats",
                    component: StatisticsPageComponent,
                    title: "NVDB - Dataleverans",
                },
                {path: "", redirectTo: "map", pathMatch: "full"},
            ],
        },
        {
            path: "view-reports/:reportId",
            canActivate: [authenticationGuard()],
            pathMatch: "full",
            component: ViewReportsPageComponent,
            title: "Ajourhållare"
        },
        {
            path: "",
            canActivate: [authenticationGuard()],
            children: [
                {
                    path: "handle-reports/:reportId",
                    component: HandleSingleReportPageComponent,
                    title: "Ajourhållare"
                },
                {
                    path: "handle-reports",
                    pathMatch: "full",
                    component: HandleReportsPageComponent,
                    title: "Ajourhållare"
                },
            ]
        },
        {path: "login", component: LoginPageComponent, title: "Logga in"},
        {path: "logout", component: LogoutPageComponent, title: "Logga ut"},
        {path: "not-found", component: NotFoundPageComponent, title: "Ogiltlig url"},
        {path: "not-authorized", component: UnauthorizedPageComponent, title: "Ej autentiserad"},
        {path: "**", component: AppRedirectComponent, title: "Ogiltlig url"},
    ];
} else if (environment.application == "NvdbPåKarta") {
    routes = [
        {path: "", redirectTo: "map", pathMatch: "full"},
        {
            path: "map",
            component: MapPageComponent,
            title: "NVDB på Karta",
        },
        {
            // eller guid?
            path: "reports/:reportGuid",
            component: PaKartaReportPageComponent,
            title: "NVDB på Karta",
        },
        {path: "not-found", component: NotFoundPageComponent, title: "Ogiltlig url"},
        {path: "**", component: AppRedirectComponent, title: "Ogiltlig url"},
    ];
} else if (environment.application == "NjdbPåWebb") {
    routes = [
        {path: "", redirectTo: "map", pathMatch: "full"},
        {
            path: "map",
            component: MapPageComponent,
            title: "NJDB på webb",
        },
        {path: "not-found", component: NotFoundPageComponent, title: "Ogiltlig url"},
        {path: "**", component: AppRedirectComponent, title: "Ogiltlig url"},
    ];
}

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}

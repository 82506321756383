import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FeatureTypeRequest } from "../_api/dataleverans/models/feature-type-request";
import { FeatureTypeRequestMultiple } from "../_api/dataleverans/models/feature-type-request-multiple";
import { NetDbApi } from "../_api/dataleverans/services/net-db-api";
import { NetDbServiceDataleverans } from "./dataleverans/net-db-dataleverans.service";
import { NetDbServicePakarta } from "./pakarta/net-db-pakarta.service";
import { environment } from "src/environments/environment";
import { ActorTypesEnum, DataslagByDeliveryType, SearchRequest } from "../_api/dataleverans/models";

@Injectable({
    providedIn: "root",
})
export abstract class NetDbService {
    abstract infoClickInCoordinate(featureTypeRequest: FeatureTypeRequest): Observable<string>;

    abstract infoClickMultiple(featureTypeRequestMultiple: FeatureTypeRequestMultiple): Observable<string>;

    abstract searchAll(searchObj: SearchRequest): Observable<string>;

    abstract getDescription(metaKey: string): Observable<string>;

    abstract getLastFeatureUpdateNVDB(): Observable<string>


    /*
    // i just call this directly for now
        abstract getAllDataslagForActor(actorType: ActorTypesEnum): Observable<DataslagByDeliveryType[]>;
    */
}

@Injectable({
    providedIn: "root",
})
export class EmptyNetDbService implements NetDbService {
    public getLastFeatureUpdateNVDB(): Observable<string> {
        throw new Error("Method not implemented.");
    }

    public infoClickInCoordinate(featureTypeRequest: FeatureTypeRequest): Observable<string> {
        throw new Error("Method not implemented.");
    }

    public infoClickMultiple(featureTypeRequestMultiple: FeatureTypeRequestMultiple): Observable<string> {
        throw new Error("Method not implemented.");
    }

    public searchAll(searchObj: SearchRequest): Observable<string> {
        throw new Error("Method not implemented.");
    }

    public getDescription(metaKey: string): Observable<string> {
        throw new Error("Method not implemented.");
    }

    public getAllDataslagForActor(actorType: ActorTypesEnum): Observable<DataslagByDeliveryType[]> {
        throw new Error("Method not implemented.");
    }
}

<div class="view_item" (click)="gotoView()" [id]="savedView.id">
    <div>
        <div class="pin_icon_container">
            <app-svg-icon [svgIcon]="pinSvg"></app-svg-icon>
        </div>
        <p>{{ title }}</p>
        <!--
                    [style.font-weight.bold]="swedenView"
        -->
    </div>
    <div>
        @if (!defaultView) {
            <div class="star_container" (click)="starClicked($event, savedView.id)"
                 [trvTooltip]="'Sätt vy som favorit'" [position]='toolTipPositions'>
                <app-svg-icon [svgIcon]="starSvg"></app-svg-icon>
            </div>
        } @else {
            <div class="star_container_filled">
                <app-svg-icon [svgIcon]="starSvg"></app-svg-icon>
            </div>
        }


        <div class="settings_button" [ngStyle]="{ visibility: swedenView ? 'hidden' : 'visible' }">
            <button
                aria-label="Sparade vyer val"
                class="btn btn-link c-report__list__report__name__menu"
                [trvPopover]="popoverComponent"
                [position]="popoverPosition"
                (shown)="onShowComponent($event)"
                (afterCloseWithType)="onAfterCloseWithType($event)"
            >
                <app-svg-icon [svgIcon]="dots3Svg"></app-svg-icon>
            </button>
        </div>
    </div>
</div>

import { AfterViewInit, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { LayerListItem, TrvMap, TrvNgMapService } from "@trafikverket/trv-ng-map";
import { svgMagnifyingGlass, svgMagnifyingPlus, svgCircleInfo, svgMagnifyingPlusFilled, svgInfo } from "src/app/shared/utils/svg-helper-data";
import { TrvModalService, TrvTooltipPosition, TrvTooltipDirective, TrvLoadingBundle } from "trv-ng-common";
import { firstValueFrom } from "rxjs";
import { NetDbService } from "src/app/service/net-db.service";
import { SvgIcon } from "src/app/shared/utils/svg-helper";
import { LayerInfoModalComponent } from './layer-info-modal/layer-info-modal.component'
import { BackgroundLayerSelectComponent } from './background-layer-select/background-layer-select.component'
import { SvgIconComponent } from "../../utils/svg-icon/svg-icon.component";
import { NgClass } from "@angular/common";
import { environment } from "src/environments/environment";
import {NvdbNavigationService} from "@app/service/nvdb-navigation.service";

@Component({
    selector: 'app-layer-section',
    templateUrl: './layer-section.component.html',
    styleUrls: ['../../map-menu/map-menu.component.scss', './layer-section.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
    BackgroundLayerSelectComponent,
    NgClass,
    TrvTooltipDirective,
    SvgIconComponent,
    TrvLoadingBundle
],
})
export class LayerSectionComponent implements AfterViewInit {
    @ViewChild(BackgroundLayerSelectComponent)
    backgroundLayerSelectComponent!: BackgroundLayerSelectComponent;

    public searchIcon: SvgIcon = svgMagnifyingGlass;
    public searchIconPlus: SvgIcon = svgMagnifyingPlus;
    public circleInfoIcon: SvgIcon = svgCircleInfo;
    public infoIcon: SvgIcon = svgInfo;
    public searchIconPlusFilled: SvgIcon = svgMagnifyingPlusFilled;

    public toolTipPositions: TrvTooltipPosition[] = ["bottom", "right"];

    constructor(
        private nvdbNavigationService: NvdbNavigationService,
        @Inject(TrvNgMapService) public trvMapService: TrvNgMapService,
        @Inject(TrvModalService) private trvModalService: TrvModalService,
        @Inject(NetDbService) private netDbService: NetDbService
    ) {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {}

    public getTrvMap(): TrvMap | undefined {
        return this.trvMapService.trvMap;
    }

    public trvMapLoaded(): boolean {
        return this.trvMapService.isMapLoaded();
    }

    public showLayerBasedOnLocal(layer: LayerListItem): boolean {
        const trvMap = this.trvMapService.trvMap!;
        if(trvMap.trvLayer.hasLayerData(layer, "onlyLocal")) {
            const onlyLocal = trvMap.trvLayer.getLayerData(layer, "onlyLocal") as boolean;
            if(!(environment.local == "true") && onlyLocal) return false;
        }
        return true;
    }

    public showInLayer(layer: LayerListItem): boolean {
        let res = false;
        if (layer.trvData.hasOwnProperty("showInLayer")) {
            let showInLayer = (layer.trvData as any).showInLayer;
            return showInLayer;
        }
        return res;
    }

    public onWmsLayerInputChange(event: any, layer: LayerListItem) {
        this.trvMapService.trvMap?.trvLayer.setLayerVisibility(layer, event.currentTarget.checked);
    }

    public layerActive(layer: LayerListItem) {
        if (layer) {
            return layer.active;
        }
        return false;
    }

    /**
     * Metod som om, lagret innehåller en maxScaleDenominator, zoomar in till den nivån på kartan.
     * @param layer Lagret som ska zoomas in på
     */
    public zoomToLayer(layer: LayerListItem) {
        this.trvMapService.trvMap!.zoomInOnLayerMaxScaleDenominator(layer);
    }

    /**
     * Metod som triggras när ett dataslag har blivit högerklickat på.
     * @param event
     * @param layer Det dataslag som klickats på
     * @returns false;
     */
    public onRightClick(event: any, layer: LayerListItem) {
        this.infoAboutLayer(layer);
        return false;
    }

    /**
     * Metod som öppnar modal som visar information om nuvarande lager.
     * @param layer
     */
    public async infoAboutLayer(layer: LayerListItem) {
        const modal = this.trvModalService.open(LayerInfoModalComponent, {
            disposeOnBackdropClick: true,
            size: this.nvdbNavigationService.isMobileDevice ? "fullscreen" : "md",
        });
        const component = modal.componentInstance;
        component.title = layer.name;

        let metaKey: string = "";
        if (layer.trvData.hasOwnProperty("metaKey")) metaKey = (layer.trvData as any).metaKey;

        if (layer.trvData.hasOwnProperty("description")) {
            layer.description = (layer.trvData as any).description;
        } else {
            if (metaKey != "") {
                var desc = await firstValueFrom(this.netDbService.getDescription(metaKey));
                desc = desc.replace("\n", "<br>");
                layer.description = desc;
            }
        }

        component.text = layer.description ? layer.description : "Lagret saknar beskrivning.";
        component.layerVisibleFrom = layer.maxScaleDenominator ? Math.round(layer.maxScaleDenominator) : layer.maxScaleDenominator;
        component.legendGraphics = layer.legendGraphics;
        component.layer = layer;

        modal.afterCloseWithType().subscribe(closeParams => {
            //console.log(closeParams)
        });
    }
}

import { Directive, EventEmitter, HostBinding, HostListener, Output } from "@angular/core";

@Directive({
    standalone: true,
    selector: "[fileDragDrop]",
})
export class FileDragNDropDirective {
    @Output() private filesChangeEmiter: EventEmitter<File[]> = new EventEmitter();
    @HostBinding("class.drag_over") private isDragOver = false;

    constructor() {}

    @HostListener("dragover", ["$event"])
    public onDragOver(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
        this.isDragOver = true;
    }

    @HostListener("dragleave", ["$event"])
    public onDragLeave(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
        this.isDragOver = false;
    }

    @HostListener("drop", ["$event"])
    public onDrop(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
        this.isDragOver = false;

        let files = evt.dataTransfer.files;
        let valid_files: Array<File> = Array.from(files);
        this.filesChangeEmiter.emit(valid_files);
    }
}




/*
export class FileDragNDropDirective {


    //@Input() private allowed_extensions : Array<string> = ['png', 'jpg', 'bmp'];
    @Output() private filesChangeEmiter: EventEmitter<File[]> = new EventEmitter();
    //@Output() private filesInvalidEmiter : EventEmitter<File[]> = new EventEmitter();
    @HostBinding("style.background") private background = "#eee";
    @HostBinding("style.border") private borderStyle = "2px dashed";
    @HostBinding("style.border-color") private borderColor = "#696D7D";
    @HostBinding("style.border-radius") private borderRadius = "5px";

    constructor() {}

    @HostListener("dragover", ["$event"])
    public onDragOver(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = "red";
        this.borderColor = "cadetblue";
        this.borderStyle = "4px solid";
    }

    @HostListener("dragleave", ["$event"])
    public onDragLeave(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
        /!*
                this.background = "#eee";
                this.borderColor = "#696D7D";
                this.borderStyle = "2px dashed";
        *!/

        this.borderStyle = "0px";
    }

    @HostListener("drop", ["$event"])
    public onDrop(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
        /!*
        this.background = "#ececec";
                this.borderColor = "#696D7D";
                this.borderStyle = "2px dashed";
                debugger;
        *!/
        let files = evt.dataTransfer.files;
        let valid_files: Array<File> = files;
        this.filesChangeEmiter.emit(valid_files);
    }
}
*/

import {AfterViewInit, Component, Inject, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {IconDefinition, IconProp} from "@fortawesome/fontawesome-svg-core";
import {faEnvelope, faHome, faInfoCircle, faLock, faMap, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import {TrvNgMapService, TrvNgMapOverlayService} from "@trafikverket/trv-ng-map";
import {TrvGlobalMessagesService, TrvTooltipDirective, TrvTooltipPosition} from "trv-ng-common";
import {
    svgArrowLeft,
    svgHamburger,
    svgLocation,
    svgMinus,
    svgPerson,
    svgPlus,
    svgPrinter,
    svgRightArrow,
    svgTrafikverketLogo,
} from "src/app/shared/utils/svg-helper-data";
import Geolocation from "ol/Geolocation";

import proj4 from "proj4";
import {register} from "ol/proj/proj4.js";
import {get as getProjection, Projection} from "ol/proj.js";
import {Geometry, Point} from "ol/geom";
import Feature from "ol/Feature";
import {SvgIcon} from "src/app/shared/utils/svg-helper";
import {NavigationState, NvdbNavigationService} from "src/app/service/nvdb-navigation.service";
import {MapComponent} from "../../components/map/map/map.component";
import {SvgIconComponent} from "../../components/utils/svg-icon/svg-icon.component";
import {MapButtonComponent} from "../../components/map/map-button/map-button.component";

import {MapButtonsComponent} from "../../components/map/map-buttons/map-buttons.component";
import {MapLabelComponent} from "../../components/map/map-label/map-label.component";
import {environment} from "../../../environments/environment";
import {FeatureToCreate, ReportService} from "@app/service/report.service";
import {ReportAvikelseService} from "@app/service/report-avikelse.service";
import {first, firstValueFrom, skip} from "rxjs";
import {NvdbReportStyle} from "@app/trv-map-extension/trv-map-extension-styles";
import {isStringValidFloat, isStringValidInteger} from "@shared/utils/utils";
import {showErrorMessage} from "@shared/animations";
import {AuthenticationService} from "@app/service/authentication.service";
import {NgClass, NgStyle} from "@angular/common";

@Component({
    selector: "app-map-page",
    templateUrl: "./map-page.component.html",
    styleUrls: ["./map-page.component.scss"],
    standalone: true,
    imports: [MapLabelComponent, MapButtonsComponent, MapButtonComponent, TrvTooltipDirective, SvgIconComponent, MapComponent, NgClass, NgStyle],
})
export class MapPageComponent implements OnInit {
    //Svg icons
    public locationIcon: SvgIcon = svgLocation;
    public plusIcon: SvgIcon = svgPlus;
    public minusIcon: SvgIcon = svgMinus;
    public svgRightArrow = svgRightArrow;
    public svgArrowLeft = svgArrowLeft;

    public title: string = "NVDB dataleverans";
    public SWEREF_99_TM = registerProjection(
        "EPSG:3006",
        'PROJCS["SWEREF99 TM", GEOGCS["SWEREF99", DATUM["SWEREF99", SPHEROID["GRS 1980",6378137,298.257222101], TOWGS84[0,0,0,0,0,0,0]], PRIMEM["Greenwich",0, AUTHORITY["EPSG","8901"]], UNIT["degree",0.0174532925199433, AUTHORITY["EPSG","9122"]], AUTHORITY["EPSG","4619"]], PROJECTION["Transverse_Mercator"], PARAMETER["latitude_of_origin",0], PARAMETER["central_meridian",15], PARAMETER["scale_factor",0.9996], PARAMETER["false_easting",500000], PARAMETER["false_northing",0], UNIT["metre",1, AUTHORITY["EPSG","9001"]], AUTHORITY["EPSG","3006"]]'
    );

    public toolTipPositions: TrvTooltipPosition[] = ["left", "bottom"];
    public toolTipPositionTop: TrvTooltipPosition[] = ["top"];

    east: number | null = null;
    north: number | null = null;
    scale: number | null = null;

    constructor(
        @Inject(AuthenticationService)
        public authenticationService: AuthenticationService,
        @Inject(TrvGlobalMessagesService)
        private globalMessagesService: TrvGlobalMessagesService,
        @Inject(TrvNgMapService) public trvMapService: TrvNgMapService,
        @Inject(NvdbNavigationService)
        public nvdbNavigation: NvdbNavigationService,
        private route: ActivatedRoute,
        private reportService: ReportService,
        private reportAvikelseService: ReportAvikelseService
    ) {
        this.nvdbNavigation.hideHamburgerMenu = false;
    }

    ngOnInit() {
        this.route.queryParams.subscribe(async params => {
            const eastValidNumber = isStringValidFloat(params["east"]);
            const northValidNumber = isStringValidFloat(params["north"]);
            const scaleValidNumber = isStringValidFloat(params["scale"]);

            const anyValueGiven = params["east"] || params["north"] || params["scale"];
            const allValuesValid = eastValidNumber && northValidNumber && scaleValidNumber;

            if (anyValueGiven && !allValuesValid) {
                let felaktigtFält = "";
                if (!eastValidNumber) felaktigtFält = "east";
                else if (!northValidNumber) felaktigtFält = "north";
                else felaktigtFält = "scale";

                this.globalMessagesService.error(`Ett fel uppstod när "${felaktigtFält}" skulle läsas. Kan ej zooma till punkt.`);
                return;
            }

            this.east = parseInt(params["east"]);
            this.north = parseInt(params["north"]);
            this.scale = parseInt(params["scale"]);

            if (params["action"] == "report") {
                await firstValueFrom(this.trvMapService.onMapLoaded().asObservable());
                this.trvMapService.trvMap!.map.once("rendercomplete", () => {
                    const data = JSON.parse(params["data"]);
                    const featuresToCreate: FeatureToCreate[] = [];
                    for (const a of data) {
                        try {
                            const feature = this.trvMapService.trvMap?.trvLayer.getFeatureFromWkt(a.wkt);
                            if (!feature) throw Error
                            featuresToCreate.push({
                                feature,
                                defaultData: {
                                    description: a.reportText,
                                },
                            });
                        } catch (e) {
                            console.log(e)
                            this.globalMessagesService.error(`Ett fel uppstod när WKT:n "${a.wkt}" skulle omvandlas till en geometri.`);
                            return;
                        }
                    }

                    if (environment.application == "NvdbDataleverans") {
                        this.reportService.queOfItemsToCreate = featuresToCreate;
                        this.reportService.totalQueLengthFromBeginning = featuresToCreate.length;

                        const firstItem = this.reportService.queOfItemsToCreate.shift()!;
                        this.reportService.addReportFeatureToMap(firstItem.feature);

                        this.reportService.setActiveItemToCreateOrEdit(firstItem, false);
                    } else if (environment.application == "NvdbPåKarta") {
                        this.reportAvikelseService.queOfItemsToCreate = featuresToCreate;
                        this.reportAvikelseService.totalQueLengthFromBeginning = featuresToCreate.length;

                        const firstItem = this.reportAvikelseService.queOfItemsToCreate.shift()!;
                        this.reportAvikelseService.addReportFeatureToMap(firstItem.feature);

                        this.reportAvikelseService.setReportAvikelseItemToCreateOrEdit(firstItem, false);
                    }
                });
            }

            if(params["viewmode"] && environment.application == "NvdbDataleverans"){
                // unfortunately we have to set this instantly to prevent the initial zoom
                this.nvdbNavigation.VIEWMODE = true

                const ids = params["viewmode"].split(",")

                for (const id of ids) {
                    if (!isStringValidInteger(id)) {
                        this.globalMessagesService.error(`Går ej att visa id ${id} i viewMode. Id måste vara ett nummer.`);
                        this.nvdbNavigation.VIEWMODE = false
                        return;
                    }
                }

                const reportIds = [...new Set(ids.map((a: string) => parseInt(a)))] as number[] // removes duplicates
                this.reportService.reportDataLoadedChanged.subscribe(() => this.setViewModeReport(reportIds))
                if(this.reportService.reportDataLoaded) this.setViewModeReport(reportIds)

            }else{
                this.nvdbNavigation.VIEWMODE = false
                this.nvdbNavigation.viewModeChanged.emit(false)
            }
        });
    }

    private async setViewModeReport(reportIds: number[]) {
        this.nvdbNavigation.reportsToDisplayInViewMode = [...this.reportService.reports, ...this.reportService.otherUsersReports].filter(a => reportIds.includes(a.id)).sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1)

        // if all items couldnt be found, try fetching all closed items
        if (this.nvdbNavigation.reportsToDisplayInViewMode.length != reportIds.length) {
            await this.reportService.refreshReports(true)
            this.nvdbNavigation.reportsToDisplayInViewMode = [...this.reportService.reports, ...this.reportService.otherUsersReports].filter(a => reportIds.includes(a.id))
        }

        for (const reportId of reportIds) {
            if (!this.nvdbNavigation.reportsToDisplayInViewMode.map(a => a.id).includes(reportId)) {
                this.globalMessagesService.error(`Kunde ej hitta ett ärende med id ${reportId}.`);
            }
        }

        this.nvdbNavigation.navigationState = NavigationState.Report
        this.nvdbNavigation.viewModeChanged.emit(true)
    }


    public zoomIn() {
        const view = this.trvMapService.trvMap?.map.getView();
        view?.setZoom(view?.getZoom()! + 1);
    }

    public zoomOut() {
        const view = this.trvMapService.trvMap?.map.getView();
        view?.setZoom(view?.getZoom()! - 1);
    }

    public gotoUserLocation() {
        const proj = this.trvMapService.trvMap?.map.getView().getProjection();

        const geolocation = new Geolocation({
            projection: this.SWEREF_99_TM?.getCode()!,
        });

        geolocation.once("change:position", () => {
            const coordinates = geolocation.getPosition();
            if (coordinates) {
                const point = new Point(coordinates);
                const feature = new Feature(point);
                this.trvMapService.trvMap?.map.getView().fit(feature.getGeometry()!.getExtent(), {
                    padding: [50, 50, 50, 50],
                    maxZoom: 18,
                });
            }
        });

        geolocation.setTracking(true);
    }

    protected readonly document = document;
}

export function registerProjection(name: string, projection: string): Projection | null {
    proj4.defs(name, projection);
    register(proj4);
    return getProjection(name);
}

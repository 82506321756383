import { EventEmitter, Injectable } from "@angular/core";
import { ReportService, Report } from "./report.service";
import { ChartConfiguration, ChartData, ChartType, Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ReportStatusesEnum } from "@app/_api/dataleverans/models";
import { FormControl, FormGroup } from "@angular/forms";
import { DateRange, TrvDateService } from "trv-ng-common";
import { DateTime } from "luxon";
import { openForClarification } from "@app/_api/dataleverans/fn/ajour/open-for-clarification";

Chart.register(ChartDataLabels);

@Injectable({
    providedIn: "root",
})
export class StatsService {
    public statsUpdated: EventEmitter<void> = new EventEmitter<void>();
    public datesForm: FormGroup<DateRangeFormGroup>;

    public allReports: Report[] = [];

    public numReportsChartData: ChartData<"bar", number[], string | string[]> = {
        labels: ["Inskickade", "Kompletterat", "Klart för beredning", "Avslutade", "Ej inskickade", "Öppna för komplettering"],
        //[numSent, numClarificationSent, numReviewed, numClosed, numDraft, numOpenforClarification]
        datasets: [
            {
                data: [0, 0, 0, 0, 0, 0],
                //backgroundColor: ["rgba(255, 99, 132, 0.2)"],
            },
        ],
    };

    public numReportsChartOptions: ChartConfiguration["options"] = {
        plugins: {
            legend: {
                display: true,
                position: "top",
            },
            datalabels: {
                anchor: "center",
                align: "center",
                formatter: function (value, context) {
                    return value > 0 ? value : "";
                },
                font: {
                    weight: "bold",
                },
            },
            tooltip: {
                callbacks: {
                    footer: function (tooltipItems) {
                        let footerText = "";

                        tooltipItems.forEach(function (tooltipItem) {
                            //sum += tooltipItem.parsed.y;
                            footerText += tooltipItem.label + " ";
                            //"Inskickade", "Kompletterat", "Klart för beredning", "Avslutade", "Ej inskickade", "Öppna för komplettering"
                            switch (tooltipItem.label) {
                                case "Inskickade":
                                    footerText = "Inskickade ej hanterade ärenden";
                                    break;
                                case "Kompletterat":
                                    footerText = "Ärenden som kompletterats, ej hanterat";
                                    break;
                                case "Klart för beredning":
                                    footerText = "Ärenden som är klart för beredning";
                                    break;
                                case "Avslutade":
                                    footerText = "Ärenden som avslutats";
                                    break;
                                case "Ej inskickade":
                                    footerText = "Ärenden som inte inte skickats till ajourhållning än";
                                    break;
                                case "Öppna för komplettering":
                                    footerText = "Ärende som öppnats för komplettering men inte skickats in";
                                    break;
                            }
                        });
                        return footerText;
                    },
                },
            },
        },
    };

    public numReportsPerSourceChartData: ChartData<"bar", number[], string | string[]> = {
        labels: ["NVDB dataleverans", "NVDB på karta"],
        datasets: [
            {
                data: [0, 0],
                label: "Antal ärenden",
                backgroundColor: ["rgba(30, 173, 205, 0.8)", "rgba(245, 40, 145, 0.8)"],
            },
        ],
    };

    public numReportsPerSourceChartOptions: ChartConfiguration["options"] = {
        plugins: {
            legend: {
                display: true,
                position: "top",
            },
            datalabels: {
                anchor: "center",
                align: "center",
                formatter: function (value, context) {
                    return value > 0 ? value : "";
                },
                font: {
                    weight: "bold",
                },
            },
        },
    };

    public numReportsOverTimeChartData: ChartData<"bar"> = {
        labels: ["Januari", "Februari", "Mars", "April", "Maj", "Juni", "Juli", "Augusti", "September", "Oktober", "November", "December"],
        datasets: [
            {
                data: [],
                //backgroundColor: ["rgba(255, 99, 132, 0.2)"],
            },
        ],
    };

    public numReportsOverTimeChartOptions: ChartConfiguration["options"] = {
        scales: {
            x: {},
            y: {
                min: 0,
            },
        },
        plugins: {
            legend: {
                display: true,
            },
            datalabels: {
                anchor: "center",
                align: "center",
                formatter: function (value, context) {
                    return value > 0 ? value : "";
                },
                font: {
                    weight: "bold",
                },
            },
        },
    };

    public pieChartType: ChartType = "pie";
    public barChartType: ChartType = "bar";

    constructor(
        public reportService: ReportService,
        private dateService: TrvDateService
    ) {
        this.datesForm = new FormGroup<DateRangeFormGroup>({
            dateRangeMinMax: new FormControl({ from: "2024-01-01", toIsInfinite: true }),
        });

        this.datesForm.valueChanges.subscribe(val => {
            this.populateData();
        });

        this.populateData();
    }

    public populateData() {
        if (this.reportService.reportDataLoaded) {
            this._populateData();
        } else {
            this.reportService.reportDataLoadedChanged.subscribe(() => {
                this._populateData();
            });
        }
    }

    public from(): DateTime {
        return DateTime.fromISO(this.datesForm.get("dateRangeMinMax")!.value!.from!);
    }

    public to(): DateTime | null {
        return this.datesForm.get("dateRangeMinMax")!.value!.to != null ? DateTime.fromISO(this.datesForm.get("dateRangeMinMax")!.value!.to!) : null;
    }

    public _populateData() {
        this.reportService.refreshReports(true).then(() => {
            this.allReports = [...this.reportService.reports, ...this.reportService.otherUsersReports];

            this.allReports.sort((a, b) => (a.created! > b.created! ? 1 : 0));
            this.allReports = this.allReports.filter(report => {
                const to = this.to();
                let dateIsInRange = report.created! >= this.from();
                if (to != null) {
                    dateIsInRange = dateIsInRange && report.created! <= to;
                }

                return dateIsInRange;
            });

            let numDraft = 0;

            let numClosed = 0;
            let numClarificationSent = 0;
            let numOpenforClarification = 0;
            let numSent = 0;
            let numReviewed = 0;

            let numRest = 0;

            let numDataleverans = 0;
            let numPaKarta = 0;

            this.allReports.forEach(report => {

                if (report.status == ReportStatusesEnum.Draft) {
                    numDraft++;
                } else if (report.status == ReportStatusesEnum.Sent) {
                    numSent++;
                } else if (report.status == ReportStatusesEnum.ClarificationSent) {
                    numClarificationSent++;
                } else if (report.status == ReportStatusesEnum.Reviewed) {
                    numReviewed++;
                } else if (report.status == ReportStatusesEnum.OpenForClarification) {
                    numOpenforClarification++;
                } else if (report.status == ReportStatusesEnum.Closed) {
                    numClosed++;
                } else {
                    numRest++;
                }

                if (report.owner) {
                    numDataleverans++;
                } else {
                    numPaKarta++;
                }
            });

            //numRest = this.allReports.length - numClarificationSent - numClosed - numDraft;
            //this.numReportsChartData.datasets[0].data = [numRest, numClarificationSent, numClosed, numDraft];
            this.numReportsChartData.datasets[0].data = [numSent, numClarificationSent, numReviewed, numClosed, numDraft, numOpenforClarification];
            this.numReportsChartData.datasets[0].label = "Antal ärenden";

            this.numReportsPerSourceChartData.datasets[0].data = [numDataleverans, numPaKarta];

            let barDataSet: { [key: string]: number[] } = {};

            this.allReports.forEach(report => {
                const year = report.created!.year;
                const month = report.created!.month;
                if (!(year in barDataSet)) {
                    barDataSet[year] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                }

                barDataSet[year][month - 1]++;
            });

            //Transform data
            for (const [key, value] of Object.entries(barDataSet)) {
                this.numReportsOverTimeChartData.datasets = [];
                this.numReportsOverTimeChartData.datasets.push({
                    data: value,
                    label: "Antal ärenden " + key,
                });
            }

            this.statsUpdated.emit();
        });
    }

    public get dateRangeText() {
        let period = "Period: ";
        period += this.from().toISODate();
        const to = this.to();
        if (to != null) {
            period += " - " + to.toISODate();
        } else {
            period += " - Tillsvidare";
        }

        return period;
    }
}

interface DateRangeFormGroup {
    dateRangeMinMax: FormControl<DateRange | null>;
}

<div class="menu-search-container">
  <div class="c-search">
    <div class="search_options">
      <div class="search_options_container">
        <div class="search_for">Sök efter:</div>
        <div class="search_for_select">
            <trv-select [formControl]="nvdbNavigation.searchMenuState.searchObjectType" [source]="searchObjectList" valueKey="id"
              sortKey="order" [displayMethod]="displayValue" [disableClear]="true"
            ></trv-select>
        </div>
      </div>
    </div>

      @if (nvdbNavigation.searchMenuState.searchObjectType.value!.id == 'plats') {
          <trv-select
              class="mb-3"
              [multi]='true'
              [formControl]='nvdbNavigation.searchMenuState.platsSearch'
              [source]='availablePlatsSearchFilter'
              placeholder='Filtrera...'
              valueKey='value'
              [displayMethod]='displayValue'
          ></trv-select>
      }

    <div class="input-group">
      <input id="searchInput" type="search" class="form-control" [placeholder]="nvdbNavigation.searchMenuState.searchObjectType.value!.displayName"
        aria-label="Sök" aria-describedby="button-addon23" [formControl]="nvdbNavigation.searchMenuState.searchString" />
        <button class="btn btn-primary" type="button" id="button-addon23">
          <app-svg-icon [svgIcon]="searchIcon"></app-svg-icon>
          <span class="visually-hidden">Sök</span>
        </button>
      </div>

      @if (nvdbNavigation.searchMenuState.searchState == SearchState.ToShortString) {
        <div @trvCollapseVoidAnimation>
          <p class="alert alert-warning c-search__nohits">Sökordet måste innehålla minst 2 tecken</p>
        </div>
      }

      @if (nvdbNavigation.searchMenuState.searchState == SearchState.Loading) {
        <div [trv-loading]="true" @trvCollapseVoidAnimation
        style="margin-top: 1rem; background-color: #ececec"></div>
      }
      @if (nvdbNavigation.searchMenuState.searchState == SearchState.Success) {
        <div @trvCollapseVoidAnimation>
          <div style="margin-top: 0.3rem">{{ nvdbNavigation.searchMenuState.currentSearchItems.length }} resultat</div>
          <div
            style="max-height: 500px; margin-top: 0.3rem; overflow-y: auto; overflow-x: hidden; padding-right: 0.2rem">
            <div class="search-item-container">
              @for (searchItem of nvdbNavigation.searchMenuState.currentSearchItems; track searchItem; let indx = $index) {
                <app-search-item
                [searchItem]="searchItem" [first]="indx == 0"></app-search-item>
              }
            </div>
          </div>
        </div>
      }

      @if (nvdbNavigation.searchMenuState.searchState == SearchState.NoResults) {
        <div @trvCollapseVoidAnimation>
          <p class="alert alert-warning c-search__nohits">
            Inga träffar på
            <span class="nohit">"{{ nvdbNavigation.searchMenuState.searchString.value }}"</span>
          </p>
        </div>
      }
    </div>
  </div>

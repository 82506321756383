/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { legendProxy } from '../fn/map-proxy/legend-proxy';
import { LegendProxy$Params } from '../fn/map-proxy/legend-proxy';
import { mapProxy } from '../fn/map-proxy/map-proxy';
import { MapProxy$Params } from '../fn/map-proxy/map-proxy';

@Injectable({ providedIn: 'root' })
export class MapProxyApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `legendProxyMapProxy()` */
  static readonly LegendProxyMapProxyPath = '/MapProxy/mapProxyLegend/{legendUrl}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `legendProxy()` instead.
   *
   * This method doesn't expect any request body.
   */
  legendProxy$Response(params: LegendProxy$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return legendProxy(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `legendProxy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  legendProxy(params: LegendProxy$Params, context?: HttpContext): Observable<void> {
    return this.legendProxy$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `mapProxyMapProxy()` */
  static readonly MapProxyMapProxyPath = '/MapProxy/mapProxy/{mapService}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mapProxy()` instead.
   *
   * This method doesn't expect any request body.
   */
  mapProxy$Response(params: MapProxy$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return mapProxy(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mapProxy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mapProxy(params: MapProxy$Params, context?: HttpContext): Observable<void> {
    return this.mapProxy$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

<div class='c-popover-options' style='min-width: 250px;'>
    <button class='c-popover-options__btn' (click)='download.emit()'>
        <fa-icon [icon]='faFloppyDisk'></fa-icon>
        Spara fil
    </button>
    @if (editable) {
        <button class='c-popover-options__btn' (click)='editDescription.emit()'>
            <fa-icon [icon]='faEdit'></fa-icon>
            Uppdatera beskrivning
        </button>
        <button class='c-popover-options__btn' (click)='remove.emit()'>
            <fa-icon [icon]='faTrashCan'></fa-icon>
            Ta bort
        </button>
    }
</div>

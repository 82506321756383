<div class="c-login c-login--fullscreen">
    <div class="c-login__background"></div>
    <div class="c-login__brand-header">
        <a href="#">
            <img src="assets/svg/trv-logo--red-bg.svg" alt="" class="c-login__brand-header-logo" />
        </a>
    </div>
    <div class="c-login__form">
        <div class="c-login__form-inner" [trv-loading]="authService.authIsLoading">
            <div class="c-login__header">
                <h1 class="c-login__header__heading">
                    <span>Logga in</span>
                    <span class="heading-divider">
                        <fa-icon [icon]="faCircle"></fa-icon>
                    </span>
                    <span>NVDB Dataleverans</span>
                </h1>
            </div>
            <div class="d-flex flex-column align-items-center p-3">
                <button class="btn btn-dark btn-lg" (click)="login()">Logga in</button>
            </div>
            <div class="c-login__form-footer">
                <h3>För support/behörighetsfrågor eller vid fel</h3>
                <p>
                    Trafikverket användarstöd IT Tel: 010-125 10 10
                    <br />
                    För kontakt med förvaltningen: <a href="mailto:NVDBpakarta@trafikverket.se">NVDBpakarta&#64;trafikverket.se</a>
                    <br>
                    Mer om NVDB Dataleverans på
                    <a href="https://www.nvdb.se/sv" target="_blank">nvdb.se</a>
                </p>
                <div class="">
                    <!--<small *ngIf="user">Inloggad som: <b>{{user.firstName}} {{user.lastName}}</b> ({{user.username}})</small>-->
                </div>
            </div>
        </div>
    </div>
</div>

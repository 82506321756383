import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

// Unfortunately we have to have this component just to force reload the not-found page on the redirect.
// there is a bug with trv-common route dashboard-hidden so we have to reload the page
@Component({
    selector: "app-app-redirect",
    template: "",
    standalone: true,
})
export class AppRedirectComponent implements OnInit {
    constructor(private router: Router) {}

    ngOnInit() {
        this.router.navigate(["/not-found"]);
    }
}

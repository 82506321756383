@if (environment.application == "NvdbDataleverans" && authenticationService.isCurrentMinAjourhallare) {
    <main style="min-width: 50rem">

        <div class="p-page">
            <div class="container">
                <div class="c-breadcrumb">
                    <nav aria-label="Brödsmulor">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a routerLink="/home">Min sida</a>
                            </li>
                            <li class="breadcrumb-item">
                                <a routerLink="/handle-reports">Hantera ärenden</a>
                            </li>
                            @if (report) {
                                <li class="breadcrumb-item active" aria-current="page">Ärende: {{ report.name }}</li>
                            }
                        </ol>
                    </nav>
                </div>

                @if (report) {
                    <div class="header_container">
                        <h2>Ärende: {{ report.name }}</h2>

                        <div class="d-flex gap-2">

                            <button class='btn btn-primary d-flex align-items-center gap-2' (click)='shareReport()'
                                    [trvTooltip]="report.owner == null ? 'Dela ärende' : 'Dela ärendet med en dataleverans användare'"
                                    [position]='toolTipPositions'>
                                <span>
                                    <fa-icon [icon]='faShareAlt'></fa-icon>
                                </span>
                                Dela ärende
                            </button>

                            <button class='btn btn-primary d-flex align-items-center gap-2' (click)='showInViewMode()'
                                    [trvTooltip]="'Öppna ärendet i kartvyn'" [position]='toolTipPositions'>
                                <span>
                                    <fa-icon [icon]='faMap'></fa-icon>
                                </span>
                                Visa ärende i kartan
                            </button>
                            <button
                                class="btn btn-primary"
                                #handleButton
                                type="button"
                                [position]="['bottom-end']"
                                (shown)="onShowHandlePopover($event)"
                                [trvPopover]="AjourhallareHandlePopoverComponent"
                            >
                                Hantera @if (handleButton.classList.contains('active')) {
                                <fa-icon [icon]="faChevronUp"></fa-icon>
                            } @else {
                                <fa-icon [icon]="faChevronDown"></fa-icon>
                            }
                            </button>
                        </div>
                    </div>
                }
                @if (report) {
                    <app-display-report [report]="report"></app-display-report>
                } @else if (loading) {
                    <div class="no_report_container" [trv-loading]="true">
                        <h2>Hämtar ärende...</h2>
                    </div>
                } @else if (invalidReportId) {
                    <div class="no_report_container">
                        <h2>Id "{{ unformattedReportId }}" ej ett giltligt id. Id:et måste vara ett nummer.</h2>
                    </div>
                } @else {
                    <div class="no_report_container">
                        <h2>{{backendErrorMessage}}</h2>
                    </div>
                }
            </div>
        </div>
    </main>
} @else {
    <div class="d-flex justify-content-center">
        <div class="c-login__form mt-4">
            <div class="c-login__form-inner">
                <div class="c-login__header">
                    <h1 class="c-login__header__heading">
                        <span>Du är ej behörig att visa sidan!</span>
                    </h1>
                </div>
                <div class="c-login__form-content">
                    <p>Var vänlig gå tillbaka till <a href="/home">mina sidor</a>.</p>
                </div>
                <div class="c-login__error-message">
                    <div class="c-error-message">
                        <div class="c-error-message__icon">
                            <fa-icon [icon]="faCircleInfo"></fa-icon>
                        </div>
                        <div class="c-error-message__text">
                            <h2>Om du ska ha behörighet:</h2>
                            <p>Var vänlig vänta eller ladda om sidan.</p>
                        </div>
                    </div>
                </div>
                <div class="c-login__form-footer">
                    <h3>För support/behörighetsfrågor eller vid fel</h3>
                    <p>
                        Trafikverket användarstöd IT Tel: 010-125 10 10
                        <br/>
                        Mer om applikationsnamn på
                        <a href="https://www.trafikverket.se" target="_blank">trafikverket.se</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
}

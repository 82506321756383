<div chart-header class="d-flex justify-content-between">
    <div>
        <h5 class="mb-1">
            Antal ärenden per status
            <span class="badge badge-phoenix badge-phoenix-warning rounded-pill fs-9 ms-2">
                <span class="badge-label"></span>
            </span>
        </h5>
        <h6 class="text-body-tertiary">{{statsService.dateRangeText}}</h6>
    </div>
    <h4>{{statsService.allReports.length}}</h4>
</div>

<canvas baseChart [data]="statsService.numReportsChartData" [type]="statsService.barChartType" [options]="statsService.numReportsChartOptions"></canvas>

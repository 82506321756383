import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-non-dashboard',
    templateUrl: './non-dashboard.component.html',
    styleUrls: ['./non-dashboard.component.scss'],
    standalone: true,
    imports: [RouterOutlet]
})
export class NonDashboardComponent {

}

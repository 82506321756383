import { NgClass } from "@angular/common";
import { Component, ViewChild } from "@angular/core";
import { StatsService } from "@app/service/stats.service";
import { ChartType } from "chart.js";
import { BaseChartDirective } from "ng2-charts";

@Component({
    selector: "app-num-reports-per-source-chart",
    standalone: true,
    imports: [BaseChartDirective, NgClass],
    templateUrl: "./num-reports-per-source-chart.component.html",
    styleUrl: "./num-reports-per-source-chart.component.scss",
})
export class NumReportsPerSourceChartComponent {
    @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

    public currentChartType:ChartType = "bar"

    constructor(public statsService: StatsService) {
        this.statsService.statsUpdated.subscribe(() => {
            this.chart?.update();
        });
    }

    public changeToPie() {
      this.currentChartType = "pie";
      this.chart?.update();
    }

    public changeToBar() {
      this.currentChartType = "bar";
      this.chart?.update();
    }

}


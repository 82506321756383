@if (environment.application == "NvdbDataleverans" && authenticationService.isCurrentMinAjourhallare) {
    <main  style="min-width: 940px">
        <div class='p-page'>
            <div class='container'>
                <div class='c-breadcrumb'>
                    <nav aria-label='Brödsmulor'>
                        <ol class='breadcrumb'>
                            <li class='breadcrumb-item'>
                                <a routerLink='/home'>Min sida</a>
                            </li>
                            <li class='breadcrumb-item active' aria-current='page'>Hantera ärenden</li>
                        </ol>
                    </nav>
                </div>
                <h1>Hantera ärenden</h1>
                <div class='table_container'>
                    <app-reports-table
                        [reportGroupIds]='reportGroupIds ?? []'
                        [showUsersReportsElseAll]='false'
                        [trv-loading]='reportGroupIds == null'
                        [ajourhallare]='true'></app-reports-table>
                </div>
            </div>
        </div>
    </main>
} @else {
    <div class="d-flex justify-content-center">
        <div class="c-login__form mt-4">
            <div class="c-login__form-inner">
                <div class="c-login__header">
                    <h1 class="c-login__header__heading">
                        <span>Du är ej behörig att visa sidan!</span>
                    </h1>
                </div>
                <div class="c-login__form-content">
                    <p>Var vänlig gå tillbaka till <a href="/home">mina sidor</a>.</p>
                </div>
                <div class="c-login__error-message">
                    <div class="c-error-message">
                        <div class="c-error-message__icon">
                            <fa-icon [icon]="faCircleInfo"></fa-icon>
                        </div>
                        <div class="c-error-message__text">
                            <h2>Om du ska ha behörighet:</h2>
                            <p>Var vänlig vänta eller ladda om sidan.</p>
                        </div>
                    </div>
                </div>
                <div class="c-login__form-footer">
                    <h3>För support/behörighetsfrågor eller vid fel</h3>
                    <p>
                        Trafikverket användarstöd IT Tel: 010-125 10 10
                        <br/>
                        Mer om applikationsnamn på
                        <a href="https://www.trafikverket.se" target="_blank">trafikverket.se</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
}

<div [trv-loading]="loading">
  <div>
    <trv-form [validationErrors]="validationErrorMap">
      <trv-form-field [labelAbove]="true" label="Namn på ärende" [labelGridColumns]="3" [control]="name">
        <input type="text" class="form-control" [formControl]="name" #inputRef />
      </trv-form-field>
    </trv-form>
    {{ inputRef.focus() }}
  </div>
  <div class="c-info__footer">
    @if (showCloseButton) {
      <button type="button" class="btn btn-link" aria-label="Stäng" (click)="close()">Avbryt</button>
    }
    <button type="button" class="btn btn-primary" aria-label="Lägg till" (click)="submit()">
      @if (!reportGroupToEdit) {
        Skapa ärende
      }
      @if (reportGroupToEdit && reportGroupToEdit.status == ReportStatusesEnum.Draft) {
        Uppdatera ärende
      }
      @if (reportGroupToEdit && reportGroupToEdit.status != ReportStatusesEnum.Draft) {
        Komplettera ärende
      }
    </button>
  </div>
</div>

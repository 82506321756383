<div class='my-options-popover'>
    <div class='logged-in-information'>
        <div class='title'> {{ authenticationService.userName }}</div>
        {{ authenticationService.maxRole }}
        @if (authenticationService.maxRole == 'Admin') {
            <div>
                <div class='title'>Du agerar som</div>
                <trv-select
                    #selectRef
                    [formControl]='nvdbNavigationService.userRoleAsForm'
                    placeholder='Välj ett objekt'
                    [source]='nvdbNavigationService.userRoles'
                    valueKey='id'
                    sortKey='userRole'
                    [disableClear]='true'
                    [enableSearch]='false'
                    [displayMethod]='nvdbNavigationService.displayUserroleObject'
                ></trv-select>
            </div>
        }
        <div class='title'>Rapportera som</div>
        <trv-select
            #selectRef2
            [formControl]='nvdbNavigationService.reportAsForm'
            placeholder='Välj ett objekt'
            [source]='nvdbNavigationService.reportAsList'
            valueKey='id'
            sortKey='name'
            [disableClear]='true'
            [enableSearch]='false'
            [displayMethod]='nvdbNavigationService.displayReportAsObject'
        ></trv-select>
    </div>
    <div class='navigation'>
        <a class='link' routerLink='/home'>
            <fa-icon [icon]='faUser'></fa-icon>
            <span class='link-text'>Mina sidor</span>
        </a>
        <a class='link' (click)='logout()'>
            <fa-icon [icon]='faSignOut'></fa-icon>
            <span class='link-text'>Logga ut</span>
        </a>
    </div>
</div>

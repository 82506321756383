@if (trvMapExtensionService.trvMapExtension.currentInfoLoading) {
    <div style='padding: 1rem'>
        <button (click)='trvMapExtensionService.trvMapExtension.cancelInfoClickSubscription()'
                class='btn btn-outline-danger'>
            Avbryt sökning
        </button>
    </div>
}
@if (trvMapExtensionService.trvMapExtension.currentInfoLoading) {
    <div
        style='width: 100%; height: 20rem' [trv-loading]='true'></div>
}
@if (!trvMapExtensionService.trvMapExtension.currentInfoLoading) {
    <div>
        @if (!trvMapExtensionService.trvMapExtension.hasSearchedForInfoObjects) {
            <div style='padding: 1rem'>
                Klicka på en företeelse i kartan från en tänd dataprodukt för att visa information om den. Om ingen
                dataprodukt är tänd kan du istället klicka på en väglänk för att få information om den.
            </div>
        } @else if (trvMapExtensionService.trvMapExtension.currentInfoObjects.length == 0) {
            <div style='padding: 1rem'
            >
                Ingen information finns i vald position.
            </div>
        } @else {
            <div>
                <div class='c-dataselect'>
                    @if (!(trvMapExtensionService.trvMapExtension.currentInfoClickMetaKey == 'VeryUniqueKey:Utvalda värden i aktuell punkt')) {
                        <div
                        >
                            <span style='font-weight: 600'>Dataprodukt:</span>
                            <trv-select
                                [formControl]='navigationService.infoClickState.dataslagType'
                                placeholder='Välj ett objekt'
                                [source]='navigationService.infoClickState.metaKeys'
                                [displayMethod]='displayObjectMetaKey'
                                [disableClear]='true'
                                (optionSelected)='metaKeySelected($event)'
                            ></trv-select>
                        </div>
                    }
                    @if (trvMapExtensionService.trvMapExtension.currentInfoClickMetaKey == 'VeryUniqueKey:Utvalda värden i aktuell punkt') {
                        <div
                            style='font-size: 16px; font-weight: 600'
                        >
                            Utvalda värden i aktuell punkt
                        </div>
                    }
                    <div style='padding-bottom: 1rem; display: flex; align-items: center'>
                        <div>
                            Antal träffar:
                            {{ trvMapExtensionService.trvMapExtension.currentInfoObjects.length }}
                        </div>
                        <div style='margin-left: auto; display: flex; align-items: center'>
                            <span style='margin-right: 0.5rem'>Auto-zoom:</span>
                            <form [formGroup]='autoZoomGroup'>
                                <trv-toggle-switch formControlName='toggleSimple'
                                ></trv-toggle-switch>
                            </form>
                        </div>
                    </div>
                    <div class='c-dataselect__header'>
                        <h2 class='visually-hidden'>Bläddra</h2>
                        <div class='btn-group' role='group' aria-label='First group'>
                            @if (showFirst) {
                                <button
                                    style='max-width: 28px; display: flex; justify-content: center'
                                    (click)='changeInfoObj(0)'
                                    class='btn btn-outline-primary'
                                    [disabled]='this.trvMapExtensionService.trvMapExtension.currentInfoObject === 0'
                                >
                                    {{ 1 }}
                                </button>
                            }
                            @if (showFirst) {
                                <button
                                    style='max-width: 28px; display: flex; justify-content: center'
                                    disabled
                                    class='btn btn-outline-primary'
                                >
                                    ...
                                </button>
                            }
                            @for (page of navigationService.infoClickState.pagination?.pages ?? []; track page) {
                                <button
                                    style='max-width: 28px; display: flex; justify-content: center'
                                    (click)='changeInfoObj(page - 1)'
                                    [class]='getInfoObjClass(page - 1)'
                                >
                                    {{ page }}
                                </button>
                            }
                            @if (showLast) {
                                <button
                                    style='max-width: 28px; display: flex; justify-content: center'
                                    disabled
                                    class='btn btn-outline-primary'
                                >
                                    ...
                                </button>
                            }
                            @if (showLast) {
                                <button
                                    style='max-width: 28px; display: flex; justify-content: center'
                                    (click)='changeInfoObj(this.trvMapExtensionService.trvMapExtension.currentInfoObjects.length - 1)'
                                    class='btn btn-outline-primary'
                                    [disabled]='
                  this.trvMapExtensionService.trvMapExtension.currentInfoObject ===
                  this.trvMapExtensionService.trvMapExtension.currentInfoObjects.length - 1
                  '
                                >
                                    {{ this.trvMapExtensionService.trvMapExtension.currentInfoObjects.length }}
                                </button>
                            }
                        </div>
                        <div style='margin-left: auto'>
                            
                            @if(trvMapExtensionService.trvMapExtension.showBackToInfoClickInCoord) {
                                <button
                                    class='btn btn-outline-secondary info_btn me-1'
                                    (click)='clearDataProdukterAndMakeLastInfoclick()'
                                    trvTooltip='Gå tillbaka till utvalda värden i aktuell punkt'
                                    [position]='toolTipPosition'
                                >
                                    <app-svg-icon [svgIcon]='backwardsIcon'></app-svg-icon>
                                    <span class='visually-hidden'>Gå tillbaka</span>
                                </button>
                            }
                            
                            @if (environment.application == 'NvdbDataleverans') {
                                <button
                                    class='btn btn-outline-secondary info_btn'
                                    (click)='reportOnInfoObject()'
                                    trvTooltip='Ändra objekt'
                                    [position]='toolTipPosition'
                                >
                                    <app-svg-icon [svgIcon]='penIcon'></app-svg-icon>
                                    <span class='visually-hidden'>Ändra objekt</span>
                                </button>
                            }
                            <button
                                class='btn btn-outline-secondary info_btn'
                                (click)='zoomToInfoObj()'
                                trvTooltip='Zooma till position'
                                [position]='toolTipPosition'
                            >
                                <app-svg-icon [svgIcon]='searchIconPlus'></app-svg-icon>
                                <span class='visually-hidden'>Zooma till</span>
                            </button>
                        </div>
                    </div>
                    <div class='c-dataselect__grid'>
                        <div class='c-dataselect__grid__header'>
                            <h3
                                (click)="infoAboutLayer(trvMapExtensionService.trvMapExtension.currentDataSlagLayer)"
                                [trvTooltip]="'Visa information om dataprodukten'"
                                [position]="toolTipPositions"
                            >
                                <a style='cursor: pointer'>
                  <span style='margin-right: 0.5rem'><app-svg-icon
                      [svgIcon]='circleInfoIcon'></app-svg-icon></span>

                  {{ trvMapExtensionService.trvMapExtension.currentInfoClickName }}
                </a>
              </h3>
              <!--<span style="width: 50%">
              Datauttag: {{ trvMapExtensionService.trvMapExtension.currentInfoclickDate.toISOString().slice(0, 10) }}
            </span> -->
          </div>
          @if (
            trvMapExtensionService.trvMapExtension.currentInfoObjects[
            trvMapExtensionService.trvMapExtension.currentInfoObject
            ]
            ) {
            <dl
              >
              @for (
                field of getObjectKeys(
                trvMapExtensionService.trvMapExtension.currentInfoObjects[
                trvMapExtensionService.trvMapExtension.currentInfoObject
                ]
                )
                ; track
                field) {
                @if (
                    showRow(field)

                  ) {
                    @if (isFieldMetaKey(field)) {
                        <dt><a href="javascript:void(0)" (click)="makeInfoClickOnMetaKey(field)" [trvTooltip]="'Tänd lagret och se mer information'" [position]="toolTipPositions">{{ displayValueName(field) }}</a></dt>
                    } @else {
                        <dt>{{ displayValueName(field) }}</dt>
                    }
                  <dd>
                    @if (
                      isLink(
                      trvMapExtensionService.trvMapExtension.currentInfoObjects[
                      trvMapExtensionService.trvMapExtension.currentInfoObject
                      ][field]
                      )
                      ) {
                      <a
                        [href]='
                        getUrl(
                        trvMapExtensionService.trvMapExtension.currentInfoObjects[
                        trvMapExtensionService.trvMapExtension.currentInfoObject
                        ][field]
                        )
                        '
                                                    target='_blank'
                                                >
                                                    {{
                                                        trvMapExtensionService.trvMapExtension.currentInfoObjects[
                                                            trvMapExtensionService.trvMapExtension.currentInfoObject
                                                            ][field]
                                                    }}
                                                </a>
                                            }
                                            @if (!isLink(
                                                trvMapExtensionService.trvMapExtension.currentInfoObjects[
                                                    trvMapExtensionService.trvMapExtension.currentInfoObject
                                                    ][field]
                                            )) {
                                                <span
                                                >
                        {{
                              formatValue(
                        trvMapExtensionService.trvMapExtension.currentInfoObjects[
                        trvMapExtensionService.trvMapExtension.currentInfoObject
                        ][field], trvMapExtensionService.trvMapExtension.getLayerNameFromMetakey(trvMapExtensionService.trvMapExtension.currentInfoClickMetaKey), field)
                        }}
                      </span>
                                            }
                                        </dd>
                                    }
                                }
                            </dl>
                        }
                    </div>
                </div>
            </div>
        }
    </div>
}

<div style='flex-grow: 1; height: 100%' #container cdkTrapFocus>
    @if (!isEditable) {
        <div class='report-item-control'>

            @if (isFromExternAnvandare) {
                <label class='col-form-label form-field-label label-bold'>Typ av fel</label>
                <!-- OMG den här är omöjlig att centrera vertikalt :( -->
                <span (click)='felTypInfo()' style='margin-left: 1rem; cursor: pointer; fill: #1e5c88'><app-svg-icon
                    [svgIcon]='circleInfoIcon'></app-svg-icon></span>
                <div class='value'>
                    {{ formatDisplayValue(reportItem?.deliveryType?.description) }}
                </div>
            } @else {
                <label class='col-form-label form-field-label label-bold'>Typ av dataleverans</label>
                <!-- OMG den här är omöjlig att centrera vertikalt :( -->
                <span (click)='leveransTypInfo()' style='margin-left: 1rem; cursor: pointer; fill: #1e5c88'><app-svg-icon
                    [svgIcon]='circleInfoIcon'></app-svg-icon></span>
                <div class='value'>
                    {{ formatDisplayValue(reportItem?.deliveryType?.description) }}
                </div>
            }
        </div>

        @if (reportItem && DELIVERY_TYPE_VALID_FROM_DESCRIPTION[reportItem.deliveryType.deliveryType] && !isFromExternAnvandare) {
            <div class='report-item-control'>
                <label class='col-form-label form-field-label label-bold'>
                    {{ DELIVERY_TYPE_VALID_FROM_DESCRIPTION[reportItem.deliveryType.deliveryType] }}
                </label>
                <span (click)='dateTypInfo()' style='margin-left: 1rem; cursor: pointer; fill: #1e5c88'><app-svg-icon
                    [svgIcon]='circleInfoIcon'></app-svg-icon></span>
                <div class='value'>
                    {{ reportItem.validFrom ? reportItem.validFrom.toFormat("yyyy-MM-dd") : "-" }}
                </div>
            </div>
        }

        <div class='report-item-control'>
            <label class='col-form-label form-field-label label-bold'>Beskrivning</label>
            <div class='value' style='word-wrap: break-word'>
                {{ formatDisplayValue(reportItem?.description) }}
            </div>
        </div>

        @if (!isFromExternAnvandare) {
            <div class='report-item-control'>
                <label class='col-form-label form-field-label label-bold'>Dataprodukter</label>
            </div>
            @if ((reportItem?.dataslag ?? []).length > 0) {
                <div class='dataslag_container'>
                    @for (dataslag of getSortedDataslag(); track dataslag) {
                        @if (dataslag.fields.length > 0) {
                            <div
                                (click)='toggleDataslagOpen(dataslag.gid)'
                                class='dataslag_header'
                                [ngClass]="{ 'as-table': styleAsTableElseSingle,open: isDataslagOpen(dataslag.gid) }"
                            >
                                <app-svg-icon [svgIcon]='upIcon' *ngIf='isDataslagOpen(dataslag.gid)'></app-svg-icon>
                                <app-svg-icon [svgIcon]='downIcon' *ngIf='!isDataslagOpen(dataslag.gid)'></app-svg-icon>
                                <span class='c-report__list__group__header__name no-select'>
                        {{ formatDisplayValue(dataslag.name) }}
                    </span>
                            </div>
                        }

                        <div [@groupExpand]="isDataslagOpen(dataslag.gid) ? 'expanded' : 'collapsed'"
                             class='dataslag_fields_container values_not_editable'>
                            <ng-container *ngFor='let field of dataslag.fields; let j = index'>
                                <div class='key'>
                                    {{ field.attributeName + ':' }}
                                </div>
                                <div class='value'>
                                    {{ formatDisplayValue($any(field.value)?.description ?? field.value) }}
                                </div>
                            </ng-container>
                        </div>
                    }
                </div>
            } @else {
                <div class='value'>
                    Inga dataprodukter tillagda
                </div>
            }
            <div class='report-item-control'>
                <label class='col-form-label form-field-label label-bold'>Bifogade filer</label>
                @if ((reportItem?.files?.length ?? -1) > 0) {
                    <ng-container
                        *ngTemplateOutlet='fileContainer; context: {files: reportItem?.files ?? [], editable: false}'></ng-container>
                } @else {
                    <div class='value'>
                        Inga filer tillagda
                    </div>
                }
            </div>
        }
    } @else {
        <div *ngIf='form' [formGroup]='form'>
            <div class='report-item-control'>
                <label class='col-form-label form-field-label label-bold'>Typ av dataleverans*</label>
                <span (click)='leveransTypInfo()' style='margin-left: 1rem; cursor: pointer; fill: #1e5c88'><app-svg-icon
                    [svgIcon]='circleInfoIcon'></app-svg-icon></span>
                <div
                >
                    <trv-select
                        appAutofocus
                        formControlName='deliveryType'
                        placeholder='Välj typ av dataleverans'
                        [source]='availableDeliveryTypes'
                        valueKey='deliveryType'
                        sortKey='deliveryType'
                        [enableSearch]='false'
                        [displayMethod]='displayObjectName'
                        [disableClear]='true'
                    >
                    </trv-select>
                    <div *ngIf="form.controls.deliveryType.errors?.['required']" @showErrorMessage
                         class='invalid-feedback'>Måste anges.
                    </div>
                </div>
            </div>


            @if (form.value.deliveryType && DELIVERY_TYPE_VALID_FROM_DESCRIPTION.hasOwnProperty(form.value.deliveryType.deliveryType)) {
                <div class='report-item-control'>
                    <label class='col-form-label form-field-label label-bold'
                    >
                        {{ $any(DELIVERY_TYPE_VALID_FROM_DESCRIPTION)[form.value.deliveryType.deliveryType] }}*
                    </label>
                    <span (click)='dateTypInfo()' style='margin-left: 1rem; cursor: pointer; fill: #1e5c88'><app-svg-icon
                        [svgIcon]='circleInfoIcon'></app-svg-icon></span>
                    <div>
                        <div class='d-flex'>
                            <trv-date-picker [skipClear]='true' formControlName='validFrom'
                            ></trv-date-picker>
                            <button class='btn btn-primary rounded-0'
                                    [ngClass]='isFormDateToday() ?  "set_date_button_active": "set_date_button_inactive"'
                                    (click)='form.controls.validFrom.setValue(DateTime.now().toISO())'>Idag
                            </button>
                        </div>
                        @if (form.controls.validFrom.errors?.['required'] && form.controls.validFrom.dirty) {
                            <div @showErrorMessage class='invalid-feedback d-block'>Måste anges.</div>
                        }
                    </div>

                </div>
            }

            <div class='report-item-control'>
                <label class='col-form-label form-field-label label-bold'>Ärende*</label>
                <trv-select
                    formControlName='report'
                    placeholder='Välj ett ärende'
                    valueKey='id'
                    sortKey='name'
                    [source]='availableReports'
                    [searchMethod]='searchMethodDeliveryType'
                    [displayMethod]='displayObjectName'
                    [enableSearch]='true'
                    [disableClear]='true'
                    (optionSelected)='onReportGroupChange($event)'
                ></trv-select>
            </div>
            <div class='report-item-control'>
                <label class='col-form-label form-field-label label-bold'>Beskrivning*</label>
                <div>
            <textarea class='form-control' [formControl]='description' placeholder='Beskrivning'
                      [rows]='descriptionInputRowCount'
            ></textarea>
                    @if (form.controls.description.errors?.['required'] != null) {
                        <div @showErrorMessage
                             class='invalid-feedback'>Måste anges.
                        </div>
                    } @else if (form.controls.description.errors?.['maxlength'] != null) {
                        <div @showErrorMessage class='invalid-feedback'>Max 600 tecken.</div>
                    }
                </div>
            </div>


            <div class='report-item-control' *ngIf='getRemainingOptionalDataslag().length > 0' class='mb-2'>
                <label class='col-form-label form-field-label label-bold'>Dataprodukter</label>
                <div class='dataslag_to_add_container'>
                    <trv-select
                        style='flex-grow: 1'
                        [formControl]='dataslagToAdd'
                        placeholder='Välj en dataprodukt att lägga till'
                        [source]='getRemainingOptionalDataslag()'
                        valueKey='gid'
                        sortKey='name'
                        [enableSearch]='true'
                        [displayMethod]='displayObjectName'
                        [disableClear]='true'
                    ></trv-select>

                    <button class='btn btn-primary' (click)='onAddDataslagClick(dataslagToAdd.value!)'
                            [disabled]='!dataslagToAdd.value'>
                        Lägg till
                    </button>
                </div>
            </div>
            <div class='dataslag_container' *ngIf='dataslag.controls.length > 0'>
                <div formArrayName='dataslag' *ngFor='let dataslagGroup of getSortedDataslagControls(); let i = index'>
                    <div
                        (click)='toggleDataslagOpen(dataslagGroup.value.gid ?? -1)'
                        class='dataslag_header'
                        [ngClass]='{
    open: isDataslagOpen(dataslagGroup.value.gid ?? -1)
    }'
                    >
                        <div style='display: flex; align-items: center'>
                            <app-svg-icon class='icon' [svgIcon]='upIcon'
                                          *ngIf='isDataslagOpen(dataslagGroup.value.gid ?? -1)'></app-svg-icon>
                            <app-svg-icon class='icon' [svgIcon]='downIcon'
                                          *ngIf='!isDataslagOpen(dataslagGroup.value.gid ?? -1)'></app-svg-icon>
                            <span class='c-report__list__group__header__name no-select'>
    {{ dataslagGroup.value.name }}
    </span>
                        </div>
                        <div
                            (click)='removeDataslag(dataslagGroup.value.gid ?? -1)'
                            (click)='$event.stopPropagation()'
                            *ngIf='!dataslagGroup.value.required'
                            style='color: red'
                        >
                            <app-svg-icon
                                trvTooltip='Ta bort dataprodukt'
                                [position]='toolTipPositions'
                                class='icon trashcan'
                                [svgIcon]='trashIcon'
                            ></app-svg-icon>
                        </div>
                    </div>
                    <div
                        [formGroupName]='i'
                        [@groupExpand]="isDataslagOpen(dataslagGroup.value.gid ?? -1) ? 'expanded' : 'collapsed'"
                        class='dataslag_fields_container'
                    >
                        <ng-container formArrayName='fields'
                                      *ngFor='let field of getDataslagFields(dataslagGroup).controls; let j = index'>
                            <div class='key' [formGroupName]='j'>
                                {{ field.value.name + (field.value.required ? '*' : '') }}
                            </div>
                            <div class='dataslag_field_input' id='{{ dataslagGroup.value.gid }}' [formGroupName]='j'>
                                <trv-select
                                    *ngIf='field.value.valueType === DataslagValueTypesEnum.Enum; else notEnum'
                                    class='value_container dataslag_control'
                                    formControlName='value'
                                    placeholder='Välj ett objekt'
                                    [source]='field.value.options ?? []'
                                    valueKey='id'
                                    sortKey='description'
                                    [enableSearch]='false'
                                    [displayMethod]='displayObjectName'
                                    [disableClear]='true'
                                ></trv-select>
                                <ng-template #notEnum>
                                    <input
                                        *ngIf='field.value.valueType === DataslagValueTypesEnum.String; else notEnumNorString'
                                        formControlName='value'
                                        type='text'
                                        class='form-control value dataslag_control'
                                        placeholder=''
                                    />
                                </ng-template>
                                <ng-template #notEnumNorString>
                                    <input formControlName='value' type='number'
                                           class='form-control value dataslag_control'/>
                                </ng-template>
                                <div *ngIf="$any(field).controls.value.errors?.['required']" @showErrorMessage
                                     class='invalid-feedback'>
                                    Måste anges.
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class='report-item-control'>
                <label class='col-form-label form-field-label label-bold'>Bifogade filer</label>
                <div class='value'>
                    <div class='dropzone' fileDragDrop (filesChangeEmiter)='onFileSelected($event)'>
                        <input class='form-control' type='file' name='file' id='file'
                               (change)='onFileSelected($any($event).target.files)'
                               multiple>
                        <ng-container
                            *ngTemplateOutlet='fileContainer; context: {files: form.value.files, editable: true}'></ng-container>
                    </div>
                </div>
            </div>
        </div>
    }
</div>

<!-- This is almost identical for both editable and not editable -->
<ng-template #fileContainer let-files='files' let-editable='editable'>
    <div class='file_container'>
        @for (file of files; track file) {
            <div class='file_item'>
                <div class='file_item_left'>
                    <div class='thumbnail file_icon'>
                        <fa-icon [icon]='faFileLines'></fa-icon>
                    </div>

                    <div class='text_container'>
                        <p>
                            {{ file.fileName }}
                        </p>

                        @if ({key: file.idOrTempId, map: reportService.editableFiles} | mapIncludesKeyPipe) {
                            <div class='file_description_container'>
                                <input
                                    appAutofocus
                                    type='text'
                                    placeholder='Filbeskrivning'
                                    class='form-control group_name_input'
                                    [value]='file.description'
                                    (change)="fileDescriptionChange(file.idOrTempId, $event)"
                                />
                                <button
                                    class='btn btn-secondary btn-sm'
                                    (click)='cancelFileDescriptionEdit(file.idOrTempId)'
                                    [position]="['bottom']"
                                    trvTooltip='Avbryt'
                                >
                                    <app-svg-icon [svgIcon]='svgCross'></app-svg-icon>
                                </button>
                                <button
                                    class='btn btn-primary btn-sm'
                                    (click)='saveFileDescriptions([file.idOrTempId])'
                                    [position]="['bottom']"
                                    trvTooltip='Spara'
                                >
                                    <app-svg-icon [svgIcon]='svgCheck'></app-svg-icon>
                                </button>
                            </div>
                        } @else {
                            <div class="file_description_container">
                                <div
                                    class="file_description_text"
                                    [ngClass]='{"editable_file_description": editable, "file_description_empty": file.description === ""}'
                                    (click)='editable && editFileDescription(file)'>
                                    {{ file.description !== "" ? file.description : "Ingen beskrivning angiven" }}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div class='file_item_right'>
                    <button class='btn btn-link c-report__list__group__header__menu file_handle'
                            [trvPopover]='FileOptionsPopoverComponent' [position]='popoverPosition'
                            aria-label="Fil alternativ"
                            (shown)='onShowFileOptionsComponent(editable, $event,file)'>
                        <fa-icon [icon]='faEllipsisV'></fa-icon>
                    </button>
                </div>
            </div>
        }
    </div>
</ng-template>

<div [trv-loading]='loading' style='height:100%'>
    <div class='modal-header'>
        <h4 class='modal-title' id='modal-title'>Skicka in {{ sendMultipleComponent?.allReports?.length ?? 0 }}
            ärenden</h4>
        <button type='button' class='btn-close' aria-describedby='modal-title' (click)='dismiss()'>
        </button>
    </div>

    <div class='modal-body'>
        <app-reports-table [reportGroupIds]='reportGroupIds' [isSendReportTable]='true'></app-reports-table>
    </div>

    <div class='modal-footer'>
        <div class='footer_container'>
            <div class="footer_left_container">
                @if (hasChanges()) {
                    <button type='button' class='btn btn-primary' (click)='saveAllChanges()'
                            trvTooltip='Spara alla ändringar'>
                        Spara alla ändringar
                    </button>
                    <div class="d-flex align-items-center ms-3 gap-2">
                        <div class="change_dot"></div>
                        Har förändrats
                    </div>
                }
            </div>

            <div class='footer_right_container'>
                <button type='button' class='btn btn-outline-primary me-2' (click)='close()' trvTooltip='Avbryt'>
                    Avbryt
                </button>
                <button type='button' class='btn btn-primary' (click)='send()' trvTooltip='Skicka in ärenden'>
                    Skicka in
                </button>
            </div>
        </div>
    </div>
</div>

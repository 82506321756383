import {Component, Inject, Input, OnInit} from '@angular/core';
import {TrvGlobalMessagesService, TrvModalRef, TrvModalService} from "trv-ng-common";
import {TrvNgMapService} from "@trafikverket/trv-ng-map";
import {NvdbNavigationService} from "@app/service/nvdb-navigation.service";
import {ModalReturnParams} from "@components/map-menu/layer-section/layer-info-modal/layer-info-modal.component";
import {faLink, faShareAlt} from "@fortawesome/free-solid-svg-icons";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {ReportApi} from "@_api/dataleverans/services/report-api";
import {firstValueFrom} from "rxjs";
import {svgCircleInfo} from "@shared/utils/svg-helper-data";
import {SvgIconComponent} from "@components/utils/svg-icon/svg-icon.component";
import {TrvModalConfig} from "trv-ng-common/lib/components/modal/modal-config";
import {leveransInfoMessage} from "@shared/constants";
import {Report, ReportService} from "@app/service/report.service";
import {ReportStatusesEnum} from "@_api/pakarta/models";

@Component({
    selector: 'app-share-report-page-modal',
    standalone: true,
    imports: [
        FaIconComponent,
        SvgIconComponent
    ],
    templateUrl: './share-report-page-modal.component.html',
    styleUrl: './share-report-page-modal.component.scss'
})
export class ShareReportPageModalComponent implements OnInit {
    @Input() report!: Report;

    url!: string;

    constructor(
        @Inject(ReportService) private reportService: ReportService,
        @Inject(TrvModalService) private trvModalService: TrvModalService,
        @Inject(TrvGlobalMessagesService)
        private globalMessagesService: TrvGlobalMessagesService,
        public modalRef: TrvModalRef<ShareReportPageModalComponent>, public trvMapService: TrvNgMapService, public nvdbNavigationService: NvdbNavigationService,
        private reportApiClient: ReportApi,
    ) {
    }

    ngOnInit() {
        this.url = `${location.origin}/view-reports/${this.report.uid}`
    }

    protected readonly faShareAlt = faShareAlt;
    protected readonly faLink = faLink;

    async unlockReportAndCopyLink() {
        try {
            await firstValueFrom(this.reportApiClient.unlockReport({reportId: this.report.id}));
            await this.reportService.refreshReports(this.report.status == ReportStatusesEnum.Closed);
        } catch (e) {
            console.log(e)
            this.globalMessagesService.error("Lyckades inte låsa upp ärendet")
            return;
        }

        try {
            await navigator.clipboard.writeText(this.url)
            this.globalMessagesService.success(this.report.owner == null ? "Kopierade länken" : "Kopierade länken och låste upp ärendet")
        } catch (e) {
            console.log(e)
            this.globalMessagesService.error("Lyckades inte kopiera urlen")
        }
    }

    protected readonly circleInfoIcon = svgCircleInfo;

    unlockReportInformation() {
        const config: TrvModalConfig = {
            disposeOnBackdropClick: true,
            size: "md"
        }
        if (this.nvdbNavigationService.isMobileDevice) config.size = 'fullscreen'

        this.trvModalService.info("Information om dela ärende", "Ärenden är låsta till användare som standard. För att kunna dela ärendet måste ärendet låsas upp. Det kommer då vara möjligt för alla användare att titta på ärendet med dess UID.", config);
    }
}

<div class='menu-backdrop'></div>

@if (nvdbNavigation.isMobileDevice) {
    <div class='mobile-menu' #mobileMenu [style.height]="menuHeight">
        @if (environment.application == "NvdbPåKarta" && reportAvikelseService.reportAvikelseItemToCreateOrEdit) {
            <app-report-avikelse-item-edit-modal [style.height]="'100%'"></app-report-avikelse-item-edit-modal>
        } @else if (environment.application == "NvdbDataleverans" && reportService.itemToCreateOrEdit) {
            <app-report-item-edit-modal [style.height]="'100%'"></app-report-item-edit-modal>
        } @else {
            <ng-container *ngTemplateOutlet="menu_content"></ng-container>
        }
    </div>
} @else if (nvdbNavigation.sideBarVisible && !nvdbNavigation.hideHamburgerMenu && !trvMapService.trvMap?.showStreetView) {
    <div class='sidebar-sticky sidebar-full' [style.width.px]="nvdbNavigation.sideBarWidth" @trvCollapseVoidAnimation
         #sidebar>
        <ng-container *ngTemplateOutlet="menu_content"></ng-container>
        <div class='resize_handle' #resizeHandle (mousedown)='onMouseDown($event)'></div>
    </div>
}

<ng-template #menu_content>
    @if (!nvdbNavigation.isMobileDevice) {
        <div class='menu_header has-back-nav'>
            @if (nvdbNavigation.VIEWMODE) {
                <div class="w-100">
                    <div class="d-flex align-items-center mb-2">
                        <div class='menu_header__title'>
                            {{ nvdbNavigation.siteTitle }}
                        </div>

                        <div class='menu_header__handle left'>
                            <button class='btn btn-link' (click)='menuToggle()'
                                    aria-label="Stäng meny"
                            >
                                <app-svg-icon [svgIcon]='crossIcon'></app-svg-icon>
                            </button>
                        </div>
                    </div>
                    <div class="d-flex gap-3 justify-content-between">
                        <div>
                            <b style="font-size: 1.5rem">Visningsläge</b>
                            <span (click)='visningslageInfo()'
                                  style='margin-left: 0.5rem; cursor: pointer; fill: #1e5c88'>

                    <fa-icon [icon]='faInfoCircle'
                             style='color: #1e5c88; font-size: 20px'
                    ></fa-icon>
                                    </span>
                        </div>
                        <button class='btn btn-danger' type='button' (click)="exitViewMode()">
                            Avsluta visningsläge
                        </button>

                    </div>
                </div>
            } @else {
                <div class='menu_header__title'>
                    {{ nvdbNavigation.siteTitle }}
                </div>

                <div class='menu_header__handle left'>
                    <button class='btn btn-link' (click)='menuToggle()'
                            aria-label="Stäng meny"
                    >
                        <app-svg-icon [svgIcon]='crossIcon'></app-svg-icon>
                    </button>
                </div>
            }
        </div>
    }

    <div class='menu-tab'
         [ngClass]="{'menu-tab-mobile': nvdbNavigation.isMobileDevice, 'mobile-fullscreen': menuAlmostFullHeight }">

        <div
            [ngClass]="{'draggable_pill': nvdbNavigation.isMobileDevice && !menuAlmostFullHeight }"
        >
        </div>
        <div
            (click)="nvdbNavigation.currentMobileMenuState.setValue('Medium')"
            [ngClass]="nvdbNavigation.isMobileDevice && menuAlmostFullHeight ? 'show_arrow': 'hide_arrow'"
        >
            <fa-icon [icon]='faAngleDown'></fa-icon>
        </div>
        <div class='item-parent'
             [ngClass]="{'item-parent-mobile': nvdbNavigation.isMobileDevice}"
        >
            <div class='item' (click)='changeNavigationState(NavigationState.MainMenu)' [ngClass]='{
            active: nvdbNavigation.navigationState == NavigationState.MainMenu
            }'>
                Start
                <div class='underline'></div>
            </div> 
            @if (environment.application == 'NvdbDataleverans' || environment.application == 'NvdbPåKarta') {
            <div class='item' (click)='clickReportTab()' [ngClass]='{
            active: nvdbNavigation.navigationState == NavigationState.Report
            }'>
                Rapportera
                <div class='underline'></div>
            </div>
            }
            <div class='item' (click)='changeNavigationState(NavigationState.InfoClick)' [ngClass]='{
            active: nvdbNavigation.navigationState == NavigationState.InfoClick
            }'>
                Infoklick
                <div class='underline'></div>
            </div>
            <div class='item' (click)='changeNavigationState(NavigationState.PrintMap)' [ngClass]='{
            active: nvdbNavigation.navigationState == NavigationState.PrintMap
            }'>
                Utskrift
                <div class='underline'></div>
            </div>

            @if (nvdbNavigation.isMobileDevice) {
                <div class='item' (click)='changeNavigationState(NavigationState.TeckenForklaring)' [ngClass]='{
              active: nvdbNavigation.navigationState == NavigationState.TeckenForklaring
              }'>
                    Teckenförklaring
                    <div class='underline'></div>
                </div>
            }

            @if (environment.application == 'NvdbDataleverans' && authenticationService.currentRole == 'Admin') {
                <div class='item' (click)='changeNavigationState(NavigationState.Admin)' [ngClass]='{
              active: nvdbNavigation.navigationState == NavigationState.Admin
              }'>
                    Admin
                    <div class='underline'></div>
                </div>
            }
        </div>
    </div>
    @if (nvdbNavigation.navigationState == NavigationState.MainMenu) {
        <div class="mobile-menu--content">
            <app-map-menu-item [leftIcon]='searchIcon' [itemName]="'Sök'"
                               [open]='nvdbNavigation.startMenuState.showSearchSection'
                               (click)='toggleSearch()'></app-map-menu-item>
            @if (nvdbNavigation.startMenuState.showSearchSection) {
                <div class='menu-item-content' @trvCollapseVoidAnimation>
                    <app-search-section></app-search-section>
                </div>
            }
            <app-map-menu-item [leftIcon]='pinIcon' [itemName]="'Dataprodukter'"
                               [open]='nvdbNavigation.startMenuState.showDataslagSection'
                               (click)='toggleDataslag()'></app-map-menu-item>
            @if (nvdbNavigation.startMenuState.showDataslagSection) {
                <div class='menu-item-content' @trvCollapseVoidAnimation>
                    <app-dataslag-section></app-dataslag-section>
                </div>
            }
            <app-map-menu-item [leftIcon]='layerIcon' [itemName]="'Lager'"
                               [open]='nvdbNavigation.startMenuState.showLagerSection'
                               (click)='toggleLager()'></app-map-menu-item>
            @if (nvdbNavigation.startMenuState.showLagerSection) {
                <div class='menu-item-content' @trvCollapseVoidAnimation>
                    <app-layer-section></app-layer-section>
                </div>
            }
            <app-map-menu-item [leftIcon]='starIcon' [itemName]="'Sparade vyer'"
                               [open]='nvdbNavigation.startMenuState.showVyerSection'
                               (click)='toggleVyer()'></app-map-menu-item>
            @if (nvdbNavigation.startMenuState.showVyerSection) {
                <div class='menu-item-content' @trvCollapseVoidAnimation>
                    <app-saved-view-section></app-saved-view-section>
                </div>
            }
        </div>
    }
    @if (nvdbNavigation.navigationState == NavigationState.Report) {
        <div class="mobile-menu--content">
            @if (environment.application == 'NvdbDataleverans') {
                <div>
                    <app-report-tab></app-report-tab>
                </div>
            }
            @if (environment.application == 'NvdbPåKarta') {
                <div>
                    <app-report-avikelse-tab></app-report-avikelse-tab>
                </div>
            }
        </div>
    }
    @if (nvdbNavigation.navigationState == NavigationState.InfoClick) {
        <div class="mobile-menu--content">
            <app-info-click-tab></app-info-click-tab>
        </div>
    } @else if (nvdbNavigation.navigationState == NavigationState.PrintMap) {
        <div class="mobile-menu--content">
            <div>
                <app-print-map-tab></app-print-map-tab>
            </div>
        </div>
    } @else if (nvdbNavigation.navigationState == NavigationState.Admin && environment.application ==
    'NvdbDataleverans' && authenticationService.currentRole == 'Admin') {
        <div class="mobile-menu--content">
            <div>
                <app-admin-tab></app-admin-tab>
            </div>
        </div>
    } @else if (nvdbNavigation.navigationState == NavigationState.TeckenForklaring) {
        <div class="mobile-menu--content" style="background: white; height: 100%">
            <div>
                <app-tecken-forklaring-tab></app-tecken-forklaring-tab>
            </div>
        </div>

    }
</ng-template>

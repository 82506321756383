<div class="p-3" [trv-loading]="!trvMapService.isMapLoaded()">
  <h2>Bakgrundskarta</h2>
  @if (trvMapLoaded()) {
    <div>
      @if (getTrvMap()!.trvLayer!.backgroundLayerListItems.length > 0) {
        <app-background-layer-select
          [source]="getTrvMap()!.trvLayer!.backgroundLayerListItems"
        ></app-background-layer-select>
      }
      @for (layer of getTrvMap()!.trvLayer.wmsLayerListItems; track layer; let indx = $index) {
        <div (contextmenu)="onRightClick($event, layer)">
          @if (showInLayer(layer) && layer.display && showLayerBasedOnLocal(layer)) {
            <div class="form-check c-datatypes__datatype">
              <input
                type="checkbox"
                class="form-check-input"
                [id]="layer.layerName! + layer.id"
                [value]="layer.id"
                (change)="onWmsLayerInputChange($event, layer)"
                [checked]="layerActive(layer)"
                />
                <label
                  class="form-check-label no-select width-100"
                  [ngClass]="{
                  'data-slag-not-visible-on-zoomlevel': getTrvMap()!.needToZoomInOnLayer(layer)
                  }"
                  [for]="layer.layerName! + layer.id"
                  >
                  {{ layer.name }}
                </label>
                <div class="c-datatypes__handle">
                  @if (getTrvMap()!.needToZoomInOnLayer(layer)) {
                    <button
                      class="btn btn-outline-secondary c-datatypes__btn"
                      (click)="zoomToLayer(layer)"
                      [trvTooltip]="'Zooma till 1:' + layer.maxScaleDenominator + ' för att se lagret'"
                      [position]="toolTipPositions"
                      >
                      <app-svg-icon [svgIcon]="searchIconPlusFilled"></app-svg-icon>
                      <span class="visually-hidden">Zooma till</span>
                    </button>
                  }
                  <button
                    class="btn btn-outline-secondary c-datatypes__btn"
                    (click)="infoAboutLayer(layer)"
                    [trvTooltip]="'Visa information om lagret'"
                    [position]="toolTipPositions"
                    >
                    <app-svg-icon [svgIcon]="infoIcon"></app-svg-icon>
                    <span class="visually-hidden">Om dataprodukten</span>
                  </button>
                </div>
              </div>
            }
          </div>
        }
      </div>
    }
  </div>

import {Component, Inject, Input, ViewChild} from '@angular/core'
import {TrvFormUtilityService, TrvGlobalMessagesService, TrvLoadingBundle, TrvLoadingDirective} from 'trv-ng-common'
import {LocalStorageService} from '@app/service/local-storage.service'
import {NvdbNavigationService} from '@app/service/nvdb-navigation.service'
import {ReportItem, ReportService} from '@app/service/report.service'
import {TrvNgMapService} from '@trafikverket/trv-ng-map'
import {NetDbService} from '@app/service/net-db.service'
import {ReportItemComponent} from '@components/reports/report-item/report-item.component'
import {ReportStatusesEnum} from '@_api/dataleverans/models/report-statuses-enum'
import {faDrawPolygon, faMapMarkerAlt, faRoute} from '@fortawesome/free-solid-svg-icons'
import {GeometryType} from '@shared/enums'
import {ReportStatusPipe} from '../../../pipes/report-status.pipe'
import {ReportItemComponent as ReportItemComponent_1} from '../report-item/report-item.component'
import {ReportEditComponent} from '../report-edit/report-edit.component'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {AsyncPipe} from '@angular/common'
import {AllItemsInListPipe} from "@pipes/item-in-list.pipe";
import {FOCUSED} from "@shared/constants";

//implements OnInit, OnDestroy
@Component({
    selector: 'app-report-item-edit-modal',
    templateUrl: './report-item-edit-modal.component.html',
    styleUrls: ['./report-item-edit-modal.component.scss'],
    standalone: true,
    imports: [TrvLoadingBundle, FontAwesomeModule, ReportEditComponent, ReportItemComponent_1, AsyncPipe, ReportStatusPipe, TrvLoadingDirective, AllItemsInListPipe],
})
export class ReportItemEditModalComponent {
    @ViewChild(ReportItemComponent) reportItemComponent!: ReportItemComponent

    @Input() sideBarVisible = false

    public editItemId!: number

    // report item to show in view mode. Only used in viewMode
    public viewModeReportItem!: ReportItem
    public isViewModeItemFromExternalUser = false

    public submitLoading = false

    public showMustCreateReportBeforeItemWarning = true

    public currentStatus = ModalStatus.CREATING_ITEM
    public ModalStatus = ModalStatus

    public geometryType = GeometryType.POINT

    protected readonly ReportStatusesEnum = ReportStatusesEnum
    protected readonly GeometryType = GeometryType

    protected readonly faRoute = faRoute
    protected readonly faMapMarkerAlt = faMapMarkerAlt
    protected readonly faDrawPolygon = faDrawPolygon

    constructor(
        @Inject(LocalStorageService) public localStorageService: LocalStorageService,
        @Inject(NvdbNavigationService)
        public nvdbNavigation: NvdbNavigationService,
        @Inject(ReportService) public reportService: ReportService,
        @Inject(TrvNgMapService) public trvMapService: TrvNgMapService,
        @Inject(NetDbService) public netDbService: NetDbService,
        @Inject(TrvFormUtilityService)
        @Inject(TrvGlobalMessagesService)
        private globalMessagesService: TrvGlobalMessagesService,
    ) {
        if (this.nvdbNavigation.VIEWMODE) {
            this.reportService.itemToCreateOrEditChanged.subscribe(() => {
                const item = this.reportService.itemToCreateOrEdit as number | null
                if (item != null) this.setViewModeReportItem(item)
            })
            this.setViewModeReportItem(this.reportService.itemToCreateOrEdit as number)
        } else {
            this.setModalStatuses()
            this.reportService.reportsChanged.subscribe(() => this.setModalStatuses())
            this.reportService.itemToCreateOrEditChanged.subscribe(() => this.setModalStatuses())
        }
    }

    public setViewModeReportItem(itemId: number) {
        console.log("set viewmode reportitem")
        const report = this.nvdbNavigation.reportsToDisplayInViewMode.find(a => a.reportItems.some(b => b.id === itemId))!

        this.viewModeReportItem = report.reportItems.find(a => a.id == itemId)!
        this.geometryType = this.viewModeReportItem.feature!.getGeometry()!.getType() as GeometryType
        this.isViewModeItemFromExternalUser = report.owner == null
    }

    public setModalStatuses() {
        if (this.nvdbNavigation.VIEWMODE) return

        const item = this.reportService.itemToCreateOrEdit
        const reports = this.reportService.reports

        if (!item) {
            this.showMustCreateReportBeforeItemWarning = true
            return
        }

        this.editItemId = this.reportService.itemTypeofEditing(item) ? item : -1

        const feature = this.reportService.itemTypeofEditing(item) ? this.reportService.getItemById(this.editItemId)!.feature : item.feature
        this.geometryType = feature!.getGeometry()!.getType() as GeometryType

        this.showMustCreateReportBeforeItemWarning =
            this.editItemId == -1 &&
            !reports.some(a => a.status === ReportStatusesEnum.Draft || a.status === ReportStatusesEnum.OpenForClarification)

        if (this.editItemId == -1) this.currentStatus = ModalStatus.CREATING_ITEM
        else {
            const report = this.reportService.reports.find(a => a.reportItems.some(b => b.id === this.editItemId))!
            if (!report) throw Error('if editing there has to be a report connected to the reportitem thats being edited')
            else if (report.status === ReportStatusesEnum.Draft) this.currentStatus = ModalStatus.EDIT_DRAFT_ITEM
            else if (report.status === ReportStatusesEnum.OpenForClarification) this.currentStatus = ModalStatus.EDIT_COMPLEMENT_ITEM
            else throw Error('has to be either draft or OpenForClarification')
        }
    }

    public close() {
        this.reportService.setActiveItemToCreateOrEdit(null)
        this.reportService.resetAllHighlightReportItem()
    }

    public async submit(reportItemComponent: ReportItemComponent) {
        this.submitLoading = true
        reportItemComponent.saveFileDescriptions([], true)

        if (await reportItemComponent.save()) {
            const firstItem = this.reportService.queOfItemsToCreate.shift()
            this.reportService.setActiveItemToCreateOrEdit(firstItem ?? null, false)

            if (!firstItem) this.reportService.totalQueLengthFromBeginning = null
        }
        this.submitLoading = false
    }
}

enum ModalStatus {
    CREATING_ITEM,
    EDIT_DRAFT_ITEM,
    EDIT_COMPLEMENT_ITEM,
}

import {ChangeDetectorRef, Component, Inject} from '@angular/core'
import {IconProp} from '@fortawesome/fontawesome-svg-core'
import {
    faDrawPolygon,
    faEllipsisV,
    faEye,
    faEyeSlash,
    faMapMarkerAlt,
    faRoute,
    faVectorSquare,
} from '@fortawesome/free-solid-svg-icons'
import {svgChevronDown, svgChevronUp, svgMagnifyingGlass} from 'src/app/shared/utils/svg-helper-data'
import {
    trvCollapseVoidAnimation,
    TrvGlobalMessagesService,
    TrvModalService,
    TrvPopoverPosition,
    TrvPopoverRef,
    TrvTooltipPosition,
    TrvTooltipDirective,
    TrvPopoverDirective,
} from 'trv-ng-common'
import {Report, ReportItem, ReportService} from 'src/app/service/report.service'
import {
    CdkDragDrop,
    moveItemInArray,
    transferArrayItem,
    CdkDropListGroup,
    CdkDropList,
    CdkDrag,
    CdkDragPlaceholder,
} from '@angular/cdk/drag-drop'
import {SvgIcon} from 'src/app/shared/utils/svg-helper'
import {
    ReportOptionsPopoverComponent,
} from '@components/map-menu/report-tab/report-options-popover/report-options-popover.component'
import {NvdbNavigationService} from '@app/service/nvdb-navigation.service'
import {SendReportsModalComponent} from '@components/reports/send-reports-modal/send-reports-modal.component'
import {ReportStatusesEnum} from 'src/app/_api/dataleverans/models'
import {
    ReportEditPopoverComponent,
} from '@components/map-menu/report-tab/report-edit-popover/report-edit-popover.component'
import {ReportStatusPipe} from '../../../../pipes/report-status.pipe'
import {AllItemsInListPipe} from '@pipes/item-in-list.pipe'
import {ReportTabItemComponent} from '../report-tab-item/report-tab-item.component'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {SvgIconComponent} from '../../../utils/svg-icon/svg-icon.component'
import {NgTemplateOutlet, NgClass, AsyncPipe, NgStyle} from '@angular/common'
import {AllReportItemIdsInListPipe} from '@pipes/report-item-ids-in-list.pipe'
import {RouteService} from "@app/service/route.service";
import {Router} from "@angular/router";
import {FORMATTED_REPORT_STATUS} from "@shared/constants";
import {ValueFromObjectListPipe} from "@pipes/value-from-object-list.pipe";
import {AuthenticationService} from "@app/service/authentication.service";

@Component({
    selector: 'app-reports-tab-container',
    templateUrl: './reports-tab-container.component.html',
    styleUrls: ['./reports-tab-container.component.scss'],
    animations: [trvCollapseVoidAnimation],
    standalone: true,
    imports: [
        CdkDropListGroup,
        TrvTooltipDirective,
        TrvPopoverDirective,
        NgTemplateOutlet,
        CdkDropList,
        NgClass,
        SvgIconComponent,
        FontAwesomeModule,
        CdkDrag,
        CdkDragPlaceholder,
        ReportTabItemComponent,
        AsyncPipe,
        AllItemsInListPipe,
        ReportStatusPipe,
        AllReportItemIdsInListPipe,
        ValueFromObjectListPipe,
        NgStyle,
    ],
})
export class ReportsTabContainerComponent {
    public faMapMarkerAlt: IconProp = faMapMarkerAlt as IconProp
    public faRoute: IconProp = faRoute as IconProp
    public faDrawPolygon: IconProp = faDrawPolygon as IconProp
    public faEllipsisV: IconProp = faEllipsisV as IconProp

    public downIcon: SvgIcon = svgChevronDown
    public upIcon: SvgIcon = svgChevronUp

    public toolTipPositions: TrvTooltipPosition[] = ['bottom']

    public open: boolean = true

    public popoverPosition: TrvPopoverPosition[] = ['bottom', 'bottom-end', "bottom-start", 'right', 'top', "left"]
    public editGroupComponent = ReportOptionsPopoverComponent
    public newGroupComponent = ReportEditPopoverComponent

    public selectedReportIds = this.navigationService.reportMenuState.selectedReportIds
    public openedReports = this.navigationService.reportMenuState.openedReportIds

    allCheckboxChecked = false

    constructor(
        @Inject(AuthenticationService)
        public authenticationService: AuthenticationService,
        @Inject(TrvModalService) private trvModalService: TrvModalService,
        public reportService: ReportService,
        private cdref: ChangeDetectorRef,
        private globalMessagesService: TrvGlobalMessagesService,
        @Inject(NvdbNavigationService)
        public navigationService: NvdbNavigationService,
        public router: Router
    ) {
        // if group change, remove all checked groups that are no longer drafted
        this.reportService.reportsChanged.subscribe(() => {
            const idsAvailable = this.reportService.reports
                .filter(a => a.status == ReportStatusesEnum.Draft || a.status == ReportStatusesEnum.OpenForClarification)
                .map(a => a.id)
            const idsToRemove = this.selectedReportIds.filter(a => !idsAvailable.includes(a))

            for (const idToRemove of idsToRemove) {
                const index = this.selectedReportIds.indexOf(idToRemove)
                if (index !== -1) this.selectedReportIds.splice(index, 1)
            }

            this.onCheckedReportIdsChange()
        })
    }

    onShowEditComponent(ref: TrvPopoverRef<ReportOptionsPopoverComponent>, reportGroupItem: Report): void {
        ref.componentInstance.reportGroupItem = reportGroupItem
    }

    async drop(event: CdkDragDrop<ReportItem[]>, toReport: Report) {
        const droppedItem = event.previousContainer.data[event.previousIndex]
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)

            //Order list
            toReport.reportItems.sort((a, b) => (a.id < b.id ? -1 : 1))
        } else {
            const previousReport = droppedItem.report
            droppedItem.form?.patchValue({report: {id: toReport.id, name: toReport.name}})
            try {
                await this.reportService.createOrUpdateReportItem(await this.reportService.getReportItemSaveFromId(droppedItem.id), previousReport.id)
                this.globalMessagesService.success("Förändring flyttades")

                transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex)
                //Order list
                toReport.reportItems.sort((a, b) => (a.id < b.id ? -1 : 1))
            } catch (e) {
                this.globalMessagesService.error("Ett fel uppstod när förändringen skulle byta ärende.")
            }
        }
    }

    public toggleReportOpen(reportId: number) {
        const index = this.openedReports.indexOf(reportId)
        if (index !== -1) {
            this.openedReports.splice(index, 1)
        } else {
            this.openedReports.push(reportId)
        }
    }

    checkboxClicked(reportItemId: number, event: MouseEvent) {
        event.stopPropagation()

        const index = this.selectedReportIds.indexOf(reportItemId)
        if (index !== -1) {
            this.selectedReportIds.splice(index, 1)
        } else {
            this.selectedReportIds.push(reportItemId)
        }

        this.onCheckedReportIdsChange()
    }

    public removeReports() {
        this.trvModalService
            .confirmDelete('Bekräfta ta bort ärenden', 'Är du säker att du vill ta bort de valda ärendena?', 'Ta bort', 'Avbryt', {
                disposeOnBackdropClick: true,
            })
            .afterCloseWithType()
            .subscribe(async (event: any) => {
                if (event.closingEventType === 'close') {
                    try {
                        await this.reportService.removeReportsById(this.selectedReportIds)
                        this.navigationService.reportMenuState.selectedReportIds = []
                        this.selectedReportIds = this.navigationService.reportMenuState.selectedReportIds

                        this.onCheckedReportIdsChange()
                    } catch (e) {
                        if (this.selectedReportIds.length === 1) {
                            const reportName = this.reportService.reports.find(a => a.id === this.selectedReportIds[0])!.name
                            this.globalMessagesService.error(`Ett fel uppstod när ärendet med namn "${reportName}" skulle tas bort.`)
                        } else {
                            this.globalMessagesService.error(`Ett fel uppstod när ärendena skulle tas bort.`)
                        }
                    }
                }
            })
    }

    public openSendReports() {
        const reportsToSend = this.reportService.reports.filter(a => this.selectedReportIds.includes(a.id))
        if (reportsToSend.some(a => a.reportItems.length == 0)) {
            this.globalMessagesService.error(
                'Kan ej skicka in ärenden utan förändringar. Vänligen se till att alla ärenden har förändringar innan du skickar in.',
            )
            return
        }

        const modal = this.trvModalService.open(SendReportsModalComponent, {
            disposeOnBackdropClick: false,
            size: 'xl',
        })
        const component = modal.componentInstance
        component.reportGroupIds = [...this.selectedReportIds]

        modal.afterCloseWithType().subscribe(closeParams => {
        })
    }

    allRowsCheckboxClick(event: MouseEvent) {
        // @ts-ignore (is always a checkbox)
        if (event.target.checked)
            this.navigationService.reportMenuState.selectedReportIds = this.reportService.reports
                .filter(a => a.status == ReportStatusesEnum.Draft || a.status == ReportStatusesEnum.OpenForClarification)
                .map(a => a.id)
        else this.navigationService.reportMenuState.selectedReportIds = []

        this.selectedReportIds = this.navigationService.reportMenuState.selectedReportIds
        this.onCheckedReportIdsChange()
    }

    onShowCreateComponent(ref: TrvPopoverRef<ReportEditPopoverComponent>) {
    }

    onCheckedReportIdsChange() {
        this.allCheckboxChecked =
            this.selectedReportIds.length ===
            this.reportService.reports.filter(a => a.status == ReportStatusesEnum.Draft || a.status == ReportStatusesEnum.OpenForClarification)
                .length

        this.cdref.markForCheck()
    }

    goToViewModeReport(reportId: number, uid: string | undefined) {
        const reportOwnedByCurrentUser = this.reportService.reports.find(a => a.id === reportId)

        if (reportOwnedByCurrentUser) {
            this.router.navigateByUrl(`/reports/${reportId}`)
        } else if (this.authenticationService.isMinAjourhallare) {
            this.router.navigateByUrl(`/handle-reports/${reportId}`)
        } else {
            this.router.navigateByUrl(`/view-reports/${uid}`)
        }
    }


    protected readonly faEyeSlash = faEyeSlash
    protected readonly faEye = faEye
    protected readonly ReportStatusesEnum = ReportStatusesEnum
    protected readonly NvdbNavigationService = NvdbNavigationService;
    protected readonly searchIcon = svgMagnifyingGlass;

    protected readonly FORMATTED_REPORT_STATUS = FORMATTED_REPORT_STATUS;

    reportContainsItemId(reportGroupItem: Report) {
        return reportGroupItem.reportItems.some(a => a.id === this.navigationService.currentlyHoveredReportItemInMap)
    }
}

import { Component } from "@angular/core";
import { faArrowRight, faCheckCircle, faCircle, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { Router, RouterLink } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
@Component({
    selector: 'app-unauthorized-page',
    templateUrl: './unauthorized-page.component.html',
    styleUrls: ['./unauthorized-page.component.scss'],
    standalone: true,
    imports: [FontAwesomeModule, RouterLink],
})
export class UnauthorizedPageComponent {
    public faCircle = faCircle;
    public faCheck = faCheckCircle;
    public faRight = faArrowRight;
}

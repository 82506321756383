<div class="modal-header">
    <span class="application-title modal-title" id="modal-title">{{ action }}</span>
    <button type="button" class="btn-close" aria-label="Stäng modal" aria-describedby="modal-title" (click)="modalRef.dismiss()"></button>
</div>
<div class="modal-body">
    <trv-form [validationErrors]="validationErrorMap" [formGroup]="form">
        @if (action == AjourhallningAction.informera_annan || action == AjourhallningAction.informera_kunden || action ==
        AjourhallningAction.informera_kommunen) {
        <trv-form-field [labelAbove]="true" label="Email" [labelGridColumns]="4" [control]="email">
            <input type="email" class="form-control" [formControl]="email" appAutofocus />
        </trv-form-field>
        }
        <trv-form-field [labelAbove]="true" label="Kommentar" [labelGridColumns]="4" [control]="comment">
            <textarea type="text" class="form-control" [formControl]="comment" rows="4"></textarea>
        </trv-form-field>
        @if (action == AjourhallningAction.avsluta_arende) {
        <trv-form-field label="Skicka e-post till inrapportör att avslut sker" [labelGridColumns]="4" [labelAbove]="true" [control]="sendEmail" >
            <div class="form-check">
                <input type="checkbox" id="simpleCheck1" class="form-check-input" [formControl]="sendEmail" [value]="true" />
                <label class="form-check-label" for="simpleCheck1">Skicka epost</label>
            </div>
        </trv-form-field>
        }
    </trv-form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="modalRef.dismiss()">Avbryt</button>
    <button type="button" class="btn btn-primary" (click)="submit()">{{ action }}</button>
</div>

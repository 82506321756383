<main style="min-width: 940px">
    <div class='p-page'>
        <div class='container'>
            <div class='c-breadcrumb'>
                <nav aria-label='Brödsmulor'>
                    <ol class='breadcrumb'>
                        <li class='breadcrumb-item'>
                            <a routerLink='/home'>Min sida</a>
                        </li>
                        <li class='breadcrumb-item active' aria-current='page'>Mina ärenden</li>
                    </ol>
                </nav>
            </div>
            <h1>Mina ärenden</h1>
            <div class='table_container'>
                <app-reports-table
                    [reportGroupIds]='reportGroupIds ?? []'
                    [trv-loading]='reportGroupIds == null'></app-reports-table>
            </div>
        </div>
    </div>
</main>

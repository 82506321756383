<div class="p-3 pb-0 pt-2">
    <div class="d-flex align-items-center mb-2"
    [trvTooltip]="'Ändringar i NVDB syns inte i NVDB på karta eller NVDB dataleverans förrän nästa dag.'" [position]="toolTipPositions"
    >
        <app-svg-icon style="fill: #1e5c88" [svgIcon]="circleInfoIcon"></app-svg-icon>
        <span style="font-style: italic; margin-left: 0.5rem">
            {{
                nvdbNavigation.formattedLastFeatureUpdateNVDB
                    ? 'Data uttaget från NVDB: ' + nvdbNavigation.formattedLastFeatureUpdateNVDB
                    : 'Kunde inte hämta datum då NVDB datat uppdaterades'
            }}
        </span>
    </div>

    <div class="d-flex mb-3 align-items-center">
        <div class="input-group me-2">
            <input
                [formControl]="dataslagSearch"
                type="search"
                class="form-control"
                placeholder="Sök dataprodukt"
                aria-label="Sök dataprodukt"
                aria-describedby="button-addon2"
            />

            <button class="btn btn-primary search-btn" type="button" id="button-addon2">
                <app-svg-icon [svgIcon]="searchIcon"></app-svg-icon>
                <span class="visually-hidden">Sök</span>
            </button>
        </div>

        <div class="form-check show_choosen_checkbox_container">
            <input type="checkbox" class="form-check-input" [formControl]="showChoosenDataslag" id="showChoosenDataslag" />
            <label class="form-check-label" for="showChoosenDataslag"
            [trvTooltip]="numberOfDataProdukterActive() + ' dataprodukter vald'"
            >Visa valda</label>
            
        </div>
        <button type="button" class="p-2 btn-close" aria-label="Close"
        [trvTooltip]="'Släck alla dataprodukter'"
        (click)="disableAllLayers()"
        ></button>

    </div>

    <div class="c-datatypes" (scroll)="onScrollEvent($event)" #scrollContainer [trv-loading]="!trvMapService.isMapLoaded()">
        @if (dataslagLayers.length == 0) {
        <div>Inga dataprodukter hittades.</div>
        } @for (layer of dataslagLayers; track layer) { @if (filteredDataslagLayerIds.includes(layer.id) && !showInLayer(layer) &&
        showLayerBasedOnLocal(layer)) {
        <div class="form-check c-datatypes__datatype" (contextmenu)="onRightClick($event, layer)">
            <input
                type="checkbox"
                class="form-check-input"
                [id]="layer.layerName! + layer.id"
                [value]="layer.id"
                (change)="onWmsLayerInputChange($event, layer)"
                [checked]="layerActive(layer)"
            />
            <label
                class="form-check-label no-select width-100"
                [ngClass]="{
                    'data-slag-not-visible-on-zoomlevel': getTrvMap()!.needToZoomInOnLayer(layer)
                }"
                [for]="layer.layerName! + layer.id"
            >
                {{ layer.name }}
            </label>
            <div class="c-datatypes__handle">
                @if (getTrvMap()!.needToZoomInOnLayer(layer)) {
                <button
                    class="btn btn-outline-secondary c-datatypes__btn"
                    (click)="zoomToLayer(layer)"
                    [trvTooltip]="'Zooma till 1:' + layer.maxScaleDenominator + ' för att se lagret'"
                    [position]="toolTipPositions"
                >
                    <app-svg-icon [svgIcon]="searchIconPlusFilled"></app-svg-icon>
                    <span class="visually-hidden fix">Zooma till</span>
                </button>
                }
                <button
                    class="btn btn-outline-secondary c-datatypes__btn"
                    (click)="infoAboutLayer(layer)"
                    [trvTooltip]="'Visa information om lagret'"
                    [position]="toolTipPositions"
                >
                    <app-svg-icon [svgIcon]="infoIcon"></app-svg-icon>
                    <span class="visually-hidden fix">Om Dataprodukt</span>
                </button>
            </div>
        </div>

        } }
    </div>
</div>

<div class="menu-item no-select" [ngClass]="{'closed': !open}">
  <app-svg-icon [svgIcon]="leftIcon" class="menu-item__ico"></app-svg-icon>
  <div class="menu-item-name no-select">
    {{ itemName }}
  </div>
  @if (!open) {
    <div class="menu-item-toggle">
      <app-svg-icon [svgIcon]="downIcon"></app-svg-icon>
    </div>
  }
  @if (open) {
    <div class="menu-item-toggle">
      <app-svg-icon [svgIcon]="upIcon"></app-svg-icon>
    </div>
  }
</div>

import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild} from "@angular/core";
import {
    svgCross,
    svgLayer,
    svgMapPin,
    svgStar,
    svgMagnifyingGlass,
    svgCircleInfo, svgChevronDown
} from "src/app/shared/utils/svg-helper-data";
import {TrvNgMapOverlayService, TrvNgMapService} from "@trafikverket/trv-ng-map";
import {SvgIcon} from "src/app/shared/utils/svg-helper";
import {
    trvCollapseVoidAnimation,
    TrvFormUtilityService,
    TrvGlobalMessagesService,
    TrvModalService,
    TrvSelectComponent,
    TrvUtilityService
} from "trv-ng-common";
import {MapTools} from "@trafikverket/trv-ng-map";
import {TrvMapExtensionService} from "src/app/service/trv-map-extension.service";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import {NetDbService} from "src/app/service/net-db.service";
import {
    mobileMenuState, mobileMenuStateHeights,
    NavigationState,
    NvdbNavigationService,
    searchObjectList
} from "src/app/service/nvdb-navigation.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "src/environments/environment";
import {AuthenticationService} from "src/app/service/authentication.service";
import {
    BackgroundLayerSelectComponent
} from "./layer-section/background-layer-select/background-layer-select.component";
import {Report, ReportService} from "@app/service/report.service";
import {LocalStorageService} from "@app/service/local-storage.service";

import _smallLayers from "../../../assets/data/layer-data/nvdb-layers-downscaled.json";
import {filter, take} from "rxjs";
import {AdminTabComponent} from "./admin-tab/admin-tab.component";
import {PrintMapTabComponent} from "./print-map-tab/print-map-tab.component";
import {InfoClickTabComponent} from "./info-click-tab/info-click-tab.component";
import {ReportAvikelseTabComponent} from "./reports-avikelse-tab/report-avikelse-tab.component";
import {ReportTabComponent} from "./report-tab/report-tab.component";
import {SavedViewSectionComponent} from "./saved-view-section/saved-view-section.component";
import {LayerSectionComponent} from "./layer-section/layer-section.component";
import {DataslagSectionComponent} from "./dataslag-section/dataslag-section.component";
import {SearchSectionComponent} from "./search-section/search-section.component";
import {MapMenuItemComponent} from "./map-menu-item/map-menu-item.component";
import {SvgIconComponent} from "../utils/svg-icon/svg-icon.component";
import {Location, NgClass, NgTemplateOutlet} from "@angular/common";
import {NavigationEnd, NavigationStart, Router} from '@angular/router'
import {faAngleDown, faArrowDown, faArrowsRotate, faInfoCircle, faPlug} from "@fortawesome/free-solid-svg-icons";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {FullScreen} from "ol/control";
import {ReportAvikelseService} from "@app/service/report-avikelse.service";
import {
    ReportAvikelseItemEditModalComponent
} from "@components/reports-avikelse/report-avikelse-item-edit-modal/report-avikelse-item-edit-modal.component";
import {TeckenForklaringTabComponent} from "@components/map-menu/tecken-forklaring-tab/tecken-forklaring-tab.component";
import {DateTime} from "luxon";
import {TrvModalConfig} from "trv-ng-common/lib/components/modal/modal-config";
import {
    ReportItemEditModalComponent
} from "@components/reports/report-item-edit-modal/report-item-edit-modal.component";

@Component({
    selector: "app-map-menu",
    templateUrl: "./map-menu.component.html",
    styleUrls: ["./map-menu.component.scss"],
    animations: [trvCollapseVoidAnimation],
    standalone: true,
    imports: [
        SvgIconComponent,
        NgClass,
        MapMenuItemComponent,
        SearchSectionComponent,
        DataslagSectionComponent,
        LayerSectionComponent,
        SavedViewSectionComponent,
        ReportTabComponent,
        ReportAvikelseTabComponent,
        InfoClickTabComponent,
        PrintMapTabComponent,
        AdminTabComponent,
        TrvSelectComponent,
        ReactiveFormsModule,
        FaIconComponent,
        NgTemplateOutlet,
        ReportAvikelseItemEditModalComponent,
        TeckenForklaringTabComponent,
        ReportItemEditModalComponent
    ],
})
export class MapMenuComponent implements AfterViewInit, OnInit {
    @ViewChild("sidebar") sidebar!: ElementRef;
    @ViewChild("mobileMenu") mobileMenu!: ElementRef;

    @ViewChild("resizeHandle") resizeHandle!: ElementRef;


    public layer: any;

    public mouseX = 0;
    public environment = environment;
    public hidden: boolean = false;
    public NavigationState = NavigationState;

    //Svg icons
    public pinIcon: SvgIcon = svgMapPin;
    public layerIcon: SvgIcon = svgLayer;
    public starIcon: SvgIcon = svgStar;

    public crossIcon: SvgIcon = svgCross;
    public searchIcon: SvgIcon = svgMagnifyingGlass;

    @ViewChild(BackgroundLayerSelectComponent)
    backgroundLayerSelectComponent!: BackgroundLayerSelectComponent;

    protected readonly searchObjectList = searchObjectList;

    constructor(
        @Inject(TrvNgMapService) public trvMapService: TrvNgMapService,
        @Inject(TrvNgMapOverlayService)
        @Inject(NvdbNavigationService)
        public nvdbNavigation: NvdbNavigationService,
        @Inject(NetDbService) public netdbService: NetDbService,
        @Inject(ReportService) public reportService: ReportService,
        @Inject(ReportAvikelseService) public reportAvikelseService: ReportAvikelseService,
        @Inject(TrvModalService) private trvModalService: TrvModalService,
        @Inject(TrvMapExtensionService)
        public trvMapExtensionService: TrvMapExtensionService,
        @Inject(LocalStorageService)
        private localStorageService: LocalStorageService,
        public authenticationService: AuthenticationService,
        @Inject(Router) private router: Router,
    ) {
        this.router.events.pipe(
            filter(event => event instanceof NavigationStart),
            take(1)
        ).subscribe(() => {

            // if we navigate away and the map is set, save the map state so we can go back easily
            const currentMap = this.trvMapService.trvMap
            const currentZoom = currentMap?.map.getView().getZoom()
            const currentCenter = currentMap?.map.getView().getCenter()

            if (currentMap && currentZoom && currentCenter && location.href.includes("/map")) {
                this.nvdbNavigation.previousMapState = {
                    zoom: currentZoom,
                    centerCoordinate: currentCenter,
                    backgroundLayerId: currentMap.trvLayer.currentBackroundLayer.id,
                    dataLayersId: currentMap.trvLayer.getVisibleWmsLayer().map(layer => layer.id),
                    history: currentMap.viewHistory,
                    currentHistoryIndex: currentMap.currentHistoryIndex,
                    hasMovedSinceLastSavepoint: currentMap.hasMovedSinceLastSavepoint,
                }
            }
        })

    }

    ngOnInit(): void {
        this.layer = _smallLayers;
        this.layer.BackgroundLayer.LayerList.forEach((layer: any) => {
            if (layer.hasOwnProperty("Url")) {
                let url: string = layer.Url;
                layer.Url = url.replace("{api}", environment.baseApiUrl);
            }
        });
    }


    //lastY: null | number = null
    menuHeight = "40%"
    previousDragEvents: { position: number, timeStamp: DateTime }[] = []

    // if menu is 3rem from full height
    menuAlmostFullHeight = false

    isScrolling = false
    isResizing = false
    // wait 20ms before resizing so that scroll has time to get registrerd
    isResizingTimer: number | null = null

    // dont resize when using input range element
    isUsingSlider = false

    ngAfterViewInit() {
        window.addEventListener('scroll', e => {
            const elem = e.target as HTMLElement

            // if the container is scrolled to bottom and is resized it will trigger a scrollevent that we do not care about
            const elemScrollable = elem.scrollHeight != elem.clientHeight
            const scrolledToBottom = Math.abs(elem.scrollHeight - elem.scrollTop - elem.clientHeight) < 1
            if (elemScrollable && scrolledToBottom) return

            this.isScrolling = true;
        }, true)

        window.addEventListener('scrollend', () => {
            this.isScrolling = false
        }, true)

        if (this.mobileMenu) {
            this.nvdbNavigation.currentMobileMenuState.valueChanges.subscribe(state => {
                if (state == "Changing") return
                this.nvdbNavigation.previousMobileMenuState = state

                this.menuHeight = `calc(${mobileMenuStateHeights[state]})`
                this.menuAlmostFullHeight = state === "FullScreen"

            })

            let currentTarget: HTMLElement | null = null
            this.mobileMenu.nativeElement.addEventListener("touchmove", (e: any) => {
                if (this.previousDragEvents.length === 0) {
                    currentTarget = e.target;
                    while (true) {
                        const targetIsInputSlider = currentTarget!.tagName.toLowerCase() == 'input' && currentTarget!.getAttribute("type") === "range"
                        if (targetIsInputSlider) {
                            this.isUsingSlider = true
                            return
                        }

                        if (!currentTarget?.parentElement || currentTarget.parentElement.classList.contains('mobile-menu')) break;
                        currentTarget = currentTarget!.parentElement!
                    }
                    if (!currentTarget!.classList.contains("mobile-menu--content")) currentTarget = null

                    this.previousDragEvents.push({
                        position: e.touches[0].screenY,
                        timeStamp: DateTime.now()
                    })

                    this.mobileMenu.nativeElement.classList.add('notransition');
                    this.nvdbNavigation.currentMobileMenuState.setValue("Changing")
                    return
                }

                const changeSinceLastUpdate = e.touches[0].screenY - this.previousDragEvents[this.previousDragEvents.length - 1].position
                if (!this.isResizingTimer && !this.isResizing) {
                    this.isResizingTimer = setTimeout(() => {
                        if (this.isScrolling || this.isUsingSlider) {
                            return
                        } else {
                            this.isResizing = true
                        }
                    }, 20)
                } else if (this.isResizing) {
                    this.previousDragEvents.push({position: e.touches[0].screenY, timeStamp: DateTime.now()})

                    const newHeight = Math.min(Math.max(this.mobileMenu.nativeElement.offsetHeight - changeSinceLastUpdate, this.nvdbNavigation.getMobileMenuPercentageHeight(true, "Small") * document.body.clientHeight), this.nvdbNavigation.getMobileMenuPercentageHeight(true, "FullScreen") * document.body.clientHeight)
                    this.menuHeight = newHeight + "px"
                }
            })

            this.mobileMenu.nativeElement.addEventListener("touchend", (e: any) => {
                if (this.isResizingTimer) {
                    clearTimeout(this.isResizingTimer)
                    this.isResizingTimer = null
                }

                this.isScrolling = false
                this.isResizing = false
                this.isUsingSlider = false

                this.mobileMenu.nativeElement.offsetHeight
                this.mobileMenu.nativeElement.classList.remove('notransition');

                // This code is absolutely horrible but it works. Basically i want to make the dragging of the mobileMenu more intuitive. So i use the following rules
                // 1) If its in the small state or medium state and you drag up quickly, it should go to the fullscreen mode regardless of how far you dragged
                // 2) if you drag 20% of the distance to the next state, it should go there automatically
                // 3) if neither of the previous conditions were met, simply go to the closest state

                const timeOfStoppedDrag = DateTime.now()

                let totalDistanceDragged = 0
                // if you drag quickly it should open completely despite not travelling the required amount
                let totalDistanceDraggedLast50ms = 0

                for (let i = 1; i < this.previousDragEvents.length; i++) {
                    const distanceDragged = this.previousDragEvents[i].position - this.previousDragEvents[i - 1].position

                    totalDistanceDragged -= distanceDragged

                    if (timeOfStoppedDrag.diff(this.previousDragEvents[i - 1].timeStamp).as("millisecond") < 50) {
                        totalDistanceDraggedLast50ms -= distanceDragged
                    }
                }
                this.previousDragEvents = []


                // if you drag up more than 50px the last 50ms, go to fullState
                if ((this.nvdbNavigation.previousMobileMenuState != "FullScreen") && totalDistanceDraggedLast50ms > 50) {
                    this.nvdbNavigation.currentMobileMenuState.setValue("FullScreen")
                    return;
                }

                const thresholdToChangeMenuState = 0.2

                const states: mobileMenuState[] = [
                    "Small",
                    "Medium",
                    "Large",
                    "FullScreen"
                ]
                const distances = [
                    (this.nvdbNavigation.getMobileMenuPercentageHeight(true, "Medium") - this.nvdbNavigation.getMobileMenuPercentageHeight(true, "Small")) * document.body.clientHeight,
                    (this.nvdbNavigation.getMobileMenuPercentageHeight(true, "Large") - this.nvdbNavigation.getMobileMenuPercentageHeight(true, "Medium")) * document.body.clientHeight,
                    (this.nvdbNavigation.getMobileMenuPercentageHeight(true, "FullScreen") - this.nvdbNavigation.getMobileMenuPercentageHeight(true, "Large")) * document.body.clientHeight,
                ]

                const totalSizes = [
                    this.nvdbNavigation.getMobileMenuPercentageHeight(true, "Small") * document.body.clientHeight,
                    this.nvdbNavigation.getMobileMenuPercentageHeight(true, "Medium") * document.body.clientHeight,
                    this.nvdbNavigation.getMobileMenuPercentageHeight(true, "Large") * document.body.clientHeight,
                    this.nvdbNavigation.getMobileMenuPercentageHeight(true, "FullScreen") * document.body.clientHeight
                ]

                const index = states.findIndex((a) => a == this.nvdbNavigation.previousMobileMenuState)

                // DRAG UP
                if (states[index] != "FullScreen" && distances[index] * thresholdToChangeMenuState < totalDistanceDragged && totalDistanceDragged < distances[index]) {
                    let stateToChangeTo = states[index + 1]
                    this.nvdbNavigation.currentMobileMenuState.setValue(stateToChangeTo)
                    return
                }
                // DRAG DOWN
                else if (states[index] != "Small" && distances[index - 1] * thresholdToChangeMenuState < -totalDistanceDragged && -totalDistanceDragged < distances[index - 1]) {
                    let stateToChangeTo = states[index - 1]
                    this.nvdbNavigation.currentMobileMenuState.setValue(stateToChangeTo)
                    return
                }
                // otherwise simply go to the closest one
                else {

                    let shortestDistance = 100000
                    let indexWithShortestDistance = -1
                    for (const [i, totalSize] of totalSizes.entries()) {
                        const distanceFromMouse = Math.abs(totalSize - this.mobileMenu.nativeElement.offsetHeight)
                        if (distanceFromMouse < shortestDistance) {
                            shortestDistance = distanceFromMouse
                            indexWithShortestDistance = i
                        }
                    }

                    this.nvdbNavigation.currentMobileMenuState.setValue(states[indexWithShortestDistance])
                    return
                }
            })
        }
    }

    public clickReportTab() {
        this.changeNavigationState(NavigationState.Report)

        if (environment.application == "NvdbPåKarta") {
            if (!this.nvdbNavigation.startMenuState.reportAvikelseInfoShown) {
                this.nvdbNavigation.startMenuState.reportAvikelseInfoShown = true;

                const config: TrvModalConfig = {
                    disposeOnBackdropClick: true,
                }
                if (this.nvdbNavigation.isMobileDevice) config.size = 'fullscreen'

                this.trvModalService.info(
                    'Information',
                    `På NVDB på karta kan endast ärenden som berör felaktigheter avseende den information som presenteras i kartverktyget rapporteras.
                    <br><br>
                    Övriga ärenden för det statliga vägnätet (exempelvis snöröjning, vägskador, belysning m.m.) görs på Trafikverkets formulär på hemsidan <a href="https://e-tjanster-ka.trafikverket.se/kontaktcenter/?ref=road" target="__blank">https://e-tjanster-ka.trafikverket.se/kontaktcenter/?ref=road</a> alternativt på telefonnummer 0771-921 921.
                    `,
                    config
                )
            }
        }
    }

    onMouseDown = (event: MouseEvent) => {
        this.mouseX = event.clientX;

        document.body.style.cursor = "col-resize";
        this.sidebar.nativeElement.style.userSelect = "none";
        this.sidebar.nativeElement.style.pointerEvents = "none";

        this.resizeHandle.nativeElement.style.opacity = 100;

        document.addEventListener("mousemove", this.onMouseMove);
        document.addEventListener("mouseup", this.onMouseUp);
    };

    onMouseMove = (event: MouseEvent) => {
        event.preventDefault();

        const distanceMoved = event.clientX - this.mouseX;
        this.mouseX = event.clientX;

        // shouldnt resize if mouse is to far to the left or right
        const boundingBox = this.resizeHandle.nativeElement.getBoundingClientRect();
        const rightSideX = boundingBox.x + boundingBox.width;
        if ((event.clientX < rightSideX && distanceMoved > 0) || (rightSideX < event.clientX && distanceMoved < 0)) return;

        this.nvdbNavigation.updateSidebarWidth(distanceMoved);
    };

    onMouseUp = (event: MouseEvent) => {
        document.body.style.removeProperty("cursor");

        this.sidebar.nativeElement.style.removeProperty("user-select");
        this.sidebar.nativeElement.style.removeProperty("pointer-events");

        this.resizeHandle.nativeElement.style.opacity = 0;

        // Attach the listeners to document
        document.removeEventListener("mousemove", this.onMouseMove);
        document.removeEventListener("mouseup", this.onMouseUp);

        this.localStorageService.setSidepanelWidth(this.nvdbNavigation.sideBarWidth);
    };

    public toggleDataslag(): void {
        this.nvdbNavigation.startMenuState.showDataslagSection = !this.nvdbNavigation.startMenuState.showDataslagSection;
    }

    public toggleLager(): void {
        this.nvdbNavigation.startMenuState.showLagerSection = !this.nvdbNavigation.startMenuState.showLagerSection;
    }

    public toggleSearch(): void {
        this.nvdbNavigation.startMenuState.showSearchSection = !this.nvdbNavigation.startMenuState.showSearchSection;
    }

    public toggleVyer(): void {
        this.nvdbNavigation.startMenuState.showVyerSection = !this.nvdbNavigation.startMenuState.showVyerSection;
    }

    public menuToggle(): void {
        this.nvdbNavigation.toggleSideBar();
    }

    changeNavigationState(newState: NavigationState) {
        this.nvdbNavigation.navigationState = newState
        this.nvdbNavigation.navigationStateChanged.emit()
    }

    exitViewMode() {
        this.nvdbNavigation.VIEWMODE = false
        this.nvdbNavigation.viewModeChanged.emit(false)
        this.nvdbNavigation.reportsToDisplayInViewMode = []
        this.router.navigateByUrl("/map")
    }

    visningslageInfo() {
        const config: TrvModalConfig = {
            disposeOnBackdropClick: true,
        }
        if (this.nvdbNavigation.isMobileDevice) config.size = 'fullscreen'

        this.trvModalService.info("Information om visningsläge", "Visningsläge tillåter en att visa ärenden i kartan oavsett status. Man kan alltså tillexempel visa stängda ärenden i kartan. Det går inte att redigera någonting i visningsläge.", config);
    }

    protected readonly faInfoCircle = faInfoCircle;
    protected readonly FullScreen = FullScreen;
    protected readonly downIcon = svgChevronDown;
    protected readonly faPlug = faPlug;
    protected readonly faArrowDown = faArrowDown;
    protected readonly faAngleDown = faAngleDown;
}

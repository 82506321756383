import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { faPastafarianism } from "@fortawesome/free-solid-svg-icons";
import { LayerListItem, TrvNgMapService } from "@trafikverket/trv-ng-map";
import { SvgIcon } from "src/app/shared/utils/svg-helper";
import {svgCircleInfo, svgMagnifyingGlass} from "src/app/shared/utils/svg-helper-data";
import { environment } from "src/environments/environment";
import { TrvModalRef, TrvLoadingBundle, TrvTooltipDirective, TrvTooltipPosition } from "trv-ng-common";
import { MatSliderModule } from "@angular/material/slider";
import {SvgIconComponent} from "@components/utils/svg-icon/svg-icon.component";
import {NvdbNavigationService} from "@app/service/nvdb-navigation.service";


@Component({
    selector: 'app-layer-info-modal',
    templateUrl: './layer-info-modal.component.html',
    styleUrls: ['./layer-info-modal.component.scss'],
    standalone: true,
    imports: [
        TrvLoadingBundle,
        MatSliderModule,
        SvgIconComponent,
        TrvTooltipDirective
    ],
})
export class LayerInfoModalComponent {
    @Input() title?: string;
    @Input() text?: string;
    @Input() layerVisibleFrom?: number;
    @Input() legendGraphics?: string;
    @Input() layer?: LayerListItem;

    public toolTipPositions: TrvTooltipPosition[] = ["bottom", "right"];


    public searchIcon: SvgIcon = svgMagnifyingGlass;
    constructor(private modalRef: TrvModalRef<LayerInfoModalComponent>, public trvMapService: TrvNgMapService, public nvdbNavigationService: NvdbNavigationService) {}

    close(): void {
        const returnParams: ModalReturnParams = {
            resultat: "Stängdes!",
        };
        this.modalRef.close(returnParams);
    }

    dismiss(): void {
        this.modalRef.dismiss();
    }

    public onOpacityChange(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        let parsedValue: number = parseFloat(value);
        if (Number.isNaN(parsedValue)) {
            parsedValue = 1;
        }

        if (parsedValue < 0) parsedValue = 0;

        if (parsedValue > 1) parsedValue = 1;

        this.layer!.opacity = parsedValue;
        this.layer!.layer.setOpacity(parsedValue);
    }

    public getLegendGraphics() {
        if (this.legendGraphics) {
            //return environment.baseApiUrl + "/MapProxy/mapProxyLegend/" + encodeURIComponent(this.legendGraphics);
            return this.legendGraphics;
        }
        return "";
    }

    protected readonly circleInfoIcon = svgCircleInfo;
}

export interface ModalReturnParams {
    resultat: string;
}

<div class="modal-header">
    <span class="application-title modal-title" id="modal-title">Om {{ applicationName }}</span>
    <button type="button" class="btn-close" aria-describedby="modal-title" (click)="dismiss()" aria-label="Stäng modal"></button>
</div>
<div class="modal-body">
    <div>
        <div class="about-title">Se alla Sveriges vägar i en karta</div>
        <div class="about-body">
            Titta på olika typer av information som är kopplad till vägen, till exempel vilken hastighetsgräns som gäller för en viss väg eller vilken
            typ av beläggning vägen har. Du kan rapportera felaktigheter i kartan.
            <br />
            <br />
            Ändringar i NVDB syns inte i {{ applicationName }} förrän nästa dag
        </div>

        <div class="about-title">Personuppgifter</div>
        <div class="about-body">
            <a href="https://www.trafikverket.se/gdpr" target="__blank">Hantering av personuppgifter (GDPR) på trafikverket.se</a>
        </div>

        <div class="about-title">Mer om Nationella vägdatabasen</div>
        <div class="about-body">
            <a href="http://www.nvdb.se" target="__blank">NVDB - Nationell vägdatabas</a>
        </div>

        <div class="about-title">Användarhandledning</div>
        <div class="about-body">
            <a [href]="nvdbNavigationService.HANDLEDNING_PATH" target="__blank">
                {{ nvdbNavigationService.HANDLEDNING_FILENAME }} ({{ nvdbNavigationService.HANDLEDNING_FILESIZE }})
            </a>
        </div>
        <div class="about-title">Vem ansvarar för ajourhållningen i NVDB?</div>
        <div class="about-body">
            <p>
                Leverantörsansvaret är där varje väghållare ansvarar för kvalitet på sitt eget vägnät. <br>
                Dvs. att t.ex. Trafikverket levererar data för det statliga vägnätet och det enskilda vägnätet som får statligt bidrag till underhåll av vägen. <br><br>
                Enda undantaget är trafikregler som t.ex. hastighetsgräns där Trafikverket är ansvarig för hela nätet. <br>
                Läs mer om leverantörsansvaret här. <a href="https://www.nvdb.se/sv/om-nvdb/" target="_blank">Om NVDB</a>
            </p>
        </div>
        <div class="about-title">Vid frågor eller synpunkter</div>
        <div class="about-body">
            <a href="mailto:NVDBpakarta@trafikverket.se?subject=Synpunkt">NVDBpakarta&#64;trafikverket.se</a>
        </div>
    </div>
</div>
<div class="modal-footer" style="justify-content: space-between !important">
    <span style="">
        <b>Version:</b>
        {{ version }}
    </span>
    <button type="button" class="btn btn-link" (click)="close()">Stäng</button>
</div>

@if (svgIcon != undefined && svgIcon.url.length == 0) {
  <svg
    [attr.xmlns]="svgIcon!.xmlns"
    [attr.width]="svgIcon!.width"
    [attr.height]="svgIcon!.height"
    [attr.fill]="svgIcon!.fill"
    [attr.class]="svgIcon!.class"
    [attr.viewBox]="svgIcon!.viewBox"
    >
    @for (path of svgIcon.path; track path) {
      <path [attr.d]="path" [attr.fill-rule]="svgIcon.fillRule" />
    }
  </svg>
}
@if (svgIcon != undefined && svgIcon.url.length != 0) {
  <svg [attr.width]="svgIcon!.width" [attr.height]="svgIcon!.height">
    <image [attr.xlink:href]="svgIcon.url" [attr.width]="svgIcon!.width" [attr.height]="svgIcon!.height" />
  </svg>
}

import { AfterViewInit, Component, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { Router, RouterLink } from "@angular/router";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faDownLong, faPerson, faSignOut, faUser } from "@fortawesome/free-solid-svg-icons";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { getAllDeliveryTypes } from "src/app/_api/dataleverans/fn/types/get-all-delivery-types";
import { AuthenticationService } from "src/app/service/authentication.service";
import { NvdbNavigationService } from "src/app/service/nvdb-navigation.service";
import { SvgIcon } from "src/app/shared/utils/svg-helper";
import { svgChevronDown, svgPerson } from "src/app/shared/utils/svg-helper-data";
import { TrvFormUtilityService, TrvSelectComponent, TrvUtilityService } from "trv-ng-common";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";


@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
    standalone: true,
    imports: [
    ReactiveFormsModule,
    TrvSelectComponent,
    RouterLink,
    FontAwesomeModule
],
})
export class UserMenuComponent {
    public faUser: IconProp = faUser as IconProp;
    public faSignOut: IconProp = faSignOut as IconProp;

    @ViewChild("selectRef") selectRef!: TrvSelectComponent;
    @ViewChild("selectRef2") selectRef2!: TrvSelectComponent;

    constructor(
        public nvdbNavigationService: NvdbNavigationService,
        private router: Router,
        public authenticationService: AuthenticationService,
    ) {
        this.nvdbNavigationService.userRoleAsForm.valueChanges.subscribe(val => {
            this.authenticationService.setCurrentRole(val!.userRole);
        })

    }

    public isSelectOpen() {

        return this.selectRef.popover.isOpen || this.selectRef2.popover.isOpen;
    }

    public logout() {
        this.router.navigateByUrl("/logout");
    }
}


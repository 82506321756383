import { Pipe, PipeTransform } from '@angular/core';
import { Feature } from 'ol';
import GeometryType from 'ol/geom/GeometryType';

@Pipe({
    name: 'geometryType',
    standalone: true
})
export class GeometryTypePipe implements PipeTransform {
  transform(feature: Feature): GeometryType {
    return feature.getGeometry()!.getType() as GeometryType
  }
}

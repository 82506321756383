<div class="c-report">

    @if (nvdbNavigationService.isMobileDevice) {
        <div class="d-flex gap-2 m-3 flex-wrap">
            <button
                class="btn btn-outline-primary d-flex gap-1"
                [ngClass]="{
                           active: trvMapService.getTool() == MapTools.PaKartaReportPoint
                   }"
                (click)="setMapTool(MapTools.PaKartaReportPoint)"
                aria-label="Skapa punkt"
            >
                <fa-icon [icon]="faMapMarkerAlt"></fa-icon>
                Punkt
            </button>
            <button
                class="btn btn-outline-primary d-flex gap-1"
                [ngClass]="{
                           active: trvMapService.getTool() == MapTools.PaKartaReportLine
                   }"
                (click)="setMapTool(MapTools.PaKartaReportLine)"
                aria-label="Skapa punkt"
            >
                <fa-icon [icon]="faRoute"></fa-icon>
                Linje
            </button>
            <button
                class="btn btn-outline-primary d-flex gap-1"
                [ngClass]="{
                           active: trvMapService.getTool() == MapTools.PaKartaReportPolygon
                   }"
                (click)="setMapTool(MapTools.PaKartaReportPolygon)"
                aria-label="Skapa punkt"
            >
                <fa-icon [icon]="faVectorSquare"></fa-icon>
                Yta
            </button>
            <button
                class="btn btn-outline-primary d-flex gap-1"
                [ngClass]="{
                           active: trvMapService.getTool() == MapTools.PaKartaReportTranslate
                   }"
                (click)="setMapTool(MapTools.PaKartaReportTranslate)"
                aria-label="Skapa punkt"
            >
                <fa-icon [icon]="faMove"></fa-icon>
                Flytta
            </button>
            <button
                class="btn btn-outline-primary d-flex gap-1"
                [ngClass]="{
                           active: trvMapService.getTool() == MapTools.PaKartaReportEdit
                   }"
                (click)="setMapTool(MapTools.PaKartaReportEdit)"
                aria-label="Skapa punkt"
            >
                <fa-icon [icon]="faEdit"></fa-icon>
                Editera
            </button>
            <button
                class="btn btn-outline-primary d-flex gap-1"
                [ngClass]="{
                           active: trvMapService.getTool() == MapTools.PaKartaReportDelete
                   }"
                (click)="setMapTool(MapTools.PaKartaReportDelete)"
                aria-label="Skapa punkt"
            >
                <fa-icon [icon]="faTrash"></fa-icon>
                Ta bort
            </button>

        </div>
    } @else {
        <div class="c-report__head" style="padding: 1rem 1rem 0rem 1rem">
            <div class="c-report__head__handle">
                <div role="group" class="btn-group dataleverans-report-group">
                    <button
                        class="btn btn-outline-primary"
                        style="margin: 0; border-right: 0"
                        [ngClass]="{
          active: trvMapService.getTool() == MapTools.PaKartaReportPoint
          }"
                        (click)="setMapTool(MapTools.PaKartaReportPoint)"
                        trvTooltip="Skapa punkt"
                        aria-label="Skapa punkt"
                        [position]="toolTipPositions"
                    >
                        <fa-icon [icon]="faMapMarkerAlt"></fa-icon>
                    </button>
                    <div class="btn-divider"></div>
                    <button
                        class="btn btn-outline-primary"
                        style="margin: 0; border-left: 0; border-right: 0"
                        [ngClass]="{
          active: trvMapService.getTool() == MapTools.PaKartaReportLine
          }"
                        (click)="setMapTool(MapTools.PaKartaReportLine)"
                        trvTooltip="Skapa linje"
                        aria-label="Skapa linje"
                        [position]="toolTipPositions"
                    >
                        <fa-icon [icon]="faRoute"></fa-icon>
                    </button>
                    <div class="btn-divider"></div>
                    <button
                        class="btn btn-outline-primary"
                        style="margin: 0; border-left: 0; border-right: 0"
                        [ngClass]="{
          active: trvMapService.getTool() == MapTools.PaKartaReportPolygon
          }"
                        (click)="setMapTool(MapTools.PaKartaReportPolygon)"
                        trvTooltip="Skapa yta"
                        aria-label="Skapa yta"
                        [position]="toolTipPositions"
                    >
                        <fa-icon [icon]="faVectorSquare"></fa-icon>
                    </button>
                    <div class="btn-divider"></div>
                    <button
                        class="btn btn-outline-primary"
                        style="margin: 0; border-left: 0; border-right: 0"
                        [ngClass]="{
          active: trvMapService.getTool() == MapTools.PaKartaReportTranslate
          }"
                        (click)="setMapTool(MapTools.PaKartaReportTranslate)"
                        trvTooltip="Flytta geometri"
                        aria-label="Flytta geometri"
                        [position]="toolTipPositions"
                    >
                        <fa-icon [icon]="faMove"></fa-icon>
                    </button>
                    <div class="btn-divider"></div>
                    <button
                        class="btn btn-outline-primary"
                        style="margin: 0; border-left: 0; border-right: 0"
                        [ngClass]="{
          active: trvMapService.getTool() == MapTools.PaKartaReportEdit
          }"
                        (click)="setMapTool(MapTools.PaKartaReportEdit)"
                        trvTooltip="Editera geometri"
                        aria-label="Editera geometri"
                        [position]="toolTipPositions"
                    >
                        <fa-icon [icon]="faEdit"></fa-icon>
                    </button>
                    <div class="btn-divider"></div>
                    <button
                        class="btn btn-outline-primary"
                        style="margin: 0; border-left: 0"
                        [ngClass]="{
          active: trvMapService.getTool() == MapTools.PaKartaReportDelete
          }"
                        (click)="setMapTool(MapTools.PaKartaReportDelete)"
                        trvTooltip="Ta bort geometri"
                        aria-label="Ta bort geometri"
                        [position]="toolTipPositions"
                    >
                        <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    }


    @if (reportAvikelseService.unsentReportAvikelseItem.length == 0) {
        <div class="c-report__body">
            <div>
                Du har inte skapat någon avvikelse ännu.
                <br/>
                Placera ut en geometri för att skapa ny avvikelse.
            </div>
        </div>
    }
    @if (reportAvikelseService.unsentReportAvikelseItem.length) {
        <div style="margin-top: 1rem;">
            <div
                style="display: flex; justify-content: space-between; align-items: center; padding: 0.95rem; background-color: #e9ecef; border-bottom: 1px solid white">
                <span>Avvikelser <strong>({{ reportAvikelseService.unsentReportAvikelseItem.length }})</strong></span>
                <span>
          <button class="btn btn-primary" (click)="submitAvikelser()"
                  [disabled]="reportAvikelseService.unsentReportAvikelseItem.length == 0">Skicka in</button>
        </span>
            </div>
            @for (avikelse of reportAvikelseService.unsentReportAvikelseItem; track avikelse) {
                <div>
                    <app-report-avikelse-tab-item [avikelseItemWithFeature]="avikelse"></app-report-avikelse-tab-item>
                </div>
            }
        </div>
    }
</div>

import {Component, Inject} from '@angular/core';
import {LayerListItem, TrvNgMapService} from "@trafikverket/trv-ng-map";
import {NvdbNavigationService} from "@app/service/nvdb-navigation.service";

@Component({
    selector: 'app-tecken-forklaring-tab',
    standalone: true,
    imports: [],
    templateUrl: './tecken-forklaring-tab.component.html',
    styleUrl: './tecken-forklaring-tab.component.scss'
})
export class TeckenForklaringTabComponent {

    constructor(
        @Inject(TrvNgMapService) public trvMapService: TrvNgMapService
    ) {
    }

    public getLegendGraphics(layer: LayerListItem) {
        if (layer.legendGraphics) {
            return layer.legendGraphics;
        }
        return "";
    }

}

import { Fill, Icon, Stroke, Style } from "ol/style";

export const NvdbReportStyleHidden = new Style({
    fill: new Fill({
        color: "rgba(0, 0, 0, 0)",
    }),
    stroke: new Stroke({
        color: "rgba(0, 0, 0, 0)",
    }),
});

export const NvdbReportStyle = new Style({
    fill: new Fill({
        color: "rgba(215, 0, 0, 0.2)",
    }),
    stroke: new Stroke({
        color: "rgba(215, 0, 0, 1)",
        width: 3,
    }),
    image: new Icon({
        anchor: [0.5, 1],
        anchorXUnits: "fraction",
        anchorYUnits: "fraction",
        scale: 2,
        src: "assets/img/pins/svg/_map-marker-hover.svg",
    }),
});

export const NvdbReportStyleHover = new Style({
    fill: new Fill({
        color: "rgba(95, 0, 0, 0.4)",
    }),
    stroke: new Stroke({
        color: "rgba(95, 0, 0, 1)",
        width: 6,
    }),
    image: new Icon({
        anchor: [0.5, 1],
        anchorXUnits: "fraction",
        anchorYUnits: "fraction",
        scale: 2.2,
        rotation: 0.1,
        src: "assets/img/pins/svg/_map-marker-static.svg",
    }),
});

export const NvdbReportStyleFocused = new Style({
    fill: new Fill({
        color: "rgba(30, 92, 136, 0.4)",
    }),
    stroke: new Stroke({
        color: "rgba(30, 92, 136, 1)",
        width: 6,
    }),
    image: new Icon({
        anchor: [0.5, 1],
        anchorXUnits: "fraction",
        anchorYUnits: "fraction",
        scale: 2.2,
        rotation: 0.1,
        src: "assets/img/pins/svg/_map-marker-edit.svg",
    }),
});

export const NvdbReportStyleLoadingSnapGrip = new Style({
    stroke: new Stroke({
        color: "rgba(255,0,0,0.3)",
        width: 3,
    }),
    fill: new Fill({
        color: "rgba(215, 0, 0, 0.2)",
    }),
    image: new Icon({
        anchor: [0.5, 0.9],
        anchorXUnits: "fraction",
        anchorYUnits: "fraction",
        scale: 0.35,
        src: "assets/img/pins/pin_red.png",
    }),
});

@if (nvdbNavigation.VIEWMODE) {
    <app-reports-tab-container></app-reports-tab-container>
} @else {
    <div class="c-report" [trv-loading]='!reportService.reportDataLoaded'>
        <div class="c-report__head" style="padding: 1rem 1rem 0rem 1rem">
            <div class="c-report__head__handle">
                <div role="group" class="btn-group dataleverans-report-group" >
                    <button
                        class="btn btn-outline-primary"
                        style="margin: 0; border-right: 0"
                        [ngClass]="{
          active: trvMapService.getTool() == MapTools.DataLeveransReportPoint
          }"
                        (click)="setMapTool(MapTools.DataLeveransReportPoint)"
                        [position]="toolTipPositions"
                        trvTooltip="Rapporteringsverktyg (punkt)"
                        aria-label="Rapporteringsverktyg (punkt)"
                    >
                        <fa-icon [icon]="faMapMarkerAlt"></fa-icon>
                    </button>
                    <div class="btn-divider"></div>
                    <button
                        class="btn btn-outline-primary"
                        style="margin: 0; border-left: 0; border-right: 0"
                        [ngClass]="{
          active: trvMapService.getTool() == MapTools.DataLeveransReportLine
          }"
                        (click)="setMapTool(MapTools.DataLeveransReportLine)"
                        trvTooltip="Rapporteringsverktyg (linje)"
                        aria-label="Rapporteringsverktyg (linje)"
                        [position]="toolTipPositions"
                    >
                        <fa-icon [icon]="faRoute"></fa-icon>
                    </button>
                    <div class="btn-divider"></div>
                    <button
                        class="btn btn-outline-primary"
                        style="margin: 0; border-left: 0; border-right: 0"
                        [ngClass]="{
          active: trvMapService.getTool() == MapTools.DataLeveransReportPolygon
          }"
                        (click)="setMapTool(MapTools.DataLeveransReportPolygon)"
                        trvTooltip="Rapporteringsverktyg (polygon)"
                        aria-label="Rapporteringsverktyg (polygon)"
                        [position]="toolTipPositions"
                    >
                        <fa-icon [icon]="faDrawPolygon"></fa-icon>
                    </button>
                    <div class="btn-divider"></div>
                    <button
                        class="btn btn-outline-primary"
                        style="margin: 0; border-left: 0; border-right: 0"
                        [ngClass]="{
          active: trvMapService.getTool() == MapTools.DataLeveransReportTranslate
          }"
                        (click)="setMapTool(MapTools.DataLeveransReportTranslate)"
                        trvTooltip="Flytta geometri"
                        aria-label="Flytta geometri"
                        [position]="toolTipPositions"
                    >
                        <fa-icon [icon]="faMove"></fa-icon>
                    </button>
                    <div class="btn-divider"></div>
                    <button
                        class="btn btn-outline-primary"
                        style="margin: 0; border-left: 0; border-right: 0"
                        [ngClass]="{
          active: trvMapService.getTool() == MapTools.DataLeveransReportEdit
          }"
                        (click)="setMapTool(MapTools.DataLeveransReportEdit)"
                        trvTooltip="Editera geometri"
                        aria-label="Editera geometri"
                        [position]="toolTipPositions"
                    >
                        <fa-icon [icon]="faEdit"></fa-icon>
                    </button>
                    <div class="btn-divider"></div>
                    <button
                        class="btn btn-outline-primary"
                        style="margin: 0; border-left: 0"
                        [ngClass]="{
          active: trvMapService.getTool() == MapTools.DataLeveransReportDelete
          }"
                        (click)="setMapTool(MapTools.DataLeveransReportDelete)"
                        trvTooltip="Ta bort geometri"
                        aria-label="Ta bort geometri"
                        [position]="toolTipPositions"
                    >
                        <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                </div>
            </div>
            <div class="c-report__head__toggle no-select">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" [formControl]="snapToNet" id="snap_to_net"/>
                    <label class="form-check-label" for="snap_to_net">Fäst mot nät</label>
                </div>
            </div>
        </div>

        @if (!snapToNet.value) {
            <div style="margin-top: 1rem"></div>
        }

        <!-- TODO nät typen har ingen påverkan just nu -->
        @if (snapToNet.value) {
            <div class="c-report__body">
                @if (snapToNet.value) {
                    <div>
                        <div class="no-select mb-1">Välj aktivt nät:</div>
                        <trv-select
                            [formControl]="snapToNetType"
                            placeholder="Välj ett objekt"
                            [source]="nvdbNavigation.networkTypes"
                            valueKey="id"
                            [searchMethod]="searchMethod"
                            [enableSearch]="false"
                            [displayMethod]="displayObject"
                            [disableClear]="true"
                        ></trv-select>
                    </div>
                }
            </div>
        }
        <app-reports-tab-container></app-reports-tab-container>
    </div>
}

import {AfterViewInit, ChangeDetectorRef, Component, Inject, Input, ViewChild} from "@angular/core";
import {ReportsTableComponent} from "../reports-table/reports-table.component";
import {FormBuilder} from "@angular/forms";
import {
    TrvGlobalMessagesService,
    TrvModalRef,
    TrvModalService,
    TrvLoadingBundle,
    TrvTooltipDirective, TrvLoadingDirective
} from "trv-ng-common";
import {ReportService} from "@app/service/report.service";
import {H} from "@angular/cdk/keycodes";

@Component({
    selector: "app-send-reports-modal",
    templateUrl: "./send-reports-modal.component.html",
    styleUrls: ["./send-reports-modal.component.scss"],
    standalone: true,
    imports: [TrvLoadingBundle, ReportsTableComponent, TrvTooltipDirective, TrvLoadingDirective],
})
export class SendReportsModalComponent implements AfterViewInit {
    @ViewChild(ReportsTableComponent, {static: false}) sendMultipleComponent: ReportsTableComponent | null = null;
    @Input() reportGroupIds: number[] = [];

    constructor(
        @Inject(ReportService) public reportService: ReportService,
        private cdref: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private globalMessagesService: TrvGlobalMessagesService,
        private trvModalService: TrvModalService,
        private modalRef: TrvModalRef<SendReportsModalComponent>
    ) {
    }

    ngAfterViewInit() {
        setTimeout(() => {
            // confirm close on click outside
            const backdrop = document.querySelector(".cdk-overlay-backdrop.cdk-overlay-dark-backdrop.cdk-overlay-backdrop-showing");
            if (backdrop) {
                backdrop.addEventListener("click", () => this.attemptClose());
            }
        }, 0);
    }

    public attemptClose(): void {
        if (
            Object.keys(this.reportService!.pendingReportGroupNameChanges).length > 0 ||
            this.reportService.reportItemsWithChanges.length > 0
        ) {
            this.trvModalService
                .confirm(
                    "Osparade ändringar",
                    "Om du stänger kommer dina osparade ändringar försvinna. Är du säker att du vill fortsätta?",
                    "Ja",
                    "Nej",
                    {disposeOnBackdropClick: true}
                )
                .afterCloseWithType()
                .subscribe(confirmed => {
                    if (confirmed.closingEventType === "close") {
                        for (const groupId of this.reportGroupIds) {
                            const group = this.reportService.reports.find(group => group.id === groupId)!;
                            for (const reportItem of group.reportItems) {
                                this.reportService.resetItemForm(reportItem.id);
                            }
                        }
                        this.modalRef.dismiss();
                    }
                });
        } else {
            this.modalRef.dismiss();
        }
    }

    public close(): void {
        this.attemptClose();
    }

    public dismiss(): void {
        this.attemptClose();
    }

    loading = false;

    public async saveAllChanges() {
        this.loading = true;

        try {
            await this.reportService.updateMultipleReports(this.sendMultipleComponent!.allReports);

            this.globalMessagesService.success("Alla ärenden uppdaterades.");

            this.sendMultipleComponent!.editableReportItems = [];
            this.sendMultipleComponent!.editableReportGroups = [];
        } catch (e) {
            this.globalMessagesService.error("Ett fel uppstod när alla ändringar skulle sparas.");
        }

        this.loading = false;

        this.sendMultipleComponent!.cdref.detectChanges();
        this.cdref.detectChanges();
    }

    public hasChanges(){
        const includedGroupsHasReportItemWithChanges = this.reportGroupIds.some(a => this.reportService.reports.find(b => b.id === a)?.reportItems.some(b => this.reportService.reportItemsWithChanges.includes(b.id)))
        const includedGroupsHasChanges = Object.keys(this.reportService.pendingReportGroupNameChanges).some(a => this.reportGroupIds.includes(parseInt(a)))

        return includedGroupsHasReportItemWithChanges || includedGroupsHasChanges
    }
    public async send() {
        this.loading = true;

        const hasChanges = this.hasChanges()
        this.trvModalService
            .confirm(`Bekräfta ${hasChanges ? 'spara och ' : ''}skicka in ärenden`, `Är du säker att du vill ${hasChanges ? 'spara dina ändringar och ' : ''}skicka in ärendena?`, `${hasChanges ? 'Spara och Skicka in' : 'Skicka in'}`, "Avbryt", {
                disposeOnBackdropClick: true,
            })
            .afterCloseWithType()
            .subscribe(async (event: any) => {
                if (event.closingEventType !== "close") {
                    this.loading = false;
                    return;
                }

                try {
                    if (hasChanges) {
                        await this.saveAllChanges()
                    }
                } catch (e) {
                    this.loading = false;
                    this.globalMessagesService.error(`Ett fel uppstod när ärendena skulle sparas in.`);
                    return
                }

                // make sure the report still exists when sending it in
                const reportsToSend = this.reportService.reports
                    .filter(a => this.sendMultipleComponent!.reportGroupIds.includes(a.id))
                    .map(a => a.id);
                try {
                    await this.reportService.sendReports(reportsToSend);

                    this.globalMessagesService.success(`${reportsToSend.length} ärenden har skickats in.`);
                    this.modalRef.dismiss();
                } catch (e) {
                    this.loading = false;
                    this.globalMessagesService.error(`Ett fel uppstod när ärendena skulle skickas in.`);
                }
            });
    }

    Object = Object;
}

import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    faMapMarkerAlt,
    faRoute,
    faVectorSquare,
    faEllipsisV,
    faPlusSquare,
    faPlusCircle,
    faAdd,
    faRemove,
    faTrash,
    faEdit,
    faUpDownLeftRight,
    faDrawPolygon,
} from "@fortawesome/free-solid-svg-icons";
import { LayerListItem, MapTools, MapToolsType, TrvNgMapService } from "@trafikverket/trv-ng-map";
import { ReportItem, ReportService } from "src/app/service/report.service";
import { svgPlusSquare } from "src/app/shared/utils/svg-helper-data";
import {
    TrvFormUtilityService,
    TrvModalService,
    TrvPopoverPosition,
    TrvPopoverRef,
    TrvSelectOption,
    TrvTooltipPosition,
    TrvUtilityService,
    TrvLoadingBundle,
    TrvTooltipDirective,
    TrvSelectComponent,
    TrvLoadingDirective
} from "trv-ng-common";
import { SvgIcon } from "src/app/shared/utils/svg-helper";
import { TrvMapExtensionService } from "src/app/service/trv-map-extension.service";
import { RouteService } from '@app/service/route.service'
import { NvdbNavigationService } from '@app/service/nvdb-navigation.service'
import {
    ReportEditPopoverComponent
} from '@components/map-menu/report-tab/report-edit-popover/report-edit-popover.component'
import { ReportsTabContainerComponent } from "./reports-tab-container/reports-tab-container.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgClass } from "@angular/common";
@Component({
    selector: 'app-report-tab',
    templateUrl: './report-tab.component.html',
    styleUrls: ['../../map-menu/map-menu.component.scss', './report-tab.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        TrvLoadingBundle,
        TrvTooltipDirective,
        NgClass,
        FontAwesomeModule,
        ReactiveFormsModule,
        TrvSelectComponent,
        ReportsTabContainerComponent,
        TrvLoadingDirective
    ],
})
export class ReportTabComponent {
    public faMapMarkerAlt: IconProp = faMapMarkerAlt as IconProp;
    public faRoute: IconProp = faRoute as IconProp;
    public faVectorSquare: IconProp = faVectorSquare as IconProp;
    public faDrawPolygon: IconProp = faDrawPolygon as IconProp;
    public faAdd: IconProp = faAdd as IconProp;
    public faMove: IconProp = faUpDownLeftRight as IconProp;
    public faEdit: IconProp = faEdit as IconProp;
    public faTrash: IconProp = faTrash as IconProp;

    public iconPlusSquare: SvgIcon = svgPlusSquare;

    public attached: AttachedType = AttachedType.Point;

    public popoverPosition: TrvPopoverPosition[] = ["bottom", "bottom-end", "bottom-start", "right", "right-end", "top"];
    public popoverComponent = ReportEditPopoverComponent;

    public toolTipPositions: TrvTooltipPosition[] = ["bottom", "right"];

    public MapTools = MapTools;

    // why do i have to include null if i initialize it instantly, typescript please
    public snapToNet = this.nvdbNavigation.reportMenuState.snapToNet;
    public snapToNetType = this.nvdbNavigation.reportMenuState.snapToNetType;

    constructor(
        @Inject(TrvNgMapService) public trvMapService: TrvNgMapService,
        @Inject(TrvModalService) private trvModalService: TrvModalService,
        @Inject(TrvMapExtensionService)
        public trvMapExtensionService: TrvMapExtensionService,
        private formUtilityService: TrvFormUtilityService,
        private trvUtilityService: TrvUtilityService,
        public reportService: ReportService,
        public routeService: RouteService,
        @Inject(NvdbNavigationService)
        public nvdbNavigation: NvdbNavigationService
    ) {
        this.snapToNet.valueChanges.subscribe(value => {
            this.routeService.snapToNetType = value ? this.snapToNetType.value : false;
            this.updateNetVisability();
        });
        this.snapToNetType.valueChanges.subscribe(value => {
            this.routeService.snapToNetType = this.snapToNet.value ? value : false;
        });

        this.routeService.snapToNetType = this.snapToNet.value ? this.snapToNetType.value : false;
    }

    // the ids of nets that were automatically activated. We need to track these since we should
    // not decative nets that were activated manually
    private autoActivatedLayerNames: string[] = [];
    private VAGNAT_LAYER_NAME = "EditSupportVagnatBil";
    private VAGNAT_LAYER_ID = "vagnatBil";

    // todo add the other networks, has to be done in the backend though

    public updateNetVisability() {
        if (!this.trvMapService.trvMap) return;

        const vagnatLayer = this.trvMapService.trvMap.trvLayer.wmsLayerListItems.find(
            (layer: LayerListItem) => layer.id === "vagnatBil"
        );

        const activeToolIsPointOrLine =
            this.trvMapService.trvMap.activeMapTool?.value === MapTools.DataLeveransReportLine ||
            this.trvMapService.trvMap.activeMapTool?.value === MapTools.DataLeveransReportPoint;
        const isVagnatLayerInactive = !this.trvMapService.trvMap.trvLayer.wmsLayerListItems.find(
            (layer: LayerListItem) => layer.id === this.VAGNAT_LAYER_ID
        )?.active;

        const shouldActivateVagnatLayer = this.snapToNet.value && activeToolIsPointOrLine;

        const wasVagnatLayerAutomaticallyActivated = this.autoActivatedLayerNames.some(id => id === this.VAGNAT_LAYER_ID);

        if (shouldActivateVagnatLayer && isVagnatLayerInactive) {
            this.trvMapService.trvMap?.trvLayer.setLayerVisibility(vagnatLayer!, true);
            this.autoActivatedLayerNames.push(this.VAGNAT_LAYER_ID);
        } else if (!shouldActivateVagnatLayer && wasVagnatLayerAutomaticallyActivated) {
            this.trvMapService.trvMap?.trvLayer.setLayerVisibility(vagnatLayer!, false);
            this.autoActivatedLayerNames = this.autoActivatedLayerNames.filter(name => name !== this.VAGNAT_LAYER_ID);
        }

    }

    public displayObject = (object: { id: number; name: string }) => {
        return object.name;
    };

    public searchMethod = (searchInput: string, itemValue: TrvSelectOption, itemIndex: number, options: TrvSelectOption[]) => {
        const label = this.trvUtilityService.removeHtml(itemValue.label.toLowerCase());
        const matchIndex = label.search(this.trvUtilityService.escapeRegExp(searchInput.toLowerCase()));
        return matchIndex >= 0;
    };

    public setMapTool(mapTool: MapToolsType) {
        if (this.trvMapService.trvMap?.activeMapTool?.value == mapTool) {
            this.trvMapService.trvMap?.changeActiveMapTool(MapTools.Pointer);
        } else {
            this.trvMapService.trvMap?.changeActiveMapTool(mapTool);
        }
        this.updateNetVisability();
    }

    /*public setMapToolMode(reportToolMode: ReportToolMode) {
        const trvMapExtenstion = this.trvMapExtensionService.trvMapExtension;
        trvMapExtenstion.setCurrentReportToolMode(reportToolMode);
    }

    public reportToolModeActive(reportToolMode: ReportToolMode) {
        let res = false;
        const trvMapExtenstion = this.trvMapExtensionService.trvMapExtension;
        if (trvMapExtenstion) {
            res = trvMapExtenstion.currentReportToolMode === reportToolMode;
        }

        return res;
    }*/

    public anyReportToolActive() {
        let res = false;
        if (this.trvMapService.trvMap) {
            let currTool = this.trvMapService.trvMap.activeMapTool?.value;
            res = currTool === MapTools.NvdbReportPoint || currTool === MapTools.NvdbReportLine || currTool === MapTools.NvdbReportPolygon;
        }
        return res;
    }

    public setAttachedType(attached: AttachedType) {
        this.attached = attached;
    }

    public goToReportItem(reportItem: ReportItem) {
        if (reportItem.feature) {
            this.trvMapService.trvMap?.zoomInOnFeature(reportItem.feature, 1, this.nvdbNavigation.getPaddingForZoomToExtentOnMainMap(100))
        }
    }

    onShowComponent(ref: TrvPopoverRef<ReportEditPopoverComponent>): void { }
}

export enum AttachedType {
    Point,
    Line,
    Polygon,
}

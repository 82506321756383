import { Pipe, PipeTransform } from '@angular/core';
import { Report } from '@app/service/report.service'

@Pipe({
  name: 'allReportItemIdsInList',
  standalone: true
})
export class AllReportItemIdsInListPipe implements PipeTransform {
    transform(args: { report: Report; itemIds: number[] }): boolean {
        for (const id of args.report.reportItems.map(a => a.id)) {
            if (!args.itemIds.includes(id)) return false;
        }
        return true;
    }
}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
    ReactiveFormsModule,
} from "@angular/forms";
import { Report, ReportService } from "src/app/service/report.service";
import { SvgIcon } from "src/app/shared/utils/svg-helper";
import { svgCross } from "src/app/shared/utils/svg-helper-data";
import { TrvFormUtilityService, TrvFormValidationErrorMap, TrvGlobalMessagesService, TrvLoadingBundle, TrvFormBundle } from "trv-ng-common";
import { focusOnFirstInvalidControl } from "../../../shared/utils/utils";
import { ReportStatusesEnum } from "../../../_api/dataleverans/models";

@Component({
    selector: "app-report-edit",
    templateUrl: "./report-edit.component.html",
    styleUrls: ["./report-edit.component.scss"],
    standalone: true,
    imports: [TrvLoadingBundle, TrvFormBundle, ReactiveFormsModule],
})
export class ReportEditComponent {
    public iconX: SvgIcon = svgCross;

    public form: UntypedFormGroup;
    public validationErrorMap: TrvFormValidationErrorMap = {
        name: {
            required: "Måste anges.",
            maxlength: "Max 30 tecken.",
            nameExists: "Namnet är redan taget.",
        },
    };

    // TODO does this need reportGroupEdit? Isnt it just for creating?
    @Input() public reportGroupToEdit: Report | null = null;
    @Input() public showCloseButton: boolean = true;
    @Output() public closeEmitter: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private formBuilder: FormBuilder,
        private formUtilityService: TrvFormUtilityService,
        private globalMessagesService: TrvGlobalMessagesService,
        private reportService: ReportService
    ) {
        this.form = formBuilder.group({
            name: [null, Validators.compose([Validators.required, Validators.maxLength(30), this.nameExists()])],
        });
    }

    ngAfterViewInit(): void {
        if (this.reportGroupToEdit) {
            this.form.patchValue({
                name: this.reportGroupToEdit.name,
            });
        }
    }

    get name() {
        return this.form.get("name") as FormControl;
    }

    nameExists = (): ValidatorFn => {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!this.form) return null;

            const nameExists = this.reportService.reports.some(group => group.name === control.value);
            const nameHasChanged = this.reportGroupToEdit && this.reportGroupToEdit.name !== control.value;
            return nameHasChanged && nameExists ? { nameExists: { value: control.value } } : null;
        };
    };

    loading = false;

    public async submit() {
        if (!this.form.valid) {
            this.formUtilityService.isValid(this.form);
            focusOnFirstInvalidControl();
            this.globalMessagesService.error("Var vänlig och korrigera felen, det gick ej att skapa nytt ärende.");

            return;
        }

        this.loading = true;

        try {
            await this.reportService.createOrUpdateReport(this.name.value, this.reportGroupToEdit?.id);

            if (this.reportGroupToEdit?.id) {
                this.globalMessagesService.success(`Ärendenamnet uppdaterades till "${this.name.value}".`);
            } else {
                this.globalMessagesService.success(`Ärende "${this.name.value}" skapades.`);
            }
            this.close();
        } catch (e) {
            if (this.reportGroupToEdit?.id) {
                this.globalMessagesService.error(`Ett fel uppstod när ärendenamnet skulle uppdateras.`);
            } else {
                this.globalMessagesService.error(`Ett fel uppstod när ärendet skulle skapas.`);
            }
        }
        this.loading = false;
    }

    public close() {
        this.closeEmitter.emit();
    }

    protected readonly ReportStatusesEnum = ReportStatusesEnum;
}

import { Component, Inject, Input, OnInit } from "@angular/core";
import { faChevronDown, faChevronUp, faCircleInfo, faMagnifyingGlassPlus } from "@fortawesome/free-solid-svg-icons";
import {
    svgCheck,
    svgChevronDown,
    svgCircleInfo,
    svgCross,
    svgPencil,
    svgTrash
} from "src/app/shared/utils/svg-helper-data";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { Report, ReportItem, ReportService } from "src/app/service/report.service";
import { ReportStatusesEnum } from "@_api/pakarta/models/report-statuses-enum";
import {felTyperInfoMessage, FORMATTED_REPORT_STATUS} from "@shared/constants";
import { AllItemsInListPipe } from "@pipes/item-in-list.pipe";
import { ReportItemComponent } from "../report-item/report-item.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ReportItemExpandableHeaderComponent } from "@components/reports/report-item-expandable-header/report-item-expandable-header.component";
import { TrvModalService, TrvTableColumn } from "trv-ng-common";
import { AjourhallningAction } from "@shared/enums";
import { ActivatedRoute } from "@angular/router";
import { NvdbNavigationService } from "@app/service/nvdb-navigation.service";
import { combineLatest, filter, startWith } from "rxjs";
import { AjourhallningActionModalComponent } from "@components/modals/ajourhallning-action-modal/ajourhallning-action-modal.component";
import { ValueFromObjectListPipe } from "@pipes/value-from-object-list.pipe";
import { DatePipe, NgClass } from "@angular/common";
import { CdkTableModule } from "@angular/cdk/table";
import { groupExpand } from '@shared/animations'
import { TrvNgMapComponent, TrvNgMapService } from "@trafikverket/trv-ng-map";
import _downscaled from "../../../../assets/data/layer-data/nvdb-layers-downscaled.json";
import { createEmpty, extend } from "ol/extent";
import { NvdbReportStyle, NvdbReportStyleHover } from "@app/trv-map-extension/trv-map-extension-styles";
import { Feature } from "ol";
import { DateTime } from 'luxon'
import { environment } from '../../../../environments/environment'
import { ReportStatusPipe } from '@pipes/report-status.pipe'
import { Circle, Fill, Style } from "ol/style";
import {SvgIconComponent} from "@components/utils/svg-icon/svg-icon.component";
import {TrvModalConfig} from "trv-ng-common/lib/components/modal/modal-config";

@Component({
    selector: "app-display-report",
    templateUrl: "./display-report.component.html",
    styleUrls: ["./display-report.component.scss"],
    animations: [groupExpand],
    standalone: true,
    imports: [
        FontAwesomeModule,
        ReportItemComponent,
        AllItemsInListPipe,
        ReportItemExpandableHeaderComponent,
        ValueFromObjectListPipe,
        NgClass,
        CdkTableModule,
        TrvNgMapComponent,
        DatePipe,
        ReportStatusPipe,
        SvgIconComponent,
    ],
})
export class DisplayReportComponent implements OnInit {
    public layerdata: any = _downscaled;
    public featureLookup: { [reportItemId: number]: Feature } = {};

    @Input() report!: Report;

    public showEventLogg = true;
    public showMap = true;
    public openedReportItems: number[] = [];

    // ICONS
    protected readonly faChevronDown = faChevronDown;
    protected readonly faChevronUp = faChevronUp;
    protected readonly faMagnifyingGlassPlus = faMagnifyingGlassPlus;
    protected readonly circleInfoIcon = svgCircleInfo;
    //

    // EVENT TABLE
    public tableColumns: TrvTableColumn[] = [];
    public visibleColumnKeys: string[] = [];
    //

    // OTHER
    protected readonly ReportStatusesEnum = ReportStatusesEnum;
    protected readonly FORMATTED_REPORT_STATUS = FORMATTED_REPORT_STATUS;

    protected readonly DateTime = DateTime;
    protected readonly environment = environment;
    //

    constructor(
        @Inject(NvdbNavigationService)
        public nvdbNavigation: NvdbNavigationService,
        @Inject(ReportService) public reportService: ReportService,
        private trvModalService: TrvModalService,
        @Inject(TrvNgMapService) private trvMapService: TrvNgMapService
    ) {

        this.layerdata.BackgroundLayer.LayerList.forEach((layer: any) => {
            if (layer.hasOwnProperty("Url")) {
                let url: string = layer.Url;
                layer.Url = url.replace("{api}", environment.baseApiUrl);
            }
        });

        this.tableColumns = [
            { key: "date", label: "Datum", visible: true, disabled: false },
            { key: "eventType", label: "Händelse", visible: true, disabled: false },
            { key: "user", label: "Användare", visible: true, disabled: false },
            { key: "comment", label: "Kommentar", visible: true, disabled: false },
        ];
        this.visibleColumnKeys = this.tableColumns.map(a => a.key);
    }

    ngOnInit(): void {
        const onMapLoadedForId = this.trvMapService.onMapLoaded("handle-report" + this.report.id);
        onMapLoadedForId.subscribe(() => {

            this.addFeaturesToMap(this.report.id);
        });
    }

    public toggleMap() {
        this.showMap = !this.showMap;
    }

    public toggleEventLog() {
        this.showEventLogg = !this.showEventLogg;
    }

    public toggleReportItemOpen(itemId: number) {
        if (!this.openedReportItems.includes(itemId)) {
            this.openedReportItems = [...this.openedReportItems, itemId];
        } else {
            this.openedReportItems = this.openedReportItems.filter(id => id !== itemId);
        }
    }

    public mouseEnterReportItem(item: ReportItem) {
        if (this.report && this.featureLookup[item.id]) {
            this.featureLookup[item.id].setStyle(NvdbReportStyleHover);
        }
    }

    public mouseLeaveReportItem(item: ReportItem) {
        if (this.report && this.featureLookup[item.id]) {
            this.featureLookup[item.id].setStyle(NvdbReportStyle);
        }
    }

    public zoomToReportItem(item: ReportItem) {
        const trvMap = this.trvMapService.getTrvMap("handle-report" + this.report.id);
        if (trvMap && this.featureLookup[item.id]) {
            trvMap.zoomInOnFeature(this.featureLookup[item.id], 1000, [40,40,40,40]);
        }
    }

    public handleZoomAll(event: Event) {
        event.stopPropagation();
        if (!this.showMap) this.showMap = true;
        this.zoomToall();
    }

    public zoomToall() {
        const trvMap = this.trvMapService.getTrvMap("handle-report" + this.report.id);
        if (trvMap) {
            let extent = createEmpty();
            let zoomIn = this.report.reportItems.length > 0;
            let onlyOnefeature = undefined
            Object.entries(this.featureLookup).forEach(([itemId, feature]) => {
                extend(extent, feature.getGeometry()?.getExtent()!);
                if (Object.entries(this.featureLookup).length == 1) {
                    onlyOnefeature = feature;
                }
            });
            if (onlyOnefeature != undefined)
                trvMap.zoomInOnFeature(onlyOnefeature, 1000, [40,40,40,40])
            else
                if (zoomIn) trvMap.zoomInOnExtent(extent, 1000, [40,40,40,40]);
        }
    }

    public addFeaturesToMap(groupId: number) {
        if (this.report) {
            const trvMap = this.trvMapService.getTrvMap("handle-report" + groupId);
            if (trvMap) {
                trvMap.trvLayer.workingLayer?.getSource()?.clear();
                let extent = createEmpty();
                let zoomIn = this.report.reportItems.length > 0;
                let onlyOnefeature = undefined
                this.report.reportItems.forEach(item => {
                    const feature = trvMap.trvLayer.getFeatureFromWkt(item.wkt);
                    trvMap.trvLayer.workingLayer?.getSource()?.addFeature(feature);

                    feature.setStyle(NvdbReportStyle);
                    if (this.report?.reportItems.length == 1) {
                        onlyOnefeature = feature;
                    }
                    extend(extent, feature.getGeometry()?.getExtent()!);
                    this.featureLookup[item.id] = feature;
                });

                if (onlyOnefeature != undefined)
                    trvMap.zoomInOnFeature(onlyOnefeature, 0, [40,40,40,40])
                else
                    if (zoomIn) trvMap.zoomInOnExtent(extent, 0, [40,40,40,40]);
            }
        }
    }

    unlockedInfo() {
        const config: TrvModalConfig = {
            disposeOnBackdropClick: true,
        }
        if (this.nvdbNavigation.isMobileDevice) config.size = 'fullscreen'

        this.trvModalService.info("Information om upplåst ärende", "Ärenden är låsta till användare som standard. Om ett ärende är upplåst kan det ses av alla användare om de har fyllt i rätt ärende UID.", config);
    }
}

<div class='c-report__list__report'
     (click)="itemClicked()"
     [ngStyle]="nvdbNavigationService.currentlyHoveredReportItemInMap == reportItem.id ? {background:'#f1f2f3'} : null"
     [style.cursor]="disableDragAndDrop ? 'default' : 'move'" style='cursor: move' (mouseenter)='mouseEnter()'
     (mouseleave)='mouseLeave()'>
    <span class='me-3'>{{ sequenceNumber }}</span>
    @if (reportItem.geomtryType == GeometryType.POINT) {
        <fa-icon class='geom-icon' [icon]='faMapMarkerAlt'></fa-icon>
    } @else if (reportItem.geomtryType == GeometryType.LINE_STRING || reportItem.geomtryType ==
    GeometryType.MULTI_LINE_STRING) {
        <fa-icon class='geom-icon' [icon]='faRoute'></fa-icon>
    } @else if (reportItem.geomtryType == GeometryType.POLYGON || reportItem.geomtryType == GeometryType.MULTI_POLYGON) {
        <fa-icon class='geom-icon' [icon]='faDrawPolygon'></fa-icon>
    }
    <span class='c-report__list__report__name'>
        {{ reportItem.description }}
    </span>

    <div (click)='reportService.toggleItemVisible($event, reportItem.id)' class='c-report__list__report__show'
         [ngClass]='{ hidden: ({ items: [reportItem.id], list: reportService.hiddenReportItems} | allItemsInList) }'>
        @if (!({items: [reportItem.id], list: reportService.hiddenReportItems} | allItemsInList)) {
            <fa-icon [icon]='faEye'></fa-icon>
        } @else if ({items: [reportItem.id], list: reportService.hiddenReportItems} | allItemsInList) {
            <fa-icon [icon]='faEyeSlash'></fa-icon>
        }
    </div>

    <div class='c-report__list__report__handle'>
        <button class='btn btn-link c-report__list__report__name__menu' [trvPopover]='popoverComponent'
                aria-label="Förändring alternativ"
                [position]='popoverPosition' (shown)='onShowComponent($event)'
                (afterCloseWithType)='onAfterCloseWithType($event)'>
            <fa-icon [icon]='faEllipsisV'></fa-icon>
        </button>
    </div>
</div>

import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { TrvGlobalMessagesService, TrvModalService, TrvPopoverDirective, TrvPopoverPosition, TrvPopoverRef } from "trv-ng-common";
import { faEdit, faFloppyDisk, faTrash, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { SvgIconComponent } from "@components/utils/svg-icon/svg-icon.component";

@Component({
    selector: "app-file-options-popover",
    standalone: true,
    imports: [FaIconComponent, TrvPopoverDirective, SvgIconComponent],
    templateUrl: "./file-options-popover.component.html",
    styleUrl: "./file-options-popover.component.scss",
})
export class FileOptionsPopoverComponent {
    @Input() editable: boolean = false;
    @Input() fileId!: number;
    @Input() reportItemId!: number;

    @Output() download = new EventEmitter<void>();
    @Output() editDescription = new EventEmitter<void>();
    @Output() remove = new EventEmitter<void>();

    faEdit = faEdit;
    faTrashCan = faTrashCan;
    faFloppyDisk = faFloppyDisk;

    constructor(public trvPopoverRef: TrvPopoverRef) {}
}

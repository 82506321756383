import { AfterViewInit, ChangeDetectorRef, Component, Inject } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { svgArrowLeft, svgBackwards, svgCircleInfo, svgCross, svgMagnifyingGlass, svgMagnifyingPlus, svgPencil } from "@shared/utils/svg-helper-data";
import { LayerListItem, TrvNgMapOverlayService, TrvNgMapService } from "@trafikverket/trv-ng-map";
import { NvdbNavigationService } from "@app/service/nvdb-navigation.service";
import { NetDbService } from "@app/service/net-db.service";
import { ReportService } from "@app/service/report.service";
import {
    TrvFormUtilityService,
    TrvGlobalMessagesService,
    TrvModalService,
    TrvTooltipPosition,
    TrvUtilityService,
    TrvSelectComponent,
    TrvLoadingBundle,
    TrvToggleSwitchComponent,
    TrvDashboardBundle,
    TrvTooltipDirective,
} from "trv-ng-common";
import { TrvMapExtensionService } from "@app/service/trv-map-extension.service";
import { HttpClient } from "@angular/common/http";
import { FormBuilder, FormControl, FormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import { LocalStorageService } from "@app/service/local-storage.service";
import { AuthenticationService } from "@app/service/authentication.service";
import { DeliveryTypesEnum } from "@_api/dataleverans/models/delivery-types-enum";
import { SvgIcon } from "@shared/utils/svg-helper";
import { DataslagValueTypesEnum } from "@_api/dataleverans/models/dataslag-value-types-enum";
import { DataslagField } from "@_api/dataleverans/models/dataslag-field";
import { SvgIconComponent } from "../../utils/svg-icon/svg-icon.component";
import { LayerInfoModalComponent } from "@components/map-menu/layer-section/layer-info-modal/layer-info-modal.component";
import { firstValueFrom } from "rxjs";
import { DateTime } from "luxon";
import { RouterLink } from "@angular/router";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";

@Component({
    selector: "app-info-click-tab",
    templateUrl: "./info-click-tab.component.html",
    styleUrls: ["./info-click-tab.component.scss"],
    standalone: true,
    imports: [
        TrvSelectComponent,
        TrvLoadingBundle,
        TrvDashboardBundle,
        TrvTooltipDirective,
        TrvToggleSwitchComponent,
        ReactiveFormsModule,
        SvgIconComponent,
        RouterLink,
        FaIconComponent
    ],
})
export class InfoClickTabComponent implements AfterViewInit {
    public crossIcon: SvgIcon = svgCross;
    public searchIcon: SvgIcon = svgMagnifyingGlass;
    public searchIconPlus: SvgIcon = svgMagnifyingPlus;
    public circleInfoIcon: SvgIcon = svgCircleInfo;
    public arrowLeft: SvgIcon = svgArrowLeft;
    public penIcon: SvgIcon = svgPencil;
    public backwardsIcon: SvgIcon = svgBackwards;

    


    public toolTipPositions: TrvTooltipPosition[] = ["bottom", "right"];

    public autoZoomInfoObject = this.navigationService.isMobileDevice;

    constructor(
        @Inject(NetDbService) private netDbService: NetDbService,
        private cdref: ChangeDetectorRef,
        @Inject(TrvNgMapService) public trvMapService: TrvNgMapService,
        @Inject(TrvNgMapOverlayService)
        private trvMapOverlayService: TrvNgMapOverlayService,
        @Inject(NvdbNavigationService)
        public navigationService: NvdbNavigationService,
        @Inject(NetDbService) public netdbService: NetDbService,
        @Inject(ReportService) public reportService: ReportService,
        @Inject(TrvModalService) private trvModalService: TrvModalService,
        @Inject(TrvGlobalMessagesService)
        private globalMessagesService: TrvGlobalMessagesService,
        @Inject(TrvMapExtensionService)
        public trvMapExtensionService: TrvMapExtensionService,
        private formUtilityService: TrvFormUtilityService,
        private trvUtilityService: TrvUtilityService,
        private http: HttpClient,
        private formBuilder: FormBuilder,
        @Inject(LocalStorageService)
        private localStorageService: LocalStorageService,
        public authenticationService: AuthenticationService
    ) {
        this.autoZoomGroup = new FormGroup<ToggleSwitchFormGroup>({
            toggleSimple: new FormControl(this.autoZoomInfoObject),
        });

        this.autoZoomGroup.get("toggleSimple")!.valueChanges.subscribe(val => {
            if (val != null) this.autoZoomInfoObject = val;
        });
    }

    ngAfterViewInit(): void {
        this.trvMapExtensionService.trvMapExtension.currentInfoObjectsChanged.subscribe(() => {
            this.calculatePagination();
            this.navigationService.infoClickState.metaKeys = this.trvMapExtensionService.trvMapExtension.infoClickMetaKeys;
            if (
                this.navigationService.infoClickState.metaKeys.length > 0 &&
                this.navigationService.infoClickState.metaKeys.includes(this.trvMapExtensionService.trvMapExtension.currentInfoClickMetaKey)
            ) {
                this.navigationService.infoClickState.dataslagType.setValue(this.trvMapExtensionService.trvMapExtension.currentInfoClickMetaKey, {
                    onlySelf: true,
                });
            }
        });
    }

    public zoomToInfoObj() {
        if (this.trvMapExtensionService.trvMapExtension.currentInfoObjects.length == 0) return;
        const layer = this.trvMapExtensionService.trvMapExtension.infoClickTempLayer;
        this.trvMapService.trvMap!.zoomInOnExtent(
            layer.getSource()!.getExtent()!,
            1000,
            this.navigationService.getPaddingForZoomToExtentOnMainMap(50)
        );
    }

    public makeInfoClickOnMetaKey(metaKey: string) {
        const trvMap = this.trvMapService.trvMap!;
        const trvMapExtension = this.trvMapExtensionService.trvMapExtension!;
        let found = trvMap.trvLayer.wmsLayerListItems.find(layer => trvMap.trvLayer.getLayerData(layer, "metaKey"));
        const lay = trvMap.trvLayer.wmsLayerListItems.find(layer => trvMap.trvLayer.getLayerData(layer, "metaKey") == metaKey);
        if (lay) {
            trvMap.trvLayer.setLayerVisibility(lay, true);
            trvMapExtension.infoClicked(trvMapExtension.lastInfoClickExtent, trvMap);
        }
    }

    public clearDataProdukterAndMakeLastInfoclick() {
        const trvMap = this.trvMapService.trvMap!;
        const trvLayer = trvMap.trvLayer;
        const trvMapExtension = this.trvMapExtensionService.trvMapExtension!;

        trvLayer.wmsLayerListItems.forEach(layer => {
            if (layer.active && trvLayer.getLayerData(layer, "showInLayer") != true) {
                trvLayer.setLayerVisibility(layer, false);
            }
        });

        trvMapExtension.infoClicked(trvMapExtension.lastInfoClickExtent, trvMap);

    }

    public isFieldMetaKey(field: string) {
        const trvMap = this.trvMapService.trvMap!;
        let found = trvMap.trvLayer.wmsLayerListItems.find(layer => trvMap.trvLayer.getLayerData(layer, "metaKey"));
        const lay = trvMap.trvLayer.wmsLayerListItems.find(layer => trvMap.trvLayer.getLayerData(layer, "metaKey") == field);
        if (lay) return true;

        return false;
    }

    public autoZoomGroup!: FormGroup<ToggleSwitchFormGroup>;

    public displayObjectMetaKey = (metaKey: string) => {
        return this.trvMapExtensionService.trvMapExtension.getLayerNameFromMetakey(metaKey);
    };

    public metaKeySelected(metaKey: string) {
        this.trvMapExtensionService.trvMapExtension.changeInfoObject(metaKey);
        const layer = this.trvMapExtensionService.trvMapExtension.infoClickTempLayer;
        if (this.autoZoomInfoObject)
            this.trvMapService.trvMap!.zoomInOnExtent(
                layer.getSource()!.getExtent()!,
                1000,
                this.navigationService.getPaddingForZoomToExtentOnMainMap(50)
            );
    }

    public getInfoObjClass(index: number) {
        if (this.trvMapExtensionService.trvMapExtension.currentInfoObject == index) return "btn btn-primary";
        return "btn btn-outline-primary";
    }

    public toolTipPosition: TrvTooltipPosition[] = ["bottom", "right", "left"];

    public reportOnInfoObject() {
        const trvMapExtension = this.trvMapExtensionService.trvMapExtension;
        if (trvMapExtension.currentInfoObjects.length == 0) return;

        const infoObj = trvMapExtension.currentInfoObjects[trvMapExtension.currentInfoObject];
        const metakey = trvMapExtension.currentInfoClickMetaKey;
        const dataslagData = this.reportService.allDataslag[this.navigationService.reportAsForm.value!.id][
            DeliveryTypesEnum.NyEllerFörändradEllerRattadForeteelse
        ].find(dataslag => dataslag.metaKey === metakey)!;

        const feature = this.trvMapService.trvMap!.trvLayer.getFeatureFromWkt(JSON.parse(infoObj.WKT).Geom);
        const deliveryType = this.reportService.deliveryTypes.find(a => a.deliveryType === DeliveryTypesEnum.NyEllerFörändradEllerRattadForeteelse)!;

        if (!dataslagData) {
            // the dataslag does not exist as an option in the dropdown, so we write the values to the description instead.
            const prettyDataslag = "Dataprodukt: " + this.trvMapExtensionService.trvMapExtension.currentInfoClickName + "\n";
            const infoValues = Object.entries(infoObj)
                .filter(([key]) => key !== "WKT" && key !== "feature")
                .map(([key, value]) => `${key}: ${value}`)
                .join("\n");

            this.reportService.setActiveItemToCreateOrEdit({
                feature: feature,
                defaultData: {
                    deliveryType: deliveryType,
                    description: prettyDataslag + infoValues,
                },
            });
        } else {
            let dataslagFields: DataslagField[] = [];
            for (const [key, value] of Object.entries(infoObj)) {
                const dataslagField = dataslagData.fields.find(dataslagField => dataslagField.name.toLowerCase() === key.toLowerCase())!;
                if (!dataslagField) continue;

                if (dataslagField.valueType === DataslagValueTypesEnum.Enum) {
                    const dataslagValue = dataslagField.options!.find(a => a.description === value)!;
                    dataslagFields.push({
                        ...dataslagField,
                        value: dataslagValue?.description ?? undefined,
                        valueId: dataslagValue?.id ?? undefined,
                    });
                } else {
                    dataslagFields.push({
                        ...dataslagField,
                        value: value as string,
                        valueId: null,
                    });
                }
            }
            this.reportService.setActiveItemToCreateOrEdit({
                feature: feature,
                defaultData: {
                    deliveryType: deliveryType,
                    dataslag: [{ ...dataslagData, fields: dataslagFields }],
                },
            });
        }
    }

    public changeInfoObj(index: number) {
        this.trvMapExtensionService.trvMapExtension.setCurrentInfoObject(index, this.trvMapService.trvMap!);

        const layer = this.trvMapExtensionService.trvMapExtension.infoClickTempLayer;
        if (this.autoZoomInfoObject)
            this.trvMapService.trvMap!.zoomInOnExtent(
                layer.getSource()!.getExtent()!,
                1000,
                this.navigationService.getPaddingForZoomToExtentOnMainMap(50)
            );
        this.cdref.detectChanges();
    }

    calculatePagination() {
        if (!this.trvMapExtensionService.trvMapExtension.currentInfoObjects) return;
        const objectsPerPage = 1;
        const totalObjects = this.trvMapExtensionService.trvMapExtension.currentInfoObjects.length;
        const totalPages = Math.ceil(totalObjects / objectsPerPage);
        let visiblePages = 4;

        let startPage: number;
        let endPage: number;

        if (totalPages <= visiblePages) {
            startPage = 1;
            endPage = totalPages;
        } else {
            const halfVisiblePages = Math.floor(visiblePages / 2);
            if (this.trvMapExtensionService.trvMapExtension.currentInfoObject <= halfVisiblePages) {
                startPage = 1;
                endPage = visiblePages;
            } else if (this.trvMapExtensionService.trvMapExtension.currentInfoObject + halfVisiblePages >= totalPages) {
                startPage = totalPages - visiblePages - 1;
                endPage = totalPages;
            } else {
                startPage = this.trvMapExtensionService.trvMapExtension.currentInfoObject - halfVisiblePages + 1;
                endPage = this.trvMapExtensionService.trvMapExtension.currentInfoObject + halfVisiblePages;
            }
        }

        // Generate the array of page numbers
        const pages = Array(endPage - startPage + 1)
            .fill(0)
            .map((_, i) => startPage + i);

        this.navigationService.infoClickState.pagination = {
            startPage,
            endPage,
            totalPages,
            pages,
        };
    }

    public async infoAboutLayer(layer: LayerListItem) {
        if (!layer) {
            this.infoAboutUtvalda();
            return;
        }

        const modal = this.trvModalService.open(LayerInfoModalComponent, {
            disposeOnBackdropClick: true,
            size: this.navigationService.isMobileDevice ? "fullscreen" : "md",
        });
        const component = modal.componentInstance;
        component.title = layer.name;

        let metaKey: string = "";
        if (layer.trvData.hasOwnProperty("metaKey")) metaKey = (layer.trvData as any).metaKey;

        if (layer.trvData.hasOwnProperty("description")) {
            layer.description = (layer.trvData as any).description;
        } else {
            if (metaKey != "") {
                var desc = await firstValueFrom(this.netDbService.getDescription(metaKey));
                desc = desc.replace("\n", "<br>");
                layer.description = desc;
            }
        }

        component.text = layer.description ? layer.description : "Lagret saknar beskrivning.";
        component.layerVisibleFrom = layer.maxScaleDenominator ? Math.round(layer.maxScaleDenominator) : layer.maxScaleDenominator;
        component.legendGraphics = layer.legendGraphics;
        component.layer = layer;

        modal.afterCloseWithType().subscribe(closeParams => {
            //console.log(closeParams)
        });
    }

    public async infoAboutUtvalda() {
        const modal = this.trvModalService.open(LayerInfoModalComponent, {
            disposeOnBackdropClick: true,
            size: this.navigationService.isMobileDevice ? "fullscreen" : "md",
        });
        const component = modal.componentInstance;
        component.title = "Utvalda värden i aktuell punkt";
        component.text = "Visar utvalda värden i aktuell punkt.";

        /*
        let metaKey: string = "";
        if (layer.trvData.hasOwnProperty("metaKey")) metaKey = (layer.trvData as any).metaKey;

        if (layer.trvData.hasOwnProperty("description")) {
            layer.description = (layer.trvData as any).description;
        } else {
            if (metaKey != "") {
                var desc = await firstValueFrom(this.netDbService.getDescription(metaKey));
                desc = desc.replace("\n", "<br>");
                layer.description = desc;
            }
        }

        component.text = layer.description ? layer.description : "Lagret saknar beskrivning.";
        component.layerVisibleFrom = layer.maxScaleDenominator ? Math.round(layer.maxScaleDenominator) : layer.maxScaleDenominator;
        component.legendGraphics = layer.legendGraphics;
        component.layer = layer;*/

        modal.afterCloseWithType().subscribe(closeParams => {
            //console.log(closeParams)
        });
    }

    get showFirst(): boolean {
        if (!this.navigationService.infoClickState.pagination) return false;
        return this.navigationService.infoClickState.pagination.startPage > 1;
    }

    get showLast(): boolean {
        if (!this.navigationService.infoClickState.pagination) return false;
        return this.navigationService.infoClickState.pagination.endPage < this.trvMapExtensionService.trvMapExtension.currentInfoObjects.length;
    }

    getObjectKeys(obj: any): string[] {
        return Object.keys(obj);
    }

    public isLink(str: any): boolean {
        if (!(typeof str == "string" || str instanceof String)) return false;
        const exp = str.startsWith("\\") || str.startsWith("http") || str.startsWith("www");
        return exp;
    }

    public getUrl(url: string) {
        if (url.startsWith("\\")) return "http://nvdb2012.trafikverket.local/fileviewer/fileviewer.ashx?file=" + url;
        return url;
    }

    public formatValue(value: any, dataslagName?: string, fieldName?: string) {
        let valueAsString = value ? value + "" : "";

        //Special cases, not handled by enum in backend
        if (dataslagName == "Vägnummer" && fieldName == "Länkroll") {
            if (valueAsString == "1") return "Normal";
            if (valueAsString == "2") return "Syskon fram";
            if (valueAsString == "3") return "Syskon bak";
            if (valueAsString == "4") return "Gren";
        }

        if (dataslagName == "Vägnummer" && fieldName == "Värdväg/gästväg") {
            if (valueAsString == "-1") return "Värd";
            if (valueAsString == "0") return "Gäst";
        }

        const isNumber = valueAsString.trim() != "" && !isNaN(valueAsString.replace(/,/, ".") as any);
        //let isDate = !isNaN(Date.parse(value));
        let isDate = this.isDateTimeString(value);

        //isDate = false;
        if (isNumber) {
            valueAsString = valueAsString.replace(/,/, ".");
            return parseFloat(parseFloat(valueAsString).toFixed(2));
        } else if (isDate) {
            return new Date(value).toLocaleDateString("sv-SE");
        } else {
            return valueAsString;
        }
    }

    public isDateTimeString(value: any): boolean {
        if (typeof value !== "string") {
            return false;
        }

        // Regular expression to match common date and datetime formats
        const dateTimeRegex = /^\d{4}-\d{2}-\d{2}$|^\d{8}$|^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3})?(?:Z|([+-]\d{2}:\d{2}))?$/;

        if (!dateTimeRegex.test(value)) {
            return false;
        }

        // Handle yyyyMMdd format by converting it to yyyy-mm-dd
        if (/^\d{8}$/.test(value)) {
            value = `${value.substring(0, 4)}-${value.substring(4, 6)}-${value.substring(6, 8)}`;
        }

        const date = new Date(value);
        return !isNaN(date.getTime());
    }

    public displayValueName(value: string) {
        const trvMap = this.trvMapService.trvMap!;
        if (!value) return value;

        const lay = trvMap.trvLayer.wmsLayerListItems.find(layer => trvMap.trvLayer.getLayerData(layer, "metaKey") == value);

        if (lay) return this.makeFirstLetterUppercase(lay.name);

        return this.makeFirstLetterUppercase(value);
    }

    public showRow(field: string) {
        if (this.trvMapExtensionService.trvMapExtension.currentInfoClickMetaKey == "VeryUniqueKey:Utvalda värden i aktuell punkt") {
            //Hide empty
            let val =
                this.trvMapExtensionService.trvMapExtension.currentInfoObjects[this.trvMapExtensionService.trvMapExtension.currentInfoObject][field];

            if (val == "" || val == null || val == undefined) {
                return false;
            }
        }

        return !(field == "WKT" || field == "feature" || field == "DIRECTION");
    }

    public makeFirstLetterUppercase(value: string) {
        if (!value) return value; // Handle null, undefined, etc.

        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    protected readonly environment = environment;
}

interface ToggleSwitchFormGroup {
    toggleSimple: FormControl<boolean | null>;
}

<main style="min-width: 940px">
    <div class='p-page'>
        <div class='container'>
            <div class='c-breadcrumb'>
                <nav aria-label='Brödsmulor'>
                    <ol class='breadcrumb'>
                        <li class='breadcrumb-item'>
                            <a routerLink='/home'>Min sida</a>
                        </li>
                        <li class='breadcrumb-item'>
                            <a routerLink='/reports'>Ärenden</a>
                        </li>
                        @if (report) {
                            <li class='breadcrumb-item active' aria-current='page'>Ärende: {{ report.name }}</li>
                        }
                    </ol>
                </nav>
            </div>

            @if (report) {
                <div class="d-flex justify-content-between mt-1 mb-3">
                    <h2 class='header_text m-0'>Ärende: {{ report.name }}</h2>
                    <div class="d-flex gap-2">
                        <button class='btn btn-primary d-flex align-items-center gap-2' (click)='showInViewMode()'
                                [trvTooltip]="'Öppna ärendet i kartvyn'" [position]='toolTipPositions'>
                                <span>
                                    <fa-icon [icon]='faMap'></fa-icon>
                                </span>
                            Visa ärende i kartan
                        </button>
                        <button class='btn btn-primary d-flex align-items-center gap-2' (click)='export()'
                                [trv-loading]="createGeopackageLoading"
                        >
                            <fa-icon style='color: white' [icon]='faFileCirclePlus'></fa-icon>
                            Skapa geopackage-fil
                        </button>
                    </div>
                </div>

                <app-display-report [report]='report'></app-display-report>
            } @else if (loading) {
                <div class='no_report_container' [trv-loading]="true">
                    <h2>
                        Hämtar ärende...
                    </h2>
                </div>
            } @else if (invalidReportId) {
                <div class='no_report_container'>
                    <h2>
                        Id "{{ unformattedReportId }}" ej ett giltligt id. Id:et måste vara ett nummer.
                    </h2>
                </div>
            } @else {
                <div class='no_report_container'>
                    <h2>
                        Kunde ej hitta något ärende med id: {{ reportId }}
                    </h2>
                </div>
            }
        </div>
    </div>
</main>

/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { close } from '../fn/ajour/close';
import { Close$Params } from '../fn/ajour/close';
import { close$Plain } from '../fn/ajour/close-plain';
import { Close$Plain$Params } from '../fn/ajour/close-plain';
import { markReceived } from '../fn/ajour/mark-received';
import { MarkReceived$Params } from '../fn/ajour/mark-received';
import { markReceived$Plain } from '../fn/ajour/mark-received-plain';
import { MarkReceived$Plain$Params } from '../fn/ajour/mark-received-plain';
import { markReviewed } from '../fn/ajour/mark-reviewed';
import { MarkReviewed$Params } from '../fn/ajour/mark-reviewed';
import { markReviewed$Plain } from '../fn/ajour/mark-reviewed-plain';
import { MarkReviewed$Plain$Params } from '../fn/ajour/mark-reviewed-plain';
import { openForClarification } from '../fn/ajour/open-for-clarification';
import { OpenForClarification$Params } from '../fn/ajour/open-for-clarification';
import { openForClarification$Plain } from '../fn/ajour/open-for-clarification-plain';
import { OpenForClarification$Plain$Params } from '../fn/ajour/open-for-clarification-plain';
import { ReportEvent } from '../models/report-event';
import { ReportRead } from '../models/report-read';
import { sendEmail } from '../fn/ajour/send-email';
import { SendEmail$Params } from '../fn/ajour/send-email';
import { sendEmail$Plain } from '../fn/ajour/send-email-plain';
import { SendEmail$Plain$Params } from '../fn/ajour/send-email-plain';
import { startProcessing } from '../fn/ajour/start-processing';
import { StartProcessing$Params } from '../fn/ajour/start-processing';
import { startProcessing$Plain } from '../fn/ajour/start-processing-plain';
import { StartProcessing$Plain$Params } from '../fn/ajour/start-processing-plain';

@Injectable({ providedIn: 'root' })
export class AjourApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `markReceivedAjour()` */
  static readonly MarkReceivedAjourPath = '/Ajour/MarkRecieved';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markReceived$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  markReceived$Plain$Response(params?: MarkReceived$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportRead>> {
    return markReceived$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markReceived$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  markReceived$Plain(params?: MarkReceived$Plain$Params, context?: HttpContext): Observable<ReportRead> {
    return this.markReceived$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportRead>): ReportRead => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markReceived()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  markReceived$Response(params?: MarkReceived$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportRead>> {
    return markReceived(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markReceived$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  markReceived(params?: MarkReceived$Params, context?: HttpContext): Observable<ReportRead> {
    return this.markReceived$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportRead>): ReportRead => r.body)
    );
  }

  /** Path part for operation `markReviewedAjour()` */
  static readonly MarkReviewedAjourPath = '/Ajour/MarkReviewed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markReviewed$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  markReviewed$Plain$Response(params?: MarkReviewed$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportRead>> {
    return markReviewed$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markReviewed$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  markReviewed$Plain(params?: MarkReviewed$Plain$Params, context?: HttpContext): Observable<ReportRead> {
    return this.markReviewed$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportRead>): ReportRead => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markReviewed()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  markReviewed$Response(params?: MarkReviewed$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportRead>> {
    return markReviewed(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markReviewed$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  markReviewed(params?: MarkReviewed$Params, context?: HttpContext): Observable<ReportRead> {
    return this.markReviewed$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportRead>): ReportRead => r.body)
    );
  }

  /** Path part for operation `openForClarificationAjour()` */
  static readonly OpenForClarificationAjourPath = '/Ajour/OpenForClarification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `openForClarification$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  openForClarification$Plain$Response(params?: OpenForClarification$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportRead>> {
    return openForClarification$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `openForClarification$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  openForClarification$Plain(params?: OpenForClarification$Plain$Params, context?: HttpContext): Observable<ReportRead> {
    return this.openForClarification$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportRead>): ReportRead => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `openForClarification()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  openForClarification$Response(params?: OpenForClarification$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportRead>> {
    return openForClarification(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `openForClarification$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  openForClarification(params?: OpenForClarification$Params, context?: HttpContext): Observable<ReportRead> {
    return this.openForClarification$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportRead>): ReportRead => r.body)
    );
  }

  /** Path part for operation `startProcessingAjour()` */
  static readonly StartProcessingAjourPath = '/Ajour/StartProcessing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startProcessing$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startProcessing$Plain$Response(params?: StartProcessing$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportRead>> {
    return startProcessing$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startProcessing$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startProcessing$Plain(params?: StartProcessing$Plain$Params, context?: HttpContext): Observable<ReportRead> {
    return this.startProcessing$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportRead>): ReportRead => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startProcessing()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startProcessing$Response(params?: StartProcessing$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportRead>> {
    return startProcessing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startProcessing$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startProcessing(params?: StartProcessing$Params, context?: HttpContext): Observable<ReportRead> {
    return this.startProcessing$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportRead>): ReportRead => r.body)
    );
  }

  /** Path part for operation `closeAjour()` */
  static readonly CloseAjourPath = '/Ajour/Close';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `close$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  close$Plain$Response(params?: Close$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportRead>> {
    return close$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `close$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  close$Plain(params?: Close$Plain$Params, context?: HttpContext): Observable<ReportRead> {
    return this.close$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportRead>): ReportRead => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `close()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  close$Response(params?: Close$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportRead>> {
    return close(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `close$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  close(params?: Close$Params, context?: HttpContext): Observable<ReportRead> {
    return this.close$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportRead>): ReportRead => r.body)
    );
  }

  /** Path part for operation `sendEmailAjour()` */
  static readonly SendEmailAjourPath = '/Ajour/Email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendEmail$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendEmail$Plain$Response(params?: SendEmail$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ReportEvent>>> {
    return sendEmail$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendEmail$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendEmail$Plain(params?: SendEmail$Plain$Params, context?: HttpContext): Observable<Array<ReportEvent>> {
    return this.sendEmail$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ReportEvent>>): Array<ReportEvent> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendEmail()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendEmail$Response(params?: SendEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ReportEvent>>> {
    return sendEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendEmail$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendEmail(params?: SendEmail$Params, context?: HttpContext): Observable<Array<ReportEvent>> {
    return this.sendEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ReportEvent>>): Array<ReportEvent> => r.body)
    );
  }

}

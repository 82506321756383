import {Component, EventEmitter, Inject, Output} from "@angular/core";
import {SvgIcon} from "src/app/shared/utils/svg-helper";
import {MapTools, PaperSize, PaperTypes, TrvNgMapService} from "@trafikverket/trv-ng-map";
import {
    svgCheckCircleFill,
    svgCrossCircleFill,
    svgDocument,
    svgFilePdf,
    svgFilePng,
    svgSquare,
} from "src/app/shared/utils/svg-helper-data";
import {
    TrvFormBundle,
    TrvGlobalMessagesService,
    TrvLoadingDirective,
    TrvModalService,
    TrvTooltipDirective
} from "trv-ng-common";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import {ReactiveFormsModule} from "@angular/forms";
import {DomSanitizer} from "@angular/platform-browser";
import {NavigationState, NvdbNavigationService} from "@app/service/nvdb-navigation.service";
import {ReportService} from "@app/service/report.service";
import {ExportAreaLoaderComponent} from "@components/reports/export-area-loader/export-area-loader.component";
import {
    MapExportPreviewModalComponent
} from "@components/map-menu/print-map-tab/map-export-preview-modal/map-export-preview-modal.component";
import {MatSliderModule} from "@angular/material/slider";
import {getCenter} from "ol/extent";
import {environment} from "../../../../environments/environment";

@Component({
    selector: "app-print-map-tab",
    templateUrl: "./print-map-tab.component.html",
    styleUrls: ["./print-map-tab.component.scss"],
    standalone: true,
    imports: [TrvFormBundle, ReactiveFormsModule, TrvTooltipDirective, MatSliderModule, TrvLoadingDirective],
})
export class PrintMapTabComponent {
    @Output() exportEvent = new EventEmitter<void>();
    @Output() previewEvent = new EventEmitter<void>();

    public title = this.navigationService.printMenuState.title;
    public fileTypeOption = this.navigationService.printMenuState.fileTypeOption;
    public exportDirectionOption = this.navigationService.printMenuState.exportDirectionOption;
    public showLabelOption = this.navigationService.printMenuState.showLabelOption;
    public showCompassOption = this.navigationService.printMenuState.showCompassOption;
    public exportSizeOption = this.navigationService.printMenuState.exportSizeOption;

    public scale = this.navigationService.printMenuState.scale;
    public slider = this.navigationService.printMenuState.slider;

    exportLoading = false;

    FileType = FileType;
    ExportDirection = ExportDirection;
    ExportSize = ExportSize;

    previousSize = ExportSize.MapView

    constructor(
        private sanitizer: DomSanitizer,
        public reportService: ReportService,
        public navigationService: NvdbNavigationService,
        @Inject(TrvNgMapService) private trvMapService: TrvNgMapService,
        @Inject(TrvGlobalMessagesService)
        private globalMessagesService: TrvGlobalMessagesService,
        @Inject(TrvModalService) private trvModalService: TrvModalService
    ) {
        this.navigationService.navigationStateChanged.subscribe(() => {
            this.saveCurrentPosition();
            this.updateExportView();
        });

        this.exportSizeOption.valueChanges.subscribe(value => {

            if(this.trvMapService.trvMap!.activeMapTool.value != MapTools.ExportRectangle) {
                this.trvMapService.trvMap!.changeActiveMapTool(MapTools.ExportRectangle)
            }

            if (this.previousSize == ExportSize.MapView && value != ExportSize.MapView) {
                const currentPaperSizeType = Object.entries(ExportSize).find(a => a[1] == value)![0]
                // @ts-ignore
                const currentPaperSize = PaperSize[currentPaperSizeType]

                const mapExtent = this.navigationService.getViewableExtentOnMainMap()

                const mapSizeHorizontal = mapExtent[2] - mapExtent[0]
                const mapSizeVertical = mapExtent[3] - mapExtent[1]

                const maxHorizontalScale = mapSizeHorizontal * 0.8 * 1000 / currentPaperSize[0]
                const maxVerticalScale = mapSizeVertical * 0.8 * 1000 / currentPaperSize[1]

                this.scale.setValue(Math.round(Math.min(maxHorizontalScale, maxVerticalScale)))
                this.centerExportArea()
            }
            this.previousSize = value

            this.updateExportView();
        });

        this.exportDirectionOption.valueChanges.subscribe(value => {
            this.updateExportView();
        });

        this.scale.valueChanges.subscribe(value => {
            let result = 0;
            for (let i = 0; i < this.navigationService.scaleIntervals.length; i++) {
                if (value < this.navigationService.scaleIntervals[i]) {
                    if (Math.abs(this.navigationService.scaleIntervals[i] - value) < Math.abs(this.navigationService.scaleIntervals[i - 1] - value)) {
                        result = i;
                    } else {
                        result = i - 1;
                    }
                    break;
                } else if (value === this.navigationService.scaleIntervals[i]) {
                    result = i;
                    break;
                }
            }
            this.slider.patchValue(result, {emitEvent: false}); // Prevent infinite loop
            this.updateExportView();
        });

        this.slider.valueChanges.subscribe(index => {
            if (this.navigationService.scaleIntervals[index] !== undefined) {
                this.scale.patchValue(this.navigationService.scaleIntervals[index], {
                    emitEvent: false,
                }); // Prevent infinite loop
            }
            this.updateExportView();
        });

        this.trvMapService.trvMap!.map.getView().on("change:resolution", () => {
            this.updateExportView();
        });
    }

    saveCurrentPosition() {
        const extent = this.trvMapService.trvMap?.trvLayer?.printLayer?.getSource()?.getExtent();
        if (extent && extent[0] != Infinity) {
            this.navigationService.printMenuState.areaCenterCoordinate = getCenter(extent)
        }
    }

    public updateExportView() {
        if (
            this.navigationService.printMenuState.exportSizeOption.value == ExportSize.MapView ||
            this.navigationService.navigationState != NavigationState.PrintMap
        ) {
            this.trvMapService.trvMap?.trvInteractions.removeExportMapToolFeature();
        } else {
            const paperString = this.exportSizeToString(this.navigationService.printMenuState.exportSizeOption.value);

            if (this.navigationService.printMenuState.areaCenterCoordinate == null) this.navigationService.printMenuState.areaCenterCoordinate = getCenter(this.navigationService.getViewableExtentOnMainMap())

            this.trvMapService.trvMap?.trvInteractions.addExportMapToolFeature(
                paperString,
                this.scale.value,
                this.navigationService.printMenuState.exportDirectionOption.value == ExportDirection.Standing,
                this.navigationService.printMenuState.areaCenterCoordinate
            );
        }
    }

    public async export() {
        this.exportLoading = true;
        try {
            this.globalMessagesService.component(ExportAreaLoaderComponent, null, 1000000);
            const userLayers = this.trvMapService.trvMap?.trvLayer?.userLayerGroup?.getLayersArray() as VectorLayer<VectorSource>[];
            let vectorLayers = this.trvMapService.trvMap?.trvLayer?.vectorLayerGroup?.getLayersArray() as VectorLayer<VectorSource>[];

            const layers = [...userLayers, ...vectorLayers];
            const map = this.trvMapService.trvMap!.map;
            let extent = this.trvMapService.trvMap?.trvLayer?.printLayer?.getSource()?.getExtent();
            if (!extent || extent[0] === Infinity) extent = map?.getView()!.calculateExtent(map.getSize());

            this.trvMapService.trvMap?.trvLayer?.tempLayer?.getSource()?.clear();
            this.trvMapService.trvMap?.trvLayer?.tempLayer2?.getSource()?.clear();

            // hide exportarea while taking screenshot
            this.saveCurrentPosition();
            this.trvMapService.trvMap?.trvInteractions.removeExportMapToolFeature();

            const result = await this.trvMapService.trvMap?.exportMap(
                this.navigationService.printMenuState.showLabelOption.value!,
                extent,
                this.exportSizeToString(this.navigationService.printMenuState.exportSizeOption.value),
                this.navigationService.printMenuState.exportDirectionOption.value == ExportDirection.Laying,
                layers,
                this.title.value ?? "",
                this.navigationService.printMenuState.fileTypeOption.value == FileType.PDF,
                this.exportSizeOption.value == ExportSize.MapView ? Math.round(this.trvMapService.trvMap?.getScaleForResolution()) : this.scale.value,
                this.navigationService.printMenuState.showCompassOption.value!,
            );

            // show exportarea again
            this.updateExportView();

            this.globalMessagesService.clearType("component");
            this.globalMessagesService.component(ExportAreaLoaderComponent, null, 0.01);

            if (!result) {
                this.globalMessagesService.error("Ett okänt fel skedde vid export.");
                this.exportLoading = false;
                return;
            }

            const modal = this.trvModalService.open(MapExportPreviewModalComponent, {
                disposeOnBackdropClick: true,
                size: this.navigationService.isMobileDevice ? "fullscreen" : "xxl",
            });

            const now = new Date();
            const formattedTime =
                now.getFullYear() +
                "_" +
                String(now.getMonth() + 1).padStart(2, "0") +
                "_" +
                String(now.getDate()).padStart(2, "0") +
                "___" +
                String(now.getHours()).padStart(2, "0") +
                "_" +
                String(now.getMinutes()).padStart(2, "0");

            // TODO let user write name
            const fileName = (environment.application == "NvdbDataleverans" ? "Dataleverans_" : "PåKarta_") + formattedTime;

            // WARNING!!! This uses an iframe to display the pdf, which is dangerous if
            // the user can choose the url
            if (result instanceof Object) {
                modal.componentInstance.src = URL.createObjectURL(result.output("blob"));
                modal.componentInstance.isPng = false;
            } else {
                modal.componentInstance.src = result;
                modal.componentInstance.isPng = true;
            }

            const self = this;

            modal.componentInstance.download.subscribe({
                next() {
                    if (result instanceof Object) {
                        result.save(fileName + ".pdf");
                    } else {
                        const link = document.createElement("a");
                        link.href = result;

                        link.download = fileName + ".png";
                        document.body.appendChild(link); // Required for Firefox
                        link.click();
                        document.body.removeChild(link);
                    }
                    self.globalMessagesService.success("Exporten klar.");
                    modal.dismiss();
                },
                error(error: any) {
                    self.globalMessagesService.error(["Ett oväntat fel har inträffat.", error.toString()]);
                },
            });
        } catch (e) {
            this.updateExportView();
            this.globalMessagesService.clearType("component");
            this.globalMessagesService.component(ExportAreaLoaderComponent, null, 0.01);
            this.exportLoading = false;
            console.log(e);

            let emsg = ["Utskriften misslyckades."]

            if (this.trvMapService.trvMap?.trvLayer.currentBackroundLayer.type == "osm") {
                emsg.push("Det går just nu inte att skriva ut bakgrundskartan \"OpenStreetMap\"");
            }

            emsg.push("Kontakta support ifall problemet återstår.")

            this.globalMessagesService.error(emsg);
        }
        this.exportLoading = false;
    }

    public exportSizeToString(exportSize: ExportSize): PaperTypes {
        switch (exportSize) {
            case ExportSize.A4:
                return "A4";
            case ExportSize.A3:
                return "A3";
            case ExportSize.A2:
                return "A2";
            case ExportSize.A1:
                return "A1";
            case ExportSize.A0:
                return "A0";
            default:
                return "";
        }
    }

    public centerExportArea() {
        this.trvMapService.trvMap?.trvInteractions.removeExportMapToolFeature();

        const mapCenter = getCenter(this.navigationService.getViewableExtentOnMainMap())

        if (mapCenter) this.navigationService.printMenuState.areaCenterCoordinate = mapCenter;

        this.updateExportView();
    }

    public zoomToExtent() {
        const exportAreaExtent = this.trvMapService.trvMap?.trvLayer?.printLayer?.getSource()?.getExtent();
        if (exportAreaExtent) {
            this.trvMapService.trvMap?.zoomInOnExtent(exportAreaExtent, 0, this.navigationService.getPaddingForZoomToExtentOnMainMap(100));
        }
    }

    public onScaleSliderChange(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        let parsedValue: number = parseInt(value);
        if (Number.isNaN(parsedValue)) {
            parsedValue = 1;
        }

        if (parsedValue < 0) parsedValue = 0;

        this.scale.patchValue(this.navigationService.scaleIntervals[parsedValue]);
    }

    formatThumbLabel = (val: number) => {
        return "1:" + this.navigationService.scaleIntervals[val];
    };
}

export enum FileType {
    PNG,
    PDF,
}

export enum ExportDirection {
    Standing,
    Laying,
}

export enum ExportSize {
    MapView,
    A4,
    A3,
    A2,
    A1,
    A0,
}

export interface ExportMapObj {
    test: string;
}

import {Component} from '@angular/core';
import {
    TrvFormBundle,
    TrvFormComponent,
    TrvFormUtilityService,
    TrvFormValidationErrorMap,
    TrvGlobalMessagesService,
    TrvLoadingBundle,
    TrvModalCloseEvent,
    TrvModalRef,
    TrvModalService,
    TrvTooltipDirective
} from 'trv-ng-common';
import {ReportsTableComponent} from '../reports-table/reports-table.component';
import {SendReportsModalComponent} from '../send-reports-modal/send-reports-modal.component';
import {ReportAvikelseService} from '@app/service/report-avikelse.service';
import {FormBuilder, FormControl, ReactiveFormsModule, UntypedFormGroup, Validators} from '@angular/forms';
import {faDrawPolygon, faMapMarkerAlt, faRoute} from '@fortawesome/free-solid-svg-icons';
import {GeometryType} from '@app/shared/enums';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {focusOnFirstInvalidControl} from '@app/shared/utils/utils';
import {LocalStorageService} from '@app/service/local-storage.service';
import {NvdbNavigationService} from "@app/service/nvdb-navigation.service";
import {TrvModalConfig} from "trv-ng-common/lib/components/modal/modal-config";

@Component({
    selector: 'app-send-report-avikelse-modal',
    standalone: true,
    imports: [
        TrvLoadingBundle,
        ReportsTableComponent,
        TrvTooltipDirective,
        TrvFormBundle,
        ReactiveFormsModule,
        FaIconComponent
    ],
    templateUrl: './send-report-avikelse-modal.component.html',
    styleUrl: './send-report-avikelse-modal.component.scss'
})
export class SendReportAvikelseModalComponent {
    // use the lopnummer of the items (their order). They cannot be removed from this view so its safe to do
    // OBS this uses the actuall lopnummer (0 indexed) and not the one shown in the html.
    public selectedReportLopnummer: number[] = []
    allCheckboxChecked = true


    public loading = false;

    public form: UntypedFormGroup;

    public validationErrorMap: TrvFormValidationErrorMap = {
        reportName: {
            required: 'Måste anges.',
            maxlength: 'Max 50 tecken.'
        },
        name: {
            maxlength: 'Max 50 tecken.'
        },
        email: {
            email: "Måste vara en giltig email",
            maxlength: 'Max 50 tecken.'
        },
        phone: {
            maxlength: 'Max 20 tecken.'
        }
    }


    get reportName() {
        return this.form.get("reportName")! as FormControl;
    }

    get name() {
        return this.form.get("name")! as FormControl;
    }

    get email() {
        return this.form.get("email")! as FormControl;
    }

    get phone() {
        return this.form.get("phone")! as FormControl;
    }

    protected readonly GeometryType = GeometryType;

    protected readonly faRoute = faRoute;
    protected readonly faMapMarkerAlt = faMapMarkerAlt;
    protected readonly faDrawPolygon = faDrawPolygon;

    constructor(
        private nvdbNavigationService: NvdbNavigationService,
        private globalMessagesService: TrvGlobalMessagesService,
        private trvModalService: TrvModalService,
        private modalRef: TrvModalRef<SendReportsModalComponent>,
        public reportAvikelseService: ReportAvikelseService,
        private formBuilder: FormBuilder,
        private formUtilityService: TrvFormUtilityService,
        private localStorageService: LocalStorageService,
    ) {
        this.form = formBuilder.group({
            reportName: [this.localStorageService.getAvikelseReportName(), Validators.compose([Validators.required, Validators.maxLength(50)])],
            name: [this.localStorageService.getAvikelseName(), Validators.compose([Validators.maxLength(50)])],
            email: [this.localStorageService.getAvikelseEmail(), Validators.compose([Validators.maxLength(50)])],
            phone: [this.localStorageService.getAvikelsePhone(), Validators.compose([Validators.maxLength(20)])],
        });

        for (let i = 0; i < this.reportAvikelseService.unsentReportAvikelseItem.length; i++) {
            this.selectedReportLopnummer.push(i)
        }
    }

    allRowsCheckboxClick(event: MouseEvent) {
        // @ts-ignore (is always a checkbox)
        if (event.target.checked) {
            this.selectedReportLopnummer = []
            for (let i = 0; i < this.reportAvikelseService.unsentReportAvikelseItem.length; i++) {
                this.selectedReportLopnummer.push(i)
            }
            this.allCheckboxChecked = true
        } else {
            this.selectedReportLopnummer = []
            this.allCheckboxChecked = false
        }
    }

    checkboxClicked(itemLopnummer: number, event: MouseEvent) {
        event.stopPropagation()

        if (this.selectedReportLopnummer.includes(itemLopnummer)) {
            this.selectedReportLopnummer = this.selectedReportLopnummer.filter(a => a !== itemLopnummer)
        } else {
            this.selectedReportLopnummer.push(itemLopnummer)
        }
        this.allCheckboxChecked = this.selectedReportLopnummer.length === this.reportAvikelseService.unsentReportAvikelseItem.length
    }

    public send(): void {
        if (this.selectedReportLopnummer.length == 0) {
            this.globalMessagesService.error(
                'Var vänlig och markera de avvikelser som ska skickas in.',
            );
            return;
        }

        //Check if form is valid
        if (!this.form.valid) {
            this.formUtilityService.isValid(this.form);
            this.globalMessagesService.error(
                'Var vänlig och korrigera felen, kan ej skicka in avvikelser.',
            );
            focusOnFirstInvalidControl();
            return;
        }

        //Check if Name, email or phone is null
        if ((this.email.value ?? "") == "" || (this.name.value ?? "") == "" || (this.phone.value ?? "") == "") {
            this.globalMessagesService.warning("Du har inte fyllt i dina kontaktuppgifter")

            const config: TrvModalConfig = {
                disposeOnBackdropClick: true,
            }
            if (this.nvdbNavigationService.isMobileDevice) config.size = 'fullscreen'

            this.trvModalService.confirm('Skicka in avvikelse utan kontaktuppgift', 'Du försöker skicka in ett ärende utan/eller delvis utan några kontaktuppgifter. <br> Vill du skicka in utan att fylla i?', 'Ja', 'Nej', config)
                .afterCloseWithType().subscribe(confirmed => this.handleClose(confirmed, 'yes'));
            return;
        }

        this.sendInAndClose();
    }

    public saveContactInfoToLocalStorage() {
        this.localStorageService.setAvikelseReportName(this.reportName.value);
        this.localStorageService.setAvikelseContactInformation(this.email.value ?? "", this.name.value ?? "", this.phone.value ?? "");
    }

    private handleClose(event: TrvModalCloseEvent, text: string): void {
        if (event.closingEventType === 'close') {
            if (text === "yes") {
                this.sendInAndClose()
            }
        } else {
            this.globalMessagesService.info("Var vänlig fyll i kontaktuppgifter om du vill bli kontaktad.")
        }
    }

    public clearContact() {
        this.localStorageService.clearAvikelseContactInformation();
        this.email.patchValue("");
        this.name.patchValue("");
        this.phone.patchValue("");
        this.globalMessagesService.info("Du rensade dina kontaktuppgifter.")
    }


    public sendInAndClose() {
        this.reportAvikelseService.sendInAvikelser(this.reportName.value, this.email.value ?? "", this.name.value ?? "", this.phone.value ?? "", this.selectedReportLopnummer);
        this.localStorageService.clearAvikelseReportName();
        this.close()
    }

    public close(): void {
        this.modalRef.dismiss();
    }

    public dismiss(): void {
        this.modalRef.dismiss();
    }


}

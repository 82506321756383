import { animate, state, style, transition, trigger } from "@angular/animations";

export const groupExpand = trigger("groupExpand", [
    state(
        "collapsed",
        style({
            overflow: "hidden",
            height: "0px",
            paddingTop: "0px",
            paddingBottom: "0px",
        })
    ),
    state(
        "expanded",
        style({
            overflow: "hidden",
            height: "*",
        })
    ),
    transition("expanded <=> collapsed", animate("400ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
])

export const showErrorMessage = trigger("showErrorMessage", [
    state("void", style({ transform: "translateY(-80%)" })),
    state("*", style({ transform: "translateY(0%)" })),
    transition("void => *", animate("150ms linear")),
])

import { Injectable } from "@angular/core";
import {first, Observable} from "rxjs";
import { FeatureTypeRequest } from "../../_api/dataleverans/models/feature-type-request";
import { FeatureTypeRequestMultiple } from "../../_api/dataleverans/models/feature-type-request-multiple";
import { NetDbApi } from "../../_api/dataleverans/services/net-db-api";
import { NetDbService } from "../net-db.service";
import { ActorTypesEnum, SearchRequest } from "src/app/_api/dataleverans/models";

@Injectable({
    providedIn: "root",
})
export class NetDbServiceDataleverans implements NetDbService {

    constructor(private netDbApiClient: NetDbApi) {}

    public getLastFeatureUpdateNVDB() {
        return this.netDbApiClient.getLastFeatureUpdateNVDB()
    }

    public infoClickInCoordinate(featureTypeRequest: FeatureTypeRequest): Observable<string> {
        return this.netDbApiClient.infoClickInCoordinate({
            body: featureTypeRequest,
        });
    }

    public infoClickMultiple(featureTypeRequestMultiple: FeatureTypeRequestMultiple): Observable<string> {
        return this.netDbApiClient.infoClickMultiple({
            body: featureTypeRequestMultiple,
        });
    }

    public searchAll(searchObj: SearchRequest): Observable<string> {
        return this.netDbApiClient.searchAll(
            {
                body: searchObj
            }
        )
    }

    public getDescription(metaKey: string): Observable<string> {
        return this.netDbApiClient.getDescription({ metaKey: metaKey });
    }

/*
    public getAllDataslagForActor(actorType: ActorTypesEnum) {
        return this.netDbApiClient.getAllDataslagForActors({
            actorType: actorType
        });
    }
*/
}

import {AfterViewInit, Component, Inject, Input} from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    UntypedFormGroup,
    ValidatorFn,
    Validators,
    ReactiveFormsModule
} from "@angular/forms";
import {
    TrvFormUtilityService,
    TrvFormValidationErrorMap,
    TrvGlobalMessagesService,
    TrvModalService,
    TrvPopoverRef,
    TrvFormBundle,
} from "trv-ng-common";
import {generateGUID, TrvNgMapService} from "@trafikverket/trv-ng-map";
import {View} from "ol";
import {LocalStorageService, SavedView} from "@app/service/local-storage.service";
import {DateTime} from "luxon";

@Component({
    selector: "app-save-view",
    templateUrl: "./save-view.component.html",
    styleUrls: ["./save-view.component.scss"],
    standalone: true,
    imports: [TrvFormBundle, ReactiveFormsModule],
})
export class SaveViewComponent implements AfterViewInit {
    public form!: UntypedFormGroup;
    public validationErrorMap: TrvFormValidationErrorMap = {
        name: {
            required: "Måste anges.",
            maxlength: "Max 30 tecken.",
            nameAlreadyExists: "Namnet är redan taget.",
        },
    };

    // if left empty you create a new savedview
    @Input() savedViewToEdit: SavedView | null = null;

    constructor(
        private trvPopoverRef: TrvPopoverRef,
        private formBuilder: FormBuilder,
        private formUtilityService: TrvFormUtilityService,
        private globalMessagesService: TrvGlobalMessagesService,
        @Inject(LocalStorageService)
        public localStorageService: LocalStorageService,
        @Inject(TrvNgMapService) private trvMapService: TrvNgMapService,
        @Inject(TrvModalService) private trvModalService: TrvModalService
    ) {
        this.form = this.formBuilder.group({
            name: ["", Validators.compose([Validators.required, Validators.maxLength(30), this.nameAlreadyExistsValidator()])],
            saveBackground: [true],
            saveDatalayer: [true],

            id: generateGUID(),
        });
    }

    // TODO annoying that we have to wait before updating it...
    ngAfterViewInit() {
        if (this.savedViewToEdit) {
            this.form.patchValue({
                name: this.savedViewToEdit.name,
                saveBackground: this.savedViewToEdit.saveBackground,
                saveDatalayer: this.savedViewToEdit.saveDataLayers,
                id: this.savedViewToEdit.id,
            });
        }
    }

    get name() {
        return this.form.get("name") as FormControl;
    }

    get saveBackground() {
        return this.form.get("saveBackground") as FormControl;
    }

    get saveDatalayer() {
        return this.form.get("saveDatalayer") as FormControl;
    }

    get id() {
        return this.form.get("id") as FormControl;
    }

    public submit() {
        if (!this.form.valid) {
            this.formUtilityService.isValid(this.form);
            this.globalMessagesService.error("Var vänlig och korrigera felen, det gick ej att spara vyn.");
            return;
        }

        const currentView = this.trvMapService.trvMap!.map.getView();
        const currentExtent = currentView.calculateExtent();
        const currentZoom = currentView.getZoom()!;
        const currentCenterCoordinate = currentView.getCenter()!;

        let backgroundId = "0";
        let dataLayersId: string[] = [];

        if (this.saveBackground.value) {
            backgroundId = this.trvMapService.trvMap!.trvLayer.currentBackroundLayer.id;
        }

        if (this.saveDatalayer.value) {
            dataLayersId = this.trvMapService.trvMap!.trvLayer.getVisibleWmsLayer().map(layer => layer.id);
        }

        let savedViews = this.localStorageService.savedViews;
        let viewToUpdate = savedViews.find(view => view.id === this.id.value);

        if (viewToUpdate != undefined) {
            viewToUpdate.saveBackground = this.saveBackground.value;
            viewToUpdate.saveDataLayers = this.saveDatalayer.value;
            viewToUpdate.saveBackground = this.saveBackground.value;
            viewToUpdate.dataLayersId = dataLayersId;
            viewToUpdate.backgroundLayerId = backgroundId;
            viewToUpdate.name = this.name.value;
            viewToUpdate.extent = currentExtent;
            viewToUpdate.zoom = currentZoom;
            viewToUpdate.centerCoordinate = currentCenterCoordinate;
            viewToUpdate.lastModifiedDate = DateTime.now()
        } else {
            savedViews.push({
                id: this.id.value,
                name: this.name.value,
                saveBackground: this.saveBackground.value,
                saveDataLayers: this.saveDatalayer.value,
                dataLayersId: dataLayersId,
                backgroundLayerId: backgroundId,
                extent: currentExtent,
                zoom: currentZoom,
                centerCoordinate: currentCenterCoordinate,
                lastModifiedDate: DateTime.now()
            });
        }

        this.localStorageService.setSavedViews(savedViews);

        if (viewToUpdate != undefined) {
            this.globalMessagesService.success(`Du uppdaterade vyn "${this.name.value}."`);
        } else {
            this.globalMessagesService.success(`Du lade till vyn "${this.name.value}."`);
        }

        this.trvPopoverRef.close("");
    }

    nameAlreadyExistsValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const name = control.value;
            const nameExists = this.localStorageService.savedViews.some(view => (view.name ?? "").toLowerCase() === (name ?? "").toLowerCase());
            const nameHasChanged = this.savedViewToEdit && this.savedViewToEdit.name !== name;
            return nameHasChanged && nameExists ? {nameAlreadyExists: {value: name}} : null;
        };
    }
}

import { NgClass, SlicePipe, DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core'
import { RouterLink } from '@angular/router';
import { SvgIconComponent } from '@app/components/utils/svg-icon/svg-icon.component';
import { AllItemsInListPipe } from '@app/pipes/item-in-list.pipe';
import { ValueFromObjectListPipe } from '@app/pipes/value-from-object-list.pipe';
import { ReportItem, ReportService } from '@app/service/report.service'
import { GeometryType } from '@app/shared/enums';
import { svgTrash, svgPencil, svgCross, svgCheck, svgArrowUp, svgArrowDown } from '@app/shared/utils/svg-helper-data';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSearch, faMapMarkerAlt, faRoute, faVectorSquare, faDrawPolygon, faEllipsisV, faChevronDown, faChevronUp, faPen, faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons';
import { TrvFilterSummaryComponent, TrvLoadingBundle, TrvPaginationComponent, TrvSelectComponent, TrvTooltipDirective } from 'trv-ng-common';

@Component({
    selector: 'app-report-item-expandable-header',
    standalone: true,
    imports: [
        TrvLoadingBundle,
        FontAwesomeModule,
        SvgIconComponent,
        NgClass,
        TrvTooltipDirective,
    ],
    templateUrl: './report-item-expandable-header.component.html',
    styleUrl: './report-item-expandable-header.component.scss'
})
export class ReportItemExpandableHeaderComponent{
    @Input() open = false;
    @Input() reportItem!: ReportItem;
    @Input() showChangeDot = false;
    @Input() sequenceNumber = 0;


    @Output() headerClicked = new EventEmitter<void>();
    @Output() zoomClicked = new EventEmitter<void>();

    // UTILS
    protected readonly GeometryType = GeometryType;

    protected readonly faMagnifyingGlassPlus = faMagnifyingGlassPlus;
    protected readonly svgCross = svgCross;

    protected readonly faMapMarkerAlt: IconProp = faMapMarkerAlt as IconProp;
    protected readonly faRoute: IconProp = faRoute as IconProp;
    protected readonly faDrawPolygon: IconProp = faDrawPolygon as IconProp;
    protected readonly faEllipsisV: IconProp = faEllipsisV as IconProp;

    protected readonly faChevronDown = faChevronDown;
    protected readonly faChevronUp = faChevronUp;
    protected readonly Object = Object;

    constructor(
        @Inject(ReportService) public reportService: ReportService
    ) {

    }

    public handleZoomClick(event: Event): void {
        event.stopPropagation();
        this.zoomClicked.emit();
    }
}

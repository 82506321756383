import { Component, Inject, OnInit } from '@angular/core'
import { DisplayReportComponent } from '@components/reports/display-report/display-report.component'
import { FaIconComponent } from '@fortawesome/angular-fontawesome'
import { ActivatedRoute, RouterLink } from '@angular/router'
import { NvdbNavigationService } from '@app/service/nvdb-navigation.service'
import { Report, ReportItem, ReportItemFile } from '@app/service/report.service'
import {TrvLoadingDirective, TrvModalService} from 'trv-ng-common'
import { ReportAvikelseService } from '@app/service/report-avikelse.service'
import { ReportStatusesEnum } from '@_api/dataleverans/models/report-statuses-enum'
import { DateTime } from 'luxon'
import { combineLatest, first, firstValueFrom, of } from 'rxjs'

@Component({
    selector: 'app-pa-karta-report-page',
    standalone: true,
    imports: [DisplayReportComponent, FaIconComponent, RouterLink, TrvLoadingDirective, /*TrvLoadingDirective*/],
    templateUrl: './pa-karta-report-page.component.html',
    styleUrl: './pa-karta-report-page.component.scss',
})
export class PaKartaReportPageComponent implements OnInit {
    report!: Report

    reportGuid!:string

    loading = true

    constructor(
        private route: ActivatedRoute,
        @Inject(NvdbNavigationService)
        public nvdbNavigation: NvdbNavigationService,
        private trvModalService: TrvModalService,
        @Inject(ReportAvikelseService) public reportAvikelseService: ReportAvikelseService,
    ) {
        this.nvdbNavigation.hideHamburgerMenu = true
        this.nvdbNavigation.backLink = '/map'
    }

    async ngOnInit() {
        this.reportGuid = this.route.snapshot.paramMap.get('reportGuid')!
        try {
            this.reportAvikelseService.getReportAvikelse(this.reportGuid).subscribe(async report => {
                if (!this.reportAvikelseService.deliveryTypesLoaded)
                    await firstValueFrom(this.reportAvikelseService.deliveryTypesLoadedChanged.asObservable())
                this.report = {
                    ...report,
                    status: report.reportStatus,
                    reportItems:
                        report.reportItems?.map(
                            a =>
                                ({
                                    id: a.id,
                                    feature: null,
                                    deliveryType: this.reportAvikelseService.deliveryTypes.find(b => b.deliveryType === a.deliveryType)!,
                                    description: a.description ?? '',
                                    report: { id: report.id, name: report.name },
                                    wkt: a.wkt,

                                    // IT SHOULD NOT HAVE THESE VALUES
                                    geomtryType: this.reportAvikelseService.getGeometryTypeFromWkt(a.wkt),
                                    dataslag: [],
                                    files: [],
                                    validFrom: null,

                                    form: null,
                                }) as ReportItem,
                        ) ?? [],
                    events: report.reportEvents ?? [],
                    lastChanged: report.date ? DateTime.fromISO(report.date) : null,
                } as unknown as Report
                this.loading = false
            })
        }catch (e) {
            console.log(e)
            this.loading = false
        }
    }
}

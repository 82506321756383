<div class='c-info' style='width: 360px'>
  <div class='c-info__header'>
    <h2>
      @if (!reportGroupToEdit) {
        Skapa ärende
      }
      @if (reportGroupToEdit && reportGroupToEdit.status == ReportStatusesEnum.Draft) {
        Uppdatera ärende
      }
      @if (reportGroupToEdit && reportGroupToEdit.status != ReportStatusesEnum.Draft) {
        Komplettera ärende
      }
    </h2>
    <button type='button' class='btn btn-link' aria-label='Stäng' (click)='close()'>
      <app-svg-icon [svgIcon]='iconX'></app-svg-icon>
    </button>
  </div>
  <div class='c-info__body'>
    <app-report-edit [showCloseButton]='true' [reportGroupToEdit]='reportGroupToEdit'
    (closeEmitter)='close()'></app-report-edit>
  </div>
</div>

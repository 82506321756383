import { ChangeDetectorRef, Component, Inject, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { NonDashboardComponent } from "./non-dashboard/non-dashboard.component";
import { DashboardComponent } from "./dashboard/dashboard.component";

import { TrvGlobalMessagesComponent } from "trv-ng-common";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    standalone: true,
    imports: [
    TrvGlobalMessagesComponent,
    DashboardComponent,
    NonDashboardComponent
],
})
export class AppComponent {
    public environment = environment;
    public showDashboard = false;

    constructor(
        @Inject(Router) private router: Router,
        private cdref: ChangeDetectorRef
    ) {

        if(environment.baseApiUrl.indexOf("/") == 0) {
            environment.baseApiUrl = `${window.location.origin}${environment.baseApiUrl}`
            console.log("BaseApiUrl: " + environment.baseApiUrl)
        }

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (
                    event.url === "/" ||
                    event.url === "/map" ||
                    event.url.includes("/map?") ||
                    event.url === "/home" ||
                    event.url.includes("/reports") ||
                    event.url.includes("/handle-reports") ||
                    event.url.includes("/view-reports") ||
                    event.url === "/actor-reports" ||
                    event.url === "/contact" ||
                    event.url === "/stats"
                ) {
                    this.showDashboard = true;
                } else {
                    this.showDashboard = false;
                }
                this.cdref.detectChanges();
            }
        });
    }
}

import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {
    faMapMarkerAlt,
    faRoute,
    faVectorSquare,
    faEllipsisV,
    faEye,
    faEyeSlash
} from "@fortawesome/free-solid-svg-icons";
import {ReportItemWithFeature, ReportAvikelseService} from "src/app/service/report-avikelse.service";
import {TrvPopoverPosition, TrvPopoverRef, TrvPopoverDirective} from "trv-ng-common";
import {
    ReportAvikelseOptionsPopoverComponent
} from "@components/map-menu/reports-avikelse-tab/report-avikelse-options-popover/report-avikelse-options-popover.component";
import {GeometryType} from "@app/shared/enums";
import {GeometryTypePipe} from "../../../../pipes/geometry-type.pipe";
import {FeatureVisiblePipe} from "../../../../pipes/feature-visible.pipe";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {NgClass} from "@angular/common";

@Component({
    selector: 'app-report-avikelse-tab-item',
    templateUrl: './report-avikelse-tab-item.component.html',
    styleUrls: ['./report-avikelse-tab-item.component.scss'],
    standalone: true,
    imports: [
        FontAwesomeModule,
        NgClass,
        TrvPopoverDirective,
        FeatureVisiblePipe,
        GeometryTypePipe
    ],
})
export class ReportAvikelseTabItemComponent implements OnInit {
    @Input() avikelseItemWithFeature!: ReportItemWithFeature;

    public faMapMarkerAlt: IconProp = faMapMarkerAlt as IconProp;
    public faRoute: IconProp = faRoute as IconProp;
    public faVectorSquare: IconProp = faVectorSquare as IconProp;
    public faEllipsisV: IconProp = faEllipsisV as IconProp;

    public faEye: IconProp = faEye as IconProp;
    public faEyeSlash: IconProp = faEyeSlash as IconProp;

    public popoverPosition: TrvPopoverPosition[] = ["bottom", "bottom-end", "bottom-start", "right", "top", "top-start", "top-end"];
    public popoverComponent = ReportAvikelseOptionsPopoverComponent;

    protected readonly GeometryType = GeometryType

    constructor(public reportAvikelseService: ReportAvikelseService,) {
    }

    ngOnInit(): void {
    }

    public onShowComponent(ref: TrvPopoverRef<ReportAvikelseOptionsPopoverComponent>): void {
        ref.componentInstance.reportAvikelseItem = this.avikelseItemWithFeature;
    }

    public onAfterCloseWithType(event: any): void {
    }

    public mouseEnter() {
        this.reportAvikelseService.highlightAvikelseItem(this.avikelseItemWithFeature);
    }

    public mouseLeave() {
        this.reportAvikelseService.resetAllFeatureStyles();
    }
}


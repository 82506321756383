<div class='map-main__buttons map_utils'>
    @if (!nvdbNavigation.isMobileDevice) {
        <app-map-label></app-map-label>
    }
    <app-map-buttons></app-map-buttons>
</div>

<!--
[ngStyle]="nvdbNavigation.isMobileDevice ? getMobileBottomStyle()  : null">
-->

<div class='map-main__buttons map-main__buttons--mapctrl'>
    <div style='display: flex; position: relative'>

        <div style='display: flex; align-items: flex-end; margin-right: 10px'>
            @if (this.trvMapService.isMapLoaded()) {
                <div style='display: flex; gap: 10px'>
                    <app-map-button
                        [svgIcon]='svgArrowLeft'
                        (click)='this.trvMapService.trvMap?.previousView()'
                        toolTip='Gå till föregående vy'
                        [toolTipPosition]='toolTipPositionTop'
                        [disabled]='!this.trvMapService.trvMap!.hasMovedSinceLastSavepoint && this.trvMapService.trvMap!.currentHistoryIndex <= 0'
                    ></app-map-button>
                    <app-map-button
                        [svgIcon]='svgRightArrow'
                        (click)='this.trvMapService.trvMap?.nextView()'
                        toolTip='Gå till nästa vy'
                        [toolTipPosition]='toolTipPositionTop'
                        [disabled]='this.trvMapService.trvMap!.currentHistoryIndex >= this.trvMapService.trvMap!.viewHistory.length - 1'
                    ></app-map-button>
                </div>
            }
        </div>
        <div>
            <button class='btn btn-light map-btn' (click)='gotoUserLocation()'
                    trvTooltip='Gå till din position'
                    aria-label="Gå till din position"
                    [position]='toolTipPositions'>
                <app-svg-icon [svgIcon]='locationIcon'></app-svg-icon>
            </button>
            <div class='zoom'>
                <button class='btn btn-light map-btn' (click)='zoomIn()' trvTooltip='Zooma in'
                        aria-label="Zooma in"
                        [position]='toolTipPositions'>
                    <app-svg-icon [svgIcon]='plusIcon'></app-svg-icon>
                </button>
                <button class='btn btn-light map-btn' (click)='zoomOut()' trvTooltip='Zooma ut'
                        aria-label="Zooma ut"
                        [position]='toolTipPositions'>
                    <app-svg-icon [svgIcon]='minusIcon'></app-svg-icon>
                </button>
            </div>
        </div>
    </div>
</div>
<app-map [east]='east' [north]='north' [scale]='scale'></app-map>

@if (reportItem) {
    <div class='item_header' [ngClass]='{ open: open }' (click)='headerClicked.emit()'>
        <span>{{ sequenceNumber }}</span>
        <span role='button' class='btn btn-link'>
        @if (open) {
            <fa-icon [icon]='faChevronUp'></fa-icon>
        } @else {
            <fa-icon [icon]='faChevronDown'></fa-icon>
        }
    </span>
        @if (showChangeDot) {
            <div class='change_dot'></div>
        }
        <div class='geom-type'>
            @if (reportItem.geomtryType == GeometryType.POINT) {
                <fa-icon class='geom-icon' [icon]='faMapMarkerAlt'></fa-icon>
            }
            @if (reportItem.geomtryType == GeometryType.LINE_STRING || reportItem.geomtryType ==
            GeometryType.MULTI_LINE_STRING) {
                <fa-icon class='geom-icon' [icon]='faRoute'></fa-icon>
            }
            @if (reportItem.geomtryType == GeometryType.POLYGON || reportItem.geomtryType == GeometryType.MULTI_POLYGON) {
                <fa-icon class='geom-icon' [icon]='faDrawPolygon'></fa-icon>
            }
        </div>
        <div class="description_container">
            <div>
                <b>
                    {{ reportItem.deliveryType.description }}
                </b>
                <span style='display: block' class='description_text'>
                {{ reportItem.description }}
            </span>
            </div>
            <div style="flex: 0 0 40px;">
            <span role='button' class='btn btn-link btn-zoom' (click)='handleZoomClick($event)'>
                <fa-icon [icon]='faMagnifyingGlassPlus'></fa-icon>
            </span>
            </div>
        </div>
    </div>
}

import { SvgIcon } from "./svg-helper";

export const svgPrinter: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-printer-fill",
    viewBox: "0 0 16 16",
    path: [
        "M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z",
        "M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z",
    ],
    fillRule: "",
    url: "",
};

export const svgGoogleMaps: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "",
    viewBox: "0 0 512 512",
    path: [
        "M320 64A64 64 0 1 0 192 64a64 64 0 1 0 128 0zm-96 96c-35.3 0-64 28.7-64 64v48c0 17.7 14.3 32 32 32h1.8l11.1 99.5c1.8 16.2 15.5 28.5 31.8 28.5h38.7c16.3 0 30-12.3 31.8-28.5L318.2 304H320c17.7 0 32-14.3 32-32V224c0-35.3-28.7-64-64-64H224zM132.3 394.2c13-2.4 21.7-14.9 19.3-27.9s-14.9-21.7-27.9-19.3c-32.4 5.9-60.9 14.2-82 24.8c-10.5 5.3-20.3 11.7-27.8 19.6C6.4 399.5 0 410.5 0 424c0 21.4 15.5 36.1 29.1 45c14.7 9.6 34.3 17.3 56.4 23.4C130.2 504.7 190.4 512 256 512s125.8-7.3 170.4-19.6c22.1-6.1 41.8-13.8 56.4-23.4c13.7-8.9 29.1-23.6 29.1-45c0-13.5-6.4-24.5-14-32.6c-7.5-7.9-17.3-14.3-27.8-19.6c-21-10.6-49.5-18.9-82-24.8c-13-2.4-25.5 6.3-27.9 19.3s6.3 25.5 19.3 27.9c30.2 5.5 53.7 12.8 69 20.5c3.2 1.6 5.8 3.1 7.9 4.5c3.6 2.4 3.6 7.2 0 9.6c-8.8 5.7-23.1 11.8-43 17.3C374.3 457 318.5 464 256 464s-118.3-7-157.7-17.9c-19.9-5.5-34.2-11.6-43-17.3c-3.6-2.4-3.6-7.2 0-9.6c2.1-1.4 4.8-2.9 7.9-4.5c15.3-7.7 38.8-14.9 69-20.5z",
    ],
    fillRule: "",
    url: "",
};

export const svgRuler: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "",
    viewBox: "0 0 512 512",
    path: [
        "M174.9 494.1c-18.7 18.7-49.1 18.7-67.9 0L14.9 401.9c-18.7-18.7-18.7-49.1 0-67.9l50.7-50.7 48 48c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-48-48 41.4-41.4 48 48c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-48-48 41.4-41.4 48 48c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-48-48 41.4-41.4 48 48c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-48-48 50.7-50.7c18.7-18.7 49.1-18.7 67.9 0l92.1 92.1c18.7 18.7 18.7 49.1 0 67.9L174.9 494.1z",
    ],
    fillRule: "",
    url: "",
};

export const svgPin: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-geo-alt-fill",
    viewBox: "0 0 16 16",
    path: ["M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"],
    fillRule: "",
    url: "",
};

export const svgTimeBack: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "",
    viewBox: "0 0 512 512",
    path: [
        "M75 75L41 41C25.9 25.9 0 36.6 0 57.9V168c0 13.3 10.7 24 24 24H134.1c21.4 0 32.1-25.9 17-41l-30.8-30.8C155 85.5 203 64 256 64c106 0 192 86 192 192s-86 192-192 192c-40.8 0-78.6-12.7-109.7-34.4c-14.5-10.1-34.4-6.6-44.6 7.9s-6.6 34.4 7.9 44.6C151.2 495 201.7 512 256 512c141.4 0 256-114.6 256-256S397.4 0 256 0C185.3 0 121.3 28.7 75 75zm181 53c-13.3 0-24 10.7-24 24V256c0 6.4 2.5 12.5 7 17l72 72c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-65-65V152c0-13.3-10.7-24-24-24z",
    ],
    fillRule: "",
    url: "",
};

export const svgInfo: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "",
    viewBox: "0 0 192 512",
    path: [
        "M48 80a48 48 0 1 1 96 0A48 48 0 1 1 48 80zM0 224c0-17.7 14.3-32 32-32H96c17.7 0 32 14.3 32 32V448h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H64V256H32c-17.7 0-32-14.3-32-32z",
    ],
    fillRule: "",
    url: "",
};

export const svgHamburger: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "25",
    height: "25",
    fill: "currentColor",
    class: "bi bi-list menu-toggle",
    viewBox: "0 0 16 16",
    path: [
        "M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z",
    ],
    fillRule: "evenodd",
    url: "",
};

export const svgTrafikverketLogo: SvgIcon = {
    xmlns: "",
    width: "147.3",
    height: "28.8",
    fill: "",
    class: "",
    viewBox: "",
    path: [],
    fillRule: "",
    url: "assets/svg/trafikverket-logo.svg",
};

export const svgPerson: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "25",
    height: "25",
    fill: "currentColor",
    class: "bi bi-person ico",
    viewBox: "0 0 16 16",
    path: [
        "M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z",
    ],
    fillRule: "",
    url: "",
};

export const svgLocation: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "",
    viewBox: "0 0 512 512",
    path: [
        "M256 0c17.7 0 32 14.3 32 32V66.7C368.4 80.1 431.9 143.6 445.3 224H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H445.3C431.9 368.4 368.4 431.9 288 445.3V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.3C143.6 431.9 80.1 368.4 66.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H66.7C80.1 143.6 143.6 80.1 224 66.7V32c0-17.7 14.3-32 32-32zM128 256a128 128 0 1 0 256 0 128 128 0 1 0 -256 0zm128-80a80 80 0 1 1 0 160 80 80 0 1 1 0-160z",
    ],
    fillRule: "",
    url: "",
};

export const svgPlus: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-plus-lg",
    viewBox: "0 0 16 16",
    path: ["M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"],
    fillRule: "evenodd",
    url: "",
};

export const svgMinus: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-dash",
    viewBox: "0 0 16 16",
    path: ["M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"],
    fillRule: "",
    url: "",
};

export const svgChevronDown: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "18",
    height: "18",
    fill: "currentColor",
    class: "bi bi-chevron-down",
    viewBox: "0 0 16 16",
    path: ["M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"],
    fillRule: "evenodd",
    url: "",
};

export const svgChevronUp: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "18",
    height: "18",
    fill: "currentColor",
    class: "bi bi-chevron-up",
    viewBox: "0 0 16 16",
    path: ["M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"],
    fillRule: "evenodd",
    url: "",
};

export const svgMagnifyingGlass: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-search",
    viewBox: "0 0 512 512",
    path: [
        "M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z",
    ],
    fillRule: "",
    url: "",
};

/**
 * <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg>
 */

export const svgMagnifyingPlus: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-zoom-in",
    viewBox: "0 0 16 16",
    path: [
        "M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0",
        "M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z",
        "M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5",
    ],
    fillRule: "evenodd",
    url: "",
};

export const svgMagnifyingPlusFilled: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-zoom-in",
    viewBox: "0 0 512 512",
    path: [
        "M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM184 296c0 13.3 10.7 24 24 24s24-10.7 24-24V232h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H232V120c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H120c-13.3 0-24 10.7-24 24s10.7 24 24 24h64v64z"
    ],
    fillRule: "",
    url: "",
};

/**
    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
    <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM184 296c0 13.3 10.7 24 24 24s24-10.7 24-24V232h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H232V120c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H120c-13.3 0-24 10.7-24 24s10.7 24 24 24h64v64z"/>
    </svg>

 */


/**
 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-zoom-in" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0"/>
  <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z"/>
  <path fill-rule="evenodd" d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5"/>
</svg>

 */

export const svgCircleInfo: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "$link-color",
    class: "bi bi-info-circle-fill",
    viewBox: "0 0 512 512",
    path: [
        "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z",
    ],
    fillRule: "",
    url: "",
};

export const svgHand: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "svg-inline--fa fa-hand",
    viewBox: "0 0 512 512",
    path: [
        "M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V240c0 8.8-7.2 16-16 16s-16-7.2-16-16V64c0-17.7-14.3-32-32-32s-32 14.3-32 32V336c0 1.5 0 3.1 .1 4.6L67.6 283c-16-15.2-41.3-14.6-56.6 1.4s-14.6 41.3 1.4 56.6L124.8 448c43.1 41.1 100.4 64 160 64H304c97.2 0 176-78.8 176-176V128c0-17.7-14.3-32-32-32s-32 14.3-32 32V240c0 8.8-7.2 16-16 16s-16-7.2-16-16V64c0-17.7-14.3-32-32-32s-32 14.3-32 32V240c0 8.8-7.2 16-16 16s-16-7.2-16-16V32z",
    ],
    fillRule: "",
    url: "",
};

export const svgArrowLeft: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "18",
    height: "18",
    fill: "currentColor",
    class: "bi bi-arrow-left",
    viewBox: "0 0 16 16",
    path: [
        "M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z",
    ],
    fillRule: "evenodd",
    url: "",
};

export const svgRightArrow: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "18",
    height: "18",
    fill: "currentColor",
    class: "bi bi-arrow-right",
    viewBox: "0 0 16 16",
    path: [
        "M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z",
    ],
    fillRule: "evenodd",
    url: "",
};

export const svgArrowUp: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-arrow-up",
    viewBox: "0 0 16 16",
    path: [
        "M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z",
    ],
    fillRule: "evenodd",
    url: "",
};

export const svgArrowDown: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-arrow-down",
    viewBox: "0 0 16 16",
    path: [
        "M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z",
    ],
    fillRule: "evenodd",
    url: "",
};

export const svgEraser: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-eraser-fill",
    viewBox: "0 0 16 16",
    path: [
        "M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm.66 11.34L3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z",
    ],
    fillRule: "",
    url: "",
};

export const svgPlusSquare: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-plus-square",
    viewBox: "0 0 16 16",
    path: [
        "M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z",
        "M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z",
    ],
    fillRule: "",
    url: "",
};

export const svgMapPin: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-geo-fill",
    viewBox: "0 0 16 16",
    path: [
        "M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z",
    ],
    fillRule: "evenodd",
    url: "",
};

export const svgLayer: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-layers",
    viewBox: "0 0 16 16",
    path: [
        "M8.235 1.559a.5.5 0 0 0-.47 0l-7.5 4a.5.5 0 0 0 0 .882L3.188 8 .264 9.559a.5.5 0 0 0 0 .882l7.5 4a.5.5 0 0 0 .47 0l7.5-4a.5.5 0 0 0 0-.882L12.813 8l2.922-1.559a.5.5 0 0 0 0-.882l-7.5-4zm3.515 7.008L14.438 10 8 13.433 1.562 10 4.25 8.567l3.515 1.874a.5.5 0 0 0 .47 0l3.515-1.874zM8 9.433 1.562 6 8 2.567 14.438 6 8 9.433z",
    ],
    fillRule: "",
    url: "",
};

export const svgStar: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-star",
    viewBox: "0 0 16 16",
    path: [
        "M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z",
    ],
    fillRule: "",
    url: "",
};

export const svgStarFill: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-star-fill",
    viewBox: "0 0 16 16",
    path: [
        "M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z",
    ],
    fillRule: "",
    url: "",
};

export const svgChatRight: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-chat-right",
    viewBox: "0 0 16 16",
    path: [
        "M2 1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9.586a2 2 0 0 1 1.414.586l2 2V2a1 1 0 0 0-1-1H2zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z",
    ],
    fillRule: "",
    url: "",
};

export const svg3Dots: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "",
    viewBox: "0 0 128 512",
    path: [
        "M64 360c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zm0-160c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zM120 96c0 30.9-25.1 56-56 56S8 126.9 8 96S33.1 40 64 40s56 25.1 56 56z",
    ],
    fillRule: "",
    url: "",
};

export const svgSquare: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-square",
    viewBox: "0 0 16 16",
    path: [
        "M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z",
    ],
    fillRule: "",
    url: "",
};

export const svgDocument: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-file-earmark",
    viewBox: "0 0 16 16",
    path: [
        "M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z",
    ],
    fillRule: "",
    url: "",
};

export const svgFilePdf: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-filetype-pdf",
    viewBox: "0 0 16 16",
    path: [
        "M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z",
    ],
    fillRule: "evenodd",
    url: "",
};

export const svgFilePng: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-filetype-png",
    viewBox: "0 0 16 16",
    path: [
        "M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5Zm-3.76 8.132c.076.153.123.317.14.492h-.776a.797.797 0 0 0-.097-.249.689.689 0 0 0-.17-.19.707.707 0 0 0-.237-.126.96.96 0 0 0-.299-.044c-.285 0-.506.1-.665.302-.156.201-.234.484-.234.85v.498c0 .234.032.439.097.615a.881.881 0 0 0 .304.413.87.87 0 0 0 .519.146.967.967 0 0 0 .457-.096.67.67 0 0 0 .272-.264c.06-.11.091-.23.091-.363v-.255H8.82v-.59h1.576v.798c0 .193-.032.377-.097.55a1.29 1.29 0 0 1-.293.458 1.37 1.37 0 0 1-.495.313c-.197.074-.43.111-.697.111a1.98 1.98 0 0 1-.753-.132 1.447 1.447 0 0 1-.533-.377 1.58 1.58 0 0 1-.32-.58 2.482 2.482 0 0 1-.105-.745v-.506c0-.362.067-.678.2-.95.134-.271.328-.482.582-.633.256-.152.565-.228.926-.228.238 0 .45.033.636.1.187.066.348.158.48.275.133.117.238.253.314.407Zm-8.64-.706H0v4h.791v-1.343h.803c.287 0 .531-.057.732-.172.203-.118.358-.276.463-.475a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.475-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.381.574.574 0 0 1-.238.24.794.794 0 0 1-.375.082H.788v-1.406h.66c.218 0 .389.06.512.182.123.12.185.295.185.521Zm1.964 2.666V13.25h.032l1.761 2.675h.656v-3.999h-.75v2.66h-.032l-1.752-2.66h-.662v4h.747Z",
    ],
    fillRule: "evenodd",
    url: "",
};

export const svgCross: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "18",
    height: "18",
    fill: "currentColor",
    class: "bi bi-x-lg",
    viewBox: "0 0 16 16",
    path: [
        "M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z",
    ],
    fillRule: "",
    url: "",
};

export const svgCrossCircle: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-x-circle",
    viewBox: "0 0 16 16",
    path: [
        "M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
        "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z",
    ],
    fillRule: "",
    url: "",
};

export const svgCrossCircleFill: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-x-circle-fill",
    viewBox: "0 0 16 16",
    path: [
        "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z",
    ],
    fillRule: "",
    url: "",
};

export const svgCheck: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-check-lg ",
    viewBox: "0 0 16 16",
    path: [
        "M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z",
    ],
    fillRule: "",
    url: "",
};

export const svgCheckCircle: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-check-circle",
    viewBox: "0 0 16 16",
    path: [
        "M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
        "M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z",
    ],
    fillRule: "",
    url: "",
};

export const svgCheckCircleFill: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-check-circle-fill",
    viewBox: "0 0 16 16",
    path: [
        "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z",
    ],
    fillRule: "",
    url: "",
};

export const svgTatortFill: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "",
    viewBox: "0 0 50 50",
    path: [
        "M12 3.6132812L12 28L9 28L9 23L7 23L7 28L4 28L4 46L21 46L35 46L46 46L46 15L35 15L35 11.279297L32 10.279297L32 4L30 4L30 9.6113281L28 8.9453125L28 4L26 4L26 8.2792969L12 3.6132812 z M 14 6.3886719L33 12.720703L33 44L28 44L28 40.855469C29.715786 40.405591 31 38.850301 31 37C31 34.802706 29.197294 33 27 33C24.802706 33 23 34.802706 23 37C23 38.850301 24.284214 40.405591 26 40.855469L26 44L21 44L21 28L14 28L14 6.3886719 z M 16 12L16 14L22 14L22 12L16 12 z M 16 16L16 18L31 18L31 16L16 16 z M 37 19L42 19L42 22L37 22L37 19 z M 16 20L16 22L24 22L24 20L16 20 z M 26 20L26 22L31 22L31 20L26 20 z M 16 24L16 26L31 26L31 24L16 24 z M 37 24L42 24L42 27L37 27L37 24 z M 23 28L23 30L31 30L31 28L23 28 z M 37 29L42 29L42 32L37 32L37 29 z M 8 32L11 32L11 34L8 34L8 32 z M 14 32L17 32L17 34L14 34L14 32 z M 37 34L42 34L42 37L37 37L37 34 z M 8 36L11 36L11 38L8 38L8 36 z M 14 36L17 36L17 38L14 38L14 36 z M 37 39L42 39L42 42L37 42L37 39 z M 8 40L11 40L11 42L8 42L8 40 z M 14 40L17 40L17 42L14 42L14 40 z",
    ],
    fillRule: "",
    url: "",
};

export const svgKommunFill: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "",
    viewBox: "0 0 50 50",
    path: [
        "M25 0C24.449219 0 24 0.449219 24 1L24 4.0625C20.535156 4.449219 17.664063 6.8125 16.53125 10L33.46875 10C32.335938 6.8125 29.464844 4.449219 26 4.0625L26 1C26 0.449219 25.550781 0 25 0 Z M 16 11.96875C15.425781 11.96875 14.96875 12.425781 14.96875 13C14.96875 13.574219 15.425781 14.03125 16 14.03125L16 22.5L18 21.6875L18 14.03125L20 14.03125L20 20.90625L22 20.1875L22 14.03125L24 14.03125L24 19.40625L25 19L26 19.40625L26 14.03125L28 14.03125L28 20.1875L30 20.90625L30 14.03125L32 14.03125L32 21.6875L34 22.5L34 14.03125C34.574219 14.03125 35.03125 13.574219 35.03125 13C35.03125 12.425781 34.574219 11.96875 34 11.96875 Z M 25 21C24.878906 21 24.769531 21.019531 24.65625 21.0625L5.65625 28.375C5.269531 28.523438 5 28.898438 5 29.3125L5 33C5 33.554688 5.449219 34 6 34L44 34C44.554688 34 45 33.554688 45 33L45 29.3125C45 28.898438 44.730469 28.523438 44.34375 28.375L25.34375 21.0625C25.226563 21.019531 25.121094 21 25 21 Z M 7 36L7 49C7 49.554688 7.449219 50 8 50L19.3125 50C19.863281 50 20.3125 49.554688 20.3125 49L20.3125 43.59375C20.3125 41.253906 21.855469 39.273438 24 38.875C25.414063 38.605469 26.851563 38.949219 27.9375 39.84375C29 40.722656 29.59375 42.03125 29.59375 43.40625L29.59375 49C29.59375 49.554688 30.039063 50 30.59375 50L42 50C42.554688 50 43 49.554688 43 49L43 36Z",
    ],
    fillRule: "",
    url: "",
};

export const svgOrtFill: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "",
    viewBox: "0 0 100 100",
    path: [
        "M78.34,44.83a9.45,9.45,0,0,0,.15-1.61,9.26,9.26,0,1,0-18.51,0,9.45,9.45,0,0,0,.15,1.61,9.75,9.75,0,0,0-3.67,7.43c0,5.12,4.38,9.4,10.19,10.39V74.06H52.09V22.11a1.82,1.82,0,0,0-1.83-1.83H24.68a1.82,1.82,0,0,0-1.83,1.83v52H18v5.66H77.38V74.06H71.81V62.65C77.63,61.66,82,57.38,82,52.26A9.77,9.77,0,0,0,78.34,44.83ZM40.12,28.57a1.09,1.09,0,0,1,1.09-1.09h4.3a1.09,1.09,0,0,1,1.09,1.09v4.3A1.09,1.09,0,0,1,45.51,34h-4.3a1.09,1.09,0,0,1-1.09-1.09Zm0,10.67a1.09,1.09,0,0,1,1.09-1.09h4.3a1.09,1.09,0,0,1,1.09,1.09v4.3a1.09,1.09,0,0,1-1.09,1.09h-4.3a1.09,1.09,0,0,1-1.09-1.09Zm0,10.67a1.09,1.09,0,0,1,1.09-1.1h4.3a1.09,1.09,0,0,1,1.09,1.1V54.2a1.09,1.09,0,0,1-1.09,1.1h-4.3a1.09,1.09,0,0,1-1.09-1.1Zm0,10.66a1.09,1.09,0,0,1,1.09-1.09h4.3a1.09,1.09,0,0,1,1.09,1.09v4.3A1.09,1.09,0,0,1,45.51,66h-4.3a1.09,1.09,0,0,1-1.09-1.09Zm-11.78-32a1.09,1.09,0,0,1,1.09-1.09h4.3a1.09,1.09,0,0,1,1.09,1.09v4.3A1.09,1.09,0,0,1,33.73,34h-4.3a1.09,1.09,0,0,1-1.09-1.09Zm0,10.67a1.09,1.09,0,0,1,1.09-1.09h4.3a1.09,1.09,0,0,1,1.09,1.09v4.3a1.09,1.09,0,0,1-1.09,1.09h-4.3a1.09,1.09,0,0,1-1.09-1.09Zm0,10.67a1.09,1.09,0,0,1,1.09-1.1h4.3a1.09,1.09,0,0,1,1.09,1.1V54.2a1.09,1.09,0,0,1-1.09,1.1h-4.3a1.09,1.09,0,0,1-1.09-1.1Zm0,10.66a1.09,1.09,0,0,1,1.09-1.09h4.3a1.09,1.09,0,0,1,1.09,1.09v4.3A1.09,1.09,0,0,1,33.73,66h-4.3a1.09,1.09,0,0,1-1.09-1.09Z",
    ],
    fillRule: "",
    url: "",
};

export const svgSweden: SvgIcon = {
    xmlns: "",
    width: "24",
    height: "24",
    fill: "currentColor",
    class: "",
    viewBox: "",
    path: [],
    fillRule: "",
    url: "assets/svg/sweden-map-64.svg",
};

export const svgRoad: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "",
    viewBox: "0 0 480.369 480.369",
    path: [
        "M240.185,0.016c-7.543,0-13.66,6.117-13.66,13.66v13.66c0,7.543,6.116,13.659,13.66,13.659 c7.542,0,13.66-6.115,13.66-13.659v-13.66C253.845,6.133,247.728,0.016,240.185,0.016z",
        "M240.185,348.663c-7.543,0-13.66,6.117-13.66,13.66v27.912c0,7.543,6.116,13.66,13.66,13.66 c7.542,0,13.66-6.117,13.66-13.66v-27.912C253.845,354.78,247.728,348.663,240.185,348.663z",
        "M240.185,257.937c-7.543,0-13.66,6.117-13.66,13.66v27.912c0,7.543,6.116,13.658,13.66,13.658 c7.542,0,13.66-6.115,13.66-13.658v-27.912C253.845,264.054,247.728,257.937,240.185,257.937z",
        "M240.185,76.482c-7.543,0-13.66,6.116-13.66,13.66v27.919c0,7.543,6.116,13.659,13.66,13.659 c7.542,0,13.66-6.116,13.66-13.659V90.143C253.845,82.6,247.728,76.482,240.185,76.482z",
        "M240.185,167.209c-7.543,0-13.66,6.116-13.66,13.66v27.913c0,7.543,6.116,13.659,13.66,13.659 c7.542,0,13.66-6.116,13.66-13.659V180.87C253.845,173.326,247.728,167.209,240.185,167.209z",
        "M240.185,439.39c-7.543,0-13.66,6.117-13.66,13.66v13.66c0,7.543,6.116,13.658,13.66,13.658 c7.542,0,13.66-6.115,13.66-13.658v-13.66C253.845,445.507,247.728,439.39,240.185,439.39z",
        "M404.563,464.976L346.491,11.941c-0.96-7.484-7.75-12.806-15.287-11.812c-7.481,0.954-12.771,7.804-11.811,15.28 l58.071,453.035c0.888,6.896,6.763,11.925,13.532,11.925c0.58,0,1.168-0.033,1.755-0.113 C400.235,479.302,405.524,472.452,404.563,464.976z",
        "M149.165,0.129c-7.497-1.041-14.327,4.321-15.287,11.812L75.806,464.976c-0.961,7.477,4.328,14.326,11.812,15.279 c0.587,0.08,1.174,0.113,1.754,0.113c6.77,0,12.646-5.027,13.533-11.925l58.072-453.035 C161.937,7.934,156.648,1.083,149.165,0.129z",
    ],
    fillRule: "",
    url: "",
};

export const svgBridge: SvgIcon = {
    xmlns: "",
    width: "24",
    height: "24",
    fill: "currentColor",
    class: "",
    viewBox: "",
    path: [],
    fillRule: "",
    url: "assets/svg/bridge.svg",
};


export const svgPencil: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "bi bi-pen",
    viewBox: "0 0 16 16",
    path: ["M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"],
    fillRule: "",
    url: "",
};

export const svgTrash: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "",
    viewBox: "0 0 16 16",
    path: ["M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"],
    fillRule: "",
    url: "",
};

export const svgBackwards: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "",
    viewBox: "0 0 512 512",
    path: ["M459.5 440.6c9.5 7.9 22.8 9.7 34.1 4.4s18.4-16.6 18.4-29V96c0-12.4-7.2-23.7-18.4-29s-24.5-3.6-34.1 4.4L288 214.3V256v41.7L459.5 440.6zM256 352V256 128 96c0-12.4-7.2-23.7-18.4-29s-24.5-3.6-34.1 4.4l-192 160C4.2 237.5 0 246.5 0 256s4.2 18.5 11.5 24.6l192 160c9.5 7.9 22.8 9.7 34.1 4.4s18.4-16.6 18.4-29V352z"],
    fillRule: "",
    url: "",
};



/**
 *
 * Empty layout for svg object.
 * Copy this and fill in your own information
 */
export const svgEmpty: SvgIcon = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    fill: "currentColor",
    class: "",
    viewBox: "0 0 16 16",
    path: [""],
    fillRule: "",
    url: "",
};




<div class="search-item" (click)="gotoSearchItem()" [style]="first ? '' : 'margin-top:1rem'">
  <div class="search-icon">
    @if (searchItem.type == 0) {
      <app-svg-icon [svgIcon]="tatortFill"></app-svg-icon>
    }
    @if (searchItem.type == 1) {
      <app-svg-icon [svgIcon]="kommunFill"></app-svg-icon>
    }
    @if (searchItem.type == 2) {
      <app-svg-icon [svgIcon]="ortFill"></app-svg-icon>
    }
    @if (
      searchItem.type == 3 ||
      searchItem.type == 4 ||
      searchItem.type == 5 ||
      searchItem.type == 8 ||
      searchItem.type == 9 ||
      searchItem.type == 10
      ) {
      <app-svg-icon
        [svgIcon]="roadFill"
      ></app-svg-icon>
    }
    @if (searchItem.type == 6) {
      <app-svg-icon [svgIcon]="bridgeFill"></app-svg-icon>
    }
    @if (searchItem.type == 7) {
      <app-svg-icon [svgIcon]="pinFill"></app-svg-icon>
    }
  </div>
  <div class="search-text">
    <a>{{ searchItem.title }}</a>
    <span>{{ searchItem.subTitle }}</span>
  </div>
</div>

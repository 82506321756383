export const environment = {
    production: true,
    application: "NvdbPåKarta",
    baseApiUrl: "__NVDB_BASE_API_URL__",
    adfsResource: "__NVDB_BASE_API_URL__",
    streetViewApiKey: "__NVDB_STREET_VIEW_API_KEY__",
    buildVersion: "__NVDB_BUILD_VERSION__",
    clientId: "__NVDB_CLIENT_ID__",
    authority: "__NVDB_AUTHORITY__",
    local: "__NVDB_IS_LOCAL__"
};

import { Component, Input } from "@angular/core";

@Component({
    selector: "app-export-area-loader",
    templateUrl: "./export-area-loader.component.html",
    styleUrls: ["./export-area-loader.component.scss"],
    standalone: true,
})
export class ExportAreaLoaderComponent {
    @Input() text!: string;

    constructor() {}
}

<form [formGroup]="selectForm">
    <div class="form-group">
        <trv-select
            formControlName="singleObject"
            placeholder="Välj ett objekt"
            [source]="source"
            valueKey="id"
            sortKey="id"
            [searchMethod]="searchMethod"
            [enableSearch]="true"
            [displayMethod]="displayObject"
            [disableClear]="true"
        ></trv-select>
    </div>
</form>

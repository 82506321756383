<div class="print-container">
    <!--<div class="print-message">
        Just nu går det inte att exportera WMTS lager.
        <br/>
        Lagret kommer att bli ignorerat vid export
    </div>-->
    <div>
        <trv-form [fullWidth]="true">
            <trv-form-field label="Spara som" [labelAbove]="true" [labelGridColumns]="2" [control]="fileTypeOption">
                <div class="print-save-as">
                    <div class="form-check">
                        <input
                            type="radio"
                            name="test1"
                            id="simpleRadio1"
                            class="form-check-input"
                            [formControl]="fileTypeOption"
                            [value]="FileType.PNG"
                        />
                        <label class="form-check-label" for="simpleRadio1">png</label>
                    </div>
                    <div class="form-check">
                        <input
                            type="radio"
                            name="test1"
                            id="simpleRadio2"
                            class="form-check-input"
                            [formControl]="fileTypeOption"
                            [value]="FileType.PDF"
                        />
                        <label class="form-check-label" for="simpleRadio2">pdf</label>
                    </div>
                </div>
            </trv-form-field>

            @if (fileTypeOption.value == FileType.PDF) {
                <trv-form-field label="Titel i pdf" [labelAbove]="true" [control]="title">
                    <div class="">
                        <input class="form-check-label" type="text" class="form-control" placeholder="Titel"
                               [formControl]="title"/>
                    </div>
                </trv-form-field>
            }

            <trv-form-field label="Teckenförklaring" [labelAbove]="true" [labelGridColumns]="2"
                            [control]="showLabelOption" [controlPadding]="true">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="simpleCheck1" [formControl]="showLabelOption"
                           [value]="false"/>
                    <label class="form-check-label" for="simpleCheck1">Visa teckenförklaring</label>
                </div>
            </trv-form-field>

            <trv-form-field label="Norrpil" [labelAbove]="true" [labelGridColumns]="2"
                            [control]="showCompassOption" [controlPadding]="true">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="simpleCheck2"
                           [formControl]="showCompassOption"/>
                    <label class="form-check-label" for="simpleCheck2">Visa norrpil</label>
                </div>
            </trv-form-field>

            <trv-form-field label="Sidorientering" [labelAbove]="true" [labelGridColumns]="2"
                            [control]="exportDirectionOption">
                <div class="print-save-as">
                    <div class="form-check">
                        <input
                            type="radio"
                            id="simpleRadio3"
                            class="form-check-input"
                            name="test2"
                            [formControl]="exportDirectionOption"
                            [value]="ExportDirection.Standing"
                        />
                        <label class="form-check-label" for="simpleRadio3">Stående</label>
                    </div>
                    <div class="form-check">
                        <input
                            type="radio"
                            id="simpleRadio4"
                            name="test2"
                            class="form-check-input"
                            [formControl]="exportDirectionOption"
                            [value]="ExportDirection.Laying"
                        />
                        <label class="form-check-label" for="simpleRadio4">Liggande</label>
                    </div>
                </div>
            </trv-form-field>

            <trv-form-field label="Sidstorlek" [labelAbove]="true" [labelGridColumns]="2" [control]="exportSizeOption">
                <div class="print-save-as">
                    <div class="form-check">
                        <input
                            type="radio"
                            id="simpleRadio6"
                            class="form-check-input"
                            name="test4"
                            [formControl]="exportSizeOption"
                            [value]="ExportSize.MapView"
                        />
                        <label class="form-check-label" for="simpleRadio6">Hela</label>
                    </div>
                    <div class="form-check">
                        <input
                            type="radio"
                            id="simpleRadio7"
                            name="test4"
                            class="form-check-input"
                            [formControl]="exportSizeOption"
                            [value]="ExportSize.A4"
                        />
                        <label class="form-check-label" for="simpleRadio7">A4</label>
                    </div>
                    <div class="form-check">
                        <input
                            type="radio"
                            id="simpleRadio8"
                            name="test4"
                            class="form-check-input"
                            [formControl]="exportSizeOption"
                            [value]="ExportSize.A3"
                        />
                        <label class="form-check-label" for="simpleRadio8">A3</label>
                    </div>
                    <div class="form-check">
                        <input
                            type="radio"
                            id="simpleRadio9"
                            name="test4"
                            class="form-check-input"
                            [formControl]="exportSizeOption"
                            [value]="ExportSize.A2"
                        />
                        <label class="form-check-label" for="simpleRadio9">A2</label>
                    </div>
                    <div class="form-check">
                        <input
                            type="radio"
                            id="simpleRadio10"
                            name="test4"
                            class="form-check-input"
                            [formControl]="exportSizeOption"
                            [value]="ExportSize.A1"
                        />
                        <label class="form-check-label" for="simpleRadio10">A1</label>
                    </div>
                    <div class="form-check">
                        <input
                            type="radio"
                            id="simpleRadio11"
                            name="test4"
                            class="form-check-input"
                            [formControl]="exportSizeOption"
                            [value]="ExportSize.A0"
                            [attr.disabled]="true"
                        />
                        <label class="form-check-label" for="simpleRadio11">A0</label>
                    </div>
                </div>
                @if (exportSizeOption.value != ExportSize.MapView) {
                    <div class="print-export-center-zoom">
                        <button class="btn btn-primary" (click)="centerExportArea()" trvTooltip="Centrera exportarea"
                                [position]="['bottom']">
                            Centrera
                        </button>
                        <button class="btn btn-primary" (click)="zoomToExtent()" trvTooltip="Zooma till exportarea"
                                [position]="['bottom']">
                            Zooma till
                        </button>
                    </div>
                }
            </trv-form-field>
            @if (exportSizeOption.value != ExportSize.MapView) {
                <trv-form-field label="Exportarea (skala)" [labelAbove]="true" [labelGridColumns]="2"
                                [control]="exportSizeOption">
                    <div style="width: 95%; z-index: 10">
                        <mat-slider
                            style="width: 100%; z-index: 10"
                            min="0"
                            [max]="navigationService.scaleIntervals.length - 1"
                            step="1"
                            discrete="thumbLabel"
                            [displayWith]="formatThumbLabel"
                        >
                            <input matSliderThumb [formControl]="slider"/>
                        </mat-slider>
                    </div>
                    <div class="print-scale-wrapper">
                        <div class="print-scale-prepend">1:</div>
                        <input type="number" class="form-control" placeholder="Skala" [formControl]="scale"/>
                    </div>
                </trv-form-field>
            }
        </trv-form>
        <div class="print-export-button">
            <div [trv-loading]="exportLoading">
                <button class="btn btn-primary" (click)="export()">Exportera</button>
            </div>
        </div>
    </div>
</div>

import {Component, ElementRef, Inject, OnInit, TemplateRef} from "@angular/core";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {
    TrvGlobalMessagesService,
    TrvLoadingDirective,
    TrvModalService,
    TrvPopoverDirective,
    TrvPopoverRef,
    TrvTableBundle,
    TrvTableColumn, TrvTooltipDirective, TrvTooltipPosition
} from "trv-ng-common";
import {Report, ReportService} from "../../service/report.service";
import {NvdbNavigationService} from "../../service/nvdb-navigation.service";
import {combineLatest, firstValueFrom, startWith} from "rxjs";
import {
    faChevronDown,
    faChevronUp,
    faCircleCheck,
    faCircleInfo,
    faFileCirclePlus,
    faHourglassHalf, faLocationPin,
    faLockOpen, faMap,
    faSquareXmark,
} from "@fortawesome/free-solid-svg-icons";
import {svgChevronDown, svgChevronUp, svgInfo} from "../../shared/utils/svg-helper-data";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {DisplayReportComponent} from "../../components/reports/display-report/display-report.component";
import {FaIconComponent, FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {SvgIconComponent} from "@components/utils/svg-icon/svg-icon.component";
import {CommonModule, NgIf} from "@angular/common";
import {CdkTableModule} from "@angular/cdk/table";
import {AjourhallningAction} from "@shared/enums";
import {ReportItemComponent} from "../../components/reports/report-item/report-item.component";
import {
    ReportItemExpandableHeaderComponent
} from "../../components/reports/report-item-expandable-header/report-item-expandable-header.component";
import {AllItemsInListPipe} from "../../pipes/item-in-list.pipe";
import {
    AjourhallningActionModalComponent
} from "@app/components/modals/ajourhallning-action-modal/ajourhallning-action-modal.component";
import {ReportStatusesEnum} from "@app/_api/dataleverans/models";
import {ValueFromObjectListPipe} from "@pipes/value-from-object-list.pipe";
import {FORMATTED_REPORT_STATUS} from "@app/shared/constants";
import {
    AjourhallareHandlePopoverComponent
} from "@components/popover/ajourhallare-handle-popover/ajourhallare-handle-popover.component";
import {isStringValidGuid, isStringValidInteger} from '@shared/utils/utils'
import {AuthenticationService} from "@app/service/authentication.service";
import {environment} from "src/environments/environment";


@Component({
    selector: 'app-view-reports-page',
    standalone: true,
    imports: [
        DisplayReportComponent,
        FaIconComponent,
        RouterLink,
        TrvTooltipDirective,
        TrvPopoverDirective,
        TrvLoadingDirective
    ],
    templateUrl: './view-reports-page.component.html',
    styleUrl: './view-reports-page.component.scss'
})
export class ViewReportsPageComponent implements OnInit {
    loading = true;

    reportUid!: string;
    invalidReportUId = false;
    backendErrorMessage: string = "Ett fel uppstod när ärendet skulle hämtas";

    report: Report | null = null;

    // ICONS
    public environment = environment;

    // EVENT TABLE
    tableColumns: TrvTableColumn[] = [];
    visibleColumnKeys: string[] = [];

    // OTHER
    protected readonly ReportStatusesEnum = ReportStatusesEnum;

    protected readonly faChevronDown = faChevronDown;
    protected readonly faChevronUp = faChevronUp;
    protected readonly faCircleInfo = faCircleInfo;

    public toolTipPositions: TrvTooltipPosition[] = ["bottom", "right"];

    createGeopackageLoading = false

    constructor(
        private route: ActivatedRoute,
        @Inject(NvdbNavigationService)
        public nvdbNavigation: NvdbNavigationService,
        private globalMessagesService: TrvGlobalMessagesService,
        @Inject(ReportService) public reportService: ReportService,
        public authenticationService: AuthenticationService,
        public router: Router
    ) {
        this.nvdbNavigation.hideHamburgerMenu = true;
        this.nvdbNavigation.backLink = "/reports";

        this.tableColumns = [
            {key: "date", label: "Datum", visible: true, disabled: false},
            {key: "eventType", label: "Händelse", visible: true, disabled: false},
            {key: "user", label: "Användare", visible: true, disabled: false},
            {key: "comment", label: "Kommentar", visible: true, disabled: false},
        ];

        this.visibleColumnKeys = this.tableColumns.map(a => a.key);
    }

    async ngOnInit() {
        this.reportUid = this.route.snapshot.paramMap.get("reportId")!;
        const isReportUidValid = isStringValidGuid(this.reportUid);

        if (!isReportUidValid) {
            this.invalidReportUId = true;
            this.loading = false;
            return;
        }

        if (!this.reportService.reportDataLoaded) {
            await firstValueFrom(this.reportService.reportDataLoadedChanged)
        }

        var result = await this.reportService.getSingleReportByUid(this.reportUid)
        if (typeof result == "string") {
            this.backendErrorMessage = result
            this.loading = false;
            return;
        } else {
            this.report = result
        }
        this.loading = false;

        this.reportService.reportsChanged.subscribe(async () => {
            this.loading = true;
            this.report = [...this.reportService.reports, ...this.reportService.otherUsersReports].find(a => a.uid === this.reportUid)!;

            if (!this.report) {
                var result = await this.reportService.getSingleReportByUid(this.reportUid)
                if (typeof result == "string") {
                    this.backendErrorMessage = result
                } else {
                    this.report = result
                }
            }
            this.loading = false;
        });

    }

    showInViewMode() {
        this.router.navigateByUrl(`/map?viewmode=${this.report!.id}`)
    }

    protected readonly faMap = faMap;
    protected readonly faFileCirclePlus = faFileCirclePlus;

    async export() {
        this.createGeopackageLoading = true
        await this.reportService.exportReportToGeopackage(this.report!);
        this.createGeopackageLoading = false
    }
}

<div class='c-error c-error--fullscreen'>
    <div class='c-error__brand-header'>
        <a href='#'>
            <img src='assets/svg/trv-logo-white.svg' alt='' class='c-error__brand-header-logo' />
        </a>
    </div>
    <div class='c-error__message'>
        <h1>
            <span class='c-error__message-text text-center mb-2'>Felkod</span>
            <span class='c-error__message-code mb-3'>
                <span class='c-error__message-code-text'>404</span>
            </span>
            <span class='c-error__message-icon'>
                <fa-icon [icon]='faPlug'></fa-icon>
            </span>
        </h1>
        <h2>Sidan kunde inte hittas</h2>
        <div class='text-center'>
            <a class='btn btn-outline-light mt-3' href='#'>Gå till startsida</a>
        </div>
    </div>
</div>

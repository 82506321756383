import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { NvdbNavigationService } from "src/app/service/nvdb-navigation.service";
import { Report, ReportService } from "../../service/report.service";
import { filter } from "rxjs/operators";
import { take, tap } from "rxjs";
import { TrvLoadingBundle } from "trv-ng-common";
import { ReportsTableComponent } from "../../components/reports/reports-table/reports-table.component";
import { NavigationEnd, NavigationStart, Router, RouterLink } from "@angular/router";

@Component({
    selector: "app-reports-page",
    templateUrl: "./reports-page.component.html",
    styleUrls: ["./reports-page.component.scss"],
    standalone: true,
    imports: [RouterLink, ReportsTableComponent, TrvLoadingBundle],
})
export class ReportsPageComponent implements AfterViewInit {
    @ViewChild(ReportsTableComponent) multipleReportsElement!: ReportsTableComponent;

    constructor(
        @Inject(NvdbNavigationService)
        public nvdbNavigation: NvdbNavigationService,
        @Inject(ReportService) public reportService: ReportService,
        @Inject(Router) private router: Router
    ) {
        this.nvdbNavigation.hideHamburgerMenu = true;
        this.nvdbNavigation.backLink = "/home";

        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.multipleReportsElement && event.url !== "/reports") {
                    this.nvdbNavigation.previousReportsTableState = {
                        page: this.multipleReportsElement.currentPage.value,
                        filters: this.multipleReportsElement.filterForm,
                        openedReports: this.multipleReportsElement.openedReportGroups,
                        openedReportItems: this.multipleReportsElement.openedReportItems,
                        scroll: window.scrollY,
                    };
                }
            }
        });
    }

    loading = false;
    reportGroupIds: number[] | undefined = undefined;

    ngAfterViewInit(): void {
        this.reportGroupIds = this.reportService.reports.map(group => group.id);
        this.reportService.reportsChanged.subscribe(() => {
            this.reportGroupIds = this.reportService.reports.map(group => group.id);
        });

        const state = this.nvdbNavigation.previousReportsTableState;
        if (state) {
            this.multipleReportsElement.setTableState(state);
        }
    }
}

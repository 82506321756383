import { Component, Inject, Input, OnInit } from "@angular/core";
import {
    TrvGlobalMessagesService,
    TrvModalService,
    TrvPopoverPosition,
    TrvPopoverRef,
    TrvPopoverDirective,
    TrvTooltipPosition, TrvTooltipDirective,
} from 'trv-ng-common'
import { LocalStorageService, SavedView } from "src/app/service/local-storage.service";
import { svg3Dots, svgPin, svgStarFill } from "src/app/shared/utils/svg-helper-data";
import { SvgIcon } from "src/app/shared/utils/svg-helper";
import { TrvNgMapService } from "@trafikverket/trv-ng-map";
import {
    SaveViewOptionsComponent
} from '@components/map-menu/saved-view-section/save-view-options/save-view-options.component'
import { NgStyle } from "@angular/common";
import { SvgIconComponent } from "../../../utils/svg-icon/svg-icon.component";

@Component({
    selector: "app-saved-view-item",
    templateUrl: "./saved-view-item.component.html",
    styleUrls: ["./saved-view-item.component.scss"],
    standalone: true,
    imports: [SvgIconComponent, NgStyle, TrvPopoverDirective, TrvTooltipDirective],
})
export class SavedViewItemComponent {
    public pinSvg: SvgIcon = svgPin;
    public dots3Svg: SvgIcon = svg3Dots;
    public starSvg: SvgIcon = svgStarFill;

    @Input() title: string = "";
    @Input() savedView!: SavedView;

    @Input() defaultView: boolean = false;
    // sweden view is the default view over sweden that cannot be removed or altered
    @Input() swedenView: boolean = false;

    public popoverPosition: TrvPopoverPosition[] = ["bottom", "bottom-end", "bottom-start", "right", "top", "top-end"];
    public popoverComponent = SaveViewOptionsComponent;

    public toolTipPositions: TrvTooltipPosition[] = ["bottom", "right"];

    constructor(
        private localStorageService: LocalStorageService,
        @Inject(TrvNgMapService) private trvMapService: TrvNgMapService,

        @Inject(TrvGlobalMessagesService) private globalMessagesService: TrvGlobalMessagesService
    ) {}

    onShowComponent(ref: TrvPopoverRef<SaveViewOptionsComponent>): void {
        ref.componentInstance.inputSavedView = this.savedView;
    }

    onAfterCloseWithType(event: any): void {}

    public gotoView() {
        this.trvMapService.trvMap?.setMapStateWithCoord(
            this.savedView.centerCoordinate,
            this.savedView.zoom,
            this.savedView.saveBackground ? this.savedView.backgroundLayerId : undefined,
            this.savedView.saveDataLayers ? this.savedView.dataLayersId : undefined,
            1000,
            true
        );
    }

    starClicked(event: MouseEvent, viewId: string) {
        event.stopPropagation();
        this.localStorageService.setDefaultViewGuid(viewId);

        this.globalMessagesService.success("Uppdaterade favoritvy.");
    }
}

import {ApplicationRef, EventEmitter, Inject, Injectable, OnInit} from "@angular/core";
import {BehaviorSubject, first, firstValueFrom, last, max, take} from "rxjs";
import {Coordinate} from "ol/coordinate";
import {LocalStorageService} from "./local-storage.service";
import {MapTools, TrvNgMapService} from "@trafikverket/trv-ng-map";
import {filter, map} from "rxjs/operators";
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {DateRange, TrvFormUtilityService, TrvUtilityService} from "trv-ng-common";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {environment} from "src/environments/environment";
import {AuthenticationService, UserRole} from "./authentication.service";
import {ExportDirection, ExportSize, FileType} from "../components/map-menu/print-map-tab/print-map-tab.component";
import {FilterForm} from "@app/components/reports/reports-table/reports-table.component";
import {AdminApi} from "@_api/dataleverans/services/admin-api";
import {AdminConfigurationRead} from "@_api/dataleverans/models/admin-configuration-read";
import {AdminTypeEnum} from "@_api/dataleverans/models/admin-type-enum";
import {AdminConfigurationSave} from "@_api/dataleverans/models/admin-configuration-save";
import {DateTime} from "luxon";
import {Extent, getCenter} from "ol/extent";
import {Feature} from "ol";
import {ActorTypesEnum} from "@_api/dataleverans/models/actor-types-enum";
import {Report} from "@app/service/report.service";
import {TrvMapExtensionService} from "@app/service/trv-map-extension.service";
import {Pixel} from "ol/pixel";
import { DupAdminValueRead } from "@app/_api/dataleverans/models";
import { DupApi } from "@app/_api/pakarta/services";
import {NetDbApi} from "@_api/pakarta/services/net-db-api";
import {NetDbService} from "@app/service/net-db.service";
import {SWEDEN_VIEW} from "@shared/constants";

export type mobileMenuState = "FullScreen" | "Large" | "Medium" | "Small" | "Changing";

// Small and Large is set to fit certain content so it cannot have percentages unfortunetly
export const mobileMenuStateHeights: { [key in mobileMenuState]: string } = {
    // OBS make sure to have spaces, otherwise calc() doesnt work. Also you can only use percentage minus rem at the moment
    // - 2.5rem
    FullScreen: "100%",
    Large: "75%",
    Medium: "40%",
    Small: "3.7rem",
    Changing: "-1",
};

@Injectable({
    providedIn: "root",
})
export class NvdbNavigationService {
    public siteTitle: string = "";

    public currentlyHoveredReportItemInMap: null | number = null;

    // is currently in viewMode. viewMode is used to view reports from myReports and ajourhallare in the map
    // you cannot edit anything or see your regular reports in this view
    public VIEWMODE = false;
    public reportsToDisplayInViewMode: Report[] = [];
    public viewModeChanged = new EventEmitter<boolean>();

    /**
     * Variable that indicates if the sidebar is visible
     */
    public currentPageIsMap = true;
    public sideBarVisible = true;
    public sideBarVisibleChanged = new EventEmitter<void>();
    public reportItemEditModalVisible = false;
    public reportItemEditModalVisibleChanged = new EventEmitter<void>();

    public sideBarWidth: number = 400;
    public reportItemEditModalWidth: number = 400;

    public readonly sideBarMinWidth = 400;
    public readonly reportItemEditModalMinWidth = 390;

    public menuCurrentHeight = "4rem";

    // 1rem
    public reportItemEditModalMargin = 16;

    public readonly minViewportWidthBreakPoint = 770;

    /**
     * Variable that holds the current Navigation state
     */
    public navigationState = NavigationState.MainMenu;
    public navigationStateChanged = new EventEmitter<void>();
    public wasPreviouslyOnPrintMap = false;

    // remember the state of the map/pages for when we return to it
    public previousMapState: MapState | null = null;

    // remember the state of the tables for when we return to it
    public previousReportsTableState: TableState | null = null;
    public previousHandleReportsTableState: TableState | null = null;

    public isAuthenticated: boolean = false;

    public NVDB_DATALEVERANS_HANDLEDNING_PATH = "/assets/docs/Handledning_nvdb_dataleverans.pdf";
    public NVDB_DATALEVERANS_HANDLEDNING_AJOUR_PATH = "/assets/docs/Handledning_nvdb_dataleverans_ajourhallare.pdf";
    public NVDB_PA_KARTA_HANDLEDNING_PATH = "/assets/docs/Handledning_nvdb_pa_karta.pdf";

    public HANDLEDNING_PATH = this.NVDB_DATALEVERANS_HANDLEDNING_PATH;
    public HANDLEDNING_FILENAME = this.NVDB_DATALEVERANS_HANDLEDNING_PATH.replace("/assets/docs/", "");
    public HANDLEDNING_FILESIZE = "";

    // @@@@@@___MOBILE VIEW___@@@@@@
    currentMobileMenuState = new FormControl<mobileMenuState>("Medium", {nonNullable: true});
    // only accepts fixed states, so it ignores "Changing"
    previousMobileMenuState: mobileMenuState = "Medium";

    public HANDLEDNING_AJOUR_FILENAME = this.NVDB_DATALEVERANS_HANDLEDNING_AJOUR_PATH.replace("/assets/docs/", "");
    public HANDLEDNING_AJOUR_FILESIZE = "";

    //////////////////////////////////////////////////////////////////////
    // Variables containing the state of the tables on pages myReports and handleReports.//
    // We keep all state here so it is persistant. //
    //////////////////////////////////////////////////////////////////////
    /*
        public previousMyReportsState: TableState = {
            page: 1,
            filters: FormGroup<FilterForm>;
            openedReports: number[];
            openedReportItems: number[];
        }
        public previousHandleReportsState: TableState = {

        }
    */

    //////////////////////////////////////////////////////////////////////
    // Variables that indicates if a section in the menu is open or not.//
    //////////////////////////////////////////////////////////////////////



    public startMenuState = {
        showSearchSection: false,
        showDataslagSection: false,
        showLagerSection: false,
        showVyerSection: false,
        dataSlagScrollTop: 0,
        reportAvikelseInfoShown: false,
    };

    public searchMenuState = {
        searchString: new FormControl<string>(""),
        searchObjectType: new FormControl(searchObjectList[0]),
        platsSearch: new FormControl<{ key: PlatsSearchOption; displayName: string }[]>([], {nonNullable: true}),
        currentSearchItems: [] as SearchItem[],
        searchState: SearchState.None as SearchState,
    };

    public infoClickState = {
        pagination: null as {
            startPage: number;
            endPage: number;
            totalPages: number;
            pages: number[];
        } | null,
        dataslagType: new FormControl<any>(null, Validators.required),
        metaKeys: [] as string[],
    };

    public networkTypes = [
        //{ id: 0, name: "Alla nät" },
        {id: 1, name: "Bilnät"},
        //{ id: 2, name: "Cykelnät" },
        //{ id: 3, name: "Gångnät" },
    ];

    public reportMenuState = {
        openedReportIds: [] as number[],
        selectedReportIds: [] as number[],
        snapToNet: new FormControl(false, {nonNullable: true}),
        snapToNetType: new FormControl(this.networkTypes[0], {nonNullable: true}),
    };

    public scaleIntervals = [400, 500, 1000, 5000, 10000, 50000, 100000, 500000, 1000000, 5000000];
    public printMenuState = {
        //exportSize: ExportSize.MapView,
        //exportDirection: ExportDirection.Standing,
        //fileType: FileType.PNG,
        showLabel: false,

        areaCenterCoordinate: null as null | Coordinate,

        title: new FormControl<string>("", {nonNullable: true}),
        fileTypeOption: new FormControl<FileType>(FileType.PNG, {nonNullable: true}),
        exportDirectionOption: new FormControl<ExportDirection>(ExportDirection.Standing, {nonNullable: true}),
        exportSizeOption: new FormControl<ExportSize>(ExportSize.MapView, {nonNullable: true}),
        showLabelOption: new FormControl<boolean>(false),
        showCompassOption: new FormControl<boolean>(true),
        scale: new FormControl<number>(5000, {nonNullable: true}),
        slider: new FormControl(this.scaleIntervals.indexOf(5000), {nonNullable: true}),
    };

    /*
        initMessageRange: any = {
            from: "2023-10-03T00:00:00+02:00",
            to: null,
            toIsInfinite: false,
        };*/

    /*
    <b>Det här är ett meddela från förvaltningen.</b><br>\nDen här applikationen är fortfarande i utvecklingsfasen, och vissa funktioner fungerar inte just nu. <br>\nBuggrapportering sker via lime.\n<br>\n<br>\nDen här texten kan (inte just nu) redigeras via admin-gränssnittet.\n<br>\n<br>\n\n<i>Exempel på bild i texten:</i> <br>\n<img width="200px" height="200px" src="https://www.nvdb.se/ClientResources/Images/NVDB_logo.svg">
    */

    public savedViewsSortBy = new FormControl(sortByOptions.find(a => a.strategy == SortingStrategies.CREATED_NEW_FIRST)!, {nonNullable: true})


    public adminMenuState = {
        messageFromForvaltning: new FormControl<string>("", {nonNullable: true}),
        messagePeriodStart: new FormControl<string | null>(DateTime.now().toISO(), {validators: [Validators.required]}),
        messagePeriodEnd: new FormControl<string | null>(null),
    };

    public adminConfiguration: null | AdminConfigurationRead = null;

    public async updateAdminConfiguration(configuration: AdminConfigurationSave) {
        this.adminConfiguration = await firstValueFrom(this.adminApi.updateConfiguration({ body: configuration }));
    }

    public async loadAdminConfiguration() {
        if (!this.adminConfiguration) {
            this.adminConfiguration = await firstValueFrom(this.adminApi.getConfiguration());
        }
    }


    public dupAdminMenuState = {
        messageFromForvaltningDup: new FormControl<string>("", { nonNullable: true }),
        messagePeriodStartDup: new FormControl<string | null>(DateTime.now().toISO(), { validators: [Validators.required] }),
        messagePeriodEndDup: new FormControl<string | null>(null),
    };

    public adminConfigurationDup:  null | DupAdminValueRead[] = null;

    public async updateDupAdminConfiguration(configuration: AdminConfigurationSave) {
        this.adminConfiguration = await firstValueFrom(this.adminApi.updateConfiguration({ body: configuration }));
    }

    public async loadDupAdminConfiguration() {
        if (this.adminConfigurationDup == null) {
            this.adminConfigurationDup = await firstValueFrom(this.adminApi.getDupConfiguration());
        }
    }

    public async loadDupAdminConfigurationPaKarta() {
        if (this.adminConfigurationDup == null) {
            this.adminConfigurationDup = await firstValueFrom(this.dupApi.getAdminValues());
        }
    }

    public gotoSweden() {
        this.trvMapService.trvMap?.setMapStateWithCoord(SWEDEN_VIEW.centerCoordinate, SWEDEN_VIEW.zoom, undefined, undefined, 1000, true);
    }

    public updateDupAdminForm(adminValues: DupAdminValueRead[]) {

        const messageFromForvaltning = adminValues.find(adminValue => adminValue.uid === MESSAGE_FROM_FORVALTNING_UID_NVDB_PA_KARTA)
        if(messageFromForvaltning) this.dupAdminMenuState.messageFromForvaltningDup.setValue(messageFromForvaltning.value ?? "");

        const messageFromForvaltningPeriodStart = adminValues.find(adminValue => adminValue.uid === MESSAGE_FROM_FORVALTNING_PERIOD_START_UID_NVDB_PA_KARTA)
        if(messageFromForvaltningPeriodStart) this.dupAdminMenuState.messagePeriodStartDup.setValue(messageFromForvaltningPeriodStart.value ?? null);

        const messageFromForvaltningPeriodEnd = adminValues.find(adminValue => adminValue.uid === MESSAGE_FROM_FORVALTNING_PERIOD_END_UID_NVDB_PA_KARTA)
        if(messageFromForvaltningPeriodEnd) this.dupAdminMenuState.messagePeriodEndDup.setValue(messageFromForvaltningPeriodEnd.value ?? null);

        this.dupAdminMenuState.messageFromForvaltningDup.updateValueAndValidity();
        this.dupAdminMenuState.messagePeriodStartDup.updateValueAndValidity();
        this.dupAdminMenuState.messagePeriodEndDup.updateValueAndValidity();

    }

    public updateAdminForm(configuration: AdminConfigurationRead) {
        const messageFromForvaltningValue = configuration.values?.find(a => a.type === AdminTypeEnum.MessageFromForvaltningen);
        if (messageFromForvaltningValue) this.adminMenuState.messageFromForvaltning.setValue(messageFromForvaltningValue.value!);

        const periodStart = configuration.values?.find(a => a.type === AdminTypeEnum.MessagePeriodStart);
        const periodEnd = configuration.values?.find(a => a.type === AdminTypeEnum.MessagePeriodEnd);
        if (periodStart) this.adminMenuState.messagePeriodStart.setValue(periodStart?.value ?? null);
        this.adminMenuState.messagePeriodEnd.setValue(periodEnd?.value ?? null);

        this.adminMenuState.messageFromForvaltning.updateValueAndValidity();
        this.adminMenuState.messagePeriodStart.updateValueAndValidity();
        this.adminMenuState.messagePeriodEnd.updateValueAndValidity();
    }

    public showLabelDescription: boolean = true;

    public hideHamburgerMenu: boolean = false;
    public backLink: string = "";

    public reportAsList: { id: ActorTypesEnum; name: string }[] = [];
    public reportAsForm = new FormControl<{ id: ActorTypesEnum; name: string }>(this.reportAsList[0], {
        validators: Validators.required,
        nonNullable: true,
    });

    public userRoles: { id: number; userRole: UserRole }[] = [
        {
            id: 1,
            userRole: "Admin",
        },
        {
            id: 2,
            userRole: "Ajourhållare",
        },
        {
            id: 3,
            userRole: "Dataleverantör",
        },
    ];
    public userRoleAsForm = new FormControl<{ id: number; userRole: UserRole }>(this.userRoles[0], {
        validators: Validators.required,
        nonNullable: true,
    });

    public isMobileDevice = false;


    formattedLastFeatureUpdateNVDB: string | null = null

    constructor(
        @Inject(TrvMapExtensionService)
        public trvMapExtensionService: TrvMapExtensionService,
        @Inject(ApplicationRef) private appRef: ApplicationRef,
        @Inject(LocalStorageService)
        private localStorageService: LocalStorageService,
        @Inject(TrvNgMapService)
        private trvMapService: TrvNgMapService,
        private formUtilityService: TrvFormUtilityService,
        private trvUtilityService: TrvUtilityService,
        private oidcService: OidcSecurityService,
        private adminApi: AdminApi,
        private dupApi: DupApi,
        private netDbService: NetDbService
    ) {
        if (environment.application == "NvdbDataleverans") {
            this.HANDLEDNING_PATH = this.NVDB_DATALEVERANS_HANDLEDNING_PATH;
        } else if (environment.application == "NvdbPåKarta") {
            this.HANDLEDNING_PATH = this.NVDB_PA_KARTA_HANDLEDNING_PATH;
        }
        this.HANDLEDNING_FILENAME = this.HANDLEDNING_PATH.replace("/assets/docs/", "");
        this.setHandledningFileSize();

        this.reportAsForm = new FormControl(this.reportAsList[0], {
            validators: Validators.required,
            nonNullable: true,
        });

        this.userRoleAsForm = new FormControl(this.userRoles[0], {
            validators: Validators.required,
            nonNullable: true,
        });

        if (window.innerWidth <= 767) {
            this.closeSideBar();
        }

        this.sideBarWidth = this.localStorageService.getSidepanelWidth();
        this.reportItemEditModalWidth = this.localStorageService.getReportItemEditModalWidth();

        this.reportItemEditModalVisibleChanged.subscribe(() => this.updateReportEditModalWidth());
        this.sideBarVisibleChanged.subscribe(() => this.updateSidebarWidth());

        this.trvMapService.onMapLoaded().subscribe(() => {
            this.updateCustomMapLabelPosition();
        });

        this.navigationStateChanged.subscribe(() => {
            const currentlyExportOrInfoClick =
                this.trvMapService.trvMap!.activeMapTool.value === MapTools.ExportRectangle ||
                this.trvMapService.trvMap!.activeMapTool.value === MapTools.NvdbInfo; // shouldnt save if the tool is export or infoclick

            if (this.navigationState == NavigationState.PrintMap) {
                this.wasPreviouslyOnPrintMap = true;

                this.trvMapService.trvMap!.changeActiveMapTool(MapTools.ExportRectangle, !currentlyExportOrInfoClick);
            } else if (this.navigationState == NavigationState.InfoClick) {
                this.trvMapService.trvMap!.changeActiveMapTool(MapTools.NvdbInfo, !currentlyExportOrInfoClick);

                this.wasPreviouslyOnPrintMap = false;
            } else {
                if (this.wasPreviouslyOnPrintMap && this.trvMapService.trvMap!.prevMapTool != MapTools.ExportRectangle) {
                    this.trvMapService.trvMap!.changeActiveMapTool(this.trvMapService.trvMap!.prevMapTool, true);
                }

                this.wasPreviouslyOnPrintMap = false;
            }

            if (this.isMobileDevice && this.currentMobileMenuState.value == "Small") {
                this.currentMobileMenuState.setValue("Medium");
            }
        });

        // TODO fill this list more
        let mobileDevices = /android|iphone|kindle|ipad/i;

        if (mobileDevices.test(navigator.userAgent)) {
            this.isMobileDevice = true;
        }

    }

    public displayReportAsObject = (object: { id: number; name: string }) => {
        return object.name;
    };

    public displayUserroleObject = (object: { id: number; userRole: UserRole }) => {
        return object.userRole;
    };

    public updateCustomMapLabelPosition() {
        const className = ".ol-scale-line";
        let viewHtml = document.querySelector(className) as HTMLElement;
        if (viewHtml) {
            if (this.isMobileDevice) {

                const mobileMenuClassName = ".mobile-menu"
                let mobileMenuViewHtml = document.querySelector(mobileMenuClassName) as HTMLElement;
                if (mobileMenuViewHtml) {
                    //viewHtml.style.bottom = `calc(0.1rem + ${mobileMenuViewHtml.offsetHeight}px)`;
                    viewHtml.style.bottom = `4rem`;
                }

            } else {
                let offset = 10;
                if (window.innerWidth > this.minViewportWidthBreakPoint) {
                    if (this.sideBarVisible) offset += this.sideBarWidth;
                }
                viewHtml.style.left = offset + "px";
            }
        }
    }

    public updateSidebarWidth(distanceMoved: number = 0) {
        let sideBarWidth = this.sideBarWidth;

        sideBarWidth += distanceMoved;

        const underMinSize = sideBarWidth < this.sideBarMinWidth && distanceMoved < 0;

        let maxSize = document.body.clientWidth - this.reportItemEditModalMargin;
        if (this.reportItemEditModalVisible) maxSize -= this.reportItemEditModalMargin + this.reportItemEditModalWidth;

        const overMaxSize = maxSize < sideBarWidth && distanceMoved >= 0;

        if (underMinSize) {
            sideBarWidth = this.sideBarMinWidth;
        } else if (overMaxSize) {
            sideBarWidth = maxSize;
        }

        this.sideBarWidth = sideBarWidth;

        this.updateCustomMapLabelPosition();
    }

    public updateReportEditModalWidth(distanceMoved: number = 0) {
        let reportEditModalWidth = this.reportItemEditModalWidth;

        reportEditModalWidth += distanceMoved;

        const underMinSize = reportEditModalWidth < this.reportItemEditModalMinWidth && distanceMoved < 0;

        let maxSize = document.body.clientWidth - this.reportItemEditModalMargin;
        if (this.sideBarVisible) maxSize -= this.sideBarWidth + this.reportItemEditModalMargin;

        const overMaxSize = maxSize < reportEditModalWidth && distanceMoved >= 0;

        if (underMinSize) {
            reportEditModalWidth = this.reportItemEditModalMinWidth;
        } else if (overMaxSize) {
            reportEditModalWidth = maxSize;
        }

        this.reportItemEditModalWidth = reportEditModalWidth;
        this.updateCustomMapLabelPosition();
    }

    public toggleSideBar(): void {
        this.sideBarVisible = !this.sideBarVisible;
        this.sideBarVisibleChanged.emit();
        this.appRef.tick();
    }

    public closeSideBar(): void {
        this.sideBarVisible = false;
        this.sideBarVisibleChanged.emit();
        this.appRef.tick();
    }

    public openSideBar(): void {
        this.sideBarVisible = true;
        this.sideBarVisibleChanged.emit();
        this.appRef.tick();
    }

    public toggleLabelDescription() {
        this.showLabelDescription = !this.showLabelDescription;
        this.appRef.tick();
    }

    public async setHandledningFileSize() {
        let fileSize = await this.getFileSize(this.HANDLEDNING_PATH);
        this.HANDLEDNING_FILESIZE = fileSize;

        let fileSizeAjour = await this.getFileSize(this.NVDB_DATALEVERANS_HANDLEDNING_AJOUR_PATH);
        this.HANDLEDNING_AJOUR_FILESIZE = fileSizeAjour;
    }

    public async getFileSize(path: string) {
        return await fetch(path)
            .then(response => response.blob())
            .then(blob => {
                // Get the size of the blob (file)
                const fileSize = blob.size;
                // Convert bytes to kilobytes (or any other format as needed)
                return (fileSize / 1024).toFixed(2) + " KB";
            })
            .catch(error => {
                return "";
            });
    }

    // get the "effective" map extent when disregarding modals and menues that covers the map
    // OBS! works on main map only
    public getViewableExtentOnMainMap(): Extent {
        const map = this.trvMapService.trvMap!.map;
        const mapExtent = map.getView().calculateExtent();

        if (this.isMobileDevice) {
            mapExtent[1] = (mapExtent[3] - mapExtent[1]) * this.getMobileMenuPercentageHeight(true) + mapExtent[1];
        } else {
            let totalWidthCoveredByModals = 0;
            if (this.sideBarVisible) totalWidthCoveredByModals += this.sideBarWidth;
            if (this.reportItemEditModalVisible) totalWidthCoveredByModals += this.reportItemEditModalWidth + this.reportItemEditModalMargin;

            const proportionCoveredByMenus = totalWidthCoveredByModals / document.body.clientWidth;
            mapExtent[0] += (mapExtent[2] - mapExtent[0]) * proportionCoveredByMenus;
        }

        return mapExtent;
    }

    // get the "effective" center pixel when disregarding modals and menues that covers the map
    // OBS! works on main map only
    public getViewableCenterPixelOnMainMap(): Pixel {
        const map = this.trvMapService.trvMap!.map;
        const canvasSize = map.getSize()!;

        if (this.isMobileDevice) {
            return [canvasSize[0] / 2, (canvasSize[1] * (1 - this.getMobileMenuPercentageHeight(true))) / 2];
        } else {
            let totalWidthCoveredByModals = 0;
            if (this.sideBarVisible) totalWidthCoveredByModals += this.sideBarWidth;
            if (this.reportItemEditModalVisible) totalWidthCoveredByModals += this.reportItemEditModalWidth + this.reportItemEditModalMargin;

            return [(canvasSize[0] + totalWidthCoveredByModals) / 2, canvasSize[1] / 2];
        }
    }

    // return padding for the function "zoomInOnExtent" so that the menus are ignored plus the regular padding.
    // OBS! works on main map only
    public getPaddingForZoomToExtentOnMainMap(regularPadding: number) {
        if (!this.trvMapService.trvMap?.map) return [regularPadding, regularPadding, regularPadding, regularPadding];

        const canvasSize = this.trvMapService.trvMap!.map.getSize()!;

        if (this.isMobileDevice) {
            return [regularPadding, regularPadding, regularPadding + canvasSize[1] * this.getMobileMenuPercentageHeight(true), regularPadding];
        } else {
            let totalWidthCoveredByModals = 0;
            if (this.sideBarVisible) totalWidthCoveredByModals += this.sideBarWidth;
            if (this.reportItemEditModalVisible) totalWidthCoveredByModals += this.reportItemEditModalWidth + this.reportItemEditModalMargin;

            return [regularPadding, regularPadding, regularPadding, regularPadding + totalWidthCoveredByModals];
        }
    }

    // get height of mobile menu in percentage. If excludeHeader, give percentage of map, otherwise give percentage of entire screen. if state is null, use the currentState (previous if its changing)
    getMobileMenuPercentageHeight(excludeHeader = false, state?: mobileMenuState) {
        const currentHeight = mobileMenuStateHeights[state ? state : this.previousMobileMenuState];

        const screenHeightPixels = document.body.clientHeight;
        const rootFontSizePixels = parseFloat(getComputedStyle(document.documentElement).fontSize);
        let screenHeightRem = screenHeightPixels / rootFontSizePixels;

        const remHeightOfHeader = 3.5;

        if (currentHeight.includes("%")) {
            let percentage = parseInt(currentHeight.replace(/.*?([0-9]+)%.*/, "$1")) / 100;
            if (excludeHeader) {
                percentage = percentage / ((screenHeightRem - remHeightOfHeader) / screenHeightRem);
            }

            return percentage;
        } else {
            if (excludeHeader) {
                screenHeightRem -= remHeightOfHeader;
            }

            const remInPercentage = parseFloat(currentHeight.replace(/.*?([0-9.]*)rem.*/, "$1")) / screenHeightRem;

            return remInPercentage;
        }
    }
}

export enum NavigationState {
    MainMenu,
    Report,
    InfoClick,
    PrintMap,
    TeckenForklaring,
    Admin,
}

interface MapState {
    zoom: number;
    centerCoordinate: Coordinate;
    backgroundLayerId: string;
    dataLayersId: string[];

    history: {
        center: Coordinate;
        zoom: number;
    }[];
    currentHistoryIndex: number;
    hasMovedSinceLastSavepoint: boolean;
}

export interface TableState {
    page: number;
    filters: FormGroup<FilterForm>;
    openedReports: number[];
    openedReportItems: number[];
    scroll: number;
}

// SEARCH
export interface SearchObjectType {
    order: number;
    id: string;
    displayName: string;
}

export enum PlatsSearchOption {
    TATORT,
    ORT,
    KOMMUN,
    KOORDINAT,
    VAGNUMMER,
}

export interface SearchItem {
    title: string;
    subTitle: string;
    extent: Extent;
    feature?: Feature;
    type: number;
}

export enum SearchState {
    None,
    ToShortString,
    Loading,
    Success,
    NoResults,
}

export const searchObjectList: SearchObjectType[] = [
    {order: 1, id: "plats", displayName: "Plats, vägnummer, koordinat"},
    {order: 2, id: "gatunamn", displayName: "Gatunamn"},
    {order: 3, id: "elementid", displayName: "Element id"},
    {
        order: 4,
        id: "driftbidragstatlig",
        displayName: "Driftbidrag statligt",
    },
    {order: 6, id: "bro", displayName: "Bro och tunnel"},
];


export enum SortingStrategies {
    CREATED_NEW_FIRST,
    CREATED_OLD_FIRST,
    ALPHA_A_TO_Z,
    ALPHA_Z_TO_A,
}
export const sortByOptions = [
    {strategy: SortingStrategies.CREATED_NEW_FIRST, name: 'Sortera nyaste först'},
    {strategy: SortingStrategies.CREATED_OLD_FIRST, name: 'Sortera äldsta först'},
    {strategy: SortingStrategies.ALPHA_A_TO_Z, name: 'Sortera A-Z'},
    {strategy: SortingStrategies.ALPHA_Z_TO_A, name: 'Sortera Z-A'},
]


export const availablePlatsSearchFilter: {
    key: PlatsSearchOption;
    displayName: string;
}[] = [
    {key: PlatsSearchOption.TATORT, displayName: "Tätorter"},
    {key: PlatsSearchOption.ORT, displayName: "Orter"},
    {key: PlatsSearchOption.KOMMUN, displayName: "Kommuner"},
    {key: PlatsSearchOption.KOORDINAT, displayName: "Koordinat"},
    {key: PlatsSearchOption.VAGNUMMER, displayName: "Vägnummer"},
];

export const MESSAGE_FROM_FORVALTNING_UID_NVDB_PA_KARTA:string = "NVDBpaKartaMessageFranForvaltning";
export const MESSAGE_FROM_FORVALTNING_PERIOD_START_UID_NVDB_PA_KARTA:string = "NVDBpaKartaMessageFranForvaltningPeriodStart";
export const MESSAGE_FROM_FORVALTNING_PERIOD_END_UID_NVDB_PA_KARTA:string = "NVDBpaKartaMessageFranForvaltningPeriodEnd";

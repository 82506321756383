<div class="container">
    @for (layer of this.trvMapService.trvMap!.trvLayer.getVisibleWmsLayer(); track layer) {
        <div>
            @if (layer.legendGraphics) {
                <div>
                    <b>{{ layer.name }}</b>
                    <br/>
                    <img [src]="getLegendGraphics(layer)" [alt]="'Tecken för {{layer.name}} i kartan'"/>
                </div>
            }
        </div>
    } @empty {
        <div>
            Inga dataprodukter är valda.
        </div>
    }

</div>

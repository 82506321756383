import { Component, Inject } from "@angular/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faMapMarkerAlt, faRoute, faVectorSquare, faAdd, faUpDownLeftRight, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { MapTools, MapToolsType, TrvNgMapService } from "@trafikverket/trv-ng-map";
import { ReportItemWithFeature, ReportAvikelseService } from "src/app/service/report-avikelse.service";
import { SvgIcon } from "src/app/shared/utils/svg-helper";
import { svgPlusSquare } from "src/app/shared/utils/svg-helper-data";
import { TrvGlobalMessagesService, TrvTooltipPosition, TrvTooltipDirective, TrvModalService } from "trv-ng-common";
import { ReportAvikelseTabItemComponent } from "./report-avikelse-tab-item/report-avikelse-tab-item.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgClass } from "@angular/common";
import { SendReportAvikelseModalComponent } from "@app/components/reports/send-report-avikelse-modal/send-report-avikelse-modal.component";
import {NvdbNavigationService} from "@app/service/nvdb-navigation.service";

@Component({
    selector: 'app-report-avikelse-tab',
    templateUrl: './report-avikelse-tab.component.html',
    styleUrls: ['./report-avikelse-tab.component.scss'],
    standalone: true,
    imports: [
        TrvTooltipDirective,
        NgClass,
        FontAwesomeModule,
        ReportAvikelseTabItemComponent
    ],
})
export class ReportAvikelseTabComponent {
    public faMapMarkerAlt: IconProp = faMapMarkerAlt as IconProp;
    public faRoute: IconProp = faRoute as IconProp;
    public faVectorSquare: IconProp = faVectorSquare as IconProp;
    public faAdd: IconProp = faAdd as IconProp;
    public faMove: IconProp = faUpDownLeftRight as IconProp;
    public faEdit: IconProp = faEdit as IconProp;
    public faTrash: IconProp = faTrash as IconProp;
    public iconPlusSquare: SvgIcon = svgPlusSquare;

    public toolTipPositions: TrvTooltipPosition[] = ["bottom", "right"];

    public MapTools = MapTools;


    constructor(
        @Inject(TrvNgMapService) public trvMapService: TrvNgMapService,
        public reportAvikelseService: ReportAvikelseService,
        private trvModalService: TrvModalService,
        public nvdbNavigationService: NvdbNavigationService
    ) { }

    public setMapTool(mapTool: MapToolsType) {
        if (this.trvMapService.trvMap?.activeMapTool?.value == mapTool) {
            this.trvMapService.trvMap?.changeActiveMapTool(MapTools.Pointer);
        } else {
            this.trvMapService.trvMap?.changeActiveMapTool(mapTool);
        }
    }

    public submitAvikelser() {
        this.trvModalService.open(SendReportAvikelseModalComponent, {
            disposeOnBackdropClick: false,
            size: this.nvdbNavigationService.isMobileDevice ? "fullscreen" : "lg",
        });
    }
}


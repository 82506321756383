@if (this.trvMapService.isMapLoaded() && this.trvMapService.trvMap!.trvLayer.isAnyLayerWithLabelActive()) {
  <div>
    <div class="map-main__info">
      <div class="c-info" [ngStyle]="!nvdbNavigation.showLabelDescription ? { width: 'auto' } : {'min-width': '300px'}">
        <div class="c-info__header">
          @if (nvdbNavigation.showLabelDescription) {
            <h2>Teckenförklaring</h2>
          }
          <button type="button" class="btn btn-link" aria-label="Stäng" (click)="nvdbNavigation.toggleLabelDescription()">
            @if (nvdbNavigation.showLabelDescription) {
              <app-svg-icon [svgIcon]="upIcon"></app-svg-icon>
            }
            @if (!nvdbNavigation.showLabelDescription) {
              <app-svg-icon [svgIcon]="downIcon"></app-svg-icon>
            }
          </button>
        </div>
        @if (nvdbNavigation.showLabelDescription) {
          <div class="c-info__body" style="max-height: 50svh; overflow-y: auto">
            <div style="padding: 10px">
              @for (layer of this.trvMapService.trvMap!.trvLayer.getVisibleWmsLayer(); track layer) {
                <div>
                  @if (layer.legendGraphics) {
                    <div>
                      <b>{{ layer.name }}</b>
                      <br />
                      <img [src]="getLegendGraphics(layer)" [alt]="'Tecken för {{layer.name}} i kartan'"/>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        }
      </div>
    </div>
  </div>
}

/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DeliveryTypeResponse } from '../models/delivery-type-response';
import { getDeliveryTypes } from '../fn/report-avikelse/get-delivery-types';
import { GetDeliveryTypes$Params } from '../fn/report-avikelse/get-delivery-types';
import { getDeliveryTypes$Plain } from '../fn/report-avikelse/get-delivery-types-plain';
import { GetDeliveryTypes$Plain$Params } from '../fn/report-avikelse/get-delivery-types-plain';
import { getDupReport } from '../fn/report-avikelse/get-dup-report';
import { GetDupReport$Params } from '../fn/report-avikelse/get-dup-report';
import { getDupReport$Plain } from '../fn/report-avikelse/get-dup-report-plain';
import { GetDupReport$Plain$Params } from '../fn/report-avikelse/get-dup-report-plain';
import { Report } from '../models/report';
import { reportAvikelse } from '../fn/report-avikelse/report-avikelse';
import { ReportAvikelse$Params } from '../fn/report-avikelse/report-avikelse';
import { reportAvikelse$Plain } from '../fn/report-avikelse/report-avikelse-plain';
import { ReportAvikelse$Plain$Params } from '../fn/report-avikelse/report-avikelse-plain';
import { ReportWithStatus } from '../models/report-with-status';

@Injectable({ providedIn: 'root' })
export class ReportAvikelseApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDeliveryTypesReportAvikelse()` */
  static readonly GetDeliveryTypesReportAvikelsePath = '/ReportAvikelse/DeliveryTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeliveryTypes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeliveryTypes$Plain$Response(params?: GetDeliveryTypes$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliveryTypeResponse>> {
    return getDeliveryTypes$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDeliveryTypes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeliveryTypes$Plain(params?: GetDeliveryTypes$Plain$Params, context?: HttpContext): Observable<DeliveryTypeResponse> {
    return this.getDeliveryTypes$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryTypeResponse>): DeliveryTypeResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeliveryTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeliveryTypes$Response(params?: GetDeliveryTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliveryTypeResponse>> {
    return getDeliveryTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDeliveryTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeliveryTypes(params?: GetDeliveryTypes$Params, context?: HttpContext): Observable<DeliveryTypeResponse> {
    return this.getDeliveryTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryTypeResponse>): DeliveryTypeResponse => r.body)
    );
  }

  /** Path part for operation `reportAvikelseReportAvikelse()` */
  static readonly ReportAvikelseReportAvikelsePath = '/ReportAvikelse/ReportAvikelse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportAvikelse$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reportAvikelse$Plain$Response(params?: ReportAvikelse$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Report>> {
    return reportAvikelse$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportAvikelse$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reportAvikelse$Plain(params?: ReportAvikelse$Plain$Params, context?: HttpContext): Observable<Report> {
    return this.reportAvikelse$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Report>): Report => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportAvikelse()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reportAvikelse$Response(params?: ReportAvikelse$Params, context?: HttpContext): Observable<StrictHttpResponse<Report>> {
    return reportAvikelse(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportAvikelse$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reportAvikelse(params?: ReportAvikelse$Params, context?: HttpContext): Observable<Report> {
    return this.reportAvikelse$Response(params, context).pipe(
      map((r: StrictHttpResponse<Report>): Report => r.body)
    );
  }

  /** Path part for operation `getDupReportReportAvikelse()` */
  static readonly GetDupReportReportAvikelsePath = '/ReportAvikelse/DupReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDupReport$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDupReport$Plain$Response(params?: GetDupReport$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportWithStatus>> {
    return getDupReport$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDupReport$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDupReport$Plain(params?: GetDupReport$Plain$Params, context?: HttpContext): Observable<ReportWithStatus> {
    return this.getDupReport$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportWithStatus>): ReportWithStatus => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDupReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDupReport$Response(params?: GetDupReport$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportWithStatus>> {
    return getDupReport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDupReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDupReport(params?: GetDupReport$Params, context?: HttpContext): Observable<ReportWithStatus> {
    return this.getDupReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportWithStatus>): ReportWithStatus => r.body)
    );
  }

}

import {Component, Inject, Input, OnInit} from "@angular/core";
import {SvgIcon} from "src/app/shared/utils/svg-helper";
import {
    svgTatortFill,
    svgKommunFill,
    svgOrtFill,
    svgRoad,
    svgBridge,
    svgPin
} from "src/app/shared/utils/svg-helper-data";
import {DefaultDottedStyle, TrvNgMapService, greenPinStyle, SearchStyle} from "@trafikverket/trv-ng-map";
import Point from "ol/geom/Point";
import {SvgIconComponent} from "../../../utils/svg-icon/svg-icon.component";
import {
    NvdbNavigationService,
    SearchItem
} from "@app/service/nvdb-navigation.service";


@Component({
    selector: "app-search-item",
    templateUrl: "./search-item.component.html",
    styleUrls: ["./search-item.component.scss"],
    standalone: true,
    imports: [SvgIconComponent],
})
export class SearchItemComponent implements OnInit {
    @Input() searchItem!: SearchItem;
    @Input() first: boolean = false;

    public tatortFill: SvgIcon = svgTatortFill;
    public kommunFill: SvgIcon = svgKommunFill;
    public ortFill: SvgIcon = svgOrtFill;
    public roadFill: SvgIcon = svgRoad;
    public bridgeFill: SvgIcon = svgBridge;
    public pinFill: SvgIcon = svgPin;

    constructor(@Inject(TrvNgMapService) public trvMapService: TrvNgMapService, public nvdbNavigationService: NvdbNavigationService) {
    }

    ngOnInit(): void {
    }

    public gotoSearchItem() {
        this.trvMapService.trvMap?.zoomInOnExtent(this.searchItem.extent!, 1000, this.nvdbNavigationService.getPaddingForZoomToExtentOnMainMap(100))

        this.trvMapService.trvMap?.trvLayer.tempLayer!.getSource()?.clear();

        if (this.searchItem.feature) {
            this.searchItem.feature.setStyle(SearchStyle);

            this.trvMapService.trvMap?.trvLayer.addFeature(this.searchItem.feature, this.trvMapService.trvMap?.trvLayer.tempLayer!);
        }
    }
}

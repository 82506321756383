import {ApplicationRef, EventEmitter, Inject, Injectable} from "@angular/core";
import {FOCUSED, HIDDEN, REPORT_ITEM_ID} from "@app/shared/constants";
import {TrvGlobalMessagesService} from "trv-ng-common";
import {bluePinStyle2, TrvNgMapService} from "@trafikverket/trv-ng-map";
import {combineLatest, firstValueFrom, forkJoin, merge, of, take} from "rxjs";
import {LocalStorageService} from "./local-storage.service";
import Feature from "ol/Feature";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import VectorTileLayer from "ol/layer/VectorTile";
import {Geometry} from "ol/geom";

import {ReportApi} from "../_api/dataleverans/services/report-api";
import WKT from "ol/format/WKT";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {NetDbService} from "./net-db.service";
import {AjourApi, NetDbApi, TypesApi} from "../_api/dataleverans/services";
import {
    ActorTypesEnum,
    Dataslag,
    DataslagCollection,
    DataslagCollectionOption,
    DataslagValueTypesEnum,
    DeliveryTypeRead,
    EmailTypesEnum,
    ReportEvent,
    ReportItemRead,
    ReportItemSave,
    ReportRead,
    ReportSave,
    ReportStatusesEnum,
} from "../_api/dataleverans/models";
import {DateTime} from "luxon";
import {NvdbNavigationService} from "./nvdb-navigation.service";
import {TrvMapExtensionService} from "./trv-map-extension.service";
import {AjourhallningAction, GeometryType} from "@shared/enums";
import {
    NvdbReportStyle,
    NvdbReportStyleFocused,
    NvdbReportStyleHidden,
    NvdbReportStyleHover,
} from "@app/trv-map-extension/trv-map-extension-styles";
import {AuthenticationService} from "./authentication.service";
import {environment} from "src/environments/environment";
import {DELIVERY_TYPE_VALID_FROM_DESCRIPTION} from "@shared/constants";
import {TrvMapExtension} from "@app/trv-map-extension/trv-map-extension";

@Injectable({
    providedIn: "root",
})
export class ReportService {
    public actorID = ActorTypesEnum.Kommun;

    public otherUsersReports: Report[] = [];
    // my reports
    public reports: Report[] = [];
    public reportItemsWithChanges: number[] = [];

    // currently edited file descriptions
    editableFiles: { [key: number]: string } = {};


    // either reports or otherUsersReports changed
    // OBS use the trigger function instead of emitting directly...
    public reportsChanged = new EventEmitter<void>();
    private debounceTimeout: any;


    // reportsChanged is called alot so use triggerReportsChanged instead
    triggerReportsChanged() {
        clearTimeout(this.debounceTimeout); // Clear any existing timeout
        this.debounceTimeout = setTimeout(() => {
            this.reportsChanged.emit();
        }, 50); // Set timeout for 50ms
    }

    public deliveryTypes: DeliveryTypeRead[] = [];
    public allDeliveryTypes: DeliveryTypeRead[] = [];

    public trvExtension?: TrvMapExtension;
    public allDataslag: {
        [actorType: number]: {
            [deliveryType: number]: DataslagCollection[];
        };
    } = {};

    // set reportItemId if you want to edit an existing item, and FeatureToCreate if you want to create a new one
    private _previousItemToCreateOrEdit: number | FeatureToCreate | null = null;
    // id if editing existing item or feature if creating a new item
    public itemToCreateOrEditChanged = new EventEmitter<void>();
    public itemToCreateOrEdit: number | FeatureToCreate | null = null;

    public reportDataLoaded = false;
    public reportDataLoadedChanged = new EventEmitter<boolean>();

    public hiddenReportItems: number[] = [];

    public reportsLastRefreshed = DateTime.now();

    totalQueLengthFromBeginning: number | null = null;
    queOfItemsToCreate: FeatureToCreate[] = [];

    constructor(
        @Inject(ApplicationRef) private appRef: ApplicationRef,
        private ajourApiClient: AjourApi,
        public reportApiClient: ReportApi,
        private typesApiClient: TypesApi,
        public navigationService: NvdbNavigationService,
        private netDbApiClient: NetDbApi,
        private netDbService: NetDbService,
        @Inject(TrvGlobalMessagesService)
        private globalMessagesService: TrvGlobalMessagesService,
        @Inject(LocalStorageService)
        private localStorageService: LocalStorageService,
        @Inject(TrvNgMapService) public trvNgMapService: TrvNgMapService,
        @Inject(NvdbNavigationService)
        public nvdbNavigation: NvdbNavigationService,
        public trvMapExtenstionService: TrvMapExtensionService,
        public authenticationService: AuthenticationService
    ) {
        this.hiddenReportItems = this.localStorageService.getHiddenReportItems();
        combineLatest([this.trvNgMapService.onMapLoaded(), this.reportDataLoadedChanged, this.reportsChanged]).subscribe(() => {
            if (this.trvNgMapService.isMapLoaded() && this.reportDataLoaded) this.reloadFeatures();
        });

        this.nvdbNavigation.viewModeChanged.subscribe(() => {
            this.setActiveItemToCreateOrEdit(null)
            this.reloadFeatures()
        })
    }

    setActiveItemToCreateOrEdit(item: number | FeatureToCreate | null, clearQue = true) {
        if (this.nvdbNavigation.VIEWMODE) {
            this.nvdbNavigation.reportItemEditModalVisible = item != null;
            this.nvdbNavigation.reportItemEditModalVisibleChanged.emit();

            for (const report of this.nvdbNavigation.reportsToDisplayInViewMode) {
                for (const reportItem of report.reportItems) {
                    if (!reportItem.feature) continue;

                    if (reportItem.id == item) {
                        reportItem.feature!.set(FOCUSED, true);
                        this.panToFeature(reportItem.feature!, 400);
                    } else {
                        reportItem.feature!.set(FOCUSED, false);
                    }
                }
            }

            this.trvMapExtenstionService.trvMapExtension.resetAllReportFeatureStyle();

            this.itemToCreateOrEdit = item;
            this.itemToCreateOrEditChanged.emit();

            return;
        }

        if (clearQue) {
            this.queOfItemsToCreate = []
            this.totalQueLengthFromBeginning = null
        }

        const previousItemToCreateOrEdit = this._previousItemToCreateOrEdit;

        if (this.itemTypeofEditing(previousItemToCreateOrEdit) && this.getItemById(previousItemToCreateOrEdit)) {
            this.resetItemForm(previousItemToCreateOrEdit);
            const item = this.getItemById(previousItemToCreateOrEdit);

            item!.feature!.set(FOCUSED, false);
        }

        if (this.itemTypeofCreating(previousItemToCreateOrEdit)) {
            this.removeFeatureFromMap(previousItemToCreateOrEdit.feature);
        }

        this._previousItemToCreateOrEdit = item;
        this.nvdbNavigation.reportItemEditModalVisible = item != null;
        this.nvdbNavigation.reportItemEditModalVisibleChanged.emit();

        const feature = this.itemTypeofEditing(item) ? this.getItemById(item)!.feature : item?.feature;

        if (this.navigationService.isMobileDevice) {
            this.navigationService.currentMobileMenuState.setValue(feature ? "Large" : "Medium")
        }

        if (feature) {
            this.trvMapExtenstionService.trvMapExtension.resetAllReportFeatureStyle();
            feature.set(FOCUSED, true);
            feature.setStyle(NvdbReportStyleFocused)
            this.panToFeature(feature, 400);
        }

        this.itemToCreateOrEdit = item;
        this.itemToCreateOrEditChanged.emit();
    }

    panToFeature(feature: Feature, duration = 0) {
        const panPixel = this.navigationService.getViewableCenterPixelOnMainMap()
        // pan slightly lower for points since their image is above them
        if (feature.getGeometry()?.getType() == "Point") {
            panPixel[1] += 20
        }

        setTimeout(() => {
            this.trvNgMapService.trvMap!.panToFeatureOffsetLocalPixel(feature, panPixel, 400);
        }, 50);
    }

    async initData() {
        try {
            const allReportsForUser = this.reportApiClient.get({
                includeDraft: true,
                includeClosed: true,
            });
            const allDataslagForActors = this.netDbApiClient.getAllDataslagForActors({
                actorTypes: this.nvdbNavigation.reportAsList.map(a => a.id),
            });


            const values = await firstValueFrom(
                forkJoin({
                    allDataslagForActors: environment.application == "NvdbDataleverans" ? allDataslagForActors : of(null),

                    allReportsForUser: environment.application == "NvdbDataleverans" ? allReportsForUser : of(null),
                    reportsForOtherUsers: this.authenticationService.isMinAjourhallare ? this.reportApiClient.getOthers() : this.reportApiClient.getOthersUnlocked(),

                    deliveryTypes: this.typesApiClient.getDeliveryTypes(),
                    allDeliveryTypes: this.typesApiClient.getAllDeliveryTypes()
                })
            );

            if (values.allDataslagForActors) {
                for (const actorDataslag of values.allDataslagForActors) {
                    this.allDataslag[actorDataslag.actorType] = {};
                    for (const dataslagByDeliveryType of actorDataslag.dataslagByDeliveryTypes) {
                        this.allDataslag[actorDataslag.actorType][dataslagByDeliveryType.deliveryType] = dataslagByDeliveryType.dataslag;
                    }
                }
            }

            this.deliveryTypes = values.deliveryTypes.deliveryTypes;
            this.allDeliveryTypes = values.allDeliveryTypes.deliveryTypes

            const allReports = (values.reportsForOtherUsers?.reports ?? []).concat(values.allReportsForUser?.reports ?? []);
            for (const reportRead of allReports) {
                this.AddOrUpdateReportFromReportRead(reportRead);
            }
            this.reportDataLoaded = true;
            this.reportDataLoadedChanged.emit(true);
            this.reportsLastRefreshed = DateTime.now();

            this.netDbService.getLastFeatureUpdateNVDB().pipe(take(1)).subscribe(lastFeatureUpdateNVDB => {
                if (lastFeatureUpdateNVDB) this.nvdbNavigation.formattedLastFeatureUpdateNVDB = lastFeatureUpdateNVDB.split(" ")[0];
            })

        } catch (e) {
            this.globalMessagesService.error("Gick ej att hämta data.");
        }
    }

    saveFileDescriptions(reportIds: number[]) {
        for (const report of this.reports) {
            if (!reportIds.includes(report.id)) continue
            if (!this.isReportEditable(report)) continue

            for (const item of report.reportItems) {
                item.form!.patchValue({
                    files: item.form!.value.files!.map(a => {
                        const editedDescription = this.editableFiles.hasOwnProperty(a.idOrTempId) ? this.editableFiles[a.idOrTempId] : null
                        if (editedDescription) a.description = editedDescription
                        return a;
                    }),
                });
            }
        }

        this.editableFiles = {}
    }

    public async refreshReports(othersIncludeClosed = false) {
        const allReportsForUser = this.reportApiClient.get({
            includeDraft: true,
            includeClosed: true,
        });

        const values = await firstValueFrom(
            forkJoin({
                allReportsForUser: environment.application == "NvdbDataleverans" ? allReportsForUser : of(null),
                reportsForOtherUsers: this.authenticationService.isMinAjourhallare ? this.reportApiClient.getOthers({includeClosed: othersIncludeClosed}) : this.reportApiClient.getOthersUnlocked({includeClosed: othersIncludeClosed})
            })
        );

        const allReports = (values.reportsForOtherUsers?.reports ?? []).concat(values.allReportsForUser?.reports ?? []);

        if (!allReports || allReports.length == 0) return;

        this.otherUsersReports = [];
        this.reports = [];

        for (const reportRead of allReports) {
            this.AddOrUpdateReportFromReportRead(reportRead);
        }

        this.reportsLastRefreshed = DateTime.now();
        this.reportsChanged.emit();
    }

    public reloadFeatures() {
        this.trvExtension?.reportLayer.getSource()?.clear();

        if (this.nvdbNavigation.VIEWMODE) {
            for (const report of this.nvdbNavigation.reportsToDisplayInViewMode!) {
                for (const item of report.reportItems) {
                    if (!item.feature && this.trvNgMapService.trvMap?.trvLayer) {
                        item.feature = this.trvNgMapService.trvMap?.trvLayer.getFeatureFromWkt(item.wkt) ?? null;
                        item.feature!.set(REPORT_ITEM_ID, item.id);
                    }
                    this.addReportFeatureToMap(item.feature!);
                }
            }

            this.trvNgMapService.trvMap?.zoomInOnLayer(this.trvMapExtenstionService.trvMapExtension.reportLayer)
        } else {
            for (const group of this.reports.filter(a => a.status == ReportStatusesEnum.Draft || a.status == ReportStatusesEnum.OpenForClarification)) {
                for (const item of group.reportItems) {
                    if (!item.feature && this.trvNgMapService.trvMap?.trvLayer) {
                        item.feature = this.trvNgMapService.trvMap?.trvLayer.getFeatureFromWkt(item.wkt) ?? null;
                        item.feature!.set(REPORT_ITEM_ID, item.id);
                    }

                    this.addReportFeatureToMap(item.feature!);

                    if (this.hiddenReportItems.includes(item.id)) {
                        this.setItemHidden(item.feature!, true);
                    } else {
                        this.setItemHidden(item.feature!, false);
                    }
                }
            }
            // add the feature of the item currently getting created
            if (this.itemTypeofCreating(this.itemToCreateOrEdit)) {
                this.addReportFeatureToMap(this.itemToCreateOrEdit.feature);
            }
        }
    }

    public itemTypeofCreating(item: number | FeatureToCreate | null): item is FeatureToCreate {
        return item != null && typeof item == "object";
    }

    public itemTypeofEditing(item: number | FeatureToCreate | null): item is number {
        return typeof item == "number";
    }

    async getSingleReportByUid(uid: string): Promise<Report | string> {
        try {
            const reportRead = await firstValueFrom(this.authenticationService.isCurrentMinAjourhallare ? this.reportApiClient.getSingleByUidAny({uid: uid}) : this.reportApiClient.getSingleByUid({uid: uid}));
            return this.AddOrUpdateReportFromReportRead(reportRead);
        } catch (e) {
            return (e as any).error;
        }
    }

    async getSingleReport(id: number): Promise<Report | string> {
        try {
            const reportRead = await firstValueFrom(this.authenticationService.isCurrentMinAjourhallare ? this.reportApiClient.getSingleAny({reportId: id}) : this.reportApiClient.getSingle({reportId: id}));
            return this.AddOrUpdateReportFromReportRead(reportRead);
        } catch (e) {
            return (e as any).error;
        }
    }

    private itemFormChanged(change: any, itemId: number) {
        const item = this.getItemById(itemId);
        if (!item) return;

        const dataslagHasChanged = !areAllDataslagEqual(item.dataslag ?? {}, change.dataslag ?? {});
        const filesHasChanged =
            !item.files.every(a => change.files.some((b: ReportItemFile) => b.description === a.description && b.fileName === a.fileName)) ||
            item.files.length !== change.files.length;

        // If it hasnt changed they are either both null, or neither are null but their dates are the same
        const dateHasChanged = !(
            change.validFrom == item.validFrom ||
            (change.validFrom && item.validFrom && DateTime.fromISO(change.validFrom).hasSame(item.validFrom!, "day"))
        );

        /*
                console.log(item.description != change.description);
                console.log(item.deliveryType.deliveryType != change.deliveryType.deliveryType);
                console.log(item.report.id != change.report.id);
                console.log(item.validFrom?.toISO() != change.validFrom);
                console.log(dateHasChanged);
                console.log(filesHasChanged);
                console.log(dataslagHasChanged);
        */

        const itemHasChanged =
            item.description != change.description ||
            item.deliveryType.deliveryType != change.deliveryType.deliveryType ||
            item.report.id != change.report.id ||
            dateHasChanged ||
            filesHasChanged ||
            dataslagHasChanged;

        /*
                console.log(itemHasChanged);
        */

        if (itemHasChanged && !this.reportItemsWithChanges.includes(itemId)) {
            // TODO OBS dont know if this actually triggers change detection...
            this.reportItemsWithChanges = [...this.reportItemsWithChanges, itemId];
        } else if (!itemHasChanged && this.reportItemsWithChanges.includes(itemId)) {
            this.reportItemsWithChanges = this.reportItemsWithChanges.filter(a => a !== itemId);
        }
    }

    /**
     * Removes all the reports by id
     * @param reportsToDelete list of reportId to remove
     */
    public async removeReportsById(reportsToDelete: number[]) {
        await firstValueFrom(
            this.reportApiClient.remove({
                body: {
                    reportsToDelete: reportsToDelete,
                    reportItemsToDelete: [],
                },
            })
        );

        if (reportsToDelete.length === 1) {
            const reportName = this.reports.find(a => a.id === reportsToDelete[0])!.name;
            this.globalMessagesService.success(`Ärende med id "${reportName}" togs bort.`);
        } else {
            this.globalMessagesService.success("Ärendena togs bort.");
        }

        this.reports = this.reports.filter(a => !reportsToDelete.includes(a.id));
        this.triggerReportsChanged();

        if (reportsToDelete.includes(this.localStorageService.getDefaultReportId())) {
            let newDefaultReport;

            const draftReports = this.reports.filter(a => a.status === ReportStatusesEnum.Draft);
            if (draftReports.length > 0) newDefaultReport = draftReports[0].id;

            if (!newDefaultReport) {
                const kompletteraReports = this.reports.filter(a => a.status === ReportStatusesEnum.OpenForClarification);
                if (kompletteraReports.length > 0) newDefaultReport = kompletteraReports[0].id;
            }

            if (newDefaultReport) this.localStorageService.setDefaultReportGroupId(newDefaultReport);
        }
    }

    public getGeometryTypeFromWkt(wkt: string): GeometryType | null {
        let wktFromat = new WKT();
        let feature = wktFromat.readFeature(wkt, {
            dataProjection: "EPSG:3006",
            featureProjection: "EPSG:3006",
        });

        return feature.getGeometry()!.getType() as GeometryType;
    }

    public async removeReportItemById(reportId: number, id: number) {
        await firstValueFrom(
            this.reportApiClient.remove({
                body: {
                    reportsToDelete: [],
                    reportItemsToDelete: [id],
                },
            })
        );

        const reportItem = this.getItemById(id);
        this.globalMessagesService.success(`Förändringen med beskrivning "${reportItem!.description}" togs bort.`);

        const report = this.reports.find(report => report.id === reportId);
        report!.reportItems = report!.reportItems.filter(item => item.id !== id);

        // if the removed item is the currently edited item, close the edit modal
        if (this.itemToCreateOrEdit == id) {
            this.setActiveItemToCreateOrEdit(null);
        }

        this.triggerReportsChanged();
    }

    public addReportFeatureToMap(feature: Feature) {
        if (!feature) return;

        if (feature.get(HIDDEN)) {
            feature.setStyle(NvdbReportStyleHidden);
        } else if (feature.get(FOCUSED) && this.nvdbNavigation.VIEWMODE && feature.getGeometry()?.getType() == GeometryType.POINT) {
            feature.setStyle(bluePinStyle2);
        } else if (feature.get(FOCUSED)) {
            feature.setStyle(NvdbReportStyleFocused);
        } else {
            feature.setStyle(NvdbReportStyle);
        }
        this.trvExtension!.reportLayer.getSource()!.addFeature(feature);
    }

    public resetAllHighlightReportItem() {
        if (!this.trvExtension) return;

        this.trvExtension.resetAllReportFeatureStyle();
    }

    public highlightReportItem(reportItem: ReportItem) {
        if (reportItem.feature!.get(HIDDEN) || !this.trvExtension) return;
        this.trvExtension.resetAllReportFeatureStyle();

        reportItem.feature!.setStyle(NvdbReportStyleHover);
    }

    public removeFeatureFromMap(feature: Feature) {
        try {
            const layers = this.trvNgMapService.trvMap!.map.getAllLayers();

            layers.forEach((layer: any) => {
                if (layer instanceof VectorLayer || layer instanceof VectorTileLayer) {
                    layer.getSource().removeFeature(feature);
                    /*const source = layer.getSource() as VectorSource<Geometry>;
                    if (source) source.removeFeature(feature);*/
                }
            });
        } catch (e) {
        }
    }

    public getItemById(itemId: number) {
        let item = this.reports?.find(a => a.reportItems.some(b => b.id === itemId))?.reportItems.find(a => a.id === itemId);
        if (!item) item = this.otherUsersReports?.find(a => a.reportItems.some(b => b.id === itemId))?.reportItems.find(a => a.id === itemId);
        return item;
    }

    public async moveFeature(feature: Feature<Geometry>) {
        try {
            const itemId = feature.get(REPORT_ITEM_ID);
            const item = this.getItemById(itemId)!;

            const itemSave = await this.getReportItemSaveFromId(item.id);
            itemSave.wkt = new WKT().writeFeature(feature);
            this.createOrUpdateReportItem(itemSave, item.report.id);
        } catch (e) {
            this.globalMessagesService.error("Ett fel uppstod när geometrin skulle flyttas.");
        }
    }

    resetItemForm(itemId: number) {
        const item = this.getItemById(itemId)!;
        const form = item.form;
        if (!form) throw Error("cannot reset form that is null");

        form.patchValue({
            deliveryType: item.deliveryType,
            description: item.description,
            report: item.report,
        });

        form.controls.dataslag.clear();
        form.controls.dataslag.reset();

        const newDataslag = item.dataslag.filter(a => !form?.value.dataslag?.some(b => b.gid == a.gid));
        for (const dataslag of newDataslag) {
            const dataslagCollection = this.allDataslag[this.nvdbNavigation.reportAsForm.value!.id][item.deliveryType.deliveryType]?.find(
                a => a.gid === dataslag.gid
            );
            if (dataslagCollection) this.addDataslag(form, dataslagCollection, dataslag);
        }
    }

    async sendReports(reportIds: number[]) {
        const reports = this.reports.filter(a => reportIds.includes(a.id));

        await firstValueFrom(
            this.reportApiClient.send({
                body: {
                    reportsToSend: reportIds,
                },
            })
        );

        for (const report of reports) {
            if (report.status == ReportStatusesEnum.Draft) report.status = ReportStatusesEnum.Sent;
            else if (report.status == ReportStatusesEnum.OpenForClarification) report.status = ReportStatusesEnum.ClarificationSent;
        }

        this.reports = [...this.reports];
        this.triggerReportsChanged();
    }

    getDataslagFromDataslagFormValues(form: FormGroup<ReportItemFormGroup>): Dataslag[] {
        return form.getRawValue().dataslag.map(a => ({
            ...a,
            fields: a.fields.map(b => ({
                ...b,
                value: getDataslagValue(b.value, b.valueType),
                valueId: b.value && typeof b.value == "object" && "id" in b.value ? b.value?.id : null,
            })),
        }));
    }

    public addDataslag(form: FormGroup<ReportItemFormGroup>, dataslag: DataslagCollection, defaultValues?: Dataslag) {
        let dataslagForm = new FormGroup<DataslagFormGroup>({
            fields: new FormArray<FormGroup<DataslagFieldFormGroup>>([]),
            required: new FormControl<boolean>(dataslag.required, {nonNullable: true}),
            gid: new FormControl<number>(dataslag.gid, {nonNullable: true}),

            metaKey: new FormControl<string>(dataslag.metaKey, {nonNullable: true}),
            name: new FormControl<string>(dataslag.name, {nonNullable: true}),
        });

        for (const field of dataslag.fields.sort((a,b) => {
            // Sort the required fields. First sort on if they are required, then their types and lastly their names
            if(a.required != b.required) return a.required < b.required ? 1 : -1;
            if(a.valueType != b.valueType) return a.valueType < b.valueType ? 1 : -1;
            else return a.name < b.name ? -1 : 1
        })) {
            let dataslagFieldForm = new FormGroup<DataslagFieldFormGroup>({
                value: new FormControl(undefined, {
                    validators: field.required ? [Validators.required] : [],
                    nonNullable: true,
                }),

                options: new FormControl(field.options, {nonNullable: true}),
                required: new FormControl(field.required, {nonNullable: true}),
                attributeName: new FormControl(field.name, {nonNullable: true}),
                name: new FormControl(field.name, {nonNullable: true}),
                valueType: new FormControl(field.valueType, {nonNullable: true}),
            });

            if (defaultValues) {
                const fieldDefaultValue = defaultValues.fields.find(a => a.name === field.name)!;
                //if (!fieldDefaultValue) console.log(defaultValues, field.name);

                if (field.valueType === DataslagValueTypesEnum.Enum) {
                    dataslagFieldForm.patchValue({
                        value: fieldDefaultValue?.value
                            ? ({
                                description: fieldDefaultValue.value,
                                id: fieldDefaultValue.valueId,
                            } as DataslagCollectionOption)
                            : undefined,
                    });
                } else {
                    dataslagFieldForm.patchValue({
                        value:
                            field.valueType === DataslagValueTypesEnum.Number && fieldDefaultValue?.value
                                ? parseFloat(fieldDefaultValue?.value as string)
                                : (fieldDefaultValue?.value as string),
                    });
                }
            }

            dataslagForm.controls.fields.push(dataslagFieldForm);
        }
        form.controls.dataslag.push(dataslagForm);
    }

    public async exportReportToGeopackage(report: Report) {
        try {
            const blob = await firstValueFrom(
                this.reportApiClient.exportGeopackage({
                    reportId: report.id,
                })
            );

            const currentDate: DateTime = DateTime.now();
            const dateIso = currentDate.toFormat("yyyyMMddHHmm");
            //const reportName = report.id + " " + report.name.toUpperCase().replace(" ", "_").trim() + "-" + dateIso;
            const reportName = "Report" + report.id.toString() + " - " + dateIso;

            const fileName = reportName + ".gpkg";
            const objectUrl: string = URL.createObjectURL(blob);
            const a: HTMLAnchorElement = document.createElement("a") as HTMLAnchorElement;

            a.href = objectUrl;
            a.download = fileName;
            a.click();
            URL.revokeObjectURL(objectUrl);

            this.globalMessagesService.success("Geopackage fil skapad.");
        } catch (e) {
            this.globalMessagesService.error("Ett fel uppstod när geopackage filen skulle skapas.");
        }
    }

    // VISIBILITY
    public setItemHidden(feature: Feature, hidden: boolean) {
        if (!feature) return;

        if (hidden) {
            feature.setStyle(NvdbReportStyleHidden);
        } else if (feature.get(FOCUSED) && this.nvdbNavigation.VIEWMODE && feature.getGeometry()?.getType() == GeometryType.POINT) {
            feature.setStyle(bluePinStyle2);
        } else if (feature.get(FOCUSED)) {
            feature.setStyle(NvdbReportStyleFocused);
        } else {
            feature.setStyle(NvdbReportStyle);
        }

        feature.set(HIDDEN, hidden);
    }

    public toggleItemVisible(e: any, itemId: number) {
        e.stopPropagation()

        const reportItem = this.getItemById(itemId)!;

        if (this.hiddenReportItems.includes(itemId)) {
            this.hiddenReportItems = this.hiddenReportItems.filter(a => a !== itemId);
            this.setItemHidden(reportItem.feature!, false);
        } else {
            this.hiddenReportItems = [...this.hiddenReportItems, itemId];
            this.setItemHidden(reportItem.feature!, true);
        }

        this.localStorageService.setHiddenReportItems(this.hiddenReportItems);
    }

    public toggleReportVisible(reportId: number) {
        const report = [...this.reports, ...this.otherUsersReports].find(a => a.id === reportId)!;
        const reportItemIds = report.reportItems.map(a => a.id);
        const allItemsHidden = !report.reportItems.some(a => !a.feature!.get(HIDDEN));

        if (allItemsHidden) {
            this.hiddenReportItems = this.hiddenReportItems.filter(a => !reportItemIds.includes(a));
            for (const reportItem of report.reportItems) {
                this.setItemHidden(reportItem.feature!, false);
            }
        } else {
            this.hiddenReportItems = [...this.hiddenReportItems, ...reportItemIds];
            for (const reportItem of report.reportItems) {
                this.setItemHidden(reportItem.feature!, true);
            }
        }

        this.localStorageService.setHiddenReportItems(this.hiddenReportItems);
    }

    // AJOURHALLNINGS ACTION
    async handleReportAction(reportId: number, action: AjourhallningAction, comment: string, email: string, sendEmail: boolean = true) {
        const report = [...this.reports, ...this.otherUsersReports].find(a => a.id === reportId);
        if (!report) throw Error(`unable to find the report with id ${reportId}`);

        try {
            const request = {
                body: {
                    reportId: reportId,
                    comment: comment,
                    sendEmail: sendEmail
                },
            };

            let response;
            switch (action) {
                case AjourhallningAction.klart_for_beredning:
                    response = await firstValueFrom(this.ajourApiClient.markReviewed(request));
                    this.globalMessagesService.success(`Ärende "${report.name}" är markerat som klart för beredning.`);
                    break;
                case AjourhallningAction.las_upp:
                    response = await firstValueFrom(this.ajourApiClient.openForClarification(request));
                    this.globalMessagesService.success(`Ärende "${report.name}" kräver komplettering.`);
                    break;
                case AjourhallningAction.avsluta_arende:
                    response = await firstValueFrom(this.ajourApiClient.close(request));
                    this.globalMessagesService.success(`Ärende "${report.name}" har stängts.`);
                    break;
                case AjourhallningAction.informera_kunden:
                    report!.events = await firstValueFrom(this.ajourApiClient.sendEmail({
                        reportId: reportId,
                        body: {
                            content: comment,
                            emailType: EmailTypesEnum.MessageForKund,
                            recievers: email.trim()
                        }
                    }))
                    this.globalMessagesService.success(`Email skickat.`);
                    break;
                case AjourhallningAction.informera_annan:
                    report!.events = await firstValueFrom(this.ajourApiClient.sendEmail({
                        reportId: reportId,
                        body: {
                            content: comment,
                            emailType: EmailTypesEnum.MessageForAnnan,
                            recievers: email.trim()
                        }
                    }))
                    this.globalMessagesService.success(`Email skickat.`);
                    break;
                case AjourhallningAction.informera_kommunen:
                    report!.events = await firstValueFrom(this.ajourApiClient.sendEmail({
                        reportId: reportId,
                        body: {
                            content: comment,
                            emailType: EmailTypesEnum.MessageForKomun,
                            recievers: email.trim()
                        }
                    }))
                    this.globalMessagesService.success(`Email skickat.`);
                    break;
            }

            if (response) {
                report!.status = response!.reportStatus;
                report!.events = response.reportEvents;
            }

            this.reports = [...this.reports];
            this.otherUsersReports = [...this.otherUsersReports];

            await this.refreshReports(report.status ==  ReportStatusesEnum.Closed);

            this.triggerReportsChanged();
        } catch (e) {
            switch (action) {
                case AjourhallningAction.klart_for_beredning:
                    this.globalMessagesService.error(
                        `Ett fel uppstod när ärendet med namn "${report.name}" skulle markeras som klart för beredning.`
                    );
                    break;
                case AjourhallningAction.las_upp:
                    this.globalMessagesService.error(
                        `Ett fel uppstod när ärendet med namn "${report.name}" skulle markeras som kräver komplettering.`
                    );
                    break;
                case AjourhallningAction.avsluta_arende:
                    this.globalMessagesService.error(`Ett fel uppstod när ärendet med namn "${report.name}" skulle stängas.`);
                    break;
            }
        }
    }

    // FILES
    getFileForUpload(itemFile: ReportItemFile): File | undefined {
        if (itemFile.databaseId || !itemFile.hasFile()) throw Error("This should never be called if it has id or doesnt have a file.");

        return itemFile.file;
    }

    async getFile(itemFile: ReportItemFile) {
        if (!itemFile.hasFile()) {
            itemFile.file = new File([await firstValueFrom(this.reportApiClient.downloadFile({fileId: itemFile.databaseId}))], itemFile.fileName, {
                lastModified: new Date().getTime(),
            });
        }
        return itemFile.file;
    }

    public async createOrUpdateReport(name: string, id: number | null = null) {
        const reportReadResponse = await firstValueFrom(
            this.reportApiClient.saveReport({
                body: {
                    id: id,
                    name: name,
                    author: "",
                    email: this.authenticationService.userName ?? "",
                    phoneNr: "",
                },
            })
        );
        this.localStorageService.setDefaultReportGroupId(reportReadResponse.id);
        this.AddOrUpdateReportFromReportRead(reportReadResponse);
    }

    // leave id = -1 to use the local create form
    /*
        public getReportItemFromForm(id: number = -1): ReportItem{

        }
    */

    // leave id = -1 to use the local create form
    public async getReportItemSaveFromId(id: number = -1): Promise<ReportItemSave> {
        let item = this.getItemById(id)!;
        const formValues = item.form!.getRawValue();

        let itemToSave: ReportItemSave = {
            id: id,
            dataslag: this.getDataslagFromDataslagFormValues(item.form!),
            deliveryType: formValues.deliveryType!.deliveryType,
            description: formValues.description,
            reportId: formValues.report!.id,
            validFrom: formValues.validFrom,
            seqNr: 1,
            wkt: item.wkt,
            files: [],
        };

        // upload all files on the item
        for (const file of formValues.files) {
            if (!file.databaseId) {
                file.databaseId = await firstValueFrom(
                    this.reportApiClient.uploadFile({
                        body: {
                            uploadedFile: this.getFileForUpload(file),
                        },
                    })
                );
            }

            itemToSave.files!.push({
                description: file.description,
                fileName: file.fileName,
                id: file.databaseId,
            });
        }

        itemToSave.wkt = item.wkt;
        if (!item.id || item.id < 1) {
            const feature = (this.itemToCreateOrEdit as FeatureToCreate).feature;
            if (!feature) throw Error();
            itemToSave.wkt = new WKT().writeFeature(feature);
        }

        // THIS IS YANKY AS HELL;
        // old dataslag that has been removed from the database have to be tracked manually
        let oldDataslag: Dataslag[] = [];
        for (const dataslag of item.dataslag) {
            const dataslagCollection = this.allDataslag[this.nvdbNavigation.reportAsForm.value!.id][itemToSave.deliveryType]?.find(
                a => a.gid === dataslag.gid
            );
            if (!dataslagCollection) oldDataslag.push(dataslag);
        }

        itemToSave.dataslag = [...(itemToSave.dataslag ?? []), ...oldDataslag];

        return itemToSave;
    }

    public AddOrUpdateReportFromReportRead(reportRead: ReportRead) {
        let report: Report = {
            name: reportRead.name,
            owner: reportRead.owner,
            created: DateTime.fromISO(reportRead.created),
            lastChanged: DateTime.fromISO(reportRead.updated),
            status: reportRead.reportStatus,
            events: reportRead.reportEvents,
            id: reportRead.id,
            reportItems: [],
            // TODO just send undefined or null from backend, not both
            uid: reportRead.uid!,
            author: reportRead.author!,
            email: reportRead.email!,
            phoneNumber: reportRead.phoneNr!,

            isUnlocked: reportRead.isUnlocked!,
        };

        for (const item of reportRead.reportItems) {
            this.AddOrUpdateItemFromItemRead(item, report);
        }

        // add the report or replace it if it already exists
        if (report.owner === this.authenticationService.trvUserName) {
            this.reports = [...this.reports.filter(a => a.id !== report.id), report].sort((a, b) => a.id - b.id);
        } else {
            this.otherUsersReports = [...this.otherUsersReports.filter(a => a.id !== report.id), report].sort((a, b) => a.id - b.id);
        }

        this.triggerReportsChanged();

        return report;
    }

    // previousReport only necessary if item changed its parent report
    public AddOrUpdateItemFromItemRead(itemRead: ReportItemRead, report: Report, previousReportId?: number, itemFeature?: Feature) {
        let item = {
            id: itemRead.id,
            dataslag: itemRead.dataslag,
            wkt: itemRead.wkt,
            report: {name: report.name, id: report.id},
            description: itemRead.description,
            files: itemRead.files.map(a => new ReportItemFile(a.fileName, a.description, a.id)),
            validFrom: itemRead.validFrom ? DateTime.fromISO(itemRead.validFrom) : null,

            feature: null as Feature | null | undefined,
            geomtryType: this.getGeometryTypeFromWkt(itemRead.wkt),
            deliveryType: this.allDeliveryTypes.find(a => a.deliveryType === itemRead.deliveryType)!,

            form: null as null | FormGroup<ReportItemFormGroup>,
        };

        item.feature = this.getItemById(itemRead.id)?.feature ?? itemFeature;

        if (previousReportId && previousReportId != report.id) {
            let previousReport = this.reports.find(a => a.id === previousReportId)!;
            previousReport.reportItems = previousReport.reportItems.filter(a => a.id !== itemRead.id);
        }

        const itemEditable = this.isReportEditable(report)
        if (itemEditable) {
            item.form = this.AddOrUpdateItemForm(item);
        }

        report.reportItems = [...report.reportItems.filter(a => a.id !== item.id), item].sort((a, b) => a.id - b.id);

        this.triggerReportsChanged();

        return item;
    }

    public isReportEditable(report: Report) {
        return (report.status === ReportStatusesEnum.Draft || report.status === ReportStatusesEnum.OpenForClarification) &&
            report.owner == this.authenticationService.trvUserName;
    }

    public AddOrUpdateItemForm(item: {
        id: number;
        deliveryType: DeliveryTypeRead | undefined;
        description: string;
        report: { id: number; name: string } | undefined;
        dataslag: Dataslag[];
        validFrom: DateTime | null;
        files: ReportItemFile[];
    }) {
        const currentItem = this.getItemById(item.id);
        let form = currentItem?.form;

        if (!form || item.id == -1) {
            form = new FormGroup<ReportItemFormGroup>({
                deliveryType: new FormControl(item.deliveryType, {
                    nonNullable: true,
                    validators: [Validators.required],
                }),
                report: new FormControl(item.report, {
                    nonNullable: true,
                    validators: [Validators.required],
                }),
                description: new FormControl(item.description, {
                    nonNullable: true,
                    validators: [Validators.required, Validators.maxLength(600)],
                }),
                dataslag: new FormArray<FormGroup<DataslagFormGroup>>([]),
                tempDataslag: new FormArray<FormGroup<DataslagFormGroup>>([]),
                files: new FormControl(
                    // simply clones the objects, you have to do this since they are class objects
                    // otherwise the methods doesnt get copied
                    item?.files.map(a => Object.assign(Object.create(Object.getPrototypeOf(a)), a)) ?? [],
                    {
                        nonNullable: true,
                    }
                ),
                validFrom: new FormControl(item?.validFrom?.toISO() ?? null),
            });
            form.valueChanges.subscribe(change => this.itemFormChanged(change, item?.id ?? -1));
        } else {
            if (!item.report) throw Error("must have a valid report when updating reportItemForm");
            form.patchValue({
                deliveryType: item.deliveryType,
                report: item.report,
                description: item.description,
                files: item.files.map(a => Object.assign(Object.create(Object.getPrototypeOf(a)), a)),
                validFrom: item.validFrom?.toISO(),
            });
        }

        form.controls.validFrom.clearValidators();
        if (item?.deliveryType && DELIVERY_TYPE_VALID_FROM_DESCRIPTION.hasOwnProperty(item.deliveryType.deliveryType))
            form.controls.validFrom.addValidators([Validators.required]);

        const newDataslag = item.dataslag.filter(a => !form?.value.dataslag?.some(b => b.gid == a.gid));
        for (const dataslag of newDataslag) {
            const dataslagCollection = this.allDataslag[this.nvdbNavigation.reportAsForm.value!.id][item?.deliveryType?.deliveryType ?? -1]?.find(
                a => a.gid === dataslag.gid
            );
            if (dataslagCollection) this.addDataslag(form, dataslagCollection, dataslag);
        }

        return form;
    }

    public async createOrUpdateReportItem(reportItemToSave: ReportItemSave, previousReportId?: number) {
        let response = await firstValueFrom(this.reportApiClient.saveReportItem({body: reportItemToSave}));

        await this.AddOrUpdateItemFromItemRead(response, this.reports.find(a => a.id == reportItemToSave.reportId)!, previousReportId!);

        this.reportItemsWithChanges = this.reportItemsWithChanges.filter(a => a != response.id);

        // have manually force the pipes to recalculate when changing item parent
        this.hiddenReportItems = [...this.hiddenReportItems];
    }

    public async updateMultipleReports(reports: Report[]) {
        const reportsToSave: ReportSave[] = [];
        const previousReports: { [itemId: number]: number } = {};

        this.saveFileDescriptions(reports.map(a => a.id))

        for (const report of reports) {
            const newName = this.pendingReportGroupNameChanges[report.id] ?? report.name;
            const reportToSave = {
                id: report.id,
                name: newName,
                author: "",
                email: this.authenticationService.userName ?? "",
                phone: "",
                reportItems: [],
            };
            reportsToSave.push(reportToSave);
        }

        for (const report of reports) {
            for (const item of report.reportItems) {
                const newReport = reportsToSave.find(a => a.id === item.form?.value.report?.id)!;
                newReport.reportItems!.push(await this.getReportItemSaveFromId(item.id));
                previousReports[item.id] = item.report.id;
            }
        }

        const reportReadResponse = await firstValueFrom(this.reportApiClient.saveBulk({body: {reports: reportsToSave}}));

        for (const report of reportReadResponse.reports) {
            const currentReport = this.reports.find(a => a.id === report.id)!;
            if (!currentReport) throw Error("report must exist");
            currentReport.name = report.name;

            for (const item of report.reportItems) {
                this.AddOrUpdateItemFromItemRead(item, currentReport, previousReports[item.id]);
            }
        }

        this.reportItemsWithChanges = [];
        this.pendingReportGroupNameChanges = []

        this.reports = [...this.reports];
        this.triggerReportsChanged();
    }

    // current values of all reportitems. Since we can filter reportgroups it can be hard to access them
    // when we want to save/send, so we keep updating them on each change
    pendingReportGroupNameChanges: { [id: number]: string } = {};
}

export class Report {
    owner?: string;

    lastChanged!: DateTime | null;
    created!: DateTime | null;
    status!: ReportStatusesEnum;
    events!: ReportEvent[];
    id!: number;
    uid?: string;
    name!: string;
    reportItems!: ReportItem[];

    author?: string;
    email?: string;
    phoneNumber?: string;

    isUnlocked!: boolean;
}

export class ReportItemFile {
    public fileName!: string;
    public description!: string;
    private _file?: File;

    // the id in the database. Only exists on files that have been created
    public databaseId?: number;
    // use this when keeping track of the items in the frontend
    public idOrTempId: number;

    constructor(fileName: string, description: string, id?: number, file?: File) {
        this.fileName = fileName;
        this.description = description;
        this.databaseId = id;
        this.idOrTempId = id ?? Math.floor(Math.random() * 1_000_000_000_000);
        this._file = file;
    }

    get file(): File {
        if (!this._file)
            throw Error("Filen måste finnas, använd reportServicens getFile() funktion för att hämta från databasen ifall filen inte finns.");

        return this._file;
    }

    set file(file: File) {
        this._file = file;
    }

    hasFile() {
        return this._file != null;
    }
}

export class ReportItem {
    id!: number;
    deliveryType!: DeliveryTypeRead;
    description!: string;

    report!: { id: number; name: string };

    wkt!: string;
    feature!: Feature | null | undefined;
    geomtryType!: GeometryType | null;

    validFrom!: DateTime | null;

    files!: ReportItemFile[];
    dataslag!: Dataslag[];

    // form for editing the item in the frontend.
    // Null if the item is never editable, like status is not draft/komplettering or its another users item
    form!: FormGroup<ReportItemFormGroup> | null;
}

export interface DataslagFieldFormGroup {
    value: FormControl<number | string | undefined | DataslagCollectionOption>;
    // NOT EDITABLE; ONLY HERE TO REFERENCE THEM
    options: FormControl<DataslagCollectionOption[]>;
    attributeName: FormControl<string>;
    required: FormControl<boolean>;
    name: FormControl<string>;
    valueType: FormControl<DataslagValueTypesEnum>;
}

export interface DataslagFormGroup {
    fields: FormArray<FormGroup<DataslagFieldFormGroup>>;

    // // NOT EDITABLE; ONLY HERE TO REFERENCE THEM
    // name: FormControl<string>;
    required: FormControl<boolean>;
    gid: FormControl<number>;
    metaKey: FormControl<string>;
    name: FormControl<string>;
}

export interface ReportItemFormGroup {
    deliveryType: FormControl<DeliveryTypeRead | undefined>;
    report: FormControl<
        | {
        id: number;
        name: string;
    }
        | undefined
    >;
    description: FormControl<string>;
    dataslag: FormArray<FormGroup<DataslagFormGroup>>;
    files: FormControl<ReportItemFile[]>;
    validFrom: FormControl<string | null>;

    // temporary store the dataslag if we change the role or deliverytype, so we can restore the
    // value when we change back.
    tempDataslag: FormArray<FormGroup<DataslagFormGroup>>;
}

// SavedDataslag contains all data, like if the field is required and what the displayname is. SavedDataslagData is just the values saved in the database
export interface FeatureToCreate {
    feature: Feature;

    // For example infoclick want to fill in details when creating an item
    defaultData?: {
        description?: string;
        deliveryType?: DeliveryTypeRead;
        dataslag?: Dataslag[];
    };
}

// TODO i dont know if this is to slow. It runs everytime a dataslag is changed.

function areAllDataslagEqual(o1: Dataslag[], o2: Dataslag[]) {
    if (o1.length !== o2.length) return false;

    for (const dataslag1 of o1) {
        const dataslag2 = o2.find(a => a.gid === dataslag1.gid);
        if (dataslag1.fields.length !== dataslag2?.fields?.length) return false;
        for (const field1 of dataslag1.fields) {
            const field2 = dataslag2.fields.find(a => a.name === field1.name);
            if (!field2) return false;
            // @ts-ignore when value comes from a form it can have the form {id: number, description:string}
            if (field1.value?.toString() !== field2.value?.toString() && field1.value !== field2.value?.description) return false;
        }
    }
    return true;
}

function getDataslagValue(value: undefined | string | number | DataslagCollectionOption, valueType: DataslagValueTypesEnum): string | undefined {
    let formattedValue = undefined;
    if (value && valueType === DataslagValueTypesEnum.Enum && typeof value == "object") formattedValue = value.description;
    else if (value && valueType === DataslagValueTypesEnum.Number && !isNaN(value as number)) formattedValue = value.toString();
    else if (value && DataslagValueTypesEnum.String) formattedValue = value.toString();

    return formattedValue;
}

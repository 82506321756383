<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Förhandsgranskning export</h4>
    <button type="button"
            class="btn-close"
            aria-describedby="modal-title"
            (click)="dismiss()">
    </button>
</div>
<div class="modal-body">
    <div style="text-align: center; height: 70vh">
        @if (!isPng) {
            <embed [src]="getSafeUrl(src)" type="application/pdf" width="100%" height="100%"/>
        } @else {
            <img [src]="getSafeUrl(src)" alt="PNG Image"/>
        }
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="close()">Stäng</button>
    <button type="button" class="btn btn-link" (click)="download.emit()">Ladda ned</button>
</div>

import { Component, Inject } from "@angular/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEdit, faMagnifyingGlassPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { TrvNgMapService } from "@trafikverket/trv-ng-map";
import { ReportItemWithFeature, ReportAvikelseService } from "src/app/service/report-avikelse.service";
import { TrvGlobalMessagesService, TrvModalService, TrvPopoverRef } from "trv-ng-common";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {NvdbNavigationService} from "@app/service/nvdb-navigation.service";

@Component({
    selector: 'app-report-avikelse-options-popover',
    templateUrl: './report-avikelse-options-popover.component.html',
    styleUrls: ['./report-avikelse-options-popover.component.scss'],
    standalone: true,
    imports: [FontAwesomeModule],
})
export class ReportAvikelseOptionsPopoverComponent {
    public reportAvikelseItem!: ReportItemWithFeature;

    public faMagnifyingGlassPlus: IconProp = faMagnifyingGlassPlus as IconProp;
    public faEdit: IconProp = faEdit as IconProp;
    public faTrashCan: IconProp = faTrashCan as IconProp;


    constructor(
        private nvdbNavigationService: NvdbNavigationService,
        private trvModalService: TrvModalService,
        private trvPopoverRef: TrvPopoverRef,
        private globalMessagesService: TrvGlobalMessagesService,
        @Inject(TrvNgMapService) private trvMapService: TrvNgMapService,
        private reportAvikelseService: ReportAvikelseService,
    ) {}

    ngOnInit(): void {}

    public zoomTo() {
        if (!this.reportAvikelseItem.feature) return;

        this.trvMapService.trvMap?.zoomInOnFeature(this.reportAvikelseItem.feature, 1000, this.nvdbNavigationService.getPaddingForZoomToExtentOnMainMap(100))
        this.trvPopoverRef.close("");
    }

    public edit() {
        this.reportAvikelseService.setReportAvikelseItemToCreateOrEdit(this.reportAvikelseItem);
        this.trvPopoverRef.close("");
    }

    public remove() {
        this.trvModalService
            .confirmDelete(`Bekräfta ta bort avvikelsen "${this.reportAvikelseItem.description}"`, "Är du säker att du vill ta bort avvikelsen?", "Ta bort", "Avbryt", {
                disposeOnBackdropClick: true,
            })
            .afterCloseWithType()
            .subscribe(async (event: any) => {
                if (event.closingEventType !== "close") return;

                await this.reportAvikelseService.removeAvikelseItemWithFeature(this.reportAvikelseItem);
                this.globalMessagesService.success(`Avvikelsen "${this.reportAvikelseItem.description}" togs bort.`)

                this.trvPopoverRef.close("");
            });
    }
}


import { Pipe, PipeTransform } from "@angular/core";
import { Feature } from "ol";
import { HIDDEN } from '@shared/constants'

@Pipe({
    name: "featureVisible",
    pure: false,
    standalone: true,
})
export class FeatureVisiblePipe implements PipeTransform {
    transform(feature: Feature): boolean {
        return !feature.get(HIDDEN);
    }
}

import { Component, OnInit, Input, Output, EventEmitter, Inject } from "@angular/core";
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from "@angular/forms";
import { TrvNgMapService, LayerListItem } from "@trafikverket/trv-ng-map";
import { environment } from "src/environments/environment";
import { TrvFormUtilityService, TrvUtilityService, TrvSelectOption, TrvSelectComponent } from "trv-ng-common";

@Component({
    selector: "app-background-layer-select",
    templateUrl: "./background-layer-select.component.html",
    styleUrls: ["./background-layer-select.component.scss"],
    standalone: true,
    imports: [ReactiveFormsModule, TrvSelectComponent],
})
export class BackgroundLayerSelectComponent implements OnInit {
    @Input() source!: LayerListItem[];
    @Output() changes: EventEmitter<void> = new EventEmitter<void>();

    public selectForm: FormGroup;
    constructor(
        private formUtilityService: TrvFormUtilityService,
        private trvUtilityService: TrvUtilityService,
        @Inject(TrvNgMapService) private trvMapService: TrvNgMapService
    ) {

        this.selectForm = new FormGroup<any>({
            singleObject: new FormControl(null, Validators.required),
        });

        this.selectForm.valueChanges.subscribe(x => {
            const layerItem = this.getLayerListItem();
            this.trvMapService.trvMap!.trvLayer.activateBackgroundLayer(layerItem!);
        });
    }

    ngOnInit(): void {
        //Set first value of source as default, used for userCreate
        const trvMap = this.trvMapService.trvMap!;

        this.source = this.source.filter(layer => {

            if(!layer.display)
                return false;

            if(trvMap.trvLayer.hasLayerData(layer, "onlyLocal")) {
                const onlyLocal = trvMap.trvLayer.getLayerData(layer, "onlyLocal") as boolean;
                if(!(environment.local == "true") && onlyLocal) return false;
            }
            
            return true;
        })
        
        
        const currentLayer = this.trvMapService.trvMap!.trvLayer.currentBackroundLayer;
        if (currentLayer) {
            this.changeValue(currentLayer.name);
        } else {
            this.changeValue(this.source[0].name);
        }
    }

    public changeValue(value: string): void {
        //We are looping through this to make sure we set a value that's present in this.source
        for (let index = 0; index < this.source.length; index++) {
            const ele = this.source[index];
            if (ele.name == value) {
                this.selectForm.controls["singleObject"].setValue(this.source[index], { onlySelf: true });
                break;
            }
        }
    }

    public displayObject = (object: { id: number; name: string }) => {
        return object.name;
    };

    public searchMethod = (searchInput: string, itemValue: TrvSelectOption, itemIndex: number, options: TrvSelectOption[]) => {
        const label = this.trvUtilityService.removeHtml(itemValue.label.toLowerCase());
        const matchIndex = label.search(this.trvUtilityService.escapeRegExp(searchInput.toLowerCase()));
        return matchIndex >= 0;
    };

    public getLayerListItem(): LayerListItem | null {
        const name = this.selectForm.controls["singleObject"].value.name;
        const items = this.source.filter(layerItem => layerItem.name === name);
        if (items.length > 0) return items[0];
        return null;
    }
}

import { Component, OnInit } from "@angular/core";
import { NvdbNavigationService } from "@app/service/nvdb-navigation.service";
import { environment } from "src/environments/environment";
import { TrvModalRef } from "trv-ng-common";

@Component({
    selector: 'app-application-info-modal',
    templateUrl: './application-info-modal.component.html',
    styleUrls: ['./application-info-modal.component.scss'],
    standalone: true,
})
export class ApplicationInfoModalComponent implements OnInit {

    public version = environment.buildVersion;
    public applicationName = "";
    public applicationDescription = "";
    public environment = environment;
    public handledning_path = "";
    public handledning_fileName = "";

    constructor(private modalRef: TrvModalRef<ApplicationInfoModalComponent>,
                public nvdbNavigationService: NvdbNavigationService) {}

    ngOnInit(): void {
        if(environment.application == "NvdbDataleverans") {
            this.applicationName = "NVDB Dataleverans"
            this.applicationDescription = "Beskrivning dataleverans"
        } else if (environment.application == "NvdbPåKarta") {
            this.applicationName = "NVDB på karta"
            this.applicationDescription = "Beskrivning på karta"
        } else if (environment.application == "NjdbPåWebb") {
            this.applicationName = "NJDB på webb"
            this.applicationDescription = "Beskrivning på NJDB"
        }
    }

    close(): void {
        this.modalRef.close();
    }

    dismiss(): void {
        this.modalRef.dismiss();
    }
}


import {environment} from "src/environments/environment";
import {DataslagCollection, ReportStatusesEnum} from "../_api/dataleverans/models";
import {SavedView} from "../service/local-storage.service";
import {DeliveryTypesEnum} from "@_api/pakarta/models/delivery-types-enum";
import {DateTime} from "luxon";

// just a random guid we use for the sweden view id
export const SWEDEN_VIEW_ID = "1672ffcc-e2d8-4f87-82a2-197afbe47kf1";
export const VAGNAT_OCH_NODER_LAYER_ID = "VagnatOchNoder";


function getDataLayerIds(): string[] {
    let res: string[] = [];

    if (environment.application == "NvdbDataleverans") {
        res = [VAGNAT_OCH_NODER_LAYER_ID]
    } else if (environment.application == "NvdbPåKarta") {
        if (environment.local == "true") {
            res = [VAGNAT_OCH_NODER_LAYER_ID]
        }
    } else if (environment.application == "NjdbPåWebb") {
        res = ["JarnvagsnatID", "TrafikplatserGDIID"]
    }
    return res;
}

export const SWEDEN_VIEW: SavedView = {
    id: SWEDEN_VIEW_ID,
    name: "Sverige",
    backgroundLayerId: "0",
    dataLayersId: getDataLayerIds(),
    saveBackground: true,
    saveDataLayers: true,

    extent: [181896.33, 6090353.78, 1086312.94, 7689478.31],
    zoom: 0.4,
    centerCoordinate: [609924.45, 6877630.37],
    lastModifiedDate: DateTime.fromISO('1990-01-01')
};

export const FORMATTED_REPORT_STATUS: { statusEnum: ReportStatusesEnum; value: string }[] = [
    {
        statusEnum: ReportStatusesEnum.Draft,
        value: "Utkast",
    },
    {
        statusEnum: ReportStatusesEnum.Sent,
        value: "Inskickat",
    },
    {
        statusEnum: ReportStatusesEnum.ClarificationSent,
        value: "Kompletterat",
    },
    {
        statusEnum: ReportStatusesEnum.Received,
        value: "Mottaget",
    },
    {
        statusEnum: ReportStatusesEnum.Reviewed,
        value: "Kontrollerat",
    },
    {
        statusEnum: ReportStatusesEnum.OpenForClarification,
        value: "Väntar på komplettering",
    },
    {
        statusEnum: ReportStatusesEnum.Processing,
        value: "Bearbetas",
    },
    {
        statusEnum: ReportStatusesEnum.Closed,
        value: "Stängt",
    },
];

// If a deliverytype is not present, it means validFrom does not exists and is null
export const DELIVERY_TYPE_VALID_FROM_DESCRIPTION: { [key: number]: string; } = {
    [DeliveryTypesEnum.NyttBilnat]: "Datum då vägen öppnas för trafik",
    [DeliveryTypesEnum.NyttCykelnat]: "Datum då vägen öppnas för trafik",
    [DeliveryTypesEnum.AvslutaEllerTaBortVagnat]: "Datum då vägen upphör att gälla",
    [DeliveryTypesEnum.NyEllerFörändradEllerRattadForeteelse]: "Datum då ändringen/rättningen börjar gälla",
    [DeliveryTypesEnum.AvslutaEllerTaBortForeteelse]: "Datum då företeelsen upphör att gälla",
    [DeliveryTypesEnum.AvikelseBilvagSaknas]: "",
    [DeliveryTypesEnum.AvikelseCykelEllerGangSaknas]: "",
    [DeliveryTypesEnum.AvikelseAnnat]: "",
};

// keys to get values from features
export const REPORT_ITEM_ID = "_reportItemId";
export const HIDDEN = "hidden";
export const FOCUSED = "focused";


export const externUserFormattedText = 'Extern användare'


export const felTyperInfoMessage = `
<b>
    Bilväg saknas eller ska bort
</b>
<p>
    Används när man vill rapportera att en bilväg saknas eller ska tas bort i NVDB.
</p>

<b>
    Cykel- eller gångväg saknas eller ska bort
</b>
<p>
    Används när man vill rapportera att en cykelväg eller gångväg saknas eller ska tas bort i NVDB.
</p>

<b>
    Annat
</b>
<p>
    Används då fel/brist upptäckts i kartan som t.ex. att det saknas hastighetsgräns på en väg eller att det är fel gatunamn på en väg.
</p>
`

export const leveransInfoMessage = `
<p>
<b>Nytt bilnät</b><br>
Används när en ny bilväg ska levereras. Används även då en befintlig vägs geometri ändras, till exempel en korsning byggs om till en cirkulationsplats. Då ska den nya geometrin levereras som ”Nytt bilnät” och den gamla geometrin ska levereras som ”Avsluta/Ta bort vägnät”.
</p>
<p><b>Nytt cykelnät</b><br>
Används när en ny cykelväg ska levereras. Används även då en befintlig vägs geometri ändras, se exempel ovan.
</p>
<p>
<b>Geometrirätta vägnät</b><br>
Används när en geometri är felaktigt inlagd i databasen. Exempelvis när en geometri är inlagd med fel position eller med dålig kvalitet.
</p>
<p>
<b>Avsluta/Ta bort vägnät</b><br>
Används när en väg inte är aktuell längre eller aldrig funnits på platsen. Ska även användas när exempelvis en korsning byggs om, se exempel ovan.
</p>
<p>
<b>Ny/Förändrad/Rättad företeelse</b><br>
Används när en eller flera företeelser tillkommer eller förändras på befintligt vägnät.
</p>
<p>
<b>Avsluta/Ta bort företeelse</b><br>
Används när en företeelse inte är aktuellt längre eller om en företeelse aldrig har funnits på platsen. Fyll i vilken företeelse det gäller i beskrivningsfältet.
</p>
<p>
<b>Leverans via underlag</b><br>
Används om leveransmallarna som finns på <a href="https://www.nvdb.se/sv/dokumentation/specifikationer-och-stodjande-dokument/" target="_blank">https://www.nvdb.se/sv/dokumentation/specifikationer-och-stodjande-dokument/</a> har använts eller om leveransen sker med komplett underlag utifrån gällande dataleveransbilagor. Underlag ska bifogas
</p>
<p>
<b>Rapportera fel/brist</b><br>
Används då fel/brist upptäckts i information som visas i kartan som normalt är en annan organisations ansvar att leverera, till exempel när en hastighetsgräns är fel i databasen. Upptäcks fel i Lantmäteriets topowebb bakgrundskartor ska det rapporteras via tjänsten <a href="https://forbattrakartan.lantmateriet.se/" target="_blank">https://forbattrakartan.lantmateriet.se/</a>.
</p>
`;

export const dateInfoMessage = `
<p>
Ange det <b>verkliga datumet</b> när vägnät eller företeelse börjar eller upphör att gälla.<br>
</p>
<p>
<b>Fiktivt datum</b> anges om man inte känner till det verkliga datumet eller om det verkliga datumet när vägnät eller företeelse börjar/upphör att gälla ligger långt tillbaka i tiden.
</p>
<p>
Fiktiva datum används halvårsvis (<b>åååå-01-01</b> eller <b>åååå-07-01</b>) och man anger det datum som ligger närmast bakåt i tiden vid leveranstillfället.
</p>
`;

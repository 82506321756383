import {ChangeDetectorRef, Component, Inject, OnInit} from "@angular/core";
import {svgMagnifyingGlass, svgMagnifyingPlus} from '@shared/utils/svg-helper-data';
import {FormBuilder, FormControl, FormGroup, Validators, ReactiveFormsModule} from "@angular/forms";
import {
    TrvFormUtilityService,
    TrvGlobalMessagesService,
    TrvModalService,
    TrvSelectOption,
    TrvUtilityService,
    TrvSelectComponent,
    TrvLoadingBundle, trvCollapseVoidAnimation, TrvLoadingDirective
} from "trv-ng-common";
import Point from "ol/geom/Point";
import {SearchRequest} from '@_api/dataleverans/models/search-request';
import {debounceTime, distinctUntilChanged, merge, mergeMap, of, Subject, switchMap} from "rxjs";
import {SvgIcon} from '@shared/utils/svg-helper';
import {
    availablePlatsSearchFilter,
    NvdbNavigationService,
    PlatsSearchOption, SearchItem,
    searchObjectList,
    SearchState
} from '@app/service/nvdb-navigation.service';
import {NetDbService} from '@app/service/net-db.service';
import {TrvMapExtensionService} from '@app/service/trv-map-extension.service';
import {Extent} from 'ol/extent'
import {Feature} from 'ol'
import {TrvNgMapService} from '@trafikverket/trv-ng-map'
import {SearchItemComponent} from "./search-item/search-item.component";
import {NgClass} from "@angular/common";
import {SvgIconComponent} from "../../utils/svg-icon/svg-icon.component";
import {environment} from "src/environments/environment";
import {FORMATTED_REPORT_STATUS} from "@shared/constants";

@Component({
    selector: "app-search-section",
    templateUrl: "./search-section.component.html",
    styleUrls: ["./search-section.component.scss"],
    animations: [trvCollapseVoidAnimation],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        TrvSelectComponent,
        SvgIconComponent,
        NgClass,
        TrvLoadingBundle,
        SearchItemComponent,
        TrvLoadingDirective
    ],
})
export class SearchSectionComponent {
/*
    public searchString = new FormControl<string>("")
    public searchObjectType = new FormControl(searchObjectList[0])
    public platsSearch = new FormControl<{ key: PlatsSearchOption; displayName: string }[]>([], {nonNullable: true});
*/

    displayValue(obj: { key: string, displayName: string }) {
        return obj.displayName
    };

    constructor(
        @Inject(TrvNgMapService) public trvMapService: TrvNgMapService,
        @Inject(TrvGlobalMessagesService)
        private globalMessagesService: TrvGlobalMessagesService,
        @Inject(NvdbNavigationService)
        public nvdbNavigation: NvdbNavigationService,
        @Inject(TrvMapExtensionService)
        public trvMapExtensionService: TrvMapExtensionService,
        private trvUtilityService: TrvUtilityService,
        @Inject(TrvModalService) private trvModalService: TrvModalService,
        @Inject(NetDbService) public netdbService: NetDbService
    ) {
        if (environment.application == 'NvdbDataleverans' || (environment.local == "true")) {
            if(!searchObjectList.some(a => a.id === "omledningsnamn") && environment.application != 'NvdbDataleverans'){
                searchObjectList.push({order: 5, id: "omledningsnamn", displayName: "Omledningsnamn"});
            }
            if(!searchObjectList.some(a => a.id === "feature_OID")){
                searchObjectList.push({order: 7, id: "feature_OID", displayName: "Feature OID"},);
            }
        }

        merge(this.nvdbNavigation.searchMenuState.platsSearch.valueChanges, this.nvdbNavigation.searchMenuState.searchString.valueChanges, this.nvdbNavigation.searchMenuState.searchObjectType.valueChanges)
            .pipe(
                debounceTime(250), // Adjust the time as needed (1000ms = 1 second)
                distinctUntilChanged(), // Only emit if the value has changed
                switchMap(() => {
                    const platsFilter = this.nvdbNavigation.searchMenuState.platsSearch.value;
                    const searchString = this.nvdbNavigation.searchMenuState.searchString.value?.trim();
                    const objectType = this.nvdbNavigation.searchMenuState.searchObjectType.value;

                    const searchObject: SearchRequest = {
                        driftbidrag_Statlig: true,
                        gatunamn: true,
                        kommun: true,
                        koordinat: true,
                        ort: true,
                        tatort: true,
                        vagnummer: true,
                        searchFor: objectType!.id,
                        searchString: searchString!,
                    }

                    const filterBasedOnPlats = objectType!.id == 'plats' && platsFilter.length > 0
                    if (filterBasedOnPlats) {
                        searchObject.kommun = platsFilter.some(a => a.key === PlatsSearchOption.KOMMUN)
                        searchObject.koordinat = platsFilter.some(a => a.key === PlatsSearchOption.KOORDINAT)
                        searchObject.ort = platsFilter.some(a => a.key === PlatsSearchOption.ORT)
                        searchObject.tatort = platsFilter.some(a => a.key === PlatsSearchOption.TATORT)
                        searchObject.vagnummer = platsFilter.some(a => a.key === PlatsSearchOption.VAGNUMMER)
                    }

                    if (searchString!.length == 0) {
                        this.nvdbNavigation.searchMenuState.searchState = SearchState.None;
                        return of(null);
                    } else if (searchString!.length == 1 && !searchObject.vagnummer) {
                        this.nvdbNavigation.searchMenuState.searchState = SearchState.ToShortString;
                        return of(null);
                    }

                    this.nvdbNavigation.searchMenuState.searchState = SearchState.Loading;

                    return this.netdbService.searchAll(searchObject);
                })
            )
            .subscribe(searchResult => {
                if(searchResult) this.generateSearchObjects(searchResult);
            });
    }

    public generateSearchObjects(_res: string) {
        this.nvdbNavigation.searchMenuState.currentSearchItems = [];
        let res = JSON.parse(_res);
        if (Array.isArray(res)) {
            //Begin with sorting array by elem.Type?
            res.sort((a, b) => a.Type - b.Type);

            res.forEach((elem: any) => {
                let searchFeature = this.trvMapService.trvMap?.trvLayer.getFeatureFromWkt(elem.WKT);
                let extent = searchFeature?.getGeometry()?.getExtent();
                if (extent) {
                    //If searchFeature is a point, extend the extent
                    if (searchFeature?.getGeometry() instanceof Point) {
                        let offset = [2000, 2000];
                        extent[0] = extent[0] - offset[0];
                        extent[1] = extent[1] - offset[1];
                        extent[2] = extent[2] + offset[0];
                        extent[3] = extent[3] + offset[1];
                    }

                    var subTitle: string = "";
                    var title = elem.Name;
                    if (elem.Type == 0) {
                        subTitle = "Tätort • " + elem.Kommun + " kommun • " + elem.Lan;
                    } else if (elem.Type == 1) {
                        subTitle = "Kommun • " + elem.Lan;
                    } else if (elem.Type == 2) {
                        subTitle = "Ort • " + elem.Kommun + " kommun • " + elem.Lan;
                    } else if (elem.Type == 3) {
                        subTitle = elem.Kommun + " kommun";
                    } else if (elem.Type == 4) {
                        title = "Driftbidrag statligt: " + elem.Name;
                        subTitle = elem.Kommun + " kommun";
                    } else if (elem.Type == 5) {
                        title = "Omledningsnamn: " + elem.Name;
                        subTitle = elem.Kommun + " kommun";
                    } else if (elem.Type == 6) {
                        title = elem.Name;
                        subTitle = elem.Kommun + " kommun, Identitet:" + elem.Code;
                    } else if (elem.Type == 7) {
                        title = elem.Name;
                        subTitle = "Sweref99tm";
                    } else if (elem.Type == 8) {
                        title = elem.Name;
                        subTitle = "Feature_OID: " + elem.Code;
                    } else if (elem.Type == 9) {
                        title = "Element id: " + elem.Name;
                        subTitle = elem.Kommun + " kommun";
                    } else if (elem.Type == 10) {
                        title = elem.Code + " " + elem.Name;
                        subTitle = elem.Lan;
                    }

                    let searchItem: SearchItem = {
                        title: title,
                        subTitle: subTitle,
                        extent: extent,
                        feature: searchFeature,
                        type: elem.Type,
                    };

                    this.nvdbNavigation.searchMenuState.currentSearchItems.push(searchItem);
                }
            });
        }
        if (this.nvdbNavigation.searchMenuState.currentSearchItems.length == 0) this.nvdbNavigation.searchMenuState.searchState = SearchState.NoResults;
        else this.nvdbNavigation.searchMenuState.searchState = SearchState.Success;
    }

    public searchIcon: SvgIcon = svgMagnifyingGlass;
    SearchState = SearchState
    protected readonly searchObjectList = searchObjectList;
    protected readonly availablePlatsSearchFilter = availablePlatsSearchFilter;
}


import {Component, Input} from '@angular/core';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {svgCross} from "@shared/utils/svg-helper-data";
import {SvgIconComponent} from "@components/utils/svg-icon/svg-icon.component";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {LocalStorageService} from "@app/service/local-storage.service";
import {TrvModalRef} from "trv-ng-common";
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'app-info-from-forvaltning-modal',
    standalone: true,
    imports: [
        SvgIconComponent,
        ReactiveFormsModule
    ],
    templateUrl: './info-from-forvaltning-modal.component.html',
    styleUrl: './info-from-forvaltning-modal.component.scss'
})
export class InfoFromForvaltningModalComponent {
    @Input() content = ""
    protected readonly crossIcon = svgCross;

    dontShowAgain = new FormControl<boolean>(false)

    constructor(localStorageService: LocalStorageService,
                private modalRef: TrvModalRef<InfoFromForvaltningModalComponent>
    ) {
        this.dontShowAgain.valueChanges.subscribe(dontShowAgain => {
            localStorageService.setdontShowMessage(dontShowAgain!)
            /*if (environment.application == "NvdbDataleverans") {
                localStorageService.setdontShowMessage(dontShowAgain!)
            }else{
                localStorageService.setdontShowMessage(dontShowAgain!, this.content)
            }*/
        })

    }

    close() {
        this.modalRef.close();
    }
}

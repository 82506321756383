<div class='main_container' [trv-loading]='submitLoading'>
    <div class='header'>
    <span class='title'>
      @if (geometryType == GeometryType.POINT) {
          <fa-icon class='geom-icon' [icon]='faMapMarkerAlt'></fa-icon>
      } @else if (geometryType == GeometryType.LINE_STRING || geometryType == GeometryType.MULTI_LINE_STRING) {
          <fa-icon
              class='geom-icon'
              [icon]='faRoute'
          ></fa-icon>
      } @else if (geometryType == GeometryType.POLYGON || geometryType == GeometryType.MULTI_POLYGON) {
          <fa-icon
              class='geom-icon'
              [icon]='faDrawPolygon'
          ></fa-icon>
      }
        @if (nvdbNavigation.VIEWMODE) {
            Visar förändring
        } @else if (currentStatus === ModalStatus.CREATING_ITEM && reportService.totalQueLengthFromBeginning) {
            Skapa förändring {{ reportService.totalQueLengthFromBeginning - reportService.queOfItemsToCreate.length }} av {{ reportService.totalQueLengthFromBeginning }}
        } @else if (currentStatus === ModalStatus.CREATING_ITEM) {
            Skapa förändring
        } @else if (currentStatus === ModalStatus.EDIT_DRAFT_ITEM) {
            Redigera förändring
        } @else if (currentStatus === ModalStatus.EDIT_COMPLEMENT_ITEM) {
            Komplettera förändring
        }
    </span>

        <button type='button' aria-label='Stäng' class='btn btn-link' (click)='close()'>
            <svg
                _ngcontent-app-c19=''
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='18'
                fill='currentColor'
                class='bi bi-x-lg'
                viewBox='0 0 16 16'
            >
                <path
                    _ngcontent-app-c19=''
                    d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z'
                    fill-rule=''
                    class='ng-star-inserted'
                ></path>
            </svg>
        </button>
    </div>
    <div class='top_container' [trv-loading]='!reportService.reportDataLoaded'>
        @if (nvdbNavigation.VIEWMODE) {
            <app-report-item
                style="height: 100%;"
                [isFromExternAnvandare]='isViewModeItemFromExternalUser'
                [useItemToCreateOrEdit]='true'
                [isEditable]="false"
            ></app-report-item>
        } @else if (showMustCreateReportBeforeItemWarning && reportService.reportDataLoaded) {
            <div
            >
                <b>Du har inga ärenden.</b>
                <br/>
                Du måste först skapa ett ärende innan du kan lägga till förändringar.
                <div>
                    <app-report-edit [showCloseButton]='false'></app-report-edit>
                </div>
            </div>
        } @else if (reportService.reportDataLoaded) {
            <app-report-item
                style="height: 100%;"
                [availableReports]='{reports: reportService.reports, statusesToInclude: [ReportStatusesEnum.Draft, ReportStatusesEnum.OpenForClarification]} | reportStatus'
                [useItemToCreateOrEdit]='true'
            ></app-report-item>
        }
    </div>

    @if (nvdbNavigation.VIEWMODE) {
        <div
            class='bottom_container'
        >
            <div></div>
            <button class='btn btn-outline-primary me-2' (click)='close()'>Stäng</button>
        </div>
    } @else if (!showMustCreateReportBeforeItemWarning) {
        <div
            class='bottom_container'
        >
            <div>
                * är obligatorisk
            </div>
            <div>
                <button class='btn btn-outline-primary me-2' (click)='close()'>Avbryt</button>
                <button
                    class='btn btn-primary'
                    (click)='submit(reportItemComponent)'
                    [disabled]='(currentStatus === ModalStatus.EDIT_DRAFT_ITEM || currentStatus === ModalStatus.EDIT_COMPLEMENT_ITEM) && !reportService.reportItemsWithChanges.includes(editItemId)'
                >
                    @if (currentStatus === ModalStatus.CREATING_ITEM) {
                        Skapa
                    } @else if (currentStatus === ModalStatus.EDIT_DRAFT_ITEM) {
                        Redigera
                    } @else if (currentStatus === ModalStatus.EDIT_COMPLEMENT_ITEM) {
                        Komplettera
                    }
                </button>
            </div>
        </div>
    }
</div>

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "allItemsInList",
    standalone: true,
})
export class AllItemsInListPipe implements PipeTransform {
    transform(args: { items: number[]; list: number[] }): boolean {
        for (const number of args.items) {
            if (!args.list.includes(number)) return false;
        }
        return true;
    }
}

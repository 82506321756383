import { Pipe, PipeTransform } from "@angular/core";
import { Report } from "@app/service/report.service";
import { ReportStatusesEnum } from "@_api/dataleverans/models/report-statuses-enum";
import GeometryType from "ol/geom/GeometryType";

@Pipe({
    name: "reportStatus",
    standalone: true,
})
export class ReportStatusPipe implements PipeTransform {
    transform(args: { reports: Report[] | null; statusesToInclude: ReportStatusesEnum[] }): Report[] {
        return (args.reports ?? []).filter(a => args.statusesToInclude.includes(a.status));
    }
}

import { Component } from "@angular/core";
import { OlOverlayComponent } from "@trafikverket/trv-ng-map";
import { TrvLoadingBundle } from "trv-ng-common";

// This is really dumb. Trv common doesnt include the loading component, so i have to create
// a component just to add the loading directive to it
@Component({
    selector: "app-loading-spinner",
    templateUrl: "./loading-spinner.component.html",
    styleUrls: ["./loading-spinner.component.scss"],
    standalone: true,
    imports: [TrvLoadingBundle],
})
export class LoadingSpinnerComponent extends OlOverlayComponent {}

/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DeliveryTypeResponse } from '../models/delivery-type-response';
import { getDeliveryTypes } from '../fn/types/get-delivery-types';
import { GetDeliveryTypes$Params } from '../fn/types/get-delivery-types';
import { getDeliveryTypes$Plain } from '../fn/types/get-delivery-types-plain';
import { GetDeliveryTypes$Plain$Params } from '../fn/types/get-delivery-types-plain';
import { getReportStatuses } from '../fn/types/get-report-statuses';
import { GetReportStatuses$Params } from '../fn/types/get-report-statuses';
import { getReportStatuses$Plain } from '../fn/types/get-report-statuses-plain';
import { GetReportStatuses$Plain$Params } from '../fn/types/get-report-statuses-plain';
import { ReportStatusResponse } from '../models/report-status-response';

@Injectable({ providedIn: 'root' })
export class TypesApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDeliveryTypesTypes()` */
  static readonly GetDeliveryTypesTypesPath = '/Types/DeliveryTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeliveryTypes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeliveryTypes$Plain$Response(params?: GetDeliveryTypes$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliveryTypeResponse>> {
    return getDeliveryTypes$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDeliveryTypes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeliveryTypes$Plain(params?: GetDeliveryTypes$Plain$Params, context?: HttpContext): Observable<DeliveryTypeResponse> {
    return this.getDeliveryTypes$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryTypeResponse>): DeliveryTypeResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeliveryTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeliveryTypes$Response(params?: GetDeliveryTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliveryTypeResponse>> {
    return getDeliveryTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDeliveryTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeliveryTypes(params?: GetDeliveryTypes$Params, context?: HttpContext): Observable<DeliveryTypeResponse> {
    return this.getDeliveryTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryTypeResponse>): DeliveryTypeResponse => r.body)
    );
  }

  /** Path part for operation `getReportStatusesTypes()` */
  static readonly GetReportStatusesTypesPath = '/Types/ReportStatuses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReportStatuses$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReportStatuses$Plain$Response(params?: GetReportStatuses$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportStatusResponse>> {
    return getReportStatuses$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReportStatuses$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReportStatuses$Plain(params?: GetReportStatuses$Plain$Params, context?: HttpContext): Observable<ReportStatusResponse> {
    return this.getReportStatuses$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportStatusResponse>): ReportStatusResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReportStatuses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReportStatuses$Response(params?: GetReportStatuses$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportStatusResponse>> {
    return getReportStatuses(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReportStatuses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReportStatuses(params?: GetReportStatuses$Params, context?: HttpContext): Observable<ReportStatusResponse> {
    return this.getReportStatuses$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportStatusResponse>): ReportStatusResponse => r.body)
    );
  }

}

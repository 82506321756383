import { LogLevel } from "angular-auth-oidc-client";
import { OpenIdConfiguration } from "angular-auth-oidc-client/lib/config/openid-configuration";
import { environment } from "../environments/environment";

export const authConfig: OpenIdConfiguration = {
    authority: environment.authority,
    redirectUrl: `${window.location.origin}/login`,
    postLogoutRedirectUri: `${window.location.origin}/login`,
    postLoginRoute: `${window.location.origin}`,
    unauthorizedRoute: `${window.location.origin}/login`,
    forbiddenRoute: `${window.location.origin}/login`,
    silentRenewUrl: `${window.location.origin}/silent-renew.html`,
    clientId: environment.clientId,
    customParamsAuthRequest: {
        resource: (environment.adfsResource.indexOf('http://') === 0 || environment.adfsResource.indexOf('https://') === 0)
        ? environment.adfsResource
        : `${window.location.origin}${environment.adfsResource}`
    },
    customParamsCodeRequest: {
        resource: (environment.adfsResource.indexOf('http://') === 0 || environment.adfsResource.indexOf('https://') === 0)
        ? environment.adfsResource
        : `${window.location.origin}${environment.adfsResource}`
    },
    customParamsRefreshTokenRequest:{
        resource: (environment.adfsResource.indexOf('http://') === 0 || environment.adfsResource.indexOf('https://') === 0)
        ? environment.adfsResource
        : `${window.location.origin}${environment.adfsResource}`
      },
    secureRoutes: [environment.baseApiUrl],
    autoUserInfo: false,
    scope: "openid offline_access",
    responseType: "code",
    silentRenew: true,
    allowUnsafeReuseRefreshToken: true,
    // enableIdTokenExpiredValidationInRenew was renamed in angular 15
    triggerRefreshWhenIdTokenExpired: true,
    useRefreshToken: true,
    logLevel: LogLevel.Error,
};

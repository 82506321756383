import {Component, ElementRef, Inject, OnInit, TemplateRef} from "@angular/core";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {
    TrvGlobalMessagesService,
    TrvLoadingDirective,
    TrvModalService,
    TrvPopoverDirective,
    TrvPopoverRef,
    TrvTableBundle,
    TrvTableColumn, TrvTooltipDirective, TrvTooltipPosition
} from "trv-ng-common";
import {Report, ReportService} from "../../service/report.service";
import {NvdbNavigationService} from "../../service/nvdb-navigation.service";
import {combineLatest, firstValueFrom, startWith} from "rxjs";
import {
    faChevronDown,
    faChevronUp,
    faCircleCheck,
    faCircleInfo,
    faFileCirclePlus,
    faHourglassHalf, faLocationPin,
    faLockOpen, faMap, faShareAlt,
    faSquareXmark,
} from "@fortawesome/free-solid-svg-icons";
import {svgChevronDown, svgChevronUp, svgInfo} from "../../shared/utils/svg-helper-data";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {DisplayReportComponent} from "../../components/reports/display-report/display-report.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {SvgIconComponent} from "@components/utils/svg-icon/svg-icon.component";
import {CommonModule, NgIf} from "@angular/common";
import {CdkTableModule} from "@angular/cdk/table";
import {AjourhallningAction} from "@shared/enums";
import {ReportItemComponent} from "../../components/reports/report-item/report-item.component";
import {
    ReportItemExpandableHeaderComponent
} from "../../components/reports/report-item-expandable-header/report-item-expandable-header.component";
import {AllItemsInListPipe} from "../../pipes/item-in-list.pipe";
import {
    AjourhallningActionModalComponent
} from "@app/components/modals/ajourhallning-action-modal/ajourhallning-action-modal.component";
import {ReportStatusesEnum} from "@app/_api/dataleverans/models";
import {ValueFromObjectListPipe} from "@pipes/value-from-object-list.pipe";
import {FORMATTED_REPORT_STATUS} from "@app/shared/constants";
import {
    AjourhallareHandlePopoverComponent
} from "@components/popover/ajourhallare-handle-popover/ajourhallare-handle-popover.component";
import {isStringValidInteger} from '@shared/utils/utils'
import {AuthenticationService} from "@app/service/authentication.service";
import {environment} from "src/environments/environment";
import {LayerInfoModalComponent} from "@components/map-menu/layer-section/layer-info-modal/layer-info-modal.component";
import {
    ShareReportPageModalComponent
} from "@pages/view-reports-page/share-report-page-modal/share-report-page-modal.component";

@Component({
    selector: "app-handle-single-report-page",
    templateUrl: "./handle-single-report-page.component.html",
    styleUrls: ["./handle-single-report-page.component.scss"],
    standalone: true,
    imports: [
        SvgIconComponent,
        RouterLink,
        DisplayReportComponent,
        FontAwesomeModule,
        TrvPopoverDirective,
        NgIf,
        CommonModule,
        TrvTableBundle,
        CdkTableModule,
        ReportItemComponent,
        ReportItemExpandableHeaderComponent,
        ValueFromObjectListPipe,
        AllItemsInListPipe,
        TrvLoadingDirective,
        TrvTooltipDirective,
        /*
                TrvLoadingDirective,
        */
    ],
})
export class HandleSingleReportPageComponent implements OnInit {
    loading = true;

    unformattedReportId!: string;
    reportId!: number;
    invalidReportId = false;
    backendErrorMessage: string = "Ett fel uppstod när ärendet skulle hämtas";

    report: Report | null = null;

    // ICONS
    protected readonly faChevronDown = faChevronDown;
    protected readonly faChevronUp = faChevronUp;
    protected readonly faCircleInfo = faCircleInfo;
    public environment = environment;

    // EVENT TABLE
    tableColumns: TrvTableColumn[] = [];
    visibleColumnKeys: string[] = [];

    // OTHER
    protected readonly ReportStatusesEnum = ReportStatusesEnum;
    protected readonly AjourhallareHandlePopoverComponent = AjourhallareHandlePopoverComponent;

    public toolTipPositions: TrvTooltipPosition[] = ["bottom", "right"];

    constructor(
        private route: ActivatedRoute,
        @Inject(NvdbNavigationService)
        public nvdbNavigation: NvdbNavigationService,
        private globalMessagesService: TrvGlobalMessagesService,
        @Inject(ReportService) public reportService: ReportService,
        public authenticationService: AuthenticationService,
        public router: Router,
        private trvModalService: TrvModalService,
    ) {
        this.nvdbNavigation.hideHamburgerMenu = true;
        this.nvdbNavigation.backLink = "/handle-reports";

        this.tableColumns = [
            {key: "date", label: "Datum", visible: true, disabled: false},
            {key: "eventType", label: "Händelse", visible: true, disabled: false},
            {key: "user", label: "Användare", visible: true, disabled: false},
            {key: "comment", label: "Kommentar", visible: true, disabled: false},
        ];

        this.visibleColumnKeys = this.tableColumns.map(a => a.key);
    }

    async ngOnInit() {
        this.unformattedReportId = this.route.snapshot.paramMap.get("reportId")!;
        const isReportIdValidNumber = isStringValidInteger(this.unformattedReportId);

        if (isReportIdValidNumber) {
            this.reportId = parseInt(this.unformattedReportId);
        } else {
            this.invalidReportId = true;
            this.loading = false;
            return;
        }

        if(!this.reportService.reportDataLoaded) await firstValueFrom(this.reportService.reportDataLoadedChanged)

        this.report = [...this.reportService.reports, ...this.reportService.otherUsersReports].find(a => a.id === this.reportId)!;


        this.reportService.reportsChanged.subscribe(async () => {
            this.loading = true;
            this.report = [...this.reportService.reports, ...this.reportService.otherUsersReports].find(a => a.id === this.reportId)!;
            if (!this.report) {
                var result = await this.reportService.getSingleReport(this.reportId)
                if (typeof result == "string") {
                    this.backendErrorMessage = result
                } else {
                    this.report = result
                }
            }
            this.loading = false;
        });

        if (this.report) this.loading = false;
    }

    onShowHandlePopover(ref: TrvPopoverRef<AjourhallareHandlePopoverComponent>) {
        ref.componentInstance.report = this.report!;
    }

    protected readonly infoIcon = svgInfo;

    showInViewMode() {
        this.router.navigateByUrl(`/map?viewmode=${this.reportId}`)
    }

    protected readonly faLocationPin = faLocationPin;
    protected readonly faMap = faMap;
    protected readonly faShareAlt = faShareAlt;

    shareReport() {
        const modal = this.trvModalService.open(ShareReportPageModalComponent, {
            disposeOnBackdropClick: true,
            size: this.nvdbNavigation.isMobileDevice ? "fullscreen" : "lg",
        });
        modal.componentInstance.report = this.report!
    }
}

<main>
    <div class='p-page'>
        @if (report) {
            <div class='container'>
                <div class='header_container'>
                    <h2>Ärende: {{ report.name }}</h2>
                </div>
                <app-display-report [report]='report'></app-display-report>
            </div>
        } @else if (loading) {
            <div class='no_report_container' [trv-loading]="true">
                <h2>
                    Hämtar ärende...
                </h2>
            </div>
        } @else {
            <div class='no_report_container'>
                <h2>
                    Kunde ej hitta något ärende med id: {{ this.reportGuid }}
                </h2>
            </div>
        }
    </div>
</main>

    <div [trv-loading]='loading' style='height:100%'>
        <div class='modal-header'>
            <h4 class='modal-title' id='modal-title'>Skicka in avvikelser</h4>
            <button type="button" class="btn-close" aria-describedby="modal-title" (click)="dismiss()">
            </button>
        </div>

        <div class='modal-body'>
            <div>
                Ange kontaktuppgifter nedan för att kunna bli kontaktad i ärendet. Du kan skicka in utan att fylla i
                kontaktuppgifter, men vi kan då inte kontakta dig för vidare information om ärendet.
                <br>
                <br>
                Om du redan fyllt i dina kontaktuppgifter så kommer vi ihåg dom åt dig.
                <br>
                Klicka <a href="javascript:;" (click)="clearContact()">här</a> för att rensa dina kontaktuppgifter.
            </div>
            <trv-form [validationErrors]="validationErrorMap" [fullWidth]="true">

                <div class="form-row">
                    <trv-form-field label="Ärendenamn*" [labelAbove]="true" [control]="reportName">
                        <input (input)="saveContactInfoToLocalStorage()" type="text" class="form-control"
                               placeholder="Ärendenamn" [formControl]="reportName">
                    </trv-form-field>
                </div>

                <div class="form-row">
                    <trv-form-field label="Ditt namn" [labelAbove]="true" [control]="name">
                        <input (input)="saveContactInfoToLocalStorage()" type="text" class="form-control"
                               placeholder="Namn"
                               [formControl]="name">
                    </trv-form-field>
                </div>

                <div class="form-row">
                    <trv-form-field label="Din mail" [labelAbove]="true" [control]="email">
                        <input (input)="saveContactInfoToLocalStorage()" type="email" class="form-control"
                               placeholder="Email" [formControl]="email">
                    </trv-form-field>
                </div>

                <div class="form-row">
                    <trv-form-field label="Ditt telefonnummer" [labelAbove]="true" [control]="phone">
                        <input (input)="saveContactInfoToLocalStorage()" type="text" class="form-control"
                               placeholder="Telefonnummer" [formControl]="phone">
                    </trv-form-field>
                </div>

            </trv-form>
            <div style="margin-top: 1rem;">
                <div class='form-check custom-checkbox offset'>
                    <input type='checkbox' class='form-check-input' id='allCheckbox'
                           [indeterminate]='selectedReportLopnummer.length > 0 && !allCheckboxChecked'
                           [checked]='allCheckboxChecked'
                           (click)='allRowsCheckboxClick($event)'/>
                    <label class='form-check-label' for='allCheckbox'>
                        Välj avvikelser att skicka in ({{ selectedReportLopnummer.length }})
                    </label>
                </div>

                @for (avikelse of reportAvikelseService.unsentReportAvikelseItem; let i = $index; track avikelse) {
                    <div class="send-in-avikelse-container">
                        <div class='form-check trv-prevent-table-row-click'
                             (click)='checkboxClicked(i , $event)'>
                            <input type='checkbox' class='form-check-input'
                                   [checked]='selectedReportLopnummer.includes(i)'/>
                            <label class='form-check-label'></label>
                        </div>

                        <div class="send-in-avikelse-item">{{ i + 1 }}</div>
                        <div class="send-in-avikelse-item">
                            @if (avikelse.feature!.getGeometry()!.getType() == GeometryType.POINT) {
                                <fa-icon class='geom-icon' [icon]='faMapMarkerAlt'></fa-icon>
                            }
                            @if (avikelse.feature!.getGeometry()!.getType() == GeometryType.LINE_STRING ||
                            avikelse.feature!.getGeometry()!.getType() == GeometryType.MULTI_LINE_STRING) {
                                <fa-icon class='geom-icon' [icon]='faRoute'></fa-icon>
                            }
                            @if (avikelse.feature!.getGeometry()!.getType() == GeometryType.POLYGON ||
                            avikelse.feature!.getGeometry()!.getType() == GeometryType.MULTI_POLYGON) {
                                <fa-icon class='geom-icon' [icon]='faDrawPolygon'></fa-icon>
                            }
                        </div>
                        <div class="send-in-avikelse-item">{{ avikelse.description }}</div>
                    </div>

                    <div>

                    </div>

                }
            </div>

        </div>

        <div class='modal-footer'>
            <div class='footer_container'>
                <div>
                    Hantering av personuppgifter kan du läsa om <a href="https://www.trafikverket.se/GDPR"
                                                                   target="_blank">här</a>
                </div>

                <div class='footer_right_container'>
                    <button type='button' class='btn btn-outline-primary' (click)='close()' trvTooltip='Avbryt'>Avbryt
                    </button>

                    <button type='button' class='btn btn-primary' (click)='send()' trvTooltip='Skicka in ärenden'>
                        Skicka in
                    </button>
                </div>
            </div>
        </div>
    </div>

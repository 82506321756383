import { Component, Inject } from '@angular/core'
import {
    TrvGlobalMessagesService,
    TrvModalService,
    TrvPopoverPosition,
    TrvPopoverRef,
    TrvPopoverDirective,
} from 'trv-ng-common'
import { Report, ReportService } from 'src/app/service/report.service'
import { createEmpty, extend } from 'ol/extent'
import { TrvNgMapService } from '@trafikverket/trv-ng-map'
import { ReportEditPopoverComponent } from '../report-edit-popover/report-edit-popover.component'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
    faMagnifyingGlassPlus,
    faEdit,
    faTrashCan,
    faFileExport,
    faExternalLink, faExternalLinkSquare
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { ReportStatusesEnum } from '@_api/dataleverans/models'
import {NvdbNavigationService} from "@app/service/nvdb-navigation.service";
import {Router} from "@angular/router";
import {AuthenticationService} from "@app/service/authentication.service";

@Component({
    selector: 'app-report-options-popover',
    templateUrl: './report-options-popover.component.html',
    styleUrls: ['./report-options-popover.component.scss'],
    standalone: true,
    imports: [FontAwesomeModule, TrvPopoverDirective],
})
export class ReportOptionsPopoverComponent {
    public reportGroupItem!: Report
    public popoverPosition: TrvPopoverPosition[] = ['right', 'top', "bottom-end", "bottom-start", "bottom"]
    public popoverComponent = ReportEditPopoverComponent

    public faMagnifyingGlassPlus: IconProp = faMagnifyingGlassPlus as IconProp
    public faEdit: IconProp = faEdit as IconProp
    public faFileExport: IconProp = faFileExport as IconProp
    public faTrashCan: IconProp = faTrashCan as IconProp

    constructor(
        @Inject(AuthenticationService)
        public authenticationService: AuthenticationService,
        private router: Router,
        public nvdbNavigationService: NvdbNavigationService,
        private trvPopoverRef: TrvPopoverRef,
        private reportService: ReportService,
        private trvModalService: TrvModalService,
        @Inject(TrvGlobalMessagesService)
        private globalMessagesService: TrvGlobalMessagesService,
        @Inject(TrvNgMapService) private trvMapService: TrvNgMapService,
    ) {
    }

    onShowComponent(ref: TrvPopoverRef<ReportEditPopoverComponent>): void {
        ref.componentInstance.reportGroupToEdit = this.reportGroupItem
    }

    onAfterCloseWithType(event: any): void {
    }

    public zoomIn() {
        let extent = createEmpty()
        if (this.reportGroupItem && this.reportGroupItem.reportItems.length > 0) {
            this.reportGroupItem.reportItems.forEach(reportItem => {
                if (reportItem.feature) extend(extent, reportItem.feature.getGeometry()?.getExtent()!)
            })
            this.trvMapService.trvMap?.zoomInOnExtent(extent, 1000, this.nvdbNavigationService.getPaddingForZoomToExtentOnMainMap(100))
        }
        this.trvPopoverRef.close('')
    }

    public export() {
        this.reportService.exportReportToGeopackage(this.reportGroupItem)
        this.trvPopoverRef.close('')
    }

    public remove() {
        this.trvModalService
            .confirmDelete(
                `Bekräfta ta bort ärendet "${this.reportGroupItem.name}"`,
                'Alla tillagda förändringar kommer att tas bort.',
                'Ta bort',
                'Avbryt',
                { disposeOnBackdropClick: true },
            )
            .afterCloseWithType()
            .subscribe((event: any) => {
                if (event.closingEventType === 'close') {
                    try {
                        this.reportService.removeReportsById([this.reportGroupItem.id])
                        this.trvPopoverRef.close('')
                    }catch (e) {
                        this.globalMessagesService.error(`Ett fel uppstod när ärendet med namn "${this.reportGroupItem.name}" skulle tas bort.`)
                    }
                }
            })
    }

    protected readonly ReportStatusesEnum = ReportStatusesEnum

    goToViewModeReport() {
            const viewModeReportId = this.reportGroupItem.id

            const reportOwnedByCurrentUser = this.reportService.reports.find(a => a.id === viewModeReportId)

            if (reportOwnedByCurrentUser) {
                this.router.navigateByUrl(`/reports/${viewModeReportId}`)
            } else if(this.authenticationService.isMinAjourhallare){
                this.router.navigateByUrl(`/handle-reports/${viewModeReportId}`)
            }else{
                this.router.navigateByUrl(`/view-reports/${this.reportGroupItem.uid}`)
            }
        }

    protected readonly faExternalLinkSquare = faExternalLinkSquare;
}

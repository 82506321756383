import {AfterViewInit, ChangeDetectorRef, Component, Inject, Input} from "@angular/core";
import {
    TrvDatePickerComponent,
    TrvFormUtilityService,
    TrvGlobalMessagesService,
    TrvModalService,
    TrvPopoverDirective,
    TrvPopoverPosition,
    TrvPopoverRef,
    TrvSelectComponent,
    TrvSelectOption,
    TrvTooltipDirective,
    TrvTooltipPosition,
    TrvUtilityService,
} from "trv-ng-common";
import {FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {
    ActorTypesEnum,
    DataslagCollection,
    DataslagValueTypesEnum,
    DeliveryTypeRead,
    DeliveryTypesEnum,
    ReportItemSave,
    ReportStatusesEnum,
} from "src/app/_api/dataleverans/models";
import {
    DataslagFieldFormGroup,
    DataslagFormGroup,
    FeatureToCreate,
    Report,
    ReportItem,
    ReportItemFile,
    ReportItemFormGroup,
    ReportService,
} from "src/app/service/report.service";
import {NvdbNavigationService} from "@app/service/nvdb-navigation.service";
import {NetDbService} from "@app/service/net-db.service";
import {LocalStorageService} from "@app/service/local-storage.service";
import {focusOnFirstInvalidControl} from "@shared/utils/utils";
import {SvgIcon} from "@shared/utils/svg-helper";
import {svgCheck, svgChevronDown, svgChevronUp, svgCircleInfo, svgCross, svgTrash} from "@shared/utils/svg-helper-data";
import {SvgIconComponent} from "../../utils/svg-icon/svg-icon.component";
import {AsyncPipe, CommonModule, NgClass, NgTemplateOutlet} from "@angular/common";
import {groupExpand, showErrorMessage} from "@shared/animations";
import {faEllipsisV, faFileLines} from "@fortawesome/free-solid-svg-icons";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {FileOptionsPopoverComponent} from "@components/reports/file-options-popover/file-options-popover.component";
import {AllItemsInListPipe} from "@pipes/item-in-list.pipe";
import {DELIVERY_TYPE_VALID_FROM_DESCRIPTION, dateInfoMessage, felTyperInfoMessage, leveransInfoMessage} from "@shared/constants";
import {DateTime} from "luxon";
import {FileDragNDropDirective} from "@app/directives/file-drag-ndrop.directive";
import WKT from "ol/format/WKT";
import {FOCUSED} from "@app/shared/constants";
import {A11yModule} from "@angular/cdk/a11y";
import {AutofocusDirective} from "@app/directives/autofocus.directive";
import {Feature} from "ol";
import {firstValueFrom} from "rxjs";
import {ValueFromObjectListPipe} from "@pipes/value-from-object-list.pipe";
import {MapIncludesKeyPipe} from "@pipes/map-includes-key.pipe";
import {TrvModalConfig} from "trv-ng-common/lib/components/modal/modal-config";

@Component({
    selector: "app-report-item",
    templateUrl: "./report-item.component.html",
    styleUrls: ["./report-item.component.scss"],
    //changeDetection: ChangeDetectionStrategy.OnPush,
    // TODO move this to utils folder...
    animations: [groupExpand, showErrorMessage],
    standalone: true,
    imports: [
        CommonModule,
        SvgIconComponent,
        ReactiveFormsModule,
        TrvSelectComponent,
        NgClass,
        TrvTooltipDirective,
        AsyncPipe,
        FaIconComponent,
        TrvPopoverDirective,
        AllItemsInListPipe,
        NgTemplateOutlet,
        TrvDatePickerComponent,
        FileDragNDropDirective,
        A11yModule,
        AutofocusDirective,
        ValueFromObjectListPipe,
        MapIncludesKeyPipe,
    ],
})
export class ReportItemComponent implements AfterViewInit {
    // listens to itemToCreateOrEdit in reportService and updates when that updates. Used for editing/creating single item
    @Input() useItemToCreateOrEdit: boolean = false;
    reportItemId!: number;

    @Input() isEditable = true;
    @Input() styleAsTableElseSingle = true;

    // the reports you can choose as a parent
    @Input() availableReports!: Report[];

    public form: FormGroup<ReportItemFormGroup> | null = null;

    public availableDataslag: DataslagCollection[] = [];
    public openDataslag: number[] = [];

    public dataslagToAdd = new FormControl<DataslagCollection | null>(null);

    @Input() isFromExternAnvandare = false;
    @Input() reportItem: ReportItem | null = null;
    descriptionInputRowCount = 3;

    availableDeliveryTypes: DeliveryTypeRead[] = [];

    public toolTipPositions: TrvTooltipPosition[] = ["bottom", "left", "right", "top"];
    public popoverPosition: TrvPopoverPosition[] = ["bottom", "bottom-end", "bottom-start", "right"];

    editableFileDescriptions: number[] = [];

    //#region Icons
    public iconCross: SvgIcon = svgCross;
    public downIcon: SvgIcon = svgChevronDown;
    public upIcon: SvgIcon = svgChevronUp;
    public trashIcon: SvgIcon = svgTrash;

    protected readonly faEllipsisV = faEllipsisV;
    protected readonly faFileLines = faFileLines;

    protected readonly svgCheck = svgCheck;
    protected readonly svgCross = svgCross;
    //#endregion

    //#region Enums
    protected readonly DataslagValueTypesEnum = DataslagValueTypesEnum;
    protected readonly ReportStatusesEnum = ReportStatusesEnum;

    //#endregion

    //#region Other
    protected readonly FileOptionsPopoverComponent = FileOptionsPopoverComponent;

    //#endregion

    constructor(
        @Inject(TrvModalService) private trvModalService: TrvModalService,
        @Inject(NvdbNavigationService)
        public nvdbNavigation: NvdbNavigationService,
        @Inject(ReportService) public reportService: ReportService,
        @Inject(NetDbService) public netDbService: NetDbService,
        @Inject(TrvFormUtilityService)
        private formUtilityService: TrvFormUtilityService,
        @Inject(TrvUtilityService) private trvUtilityService: TrvUtilityService,
        @Inject(ChangeDetectorRef) public cdref: ChangeDetectorRef,
        @Inject(TrvGlobalMessagesService) private globalMessagesService: TrvGlobalMessagesService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService
    ) {
    }

    ngAfterViewInit(): void {
        if (this.useItemToCreateOrEdit) {
            this.reportItemId = this.reportService.itemTypeofEditing(this.reportService.itemToCreateOrEdit)
                ? this.reportService.itemToCreateOrEdit
                : -1;

            this.reportService.itemToCreateOrEditChanged.subscribe(() => {
                const item = this.reportService.itemToCreateOrEdit;
                if (!item) return;
                this.reportItemId = this.reportService.itemTypeofEditing(item) ? item : -1;
                this.setFormValues();
            });
        } else {
            this.reportItemId = this.reportItem!.id;
        }

        if (this.reportService.reportDataLoaded) {
            this.setFormValues();
        }
        this.reportService.reportDataLoadedChanged.subscribe(loaded => {
            if (loaded) this.setFormValues();
        });

        this.setAvailableDeliveryTypes()
        this.nvdbNavigation.reportAsForm.valueChanges.subscribe(() => this.setAvailableDeliveryTypes())
    }

    setAvailableDeliveryTypes() {
        let deliveryTypes = this.reportService.deliveryTypes

        // skogsnäring ska inte ha tillgång till nytt cykelnät
        const reportAs = this.nvdbNavigation.reportAsForm.value.id
        if (reportAs == ActorTypesEnum.Skogsnaring || reportAs == ActorTypesEnum.Lantmateriet) {
            deliveryTypes = deliveryTypes.filter(a => a.deliveryType !== DeliveryTypesEnum.NyttCykelnat)
        } else if (reportAs == ActorTypesEnum.Lanstyrelsen) {
            deliveryTypes = deliveryTypes.filter(a => a.deliveryType === DeliveryTypesEnum.NyEllerFörändradEllerRattadForeteelse || a.deliveryType === DeliveryTypesEnum.AvslutaEllerTaBortForeteelse || a.deliveryType === DeliveryTypesEnum.LeveransViaUnderlag || a.deliveryType === DeliveryTypesEnum.RapporteraFelEllerBrist)
        }


        this.availableDeliveryTypes = deliveryTypes

        if (this.form?.value.deliveryType?.deliveryType == DeliveryTypesEnum.NyttCykelnat && !this.availableDeliveryTypes.some(a => a.deliveryType == DeliveryTypesEnum.NyttCykelnat)) {
            this.form.patchValue({
                deliveryType: undefined
            })
        }
    }

    async setFormValues() {
        if (!this.reportItemId) throw new Error("has to have a reportItemId at this stage");
        this.reportItem = this.reportService.getItemById(this.reportItemId)!

        const item = this.reportService.itemToCreateOrEdit;
        // CREATE NEW REPORT ITEM
        if (this.reportService.itemTypeofCreating(item)) {
            let defaultValues = item.defaultData;

            const availableReports = this.reportService.reports.filter(
                a => a.status === ReportStatusesEnum.Draft || a.status === ReportStatusesEnum.OpenForClarification
            );

            let createFormFromItem = {
                id: -1,
                deliveryType: defaultValues?.deliveryType ?? undefined,
                description: defaultValues?.description ?? "",
                report:
                    availableReports.find(a => a.id === this.localStorageService.getDefaultReportId()) ||
                    (availableReports.length > 0 && availableReports[0]) ||
                    undefined,
                dataslag: defaultValues?.dataslag ?? [],
                validFrom: null,
                files: [],
            };

            this.form = await this.reportService.AddOrUpdateItemForm(createFormFromItem);
        } else {
            // UPDATE EXISTING REPORT ITEM
            this.form = this.reportService.getItemById(this.reportItemId)?.form!;
        }

        if (!this.form) return;

        this.availableDataslag =
            this.reportService.allDataslag[this.nvdbNavigation.reportAsForm.value!.id][this.form.value.deliveryType?.deliveryType ?? -1] ?? [];

        // update description height depending on input size
        if (this.form!.controls.description.value) {
            // this is quite yank. Its annoying to check how many rows a certain amout of characters actually take up,
            // so i just assume 1 row for each 30 characters
            let descriptionRowCount = Math.max(this.form!.controls.description.value.split("\n").length, this.form!.controls.description.value.length / 30);
            const minRowCountToShow = 3;
            const maxRowCountToShow = 8;

            if (descriptionRowCount < minRowCountToShow) descriptionRowCount = minRowCountToShow;
            else if (descriptionRowCount > maxRowCountToShow) descriptionRowCount = maxRowCountToShow;

            this.descriptionInputRowCount = descriptionRowCount;
        }

        this.cdref.markForCheck();

        this.nvdbNavigation.reportAsForm.valueChanges.subscribe(change => this.updateDataslagOnRoleOrDeliveryChange(change.id, null));
        this.form.controls.deliveryType.valueChanges.subscribe(change => {
            if (change!.deliveryType != this.form?.value.deliveryType?.deliveryType)
                this.updateDataslagOnRoleOrDeliveryChange(null, change!.deliveryType);

            if (change!.deliveryType && DELIVERY_TYPE_VALID_FROM_DESCRIPTION.hasOwnProperty(change!.deliveryType)) {
                this.form!.controls.validFrom.addValidators([Validators.required]);
            } else {
                this.form!.controls.validFrom.clearValidators();
            }
            this.form!.controls.validFrom.updateValueAndValidity();
        });
        this.cdref.markForCheck();
    }

    public updateDataslagOnRoleOrDeliveryChange(reportAsId: number | null = null, deliveryType: DeliveryTypesEnum | null = null) {
        const latestReportAsId = reportAsId || this.nvdbNavigation.reportAsForm.value.id || -1;
        const latestDeliveryType = deliveryType || this.form!.value.deliveryType?.deliveryType || -1;

        this.availableDataslag = this.reportService.allDataslag[latestReportAsId][latestDeliveryType] ?? [];

        const formDataslag = this.form!.controls!.dataslag!;
        const formTempDataslag = this.form!.controls!.tempDataslag!;

        // Save dataslag to temp
        for (const dataslag of formDataslag.controls) {
            const dataslagHasValues = dataslag!.value!.fields!.some(a => a.value != null && a.value != "");
            if (dataslagHasValues) {
                const tempForm = formTempDataslag.controls.find(a => a.getRawValue().gid === dataslag.value.gid);
                if (tempForm) {
                    tempForm.patchValue({fields: dataslag.value.fields});
                } else {
                    this.form?.controls.tempDataslag.push(dataslag);
                }
            }
        }

        // Add dataslag from temp
        formDataslag.clear();
        for (const dataslag of formTempDataslag!.controls) {
            const correspondingAvailableDataslag = this.availableDataslag.find(a => a.gid === dataslag!.value!.gid);

            if (correspondingAvailableDataslag) {
                dataslag.patchValue({required: correspondingAvailableDataslag.required});
                formDataslag.push(dataslag);
            }
        }

        // Add required dataslag
        for (const dataslag of this.availableDataslag) {
            if (dataslag.required && !formDataslag.value.some(a => a.gid === dataslag.gid)) {
                this.reportService.addDataslag(this.form!, dataslag);
            }
        }
    }

    public toggleDataslagOpen(gid: number) {
        if (this.openDataslag.includes(gid)) {
            this.openDataslag = this.openDataslag.filter(dataslag => dataslag !== gid);
        } else {
            this.openDataslag.push(gid);
        }
    }

    onAddDataslagClick(dataslagToAdd: DataslagCollection) {
        this.reportService.addDataslag(this.form!, dataslagToAdd);
        this.dataslagToAdd.setValue(null);
        this.dataslag.updateValueAndValidity();
    }

    public searchMethodDeliveryType = (searchInput: string, itemValue: TrvSelectOption) => {
        if (!itemValue.label) return false;
        const label = this.trvUtilityService.removeHtml(itemValue.label.toLowerCase());
        const matchIndex = label.search(this.trvUtilityService.escapeRegExp(searchInput.toLowerCase()));
        return matchIndex >= 0;
    };

    removeDataslag(gid: number) {
        const index = this.dataslag.controls.findIndex(dataslag => dataslag.getRawValue().gid === gid);
        if (index !== -1) {
            (this.form!.controls.dataslag as FormArray).removeAt(index);
        }

        const indexTempDataslag = this.form!.controls.tempDataslag.controls.findIndex(a => a.getRawValue().gid === gid);
        if (indexTempDataslag !== -1) {
            (this.form!.controls.tempDataslag as FormArray).removeAt(indexTempDataslag);
        }
    }

    onReportGroupChange(reportGroup: { id: number; name: string }) {
        this.localStorageService.setDefaultReportGroupId(reportGroup.id.toString());
    }

    async save(): Promise<boolean> {
        console.log("saving...");
        if (!this.form || !this.form!.valid) {
            this.formUtilityService.isValid(this.form!);
            this.form!.updateValueAndValidity();
            this.form!.markAllAsTouched();
            this.form!.markAsDirty();
            this.cdref.detectChanges();

            focusOnFirstInvalidControl();

            const invalidControl = document.querySelectorAll("trv-select.ng-invalid, textarea.ng-invalid");
            const dataslagWithErrors: number[] = [];
            Array.from(invalidControl).forEach((element: Element) => {
                if (Array.from(element.parentElement!.classList)!.includes("dataslag_field_input")) {
                    const dataslagId = parseInt(element.parentElement!.id);
                    if (dataslagId) dataslagWithErrors.push(dataslagId);
                }
            });
            this.openDataslag = [...this.openDataslag, ...dataslagWithErrors];
            this.cdref.detectChanges();

            this.globalMessagesService.error(`Var vänlig och korrigera felen, kan ej ${this.reportItemId === -1 ? "skapa" : "uppdatera"} ärendet`);
            return false;
        }

        let itemToSave: ReportItemSave;
        let feature: Feature;
        if (this.reportItemId == -1) {
            feature = (this.reportService.itemToCreateOrEdit as FeatureToCreate).feature;
            itemToSave = {
                id: null,
                dataslag: this.reportService.getDataslagFromDataslagFormValues(this.form),
                deliveryType: this.form.value.deliveryType!.deliveryType,
                description: this.form.value.description!,
                reportId: this.form.value.report!.id,
                validFrom: this.form.value.validFrom,
                seqNr: 1,
                wkt: new WKT().writeFeature(feature),
                files: [],
            };

            // upload all files on the item
            for (const file of this.form.value.files ?? []) {
                if (!file.databaseId) {
                    file.databaseId = await firstValueFrom(
                        this.reportService.reportApiClient.uploadFile({
                            body: {
                                uploadedFile: this.reportService.getFileForUpload(file),
                            },
                        })
                    );
                }

                itemToSave.files!.push({
                    description: file.description,
                    fileName: file.fileName,
                    id: file.databaseId,
                });
            }
        } else {
            itemToSave = await this.reportService.getReportItemSaveFromId(this.reportItemId);
            feature = this.reportService.getItemById(this.reportItemId)!.feature!;
        }

        const previousReport = this.reportService.getItemById(this.reportItemId)?.report?.id;
        try {
            await this.reportService.createOrUpdateReportItem(itemToSave, previousReport);

            if (!itemToSave.id || itemToSave.id < 1) {
                this.globalMessagesService.success(`Förändringen "${itemToSave.description}" skapades.`);
            } else {
                this.globalMessagesService.success(`Förändringen uppdaterades.`);
            }

            if (feature) {
                feature.set(FOCUSED, false);
            }
            return true;
        } catch (e) {
            this.globalMessagesService.error(`Ett fel uppstod när förändringen skulle ${this.reportItemId === -1 ? "skapas" : "uppdateras"}.`);
            return false;
        }
    }

    // UTILS
    get dataslag() {
        return this.form!.get("dataslag") as FormArray<FormGroup<DataslagFormGroup>>;
    }

    getDataslagFields(dataslag: FormGroup<DataslagFormGroup>) {
        return (dataslag as FormGroup).get("fields") as FormArray<FormGroup<DataslagFieldFormGroup>>;
    }

    get description() {
        return this.form!.get("description") as FormControl<string>;
    }

    get deliveryType() {
        return this.form!.get("deliveryType") as FormControl<DeliveryTypeRead | undefined>;
    }

    get report() {
        return this.form!.get("report") as FormControl<
            | {
            id: number;
            name: string;
        }
            | undefined
        >;
    }

    public displayObjectName(object: any) {
        return object.name || object.description || object.reportName;
    }

    isDataslagOpen(gid: number) {
        return this.openDataslag.includes(gid);
    }

    getRemainingOptionalDataslag() {
        const addedDataslagIds = this.dataslag.controls.map(dataslag => dataslag.value.gid);
        return this.availableDataslag.filter(dataslag => !addedDataslagIds.includes(dataslag.gid) && !dataslag.required)
    }

    formatDisplayValue(value?: string | number) {
        if (value == "" || !value) value = "-";

        return value;
    }

    onFileSelected(files: File[]) {
        const itemForm = this.form!;
        const currentFiles = itemForm.value.files;

        for (const file of files) {
            let fileName = file.name;
            while (currentFiles!.some(a => a.fileName === fileName)) {
                let currentCounter = parseInt(fileName.replace(/.*\_\((\d+)\)\..*/, "$1"));
                if (isNaN(currentCounter) || !isFinite(currentCounter)) {
                    currentCounter = 0;
                }

                const extensionIndex = fileName.lastIndexOf(".");
                const baseNameWithoutExtension = fileName.substring(0, extensionIndex).replace(/\_\(\d+\)$/, "");
                const extension = fileName.substring(extensionIndex);

                fileName = baseNameWithoutExtension + `_(${currentCounter + 1})` + extension;
            }

            itemForm.patchValue({
                files: [...(itemForm.value.files ?? []), new ReportItemFile(fileName, "", undefined, file)],
            });
        }
    }

    onShowFileOptionsComponent(editable: boolean, ref: TrvPopoverRef<FileOptionsPopoverComponent>, itemFile: ReportItemFile): void {
        ref.componentInstance.editable = editable;
        ref.componentInstance.fileId = itemFile.idOrTempId;
        ref.componentInstance.reportItemId = this.reportItemId;

        ref.componentInstance.download.subscribe(async () => {
            try {
                const file = await this.reportService.getFile(itemFile);

                const blob = new Blob([file], {type: file.type});
                const url = URL.createObjectURL(blob);

                const a = document.createElement("a");
                a.href = url;
                a.download = file.name;

                // Append the anchor element to the body
                document.body.appendChild(a);

                // Trigger a click event on the anchor element
                a.click();

                // Remove the anchor element from the body
                document.body.removeChild(a);

                // Release the Blob URL
                URL.revokeObjectURL(url);

                ref.componentInstance.trvPopoverRef.close("");
            } catch (e) {
                this.globalMessagesService.error("Ett fel uppstod när filen skulle hämtas.");
            }
        });

        ref.componentInstance.editDescription.subscribe(() => {
            this.editFileDescription(itemFile);
            ref.componentInstance.trvPopoverRef.close("");
        });

        ref.componentInstance.remove.subscribe(_ => {
            this.form!.patchValue({
                files: this.form!.value.files?.filter(a => a.idOrTempId !== itemFile.idOrTempId),
            });
            ref.componentInstance.trvPopoverRef.close("");
            this.cdref.detectChanges();
        });
    }

    editFileDescription(file: ReportItemFile) {
        if (!this.reportService.editableFiles.hasOwnProperty(file.idOrTempId)) {
            this.reportService.editableFiles[file.idOrTempId] = file.description
        }
        this.reportService.editableFiles = {...this.reportService.editableFiles}
    }

    saveFileDescriptions(idOrTempIds: number[], saveAll = false) {
        this.form!.patchValue({
            files: this.form!.value.files!.map(a => {
                const editedDescription = this.reportService.editableFiles.hasOwnProperty(a.idOrTempId) ? this.reportService.editableFiles[a.idOrTempId] : null
                if (editedDescription && (idOrTempIds.includes(a.idOrTempId) || saveAll)) a.description = editedDescription
                return a;
            }),
        });
        for (const idOrTempId of saveAll ? this.form!.value.files!.map(a => a.idOrTempId) : idOrTempIds) {
            delete this.reportService.editableFiles[idOrTempId]
        }
        this.reportService.editableFiles = {...this.reportService.editableFiles}
    }

    cancelFileDescriptionEdit(idOrTempId: number) {
        delete this.reportService.editableFiles[idOrTempId]
        this.reportService.editableFiles = {...this.reportService.editableFiles}
    }

    fileDescriptionChange(idOrTempId: number, $event: any) {
        this.reportService.editableFiles[idOrTempId] = $event.target!.value!
        this.reportService.editableFiles = {...this.reportService.editableFiles}
    }

    protected readonly DELIVERY_TYPE_VALID_FROM_DESCRIPTION = DELIVERY_TYPE_VALID_FROM_DESCRIPTION;
    protected readonly DateTime = DateTime;
    protected readonly circleInfoIcon = svgCircleInfo;

    leveransTypInfo() {

        const config: TrvModalConfig = {
            disposeOnBackdropClick: true,
        }
        if (this.nvdbNavigation.isMobileDevice) config.size = 'fullscreen'

        this.trvModalService.info("Information om typer av leverans", leveransInfoMessage, config);


    }

    dateTypInfo() {

        const config: TrvModalConfig = {
            disposeOnBackdropClick: true,
        }
        if (this.nvdbNavigation.isMobileDevice) config.size = 'fullscreen'

        this.trvModalService.info("Information om datum vid leverans", dateInfoMessage, config);


    }

    felTypInfo() {
        const config: TrvModalConfig = {
            disposeOnBackdropClick: true,
        }
        if (this.nvdbNavigation.isMobileDevice) config.size = 'fullscreen'

        this.trvModalService.info("Information om typer av fel", felTyperInfoMessage, config)
    }

    protected readonly Date = Date;

    isFormDateToday() {
        return this.form?.value.validFrom && DateTime.fromISO(this.form?.value.validFrom).toISODate() === DateTime.now().toISODate();
    }

    getSortedDataslag() {
        return this.reportItem?.dataslag.sort((a, b) => a.name.localeCompare(b.name))
    }

    getSortedDataslagControls() {
        return this.dataslag.controls.sort((a, b) => a.value.name!.localeCompare(b.value.name!))
    }
}


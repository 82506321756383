<div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ title }}</h4>

    <button type="button" class="btn-close" aria-describedby="modal-title" (click)="dismiss()"></button>
</div>
<div class="modal-body">
    <div>
        <h6><strong>Beskrivning:</strong></h6>

        @if (text) {
        <p>{{ text }}</p>
        } @if (!text) {
        <p [trv-loading]="true"></p>
        }
    </div>
    @if (layerVisibleFrom) {
    <div>
        <h6><strong>Lagret synligt från skala:</strong></h6>
        <p>1:{{ layerVisibleFrom }}</p>
    </div>
    } @if (legendGraphics) {
    <div>
        <h6><strong>Teckenförklaring:</strong></h6>
        <div></div>
        <img alt="legend grafik" [src]="getLegendGraphics()" />
    </div>
    } @if (layer) {
    <div>
        <h6><strong>Sätt opacitet:</strong></h6>
        <div style="width: 100%">
            <mat-slider min="0" max="1" step="0.05" discrete="thumbLabel">
                <input (input)="onOpacityChange($event)" value="{{ layer.opacity ? layer.opacity : 1 }}" matSliderThumb />
            </mat-slider>
        </div>
    </div>
    }
    <div>
        <h6>
            <strong>Z-index:</strong>
            {{ layer?.zIndex }}
        </h6>
        <div>
            <div>
                <button type="button" class="btn btn-primary btn-sm" (click)="trvMapService.trvMap?.trvLayer?.moveLayerToTop(layer!)">
                    Flytta lager till toppen
                </button>
            </div>
        </div>
    </div>

    <div class="d-flex align-items-center mt-2"
    [trvTooltip]="'Ändringar i NVDB syns inte i NVDB på karta eller NVDB dataleverans förrän nästa dag.'" [position]="toolTipPositions"
    >
        <app-svg-icon style="fill: #1e5c88" [svgIcon]="circleInfoIcon"></app-svg-icon>
        <span style="font-style: italic; margin-left: 0.5rem">
            {{
                nvdbNavigationService.formattedLastFeatureUpdateNVDB
                    ? 'Data uttaget från NVDB: ' + nvdbNavigationService.formattedLastFeatureUpdateNVDB
                    : 'Kunde inte hämta datum då NVDB datat uppdaterades'
            }}
        </span>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="close()">Stäng</button>
</div>

<trv-global-messages></trv-global-messages>
@if (showDashboard) {
  <div>
    <app-dashboard></app-dashboard>
  </div>
}
@if (!showDashboard) {
  <div>
    <app-non-dashboard></app-non-dashboard>
  </div>
}

import {Component, Inject, Input, OnInit} from "@angular/core";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {
    faMapMarkerAlt,
    faRoute,
    faVectorSquare,
    faEllipsisV,
    faEye,
    faEyeSlash,
    faDrawPolygon
} from "@fortawesome/free-solid-svg-icons";
import {Report, ReportItem, ReportService} from "src/app/service/report.service";
import {TrvPopoverPosition, TrvPopoverRef, TrvPopoverDirective} from "trv-ng-common";
import {
    ReportItemOptionsPopoverComponent
} from "@components/map-menu/report-tab/report-item-options-popover/report-item-options-popover.component";
import {GeometryType} from "@app/shared/enums";
import {AllItemsInListPipe} from "@pipes/item-in-list.pipe";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {NgClass, AsyncPipe, NgStyle} from "@angular/common";
import {NvdbNavigationService} from "@app/service/nvdb-navigation.service";

@Component({
    selector: "app-report-tab-item",
    templateUrl: "./report-tab-item.component.html",
    styleUrls: ["./report-tab-item.component.scss"],
    standalone: true,
    imports: [FontAwesomeModule, NgClass, TrvPopoverDirective, AsyncPipe, AllItemsInListPipe, NgStyle],
})
export class ReportTabItemComponent implements OnInit {
    public faMapMarkerAlt: IconProp = faMapMarkerAlt as IconProp;
    public faRoute: IconProp = faRoute as IconProp;
    public faVectorSquare: IconProp = faVectorSquare as IconProp;
    public faDrawPolygon: IconProp = faDrawPolygon as IconProp;
    public faEllipsisV: IconProp = faEllipsisV as IconProp;
    protected readonly faEye = faEye;
    protected readonly faEyeSlash = faEyeSlash;

    protected readonly GeometryType = GeometryType;
    protected readonly Report = Report;

    @Input() public reportItem!: ReportItem;
    @Input() public sequenceNumber = 0;
    @Input() public disableDragAndDrop = false

    public popoverPosition: TrvPopoverPosition[] = ["bottom", "bottom-end", "bottom-start", "right", "top"];
    public popoverComponent = ReportItemOptionsPopoverComponent;

    constructor(@Inject(ReportService) public reportService: ReportService,
                public nvdbNavigationService: NvdbNavigationService
    ) {
    }

    ngOnInit(): void {
    }

    onShowComponent(ref: TrvPopoverRef<ReportItemOptionsPopoverComponent>): void {
        ref.componentInstance.reportItem = this.reportItem;
    }

    onAfterCloseWithType(event: any): void {
    }

    public mouseEnter() {
        this.reportService.highlightReportItem(this.reportItem);
    }

    public mouseLeave() {
        this.reportService.resetAllHighlightReportItem();
    }

    itemClicked() {
        if (this.nvdbNavigationService.isMobileDevice)
            this.reportService.setActiveItemToCreateOrEdit(this.reportItem.id);
    }
}

import {Component, Inject} from "@angular/core";
import {
    TrvGlobalMessagesService,
    TrvModalService,
    TrvPopoverPosition,
    TrvPopoverRef,
    TrvPopoverDirective
} from "trv-ng-common";
import {LocalStorageService, SavedView} from "src/app/service/local-storage.service";
import {TrvNgMapService} from "@trafikverket/trv-ng-map";
import {SaveViewComponent} from "../save-view/save-view.component";

@Component({
    selector: "app-save-view-options",
    templateUrl: "./save-view-options.component.html",
    styleUrls: ["./save-view-options.component.scss"],
    standalone: true,
    imports: [TrvPopoverDirective],
})
export class SaveViewOptionsComponent {
    public inputSavedView!: SavedView;

    public popoverPosition: TrvPopoverPosition[] = ["right", "bottom", "bottom-end", "bottom-start", "top-start", "top", "top-end"];
    public popoverComponent = SaveViewComponent;

    constructor(
        private trvPopoverRef: TrvPopoverRef,
        private localStorageService: LocalStorageService,
        @Inject(TrvNgMapService) private trvMapService: TrvNgMapService,
        @Inject(TrvModalService) private trvModalService: TrvModalService,
        @Inject(TrvGlobalMessagesService)
        private globalMessagesService: TrvGlobalMessagesService
    ) {
    }

    public gotoView() {
        this.trvMapService.trvMap?.setMapStateWithCoord(
            this.inputSavedView.centerCoordinate,
            this.inputSavedView.zoom,
            this.inputSavedView.backgroundLayerId,
            this.inputSavedView.dataLayersId,
            1000,
            true
        );
        this.trvPopoverRef.close("");
    }

    public removeView() {
        this.trvModalService
            .confirmDelete(`Radera vy?`, `Är du säker att du vill ta bort vyn "${this.inputSavedView.name}"`, "Ta bort vy", "Behåll vy", {
                disposeOnBackdropClick: true,
            })
            .afterCloseWithType()
            .subscribe(event => {
                if (event.closingEventType !== "close") return;

                this.localStorageService.setSavedViews(
                    this.localStorageService.savedViews.filter(view => view.id !== this.inputSavedView.id)
                );
                this.globalMessagesService.success(`Vyn ${this.inputSavedView.name} togs bort.`);
            });
        this.trvPopoverRef.close("");
    }

    onShowComponent(ref: TrvPopoverRef<SaveViewComponent>): void {
        console.log("SET SAVED VIEW");
        ref.componentInstance.savedViewToEdit = this.inputSavedView;
    }
}

import { Component, ViewChild } from "@angular/core";
import { StatsService } from "@app/service/stats.service";
import { BaseChartDirective } from "ng2-charts";

@Component({
    selector: 'app-num-reports-over-time-chart',
    standalone: true,
    imports: [BaseChartDirective],
    templateUrl: './num-reports-over-time-chart.component.html',
    styleUrl: './num-reports-over-time-chart.component.scss',
})
export class NumReportsOverTimeChartComponent {
    @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

    constructor(public statsService: StatsService) {
        this.statsService.statsUpdated.subscribe(() => {
            this.chart?.update();
        });
    }
}

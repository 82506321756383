import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import { AuthenticationService } from "src/app/service/authentication.service";
import { NvdbNavigationService } from "src/app/service/nvdb-navigation.service";
import {
    TrvFormValidationErrorMap,
    TrvGlobalMessagesService,
    TrvModalService,
    TrvTooltipPosition,
    TrvFormBundle,
    TrvTooltipDirective,
    TrvDateRangeComponent,
    TrvFormUtilityService,
    TrvLoadingDirective,
    TrvDatePickerComponent,
    TrvFormFieldComponent,
    TrvFormFieldGroupComponent,
    TrvTabsetBundle,
} from "trv-ng-common";
import { AdminTypeEnum } from "@_api/dataleverans/models/admin-type-enum";
import { focusOnFirstInvalidControl } from "@shared/utils/utils";
import { DateTime } from "luxon";
import { PERIOD } from "@angular/cdk/keycodes";
import { HttpClientModule } from "@angular/common/http";
import { AngularEditorConfig, AngularEditorModule } from "@wfpena/angular-wysiwyg";
import { NgClass } from '@angular/common'
import {TrvModalConfig} from "trv-ng-common/lib/components/modal/modal-config";
import { PaKartaAdminComponent } from "./pa-karta-admin/pa-karta-admin.component";
import { faBroom, faTruckMoving } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "app-admin-tab",
    templateUrl: "./admin-tab.component.html",
    styleUrls: ["./admin-tab.component.scss"],
    standalone: true,
    imports: [
        TrvFormBundle,
        ReactiveFormsModule,
        TrvTooltipDirective,
        TrvDateRangeComponent,
        TrvLoadingDirective,
        TrvDatePickerComponent,
        TrvFormFieldComponent,
        TrvFormFieldGroupComponent,
        HttpClientModule,
        AngularEditorModule,
        NgClass,
        PaKartaAdminComponent,
        TrvTabsetBundle
    ],
    providers: [],
})
export class AdminTabComponent implements OnInit {
    public toolTipPositions: TrvTooltipPosition[] = ["bottom", "right"];

    public message = this.nvdbNavigationService.adminMenuState.messageFromForvaltning;
    public periodStart = this.nvdbNavigationService.adminMenuState.messagePeriodStart;
    public periodEnd = this.nvdbNavigationService.adminMenuState.messagePeriodEnd;

    protected readonly DateTime = DateTime;
    protected readonly PERIOD = PERIOD;

    protected readonly faBroom = faBroom;
    protected readonly faTruckMoving = faTruckMoving;

    public loading = false;
    public editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: "auto",
        minHeight: "50px",
        maxHeight: "300px",
        width: "auto",
        minWidth: "0",
        translate: "yes",
        enableToolbar: true,
        showToolbar: true,
        placeholder: "Skriv meddelande från förvaltningen här..",
        defaultParagraphSeparator: "",
        defaultFontName: "Roboto",
        defaultFontSize: "",
        fonts: [
            { class: "arial", name: "Arial" },
            { class: "times-new-roman", name: "Times New Roman" },
            { class: "calibri", name: "Calibri" },
            { class: "comic-sans-ms", name: "Comic Sans MS" },
            { class: "Roboto", name: "Roboto" },
        ],
        toolbarHiddenButtons: [
            [
                "redo",
                "undo",
                "subscript",
                "superscript",
                "insertVideo",
                "justifyLeft",
                "justifyCenter",
                "justifyRight",
                "justifyFull",
                "indent",
                "outdent",
                "backgroundColor",
            ],
        ],
    };

    constructor(
        private nvdbNavigationService: NvdbNavigationService,
        public authenticationService: AuthenticationService,
        private trvModalService: TrvModalService,
        private cdref: ChangeDetectorRef,
        private formUtilityService: TrvFormUtilityService,
        @Inject(TrvGlobalMessagesService)
        private globalMessagesService: TrvGlobalMessagesService
    ) {}

    async ngOnInit() {
        //this.loading = true;

        await this.nvdbNavigationService.loadAdminConfiguration();
        await this.nvdbNavigationService.loadDupAdminConfiguration();

        this.nvdbNavigationService.updateAdminForm(this.nvdbNavigationService.adminConfiguration!);
        this.nvdbNavigationService.updateDupAdminForm(this.nvdbNavigationService.adminConfigurationDup!);

        //this.loading = false;
        this.cdref.detectChanges();
    }

    public submitMessageFromForvaltning() {
        if (!this.message!.valid) {
            this.message!.updateValueAndValidity();
            this.message!.markAllAsTouched();
            this.message!.markAsDirty();

            focusOnFirstInvalidControl();

            this.cdref.detectChanges();

            this.globalMessagesService.error(`Var vänlig och korrigera felen, kan ej spara meddelandet.`);
            return;
        }

        let periodStart = this.periodStart.value?.split("T")[0] ?? undefined;
        const periodEnd = this.periodEnd.value?.split("T")[0] ?? undefined;

        this.nvdbNavigationService.updateAdminConfiguration({
            values: [
                {
                    type: AdminTypeEnum.MessageFromForvaltningen,
                    value: this.message.value,
                },
                {
                    type: AdminTypeEnum.MessagePeriodStart,
                    value: periodStart,
                },
                {
                    type: AdminTypeEnum.MessagePeriodEnd,
                    value: periodEnd,
                },
            ],
        });

        if (periodStart == null) periodStart = DateTime.now().toISODate();

        let successMessage;
        if (periodEnd) {
            successMessage = `Meddelandet uppdaterades och kommer visas mellan ${periodStart} och ${periodEnd}.`;
        } else {
            successMessage = `Meddelandet uppdaterades och kommer visas från ${periodStart} tillsvidare.`;
        }
        this.globalMessagesService.success(successMessage);
    }

    public previewMessageFromForvaltning() {
        const config: TrvModalConfig = {
            disposeOnBackdropClick: true,
        }
        if (this.nvdbNavigationService.isMobileDevice) config.size = 'fullscreen'

        this.trvModalService.info("Information från förvaltningen", this.message.value, config)
    }

    periodStartIsTodaysDate() {
        return (this.periodStart.value?.split("T")[0] ?? undefined) == DateTime.now().toISODate();
    }
}

import { Injectable } from "@angular/core";
import { TrvMapExtension } from "../trv-map-extension/trv-map-extension";
import { Subject } from "rxjs";
import {NvdbNavigationService} from "@app/service/nvdb-navigation.service";

@Injectable({
    providedIn: "root",
})
export class TrvMapExtensionService {
    public trvMapExtension!: TrvMapExtension;

    constructor() {}

    public setTrvMapExtension(trvMapExtension: TrvMapExtension) {
        this.trvMapExtension = trvMapExtension;
    }
}

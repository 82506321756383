/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AdminConfigurationRead } from '../models/admin-configuration-read';
import { DupAdminValueRead } from '../models/dup-admin-value-read';
import { getConfiguration } from '../fn/admin/get-configuration';
import { GetConfiguration$Params } from '../fn/admin/get-configuration';
import { getConfiguration$Plain } from '../fn/admin/get-configuration-plain';
import { GetConfiguration$Plain$Params } from '../fn/admin/get-configuration-plain';
import { getDupConfiguration } from '../fn/admin/get-dup-configuration';
import { GetDupConfiguration$Params } from '../fn/admin/get-dup-configuration';
import { getDupConfiguration$Plain } from '../fn/admin/get-dup-configuration-plain';
import { GetDupConfiguration$Plain$Params } from '../fn/admin/get-dup-configuration-plain';
import { updateConfiguration } from '../fn/admin/update-configuration';
import { UpdateConfiguration$Params } from '../fn/admin/update-configuration';
import { updateConfiguration$Plain } from '../fn/admin/update-configuration-plain';
import { UpdateConfiguration$Plain$Params } from '../fn/admin/update-configuration-plain';
import { writeDupConfiguration } from '../fn/admin/write-dup-configuration';
import { WriteDupConfiguration$Params } from '../fn/admin/write-dup-configuration';

@Injectable({ providedIn: 'root' })
export class AdminApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getConfigurationAdmin()` */
  static readonly GetConfigurationAdminPath = '/Admin/Configuration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConfiguration$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfiguration$Plain$Response(params?: GetConfiguration$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AdminConfigurationRead>> {
    return getConfiguration$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConfiguration$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfiguration$Plain(params?: GetConfiguration$Plain$Params, context?: HttpContext): Observable<AdminConfigurationRead> {
    return this.getConfiguration$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminConfigurationRead>): AdminConfigurationRead => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConfiguration()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfiguration$Response(params?: GetConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<AdminConfigurationRead>> {
    return getConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConfiguration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfiguration(params?: GetConfiguration$Params, context?: HttpContext): Observable<AdminConfigurationRead> {
    return this.getConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminConfigurationRead>): AdminConfigurationRead => r.body)
    );
  }

  /** Path part for operation `updateConfigurationAdmin()` */
  static readonly UpdateConfigurationAdminPath = '/Admin/Configuration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateConfiguration$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateConfiguration$Plain$Response(params?: UpdateConfiguration$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AdminConfigurationRead>> {
    return updateConfiguration$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateConfiguration$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateConfiguration$Plain(params?: UpdateConfiguration$Plain$Params, context?: HttpContext): Observable<AdminConfigurationRead> {
    return this.updateConfiguration$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminConfigurationRead>): AdminConfigurationRead => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateConfiguration()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateConfiguration$Response(params?: UpdateConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<AdminConfigurationRead>> {
    return updateConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateConfiguration$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateConfiguration(params?: UpdateConfiguration$Params, context?: HttpContext): Observable<AdminConfigurationRead> {
    return this.updateConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminConfigurationRead>): AdminConfigurationRead => r.body)
    );
  }

  /** Path part for operation `getDupConfigurationAdmin()` */
  static readonly GetDupConfigurationAdminPath = '/Admin/DupConfiguration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDupConfiguration$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDupConfiguration$Plain$Response(params?: GetDupConfiguration$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DupAdminValueRead>>> {
    return getDupConfiguration$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDupConfiguration$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDupConfiguration$Plain(params?: GetDupConfiguration$Plain$Params, context?: HttpContext): Observable<Array<DupAdminValueRead>> {
    return this.getDupConfiguration$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DupAdminValueRead>>): Array<DupAdminValueRead> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDupConfiguration()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDupConfiguration$Response(params?: GetDupConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DupAdminValueRead>>> {
    return getDupConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDupConfiguration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDupConfiguration(params?: GetDupConfiguration$Params, context?: HttpContext): Observable<Array<DupAdminValueRead>> {
    return this.getDupConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DupAdminValueRead>>): Array<DupAdminValueRead> => r.body)
    );
  }

  /** Path part for operation `writeDupConfigurationAdmin()` */
  static readonly WriteDupConfigurationAdminPath = '/Admin/WriteDupConfiguration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `writeDupConfiguration()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  writeDupConfiguration$Response(params?: WriteDupConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return writeDupConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `writeDupConfiguration$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  writeDupConfiguration(params?: WriteDupConfiguration$Params, context?: HttpContext): Observable<void> {
    return this.writeDupConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

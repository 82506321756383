import { PERIOD } from "@angular/cdk/keycodes";
import { NgClass } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { DupAdminValueRead, DupAdminValueWrite } from "@app/_api/dataleverans/models";
import { AdminApi } from "@app/_api/dataleverans/services/admin-api";
import { AuthenticationService } from "@app/service/authentication.service";
import {
    MESSAGE_FROM_FORVALTNING_PERIOD_END_UID_NVDB_PA_KARTA,
    MESSAGE_FROM_FORVALTNING_PERIOD_START_UID_NVDB_PA_KARTA,
    MESSAGE_FROM_FORVALTNING_UID_NVDB_PA_KARTA,
    NvdbNavigationService,
} from "@app/service/nvdb-navigation.service";
import { focusOnFirstInvalidControl } from "@app/shared/utils/utils";
import { AngularEditorConfig, AngularEditorModule } from "@wfpena/angular-wysiwyg";
import { DateTime } from "luxon";
import { forkJoin } from "rxjs";
import {
    TrvDatePickerComponent,
    TrvDateRangeComponent,
    TrvFormBundle,
    TrvFormFieldComponent,
    TrvFormFieldGroupComponent,
    TrvGlobalMessagesService,
    TrvLoadingDirective,
    TrvModalConfig,
    TrvModalService,
    TrvTooltipDirective,
} from "trv-ng-common";

@Component({
    selector: "app-pa-karta-admin",
    standalone: true,
    imports: [
        TrvFormBundle,
        ReactiveFormsModule,
        TrvTooltipDirective,
        TrvDateRangeComponent,
        TrvLoadingDirective,
        TrvDatePickerComponent,
        TrvFormFieldComponent,
        TrvFormFieldGroupComponent,
        HttpClientModule,
        AngularEditorModule,
        NgClass,
    ],
    templateUrl: "./pa-karta-admin.component.html",
    styleUrl: "./pa-karta-admin.component.scss",
})
export class PaKartaAdminComponent implements OnInit {
    public message = this.nvdbNavigationService.dupAdminMenuState.messageFromForvaltningDup;
    public periodStart = this.nvdbNavigationService.dupAdminMenuState.messagePeriodStartDup;
    public periodEnd = this.nvdbNavigationService.dupAdminMenuState.messagePeriodEndDup;

    protected readonly DateTime = DateTime;
    protected readonly PERIOD = PERIOD;

    public loading = false;
    public editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: "auto",
        minHeight: "50px",
        maxHeight: "300px",
        width: "auto",
        minWidth: "0",
        translate: "yes",
        enableToolbar: true,
        showToolbar: true,
        placeholder: "Skriv meddelande från förvaltningen här..",
        defaultParagraphSeparator: "",
        defaultFontName: "Roboto",
        defaultFontSize: "",
        fonts: [
            { class: "arial", name: "Arial" },
            { class: "times-new-roman", name: "Times New Roman" },
            { class: "calibri", name: "Calibri" },
            { class: "comic-sans-ms", name: "Comic Sans MS" },
            { class: "Roboto", name: "Roboto" },
        ],
        toolbarHiddenButtons: [
            [
                "redo",
                "undo",
                "subscript",
                "superscript",
                "insertVideo",
                "justifyLeft",
                "justifyCenter",
                "justifyRight",
                "justifyFull",
                "indent",
                "outdent",
                "backgroundColor",
            ],
        ],
    };

    constructor(
        public adminService: AdminApi,
        @Inject(TrvGlobalMessagesService)
        private globalMessagesService: TrvGlobalMessagesService,
        private nvdbNavigationService: NvdbNavigationService,
        private cdref: ChangeDetectorRef,
        public authenticationService: AuthenticationService,
        private trvModalService: TrvModalService
    ) {}

    async ngOnInit() {
        
    }

    public periodStartIsTodaysDate() {
        return (this.periodStart.value?.split("T")[0] ?? undefined) == DateTime.now().toISODate();
    }

    public submitMessageFromForvaltning() {
        if (!this.message!.valid) {
            this.message!.updateValueAndValidity();
            this.message!.markAllAsTouched();
            this.message!.markAsDirty();

            focusOnFirstInvalidControl();

            this.cdref.detectChanges();

            this.globalMessagesService.error(`Var vänlig och korrigera felen, kan ej spara meddelandet.`);
            return;
        }

        let periodStart = this.periodStart.value?.split("T")[0] ?? undefined;
        const periodEnd = this.periodEnd.value?.split("T")[0] ?? undefined;

        if (periodStart == null) periodStart = DateTime.now().toISODate();

        let adminValueMessage: DupAdminValueWrite = {
            type: 1,
            uid: MESSAGE_FROM_FORVALTNING_UID_NVDB_PA_KARTA,
            updatedBy: this.authenticationService.userName,
            value: this.message.value,
        };

        let adminValueMessageStart: DupAdminValueWrite = {
            type: 2,
            uid: MESSAGE_FROM_FORVALTNING_PERIOD_START_UID_NVDB_PA_KARTA,
            updatedBy: this.authenticationService.userName,
            value: periodStart,
        };

        let adminValueMessageEnd: DupAdminValueWrite = {
            type: 3,
            uid: MESSAGE_FROM_FORVALTNING_PERIOD_END_UID_NVDB_PA_KARTA,
            updatedBy: this.authenticationService.userName,
            value: periodEnd,
        };

        const adminObservable = [
            this.adminService.writeDupConfiguration({ body: adminValueMessage }),
            this.adminService.writeDupConfiguration({ body: adminValueMessageStart }),
            this.adminService.writeDupConfiguration({ body: adminValueMessageEnd }),
        ];

        forkJoin(adminObservable).subscribe({
            next: () => {
                let successMessage;
                if (periodEnd) {
                    successMessage = `Meddelandet uppdaterades och kommer visas mellan ${periodStart} och ${periodEnd}.`;
                } else {
                    successMessage = `Meddelandet uppdaterades och kommer visas från ${periodStart} tillsvidare.`;
                }
                this.globalMessagesService.success(successMessage);
            },
            error: error => {
                console.error("One or more POST requests failed:");
                this.globalMessagesService.error("Något gick fel när admin-meddelandet skulle skrivas.")
                // Check each error
                error.forEach((err: { message: any; }, index: number) => {
                    if (err) {
                        this.globalMessagesService.error(`Request ${index + 1} failed: ${err.message}`)
                    }
                });
            },
        });
    }

    public previewMessageFromForvaltning() {
        const config: TrvModalConfig = {
            disposeOnBackdropClick: true,
        };
        if (this.nvdbNavigationService.isMobileDevice) config.size = "fullscreen";

        this.trvModalService.info("Information från förvaltningen", this.message.value, config);
    }
}


<div class='handle_popover_container'>
    <!--TODO jag vet inte riktigt när vilka knappar ska vara tillgängliga...-->
    @if (report.status === ReportStatusesEnum.Sent || report.status === ReportStatusesEnum.ClarificationSent) {
        <div class='handle_popover_item' (click)='handle(AjourhallningActions.klart_for_beredning)'>
            <fa-icon style='color: green' [icon]='faCircleCheck'></fa-icon>
            <div>{{ AjourhallningActions.klart_for_beredning }}</div>
        </div>
    }
    @if ((report.status === ReportStatusesEnum.Sent || report.status === ReportStatusesEnum.ClarificationSent) && report.owner != null) {
        <div class='handle_popover_item' (click)='handle(AjourhallningActions.las_upp)'>
            <fa-icon style='color: #1e5c88' [icon]='faLockOpen'></fa-icon>
            <div>{{ AjourhallningActions.las_upp }}</div>
        </div>
    }
    @if (report.status !== ReportStatusesEnum.Closed) {
        <div class='handle_popover_item' (click)='handle(AjourhallningActions.avsluta_arende)'>
            <fa-icon style='color: #1e5c88' [icon]='faSquareXmark'></fa-icon>
            <div>{{ AjourhallningActions.avsluta_arende }}</div>
        </div>
    }
    <hr class='action_divider' />
    <div class='handle_popover_item' (click)='handle(AjourhallningActions.informera_kunden)'>
        <fa-icon style='color: #1e5c88' [icon]='faCircleInfo'></fa-icon>
        <div>{{ AjourhallningActions.informera_kunden }}</div>
    </div>
    <div class='handle_popover_item' (click)='handle(AjourhallningActions.informera_kommunen)'>
        <fa-icon style='color: #1e5c88' [icon]='faCircleInfo'></fa-icon>
        <div>{{ AjourhallningActions.informera_kommunen }}</div>
    </div>
    <div class='handle_popover_item' (click)='handle(AjourhallningActions.informera_annan)'>
        <fa-icon style='color: #1e5c88' [icon]='faCircleInfo'></fa-icon>
        <div>{{ AjourhallningActions.informera_annan }}</div>
    </div>

    <hr class='action_divider' />

    <div class='handle_popover_item' (click)='handle(AjourhallningActions.skapa_geopackage)'>
        <fa-icon style='color: #1e5c88' [icon]='faFileCirclePlus'></fa-icon>
        <div>{{ AjourhallningActions.skapa_geopackage }}</div>
    </div>
</div>

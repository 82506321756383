<!--
&& !nvdbNavigationService.isMobileDevice
-->
@if (initialized) {
    <div class='map-main__buttons__btnlist'>
        <app-map-button
            [svgIcon]='handIcon'
            [active]='trvMapService.trvMap!.activeMapTool!.value == MapTools.Pointer'
            (click)='setMapTool(MapTools.Pointer)'
            toolTip='Panorera genom att dra i kartan'
            [toolTipPosition]="toolTipPositions"
        ></app-map-button>
        <app-map-button
            [svgIcon]='zoomIconPlus'
            [active]='trvMapService.trvMap?.activeMapTool?.value == MapTools.Zoom'
            (click)='setMapTool(MapTools.Zoom)'
            toolTip='Zooma med rektangel'
            [toolTipPosition]='toolTipPositions'
        ></app-map-button>
        <app-map-button
            [svgIcon]='swedenIcon'
            [specialSvg]='true'
            (click)='gotoSweden()'
            toolTip='Visa hela sverige'
            [toolTipPosition]='toolTipPositions'
        ></app-map-button>
        <app-map-button
            [svgIcon]='rulerIcon'
            [active]='trvMapService.trvMap?.activeMapTool?.value == MapTools.Ruler'
            (click)='setMapTool(MapTools.Ruler)'
            toolTip='Mät i kartan'
            [toolTipPosition]='toolTipPositions'
        ></app-map-button>
        <app-map-button
            [svgIcon]='pinIcon'
            [active]='trvMapService.trvMap?.activeMapTool?.value == MapTools.PinPosition'
            (click)='setMapTool(MapTools.PinPosition)'
            toolTip='Visa koordinat i karta'
            [toolTipPosition]='toolTipPositions'
        ></app-map-button>
        <app-map-button [svgIcon]='eraserIcon' (click)='eraser()' toolTip='Rensa i kartan'
                        [toolTipPosition]='toolTipPositions'></app-map-button>
        <app-map-button
            [svgIcon]='printerIcon'
            [active]='trvMapService.trvMap?.activeMapTool?.value == MapTools.ExportRectangle'
            (click)='changeNavigationState(NavigationState.PrintMap);'
            toolTip='Utskriftsformat'
            [toolTipPosition]='toolTipPositions'
        ></app-map-button>
        @if (environment.application == 'NvdbDataleverans' || environment.local == "true") {
            <app-map-button
                [svgIcon]='googleMapsIcon'
                [active]='trvMapService.trvMap?.activeMapTool?.value == MapTools.StreetView'
                (click)='setMapTool(MapTools.StreetView)'
                toolTip='Google street view'
                [toolTipPosition]='toolTipPositions'
            ></app-map-button>
        }
        @if (environment.application == 'NvdbDataleverans' || environment.local == "true") {
            <app-map-button
                [active]='datePopoverRef.active'
                #datePopoverRef='trvPopover'
                [trvPopover]='betraktelseComponent'
                (afterCloseWithType)='onAfterCloseWithType($event)'
                (shown)='onShowComponent($event)'
                (click)='showPopover($event)'
                [manualToggle]='true'
                [notice]='showBetraktelseNotice()'
                [noticeText]="'!'"
                [svgIcon]='timeBackIcon'
                toolTip='Betraktelsedatum'
                [toolTipPosition]='toolTipPositions'
            ></app-map-button>
        }
        <app-map-button
            [svgIcon]='infoIcon'
            [active]='trvMapService.trvMap?.activeMapTool?.value == MapTools.NvdbInfo'
            (click)='changeNavigationState(NavigationState.InfoClick)'
            toolTip='Klicka på dataprodukt i karta för mer information om de företeelser som finns på den positionen'
            [toolTipPosition]='toolTipPositions'
        ></app-map-button>
    </div>
}

import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { LayerListItem, TrvMap, TrvNgMapService } from "@trafikverket/trv-ng-map";
import { svgCircleInfo, svgInfo, svgMagnifyingGlass, svgMagnifyingPlus, svgMagnifyingPlusFilled } from "src/app/shared/utils/svg-helper-data";
import { trvCollapseVoidAnimation, TrvModalService, TrvTooltipPosition, TrvTooltipDirective, TrvLoadingBundle } from "trv-ng-common";
import { NetDbService } from "src/app/service/net-db.service";
import { firstValueFrom, startWith } from "rxjs";
import { SvgIcon } from "src/app/shared/utils/svg-helper";
import { NvdbNavigationService } from "src/app/service/nvdb-navigation.service";
import { LayerInfoModalComponent } from "../layer-section/layer-info-modal/layer-info-modal.component";
import { NgClass } from "@angular/common";
import { SvgIconComponent } from "../../utils/svg-icon/svg-icon.component";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { combineLatest } from "rxjs";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-dataslag-section",
    templateUrl: "./dataslag-section.component.html",
    styleUrls: ["../../map-menu/map-menu.component.scss", "./dataslag-section.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: [trvCollapseVoidAnimation],
    standalone: true,
    imports: [SvgIconComponent, NgClass, TrvTooltipDirective, ReactiveFormsModule, TrvLoadingBundle],
})
export class DataslagSectionComponent implements AfterViewInit {
    @ViewChild("scrollContainer", { static: false }) scrollContainer!: ElementRef;

    public searchIcon: SvgIcon = svgMagnifyingGlass;
    public searchIconPlus: SvgIcon = svgMagnifyingPlus;
    public searchIconPlusFilled: SvgIcon = svgMagnifyingPlusFilled;
    public circleInfoIcon: SvgIcon = svgCircleInfo;
    public infoIcon: SvgIcon = svgInfo;

    public toolTipPositions: TrvTooltipPosition[] = ["bottom", "right"];

    public filteredDataslagLayerIds: string[] = [];
    public dataslagLayers: LayerListItem[] = [];

    dataslagSearch = new FormControl("", { nonNullable: true });
    showChoosenDataslag = new FormControl(false, { nonNullable: true });

    constructor(
        @Inject(TrvNgMapService) public trvMapService: TrvNgMapService,
        @Inject(NvdbNavigationService)
        public nvdbNavigation: NvdbNavigationService,
        @Inject(TrvModalService) private trvModalService: TrvModalService,
        @Inject(NetDbService) private netDbService: NetDbService
    ) {
        combineLatest([
            this.trvMapService.trvMap!.trvLayer.layersUpdated.pipe(startWith([])),
            this.showChoosenDataslag.valueChanges.pipe(startWith(false)),
            this.dataslagSearch.valueChanges.pipe(startWith("")),
        ]).subscribe(([_, showChoosenDataslag, searchString]) => {
            if (this.getTrvMap() == null) return;

            let lowercaseSearchString = searchString.toLowerCase();
            this.dataslagLayers =
                this.getTrvMap()?.trvLayer.wmsLayerListItems!.filter(a => a.name.toLowerCase().includes(lowercaseSearchString)) ?? [];

            if (showChoosenDataslag) this.filteredDataslagLayerIds = this.dataslagLayers.filter(a => a.active).map(a => a.id);
            else this.filteredDataslagLayerIds = this.dataslagLayers.map(a => a.id);
        });

        this.dataslagSearch.valueChanges.subscribe(() => this.showChoosenDataslag.setValue(false));
    }

    ngAfterViewInit(): void {
        this.scrollContainer.nativeElement.scrollTop = this.nvdbNavigation.startMenuState.dataSlagScrollTop;
    }

    public numberOfDataProdukterActive() {
        const trvMap = this.trvMapService.trvMap!;
        const trvLayer = trvMap.trvLayer;
        let dataprod = this.trvMapService.trvMap!.trvLayer.getVisibleWmsLayer().filter(layer => trvLayer.getLayerData(layer, "showInLayer") != true)
        return dataprod.length;
    }

    public disableAllLayers() {
        const trvMap = this.trvMapService.trvMap!;
        const trvLayer = trvMap.trvLayer;
        trvLayer.wmsLayerListItems.forEach(layer => {
            if (layer.active && trvLayer.getLayerData(layer, "showInLayer") != true) {
                trvLayer.setLayerVisibility(layer, false);
            }
        });
    }

    public onScrollEvent(event: Event): void {
        const element = event.target as HTMLElement;
        this.nvdbNavigation.startMenuState.dataSlagScrollTop = element.scrollTop;
    }

    public getTrvMap(): TrvMap | undefined {
        return this.trvMapService.trvMap;
    }

    public showLayerBasedOnLocal(layer: LayerListItem): boolean {
        const trvMap = this.trvMapService.trvMap!;
        if (trvMap.trvLayer.hasLayerData(layer, "onlyLocal")) {
            const onlyLocal = trvMap.trvLayer.getLayerData(layer, "onlyLocal") as boolean;
            if (!(environment.local == "true") && onlyLocal) return false;
        }
        return true;
    }

    public showInLayer(layer: LayerListItem): boolean {
        let res = false;
        if (layer.trvData.hasOwnProperty("showInLayer")) {
            let showInLayer = (layer.trvData as any).showInLayer;
            return showInLayer;
        }

        return res;
    }

    public onWmsLayerInputChange(event: any, layer: LayerListItem) {
        this.trvMapService.trvMap?.trvLayer.setLayerVisibility(layer, event.currentTarget.checked);
    }

    public layerActive(layer: LayerListItem) {
        if (layer) {
            return layer.active;
        }
        return false;
    }

    /**
     * Metod som om, lagret innehåller en maxScaleDenominator, zoomar in till den nivån på kartan.
     * @param layer Lagret som ska zoomas in på
     */
    public zoomToLayer(layer: LayerListItem) {
        this.trvMapService.trvMap!.zoomInOnLayerMaxScaleDenominator(layer);
    }

    /**
     * Metod som triggras när ett dataslag har blivit högerklickat på.
     * @param event
     * @param layer Det dataslag som klickats på
     * @returns false;
     */
    public onRightClick(event: any, layer: LayerListItem) {
        this.infoAboutLayer(layer);
        return false;
    }

    /**
     * Metod som öppnar modal som visar information om nuvarande lager.
     * @param layer
     */
    public async infoAboutLayer(layer: LayerListItem) {
        const modal = this.trvModalService.open(LayerInfoModalComponent, {
            disposeOnBackdropClick: true,
            size: this.nvdbNavigation.isMobileDevice ? "fullscreen" : "md",
        });
        const component = modal.componentInstance;
        component.title = layer.name;

        let metaKey: string = "";
        if (layer.trvData.hasOwnProperty("metaKey")) metaKey = (layer.trvData as any).metaKey;

        if (layer.trvData.hasOwnProperty("description")) {
            layer.description = (layer.trvData as any).description;
        } else {
            if (metaKey != "") {
                var desc = await firstValueFrom(this.netDbService.getDescription(metaKey));
                desc = desc.replace("\n", "<br>");
                layer.description = desc;
            }
        }

        component.text = layer.description ? layer.description : "Lagret saknar beskrivning.";
        component.layerVisibleFrom = layer.maxScaleDenominator ? Math.round(layer.maxScaleDenominator) : layer.maxScaleDenominator;
        component.legendGraphics = layer.legendGraphics;
        component.layer = layer;

        modal.afterCloseWithType().subscribe(closeParams => {
            //console.log(closeParams)
        });
    }
}

import {AfterViewInit, Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren} from "@angular/core";
import {NvdbNavigationService} from "../../service/nvdb-navigation.service";
import {ReportService} from "../../service/report.service";
import {combineLatest, merge, startWith, take, tap} from "rxjs";
import {filter} from "rxjs/operators";
import {FilterForm, ReportsTableComponent} from "../../components/reports/reports-table/reports-table.component";
import {FORMATTED_REPORT_STATUS} from "../../shared/constants";
import {ReportStatusesEnum} from "src/app/_api/dataleverans/models";
import {TrvLoadingBundle} from "trv-ng-common";
import {NavigationEnd, NavigationStart, Router, RouterLink} from "@angular/router";
import {FormGroup} from "@angular/forms";
import {environment} from "../../../environments/environment";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {AuthenticationService} from "@app/service/authentication.service";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "app-handle-reports-page",
    templateUrl: "./handle-reports-page.component.html",
    styleUrls: ["./handle-reports-page.component.scss"],
    standalone: true,
    imports: [RouterLink, ReportsTableComponent, TrvLoadingBundle, FaIconComponent],
})
export class HandleReportsPageComponent implements AfterViewInit {
    @ViewChild(ReportsTableComponent) multipleReportsElement!: ReportsTableComponent;
    @ViewChildren(ReportsTableComponent) componentsToQuery!: QueryList<ReportsTableComponent>;

    constructor(
        @Inject(AuthenticationService)
        public authenticationService: AuthenticationService,
        @Inject(NvdbNavigationService)
        public nvdbNavigation: NvdbNavigationService,
        @Inject(ReportService) public reportService: ReportService,
        @Inject(Router) private router: Router
    ) {
        this.nvdbNavigation.hideHamburgerMenu = true;
        this.nvdbNavigation.backLink = "/home";

        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.multipleReportsElement && event.url !== "/handle-reports") {
                    this.nvdbNavigation.previousHandleReportsTableState = {
                        page: this.multipleReportsElement.currentPage.value,
                        filters: this.multipleReportsElement.filterForm,
                        openedReports: this.multipleReportsElement.openedReportGroups,
                        openedReportItems: this.multipleReportsElement.openedReportItems,
                        scroll: window.scrollY,
                    };
                }
            }
        });
    }

    loading = false;
    reportGroupIds: number[] | null = null;

    ngAfterViewInit() {
        this.reportService.reportsChanged.subscribe(() => {
            this.reportGroupIds = [...this.reportService.reports, ...this.reportService.otherUsersReports].filter(a => a.status !== ReportStatusesEnum.Draft).map(a => a.id);
        })
        this.reportGroupIds = [...this.reportService.reports, ...this.reportService.otherUsersReports].filter(a => a.status !== ReportStatusesEnum.Draft).map(a => a.id);

        if(this.multipleReportsElement) this.setTableState(this.multipleReportsElement)

        this.componentsToQuery.changes.subscribe((components: QueryList<ReportsTableComponent>) => {
            let multipleReportsElement
            if (components.toArray() && components.toArray().length > 0) multipleReportsElement = components.toArray()[0]
            else return
            this.setTableState(multipleReportsElement)
        })
    }

    setTableState(table: ReportsTableComponent){
        table.filterForm.patchValue({
            status: FORMATTED_REPORT_STATUS.filter(status => status.statusEnum !== ReportStatusesEnum.Closed),
        });

        const state = this.nvdbNavigation.previousHandleReportsTableState;
        if (state) {
            table.setTableState(state);
        }
    }

    protected readonly environment = environment;
    protected readonly faCircleInfo = faCircleInfo;
}

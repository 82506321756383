import { AfterContentInit } from "@angular/core";
import { Directive } from "@angular/core";
import { ElementRef } from "@angular/core";

@Directive({
    selector: "[appAutofocus]",
    standalone: true,
})
export class AutofocusDirective implements AfterContentInit {
    private elementRef: ElementRef;

    constructor(elementRef: ElementRef) {
        this.elementRef = elementRef;
    }
    public ngAfterContentInit(): void {
        setTimeout((): void => {
            this.elementRef.nativeElement.focus();
        }, 1);
    }
}

import { Component, Input, OnInit } from "@angular/core";
import { SvgIcon } from "src/app/shared/utils/svg-helper";


@Component({
    selector: "app-svg-icon",
    templateUrl: "./svg-icon.component.html",
    styleUrls: ["./svg-icon.component.scss"],
    standalone: true,
    imports: [],
})
export class SvgIconComponent implements OnInit {
    @Input() public svgIcon!: SvgIcon;

    constructor() {}

    ngOnInit(): void {}
}

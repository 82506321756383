<div style="padding: 1rem">
    <h5 style="margin-bottom: 1rem">{{ savedViewToEdit ? 'Uppdatera' : 'Skapa' }} vy</h5>
    <trv-form [validationErrors]="validationErrorMap">
        <trv-form-field label="Namn:" [labelGridColumns]="3" [control]="name">
            <input type="text" class="form-control" placeholder="Vynamn" [formControl]="name" />
        </trv-form-field>
        <trv-form-field label="Lager:" [labelGridColumns]="3" [control]="saveBackground" [controlPadding]="true">
            <div class="form-check custom-checkbox">
                <input type="checkbox" id="simpleCheck1" class="form-check-input" [formControl]="saveBackground" [value]="1" checked="true" />
                <label style="width: 15rem" class="form-check-label" for="simpleCheck1">Spara nuvarande bakgrundsbild</label>
            </div>
            <div class="form-check custom-checkbox">
                <input type="checkbox" id="simpleCheck2" class="form-check-input" [formControl]="saveDatalayer" [value]="1" checked="true" />
                <label style="width: 15rem" class="form-check-label" for="simpleCheck2">Spara nuvarande datalager</label>
            </div>
        </trv-form-field>
    </trv-form>
    <button class="btn btn-primary save_button" (click)="submit()">Spara vy</button>
</div>

import {Injectable} from "@angular/core";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {NavigationState, NvdbNavigationService} from "./nvdb-navigation.service";
import {firstValueFrom} from "rxjs";
import {ActorTypesEnum} from '@_api/dataleverans/models/actor-types-enum'

@Injectable({
    providedIn: "root",
})
export class AuthenticationService {
    public userName: string = "";
    public trvUserName: string = "";

    public roles: string[] = [];
    private _currentRole: UserRole = "Dataleverantör";
    private _maxRole: UserRole = "Dataleverantör";

    public initialized = false;

    public ADMIN_ROLE: string = "NVDB-Datalev-Admin"
    public AJOURHALLARE_ROLE: string = "NVDB-Datalev-Ajourhallare"

    public authIsLoading:boolean = false;

    constructor(
        private oidcService: OidcSecurityService,
        private nvdbNavigationService: NvdbNavigationService,
    ) {
    }


    public async init() {
        if (this.initialized) return;

        const payload = await firstValueFrom(this.oidcService.getPayloadFromAccessToken());
        this.trvUserName = payload.sub
        if (typeof payload.role === 'string') {
            this.roles = [payload.role];
        } else if (Array.isArray(payload.role)) {
            this.roles = payload.role;
        }
        this.setInitRole();
        this.initReportAs();

        /*console.log(this.roles)
        console.log(this.maxRole)
        console.log(this.isCurrentMinAdmin)

        console.log(this.nvdbNavigationService.reportAsList)*/

        const userData = await firstValueFrom(this.oidcService.getUserData());
        //this.userName = userData.upn;
        this.userName = payload.email;
        this.initialized = true
    }

    public initReportAs() {
        if (this.isMinAjourhallare) {
            this.nvdbNavigationService.reportAsList = [
                {
                    id: ActorTypesEnum.Kommun,
                    name: "Kommun",
                },
                {
                    id: ActorTypesEnum.Skogsnaring,
                    name: "Skogsnäring",
                },
                {
                    id: ActorTypesEnum.Lantmateriet,
                    name: "Lantmäteriet",
                },
                {
                    id: ActorTypesEnum.Lanstyrelsen,
                    name: "Länsstyrelsen",
                },
                {
                    id: ActorTypesEnum.Trafikverket,
                    name: "Trafikverket",
                },
            ];
        } else {
            this.nvdbNavigationService.reportAsList = [];

            if (this.roles.includes("NVDB-Datalev-Dataleverantor-Kommun")) {
                this.nvdbNavigationService.reportAsList.push({
                    id: ActorTypesEnum.Kommun,
                    name: "Kommun",
                });
            }

            if (this.roles.includes("NVDB-Datalev-Dataleverantor-Skogsnaring")) {
                this.nvdbNavigationService.reportAsList.push({
                    id: ActorTypesEnum.Skogsnaring,
                    name: "Skogsnäring",
                });
            }

            if (this.roles.includes("NVDB-Datalev-Dataleverantor-Lantmateriet")) {
                this.nvdbNavigationService.reportAsList.push({
                    id: ActorTypesEnum.Lantmateriet,
                    name: "Lantmäteriet",
                });
            }


            if (this.roles.includes("NVDB-Datalev-Dataleverantor-Lanstyrelsen")) {
                this.nvdbNavigationService.reportAsList.push({
                    id: ActorTypesEnum.Lanstyrelsen,
                    name: "Länsstyrelsen",
                });
            }

            if (this.roles.includes("NVDB-Datalev-Trafikverket")) {
                this.nvdbNavigationService.reportAsList.push({
                    id: ActorTypesEnum.Trafikverket,
                    name: "Trafikverket",
                });
            }
        }

        const reportAsTrafikverket = this.nvdbNavigationService.reportAsList.find(a => a.id === ActorTypesEnum.Trafikverket)

        if (reportAsTrafikverket)
            this.nvdbNavigationService.reportAsForm!.setValue(reportAsTrafikverket);
        else if (this.nvdbNavigationService.reportAsList[0])
            this.nvdbNavigationService.reportAsForm!.setValue(this.nvdbNavigationService.reportAsList[0]);
    }

    private setInitRole() {

        for (const role of this.roles) {
            if (role === "NVDB-Datalev-Ajourhallare") {
                this._currentRole = "Ajourhållare";
                this._maxRole = "Ajourhållare";
            }
            if (role === "NVDB-Datalev-Admin") {
                this._currentRole = "Admin";
                this._maxRole = "Admin";
                break;
            }
        }
    }

    public get currentRole() {
        return this._currentRole;
    }

    public get maxRole() {
        return this._maxRole;
    }

    public get isCurrentMinAjourhallare() {
        return !(this.currentRole === "Dataleverantör");
    }

    public get isCurrentMinAdmin() {
        return this.currentRole === "Admin";
    }

    public get isMinAjourhallare() {
        return this.maxRole == "Ajourhållare" || this.maxRole == "Admin";
    }

    public get isMinAdmin() {
        return this.maxRole == "Admin";
    }

    /**
     * Can only be used of your maxRole is Admin
     */
    public setCurrentRole(role: UserRole) {
        if (this.maxRole == "Admin") {
            this._currentRole = role;
            if (role != "Admin") {
                if (this.nvdbNavigationService.navigationState == NavigationState.Admin) {
                    this.nvdbNavigationService.navigationState = NavigationState.MainMenu
                    this.nvdbNavigationService.navigationStateChanged.emit()
                }
            }
        }
    }
}

export type UserRole = "Admin" | "Ajourhållare" | "Dataleverantör";

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'mapIncludesKeyPipe',
    standalone: true
})
export class MapIncludesKeyPipe implements PipeTransform {
    transform(args: { key: any; map: any }): boolean {
        return args.map.hasOwnProperty(args.key)
    }
}

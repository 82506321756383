<div class="c-popover-options">
    <button class="c-popover-options__btn" (click)="zoomTo()"><fa-icon [icon]="faMagnifyingGlassPlus"></fa-icon> Zooma till</button>

    @if(nvdbNavigationService.VIEWMODE){
        <button class="c-popover-options__btn" (click)="edit()"><fa-icon [icon]="faEdit"></fa-icon>Visa</button>
    } @else if(!nvdbNavigationService.VIEWMODE){
        <button class="c-popover-options__btn" (click)="edit()"><fa-icon [icon]="faEdit"></fa-icon> Redigera</button>
        <button class="c-popover-options__btn" (click)="remove()"><fa-icon [icon]="faTrashCan"></fa-icon> Ta bort</button>
    }
</div>

<main>
    <div class="p-page">
        <div class="container">
            <h1>Statistik - beta</h1>
            <p class="preamble">Här kan du läsa statistik om ärenden i NVDB dataleverans</p>
            <div class="bg-white p-2 mb-4 d-flex gap-4 rounded border">
                <form [formGroup]="statsService.datesForm!">
                    <trv-date-range
                        formControlName="dateRangeMinMax"
                        [showToIsInfinite]="true"
                    ></trv-date-range>
                </form>
            </div>
            <div class="d-flex gap-4 mb-4">
                <div style="width: 50%">
                    <app-chart-container>
                        <app-num-reports-chart></app-num-reports-chart>
                    </app-chart-container>
                </div>
                <div style="width: 50%">
                    <app-chart-container>
                        <app-num-reports-over-time-chart></app-num-reports-over-time-chart>
                    </app-chart-container>
                </div>
            </div>
            <div class="d-flex gap-4 mb-4">
                <div style="width: 50%">
                    <app-chart-container>
                        <app-num-reports-per-source-chart></app-num-reports-per-source-chart>
                    </app-chart-container>
                </div>
                <div style="width: 50%">
                    
                </div>
            </div>
        </div>
    </div>
</main>

export function focusOnFirstInvalidControl() {
    const invalidControl = document.querySelectorAll("trv-select.ng-invalid, textarea.ng-invalid, trv-date-picker.ng-invalid");
    if (invalidControl.length > 0) {
        // om det är en select måste vi fokusera button inuti
        const trvSelectButton = invalidControl[0].getElementsByClassName("trv-select-button")[0] as HTMLElement;
        const datePickerButton = invalidControl[0].getElementsByClassName("date-input")[0] as HTMLElement;

        if (trvSelectButton) trvSelectButton.focus();
        else if (datePickerButton) datePickerButton.focus();
        else {
            (invalidControl[0] as HTMLElement).focus();
        }
    }
}

export function isStringValidInteger(stringToTest: string|null){
    if(!stringToTest) return false
    return /^\d+$/.test(stringToTest);
}


export function isStringValidGuid(stringToTest: string|null){
    if(!stringToTest) return false
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(stringToTest);
}

export function isStringValidFloat(stringToTest: string|null){
    if(!stringToTest) return false
    return /^[\d\.\-]+$/.test(stringToTest);
}

import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { ReportStatusesEnum } from "@app/_api/dataleverans/models";
import { NvdbNavigationService } from "@app/service/nvdb-navigation.service";
import { ReportService } from "@app/service/report.service";
import { StatsService } from "@app/service/stats.service";
import { ChartConfiguration, ChartData, ChartType, ChartEvent } from "chart.js";
import { BaseChartDirective } from "ng2-charts";

@Component({
    selector: 'app-num-reports-chart',
    standalone: true,
    imports: [BaseChartDirective],
    templateUrl: './num-reports-chart.component.html',
    styleUrl: './num-reports-chart.component.scss',
})
export class NumReportsChartComponent {
    @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

    public reportChartData: Array<number> = [0, 0, 0];
    public reportLabelMFL: Array<any> = [{ data: this.reportChartData, label: "Hej" }];

    constructor(
        public nvdbNavigation: NvdbNavigationService,
        public reportService: ReportService,
        public statsService: StatsService,
        private cdref: ChangeDetectorRef,

    ) {

        this.statsService.statsUpdated.subscribe(() => {
            this.chart?.update();
        })

        

    }

}


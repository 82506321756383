@if (navigationService.VIEWMODE) {
    <!-- Hade problem att använda ng-template med drag and drop så behövde tyvärr duplicera koden-->
    <div class='c-report__list__status__header'>
        <div class="d-flex justify-content-between">
            <div>
                Visar följande {{ navigationService.reportsToDisplayInViewMode.length }} ärenden:
            </div>
        </div>
    </div>
    <div class='c-report__list__status__wrapper'>
        @for (reportGroupItem of navigationService.reportsToDisplayInViewMode; track reportGroupItem.id) {
            <div>
                <div>
                    <div class='c-report__list__group__header'
                         [ngStyle]="reportContainsItemId(reportGroupItem) ? {background:'#cdd0d3'} : null"
                         [ngClass]='{ open: openedReports.includes(reportGroupItem.id) }'>
                        <div class='c-report__list__group__header__iconName'
                             (click)='toggleReportOpen(reportGroupItem.id)'>
                            @if (openedReports.includes(reportGroupItem.id)) {
                                <app-svg-icon class='chevron_report' [svgIcon]='upIcon'></app-svg-icon>
                            }
                            @if (!openedReports.includes(reportGroupItem.id)) {
                                <app-svg-icon class='chevron_report' [svgIcon]='downIcon'></app-svg-icon>
                            }
                            <div>
                                <div class='c-report__list__group__header__name no-select'>
                                    <b>Namn:</b> {{ reportGroupItem.name }}
                                </div>
                                <div class='c-report__list__group__header__name no-select'>
                                    <b>Ärende id:</b> {{ reportGroupItem.id }}
                                </div>
                                <div class='c-report__list__group__header__name no-select'>
                                    <b>Inskickare:</b> {{ reportGroupItem.owner ?? "Extern användare" }}
                                </div>
                                <div class='c-report__list__group__header__name no-select'>
                                    <b>Antal förändringar:</b> {{ reportGroupItem.reportItems.length }}
                                </div>
                            </div>

                        </div>
                        <div (click)='reportService.toggleReportVisible(reportGroupItem.id)'
                             class='c-report__list__report__show'
                             [ngClass]='{ hidden: (({ report: reportGroupItem, itemIds: reportService.hiddenReportItems} | allReportItemIdsInList) && reportGroupItem.reportItems.length > 0) }'>
                            @if (!(({
                                report: reportGroupItem,
                                itemIds: reportService.hiddenReportItems
                            } | allReportItemIdsInList) && reportGroupItem.reportItems.length > 0)) {
                                <fa-icon [icon]='faEye'></fa-icon>
                            } @else {
                                <fa-icon [icon]='faEyeSlash'></fa-icon>
                            }
                        </div>
                        <button class='btn btn-link c-report__list__group__header__menu'
                                [trvPopover]='editGroupComponent' [position]='popoverPosition'
                                (shown)='onShowEditComponent($event, reportGroupItem)'
                                aria-label="Ärende alternativ"
                        >
                            <fa-icon [icon]='faEllipsisV'></fa-icon>
                        </button>
                    </div>
                </div>
                @if (openedReports.includes(reportGroupItem.id)) {
                    <div class='c-report__list__group__body' @trvCollapseVoidAnimation>
                        <div>
                            <div class='mt-3'>
                                <table class='info'>
                                    <tr>
                                        <th>Id</th>
                                        <td>{{ reportGroupItem.id }}</td>
                                    </tr>
                                    <tr>
                                        <th>UID</th>
                                        <td>{{ reportGroupItem.uid }}</td>
                                    </tr>
                                    <tr>
                                        <th>Ärende</th>
                                        <td>{{ reportGroupItem.name }}</td>
                                    </tr>
                                    <tr>
                                        <th>Status</th>
                                        <td> {{
                                                {
                                                    keyValue: reportGroupItem.status,
                                                    keyPropertyName: 'statusEnum',
                                                    valuePropertyName: 'value',
                                                    objectList: FORMATTED_REPORT_STATUS
                                                } | valueFromObjectList
                                            }}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>Ändrad</th>
                                        <td>{{ reportGroupItem.lastChanged?.toFormat('yyyy-MM-dd HH:mm:ss') }}</td>
                                    </tr>

                                    @if (reportGroupItem.owner && reportGroupItem.owner != "") {
                                        <tr>
                                            <th>Ägare</th>
                                            <td>{{ reportGroupItem.owner }}</td>
                                        </tr>
                                        <tr>
                                            <th>Email</th>
                                            <td>{{ reportGroupItem.email }}</td>
                                        </tr>
                                    } @else {
                                        <tr>
                                            <th>Inskickare</th>
                                            <td>{{ reportGroupItem.author }}</td>
                                        </tr>
                                        <tr>
                                            <th>Email</th>
                                            <td>{{ reportGroupItem.email }}</td>
                                        </tr>
                                        <tr>
                                            <th>Telefon</th>
                                            <td>{{ reportGroupItem.phoneNumber }}</td>
                                        </tr>
                                    }
                                </table>
                            </div>

                            <button type='button' class='btn btn-primary m-3 mt-2'
                                    (click)='goToViewModeReport(reportGroupItem.id, reportGroupItem.uid)'
                            >
                                Gå till ärendesida
                            </button>

                            @for (reportItem of reportGroupItem.reportItems; track reportItem; let indx = $index) {
                                <div>
                                    <app-report-tab-item [disableDragAndDrop]="true" [reportItem]='reportItem'
                                                         [sequenceNumber]="indx+1"></app-report-tab-item>
                                </div>
                            } @empty {
                                <div class='px-3 py-2'>Ärendet har inga tillagda
                                    förändringar
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        } @empty {
            <div>
                <div class='p-3'>
                    Du har inga skapade ärenden.
                </div>
            </div>
        }
    </div>
} @else {
    <div>
        <div class='report-main-title'>Ärenden</div>
        <div class='report_list_header'>
            <div class='form-check custom-checkbox offset'>
                <input type='checkbox' class='form-check-input' id='allCheckbox'
                       [indeterminate]='selectedReportIds.length > 0 && !allCheckboxChecked'
                       [checked]='allCheckboxChecked'
                       (click)='allRowsCheckboxClick($event)'/>
                <label class='form-check-label' for='allCheckbox'>
                    Välj {{ selectedReportIds.length > 0 ? '(' + selectedReportIds.length + ')' : ' alla' }}
                </label>
            </div>
            <div class='report_list_header_btns'>
                <button type='button' class='btn btn-primary btn-sm' (click)='removeReports()'
                        [disabled]='selectedReportIds.length === 0' trvTooltip='Ta bort valda ärenden'
                        [position]='toolTipPositions'>
                    Ta bort
                </button>

                <button type='button' class='btn btn-primary btn-sm' (click)='openSendReports()'
                        [disabled]='selectedReportIds.length === 0' trvTooltip='Skicka in valda ärenden'
                        [position]='toolTipPositions'>
                    Skicka in
                </button>

                <button class='btn btn-primary btn-sm last' [trvPopover]='newGroupComponent'
                        [position]='popoverPosition'
                        aria-label="Skapa nytt ärende"
                        (shown)='onShowCreateComponent($event)'>
                    <span trvTooltip='Skapa nytt ärende' [position]='toolTipPositions'>Skapa</span>
                </button>
            </div>
        </div>

        <!-- Hade problem att använda ng-template med drag and drop så behövde tyvärr duplicera koden-->
        <div
            cdkDropListGroup
            [cdkDropListGroupDisabled]="navigationService.isMobileDevice"
        >
            <div class='c-report__list__status__header'>
                Ej inskickade: ({{
                    ({
                        reports: reportService.reports,
                        statusesToInclude: [ReportStatusesEnum.Draft]
                    } | reportStatus).length
                }})
            </div>
            <div class='c-report__list__status__wrapper'>
                @for (reportGroupItem of {
                    reports: reportService.reports,
                    statusesToInclude: [ReportStatusesEnum.Draft]
                } | reportStatus; track reportGroupItem.id) {
                    <div>
                        <div [cdkDropListData]='reportGroupItem.reportItems' cdkDropList
                             (cdkDropListDropped)='drop($event, reportGroupItem)'
                             [attr.data-listId]='reportGroupItem.id'>
                            <div class='c-report__list__group__header'
                                 [ngClass]='{ open: openedReports.includes(reportGroupItem.id) }'>
                                <div class='c-report__list__group__header__iconName'
                                     (click)='toggleReportOpen(reportGroupItem.id)'>
                                    <div class='form-check trv-prevent-table-row-click'
                                         (click)='checkboxClicked(reportGroupItem.id, $event)'>
                                        <input type='checkbox' class='form-check-input' #checkbox
                                               [id]='reportGroupItem.id'
                                               [checked]='selectedReportIds.includes(reportGroupItem.id)'
                                               aria-label="Markera ärende"
                                        />
                                    </div>
                                    @if (openedReports.includes(reportGroupItem.id)) {
                                        <app-svg-icon class='chevron_report' [svgIcon]='upIcon'></app-svg-icon>
                                    }
                                    @if (!openedReports.includes(reportGroupItem.id)) {
                                        <app-svg-icon class='chevron_report' [svgIcon]='downIcon'></app-svg-icon>
                                    }
                                    <span class='c-report__list__group__header__name no-select'>
                                {{ reportGroupItem.name }}
                                        <b>({{ reportGroupItem.reportItems.length }})</b>
                            </span>
                                </div>
                                <div (click)='reportService.toggleReportVisible(reportGroupItem.id)'
                                     class='c-report__list__report__show'
                                     [ngClass]='{ hidden: (({ report: reportGroupItem, itemIds: reportService.hiddenReportItems} | allReportItemIdsInList) && reportGroupItem.reportItems.length > 0) }'>
                                    @if (!(({
                                        report: reportGroupItem,
                                        itemIds: reportService.hiddenReportItems
                                    } | allReportItemIdsInList) && reportGroupItem.reportItems.length > 0)) {
                                        <fa-icon [icon]='faEye'></fa-icon>
                                    } @else {
                                        <fa-icon [icon]='faEyeSlash'></fa-icon>
                                    }
                                </div>
                                <button class='btn btn-link c-report__list__group__header__menu'
                                        [trvPopover]='editGroupComponent' [position]='popoverPosition'
                                        (shown)='onShowEditComponent($event, reportGroupItem)'
                                        aria-label="Ärende alternativ"
                                >
                                    <fa-icon [icon]='faEllipsisV'></fa-icon>
                                </button>
                            </div>
                        </div>
                        @if (openedReports.includes(reportGroupItem.id)) {
                            <div class='c-report__list__group__body' @trvCollapseVoidAnimation>
                                <div [cdkDropListData]='reportGroupItem.reportItems' cdkDropList
                                     (cdkDropListDropped)='drop($event, reportGroupItem)'
                                     [attr.data-listId]='reportGroupItem.id'>
                                    @for (reportItem of reportGroupItem.reportItems; track reportItem; let indx = $index) {
                                        <div cdkDrag [cdkDragData]='{ item: reportItem, index: indx }'>
                                            <div class='example-custom-placeholder' *cdkDragPlaceholder></div>
                                            <app-report-tab-item
                                                [disableDragAndDrop]="navigationService.isMobileDevice"
                                                [reportItem]='reportItem'
                                                [sequenceNumber]="indx+1"></app-report-tab-item>
                                        </div>
                                    }
                                    @if (reportGroupItem.reportItems.length <= 0) {
                                        <div class='px-3 py-2'>Ärendet har inga tillagda
                                            förändringar
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                } @empty {
                    <div>
                        <div class='p-3'>
                            Du har inga skapade ärenden.
                        </div>
                    </div>
                }
            </div>

            @if (({
                reports: reportService.reports,
                statusesToInclude: [ReportStatusesEnum.OpenForClarification]
            } | reportStatus).length > 0) {
                <div class='c-report__list__status__header'>
                    Öppet för komplettering: ({{
                        ({
                            reports: reportService.reports,
                            statusesToInclude: [ReportStatusesEnum.OpenForClarification]
                        } | reportStatus).length
                    }})
                </div>
                <div class='c-report__list__status__wrapper'>


                    @for (reportGroupItem of {
                        reports: reportService.reports,
                        statusesToInclude: [ReportStatusesEnum.OpenForClarification]
                    } | reportStatus; track reportGroupItem.id) {
                        <div>
                            <div [cdkDropListData]='reportGroupItem.reportItems' cdkDropList
                                 (cdkDropListDropped)='drop($event, reportGroupItem)'
                                 [attr.data-listId]='reportGroupItem.id'>
                                <div class='c-report__list__group__header'
                                     [ngClass]='{ open: openedReports.includes(reportGroupItem.id) }'>
                                    <div class='c-report__list__group__header__iconName'
                                         (click)='toggleReportOpen(reportGroupItem.id)'>
                                        <div class='form-check trv-prevent-table-row-click'
                                             (click)='checkboxClicked(reportGroupItem.id, $event)'>
                                            <input type='checkbox' class='form-check-input'
                                                   style='cursor: pointer'
                                                   [id]='reportGroupItem.id'
                                                   [checked]='selectedReportIds.includes(reportGroupItem.id)'/>
                                            <label class='form-check-label'></label>
                                        </div>
                                        @if (openedReports.includes(reportGroupItem.id)) {
                                            <app-svg-icon class='chevron_report' [svgIcon]='upIcon'></app-svg-icon>
                                        } @else if (!openedReports.includes(reportGroupItem.id)) {
                                            <app-svg-icon class='chevron_report' [svgIcon]='downIcon'></app-svg-icon>
                                        }
                                        <span class='c-report__list__group__header__name no-select'>
                                {{ reportGroupItem.name }}
                                            <b>({{ reportGroupItem.reportItems.length }})</b>
                            </span>
                                    </div>
                                    <div (click)='reportService.toggleReportVisible(reportGroupItem.id)'
                                         class='c-report__list__report__show'
                                         [ngClass]='{ hidden: (({ report: reportGroupItem, itemIds: reportService.hiddenReportItems} | allReportItemIdsInList) && reportGroupItem.reportItems.length > 0) }'>
                                        @if (!(({
                                            report: reportGroupItem,
                                            itemIds: reportService.hiddenReportItems
                                        } | allReportItemIdsInList) && reportGroupItem.reportItems.length > 0)) {
                                            <fa-icon [icon]='faEye'></fa-icon>
                                        } @else {
                                            <fa-icon [icon]='faEyeSlash'></fa-icon>
                                        }
                                    </div>
                                    <button class='btn btn-link c-report__list__group__header__menu'
                                            [trvPopover]='editGroupComponent' [position]='popoverPosition'
                                            aria-label="Ärende alternativ"
                                            (shown)='onShowEditComponent($event, reportGroupItem)'>
                                        <fa-icon [icon]='faEllipsisV'></fa-icon>
                                    </button>
                                </div>
                            </div>
                            @if (openedReports.includes(reportGroupItem.id)) {
                                <div class='c-report__list__group__body' @trvCollapseVoidAnimation>
                                    <div [cdkDropListData]='reportGroupItem.reportItems' cdkDropList
                                         (cdkDropListDropped)='drop($event, reportGroupItem)'
                                         [attr.data-listId]='reportGroupItem.id'>
                                        @for (reportItem of reportGroupItem.reportItems; track reportItem; let indx = $index) {
                                            <div cdkDrag [cdkDragData]='{ item: reportItem, index: indx }'>
                                                <div class='example-custom-placeholder' *cdkDragPlaceholder></div>
                                                <app-report-tab-item
                                                    [disableDragAndDrop]="navigationService.isMobileDevice"
                                                    [reportItem]='reportItem'
                                                    [sequenceNumber]="indx+1"></app-report-tab-item>
                                            </div>
                                        }
                                        @if (reportGroupItem.reportItems.length <= 0) {
                                            <div class='px-3 py-2'>Ärendet har inga tillagda
                                                förändringar
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            }
        </div>
    </div>

}

import { Component, Inject, OnInit } from "@angular/core";
import { LayerListItem, TrvMap, TrvNgMapService } from "@trafikverket/trv-ng-map";
import { trvCollapseVoidAnimation, trvFadeAnimation } from "trv-ng-common";
import { NvdbNavigationService } from "src/app/service/nvdb-navigation.service";
import { SvgIcon } from "src/app/shared/utils/svg-helper";
import { svgChevronDown, svgChevronUp, svgCross } from "src/app/shared/utils/svg-helper-data";
import LayerGroup from "ol/layer/Group";
import { environment } from "src/environments/environment";
import { SvgIconComponent } from "../../utils/svg-icon/svg-icon.component";
import { NgStyle } from "@angular/common";

@Component({
    selector: "app-map-label",
    templateUrl: "./map-label.component.html",
    styleUrls: ["./map-label.component.scss"],
    animations: [trvFadeAnimation],
    standalone: true,
    imports: [
    NgStyle,
    SvgIconComponent
],
})
export class MapLabelComponent {
    public downIcon: SvgIcon = svgChevronDown;
    public upIcon: SvgIcon = svgChevronUp;

    constructor(
        @Inject(TrvNgMapService) public trvMapService: TrvNgMapService,
        @Inject(NvdbNavigationService)
        public nvdbNavigation: NvdbNavigationService
    ) {}

    public getLegendGraphics(layer: LayerListItem) {
        if (layer.legendGraphics) {
            //return environment.baseApiUrl + "/MapProxy/mapProxyLegend/" + encodeURIComponent(layer.legendGraphics);
            return layer.legendGraphics;
        }
        return "";
    }
}

import {
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from "@angular/core";
import {FormArray, FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {
    DateRange, TrvDateRangeComponent,
    TrvFilterSummaryComponent,
    TrvGlobalMessagesService,
    TrvLoadingBundle,
    TrvLoadingDirective,
    TrvModalService,
    TrvPaginationComponent,
    TrvSelectComponent,
    TrvTooltipDirective,
} from "trv-ng-common";
import {Report, ReportItem, ReportService} from "../../../service/report.service";
import {
    faArrowsRotate,
    faChevronDown,
    faChevronUp,
    faDrawPolygon,
    faEllipsisV,
    faK, faLocationPin,
    faMapMarkerAlt,
    faRoute,
    faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
    svgArrowDown,
    svgArrowUp,
    svgCheck,
    svgCross,
    svgInfo,
    svgPencil,
    svgTrash
} from "../../../shared/utils/svg-helper-data";
import {filter} from "rxjs/operators";
import {BehaviorSubject, Subject} from "rxjs";
import {DeliveryTypeRead, ReportStatusesEnum} from "src/app/_api/dataleverans/models";
import {externUserFormattedText, FORMATTED_REPORT_STATUS} from "../../../shared/constants";
import {ReportItemComponent} from "@components/reports/report-item/report-item.component";

import _downscaled from "../../../../assets/data/layer-data/nvdb-layers-downscaled.json";
import {TrvNgMapComponent, TrvNgMapService} from "@trafikverket/trv-ng-map";
import {NvdbReportStyle, NvdbReportStyleHover} from "@app/trv-map-extension/trv-map-extension-styles";
import {createEmpty, extend} from "ol/extent";
import {ValueFromObjectListPipe} from "@pipes/value-from-object-list.pipe";
import {AllItemsInListPipe} from "@pipes/item-in-list.pipe";
import {ReportItemComponent as ReportItemComponent_1} from "../report-item/report-item.component";
import {Router, RouterLink} from "@angular/router";
import {SvgIconComponent} from "../../utils/svg-icon/svg-icon.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {DatePipe, NgClass, SlicePipe} from "@angular/common";
import {GeometryType} from "@app/shared/enums";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {
    ReportItemExpandableHeaderComponent
} from "../report-item-expandable-header/report-item-expandable-header.component";
import {groupExpand, showErrorMessage} from "@shared/animations";
import Feature from "ol/Feature";
import {DateTime} from "luxon";
import {NvdbNavigationService, TableState} from "@app/service/nvdb-navigation.service";
import {ReportStatusPipe} from "@pipes/report-status.pipe";
import {A11yModule} from "@angular/cdk/a11y";
import {AutofocusDirective} from "@app/directives/autofocus.directive";
import {environment} from "src/environments/environment";

@Component({
    selector: "app-reports-table",
    templateUrl: "./reports-table.component.html",
    styleUrls: ["./reports-table.component.scss"],
    animations: [groupExpand, showErrorMessage],
    standalone: true,
    imports: [
        TrvLoadingBundle,
        ReactiveFormsModule,
        FontAwesomeModule,
        TrvSelectComponent,
        TrvFilterSummaryComponent,
        SvgIconComponent,
        NgClass,
        RouterLink,
        TrvTooltipDirective,
        ReportItemComponent_1,
        TrvPaginationComponent,
        SlicePipe,
        DatePipe,
        AllItemsInListPipe,
        ValueFromObjectListPipe,
        TrvNgMapComponent,
        ReportItemExpandableHeaderComponent,
        ReportStatusPipe,
        A11yModule,
        AutofocusDirective,
        TrvLoadingDirective,
        TrvDateRangeComponent,
    ],
})
export class ReportsTableComponent implements OnInit, OnChanges {

    @Input() reportGroupIds: number[] = [];

    // wether or not to show the reports of the current user or all users
    @Input() showUsersReportsElseAll: boolean = true;
    // should add a few more fields and buttons if ajourhållare
    @Input() ajourhallare: boolean = false;
    // hide some buttons in the send report table view
    @Input() isSendReportTable: boolean = false;

    public layerdata: any = _downscaled;

    public featureLookup: { [reportItemId: number]: Feature } = {};

    loading = false;

    allReports: Report[] = [];
    filteredReportGroupIds: number[] = [];
    filteredReportItemIds: number[] = [];
    sortedReportGroupIds: number[] = [];

    loadingIndividualItems: number[] = [];

    currentPage = new BehaviorSubject<number>(1);
    pageSize = 10;

    // TODO the filters are kinda sketchy... we have to handle how they should open automatically...
    // if you filter on reportitems all groups should open automatically. However you should still be able to close them,
    // so we have to overwrite that
    allGroupsOpened = false;
    openedReportGroups: number[] = [];
    openedReportItems: number[] = [];

    editableReportGroups: number[] = [];
    editableReportItems: number[] = [];

    sortedColumnProperty: keyof Report | "itemCount" | null = "id";
    sortedColumnDirection: SortDirection = SortDirection.desc;

    public reportsToDisplay: Report[] = [];

    public deliveryTypes!: DeliveryTypeRead[];

    filterForm = new FormGroup<FilterForm>({
        id: new FormControl<string>(""),
        name: new FormControl<string>(""),
        username: new FormControl<string>(""),
        description: new FormControl<string>(""),
        changedDate: new FormControl<DateRange>({
            from: undefined,
            to: undefined,
            toIsInfinite: false
        }, {nonNullable: true}),
        // use the formattedStatusEnum table as source
        status: new FormControl<{ statusEnum: number; value: string }[]>([], {nonNullable: true}),

    });

    protected readonly GeometryType = GeometryType;
    protected readonly externUserFormattedText = externUserFormattedText;

    private ajourhallareAndClosedIncluded = false;

    constructor(
        private nvdbNavigationService: NvdbNavigationService,
        private router: Router,
        @Inject(TrvModalService) public trvModalService: TrvModalService,
        public cdref: ChangeDetectorRef,
        @Inject(ReportService) public reportService: ReportService,
        private globalMessagesService: TrvGlobalMessagesService,
        @Inject(TrvNgMapService) private trvMapService: TrvNgMapService
    ) {
        this.layerdata.BackgroundLayer.LayerList.forEach((layer: any) => {
            if (layer.hasOwnProperty("Url")) {
                let url: string = layer.Url;
                layer.Url = url.replace("{api}", environment.baseApiUrl);
            }
        });
    }

    // sometimes we want to set the state directly without triggering all subscriptions
    valueChangeEnabled = true;

    ngOnInit() {
        this.updateAllAvailableReports();
        this.reportService.reportsChanged.subscribe(() => {
            this.updateAllAvailableReports();
        });

        this.deliveryTypes = this.reportService.allDeliveryTypes;
        this.filterForm.valueChanges.subscribe(filterValues => {
            this.updateFilteredReportGroups();
            this.allGroupsOpened = filterValues.description != "" && filterValues.description != null;
            if (this.valueChangeEnabled) this.currentPage.next(1);
            this.cdref.detectChanges();
        });

        this.filterForm.controls.status.valueChanges.subscribe(async value => {
            const changeAddedIncludeOthersClosed =
                !this.ajourhallareAndClosedIncluded && this.ajourhallare && (value.some(a => a.statusEnum == ReportStatusesEnum.Closed) || value.length == 0);

            if (changeAddedIncludeOthersClosed) {
                this.ajourhallareAndClosedIncluded = true;
                this.loading = true;
                await this.reportService.refreshReports(true);
                this.loading = false;
            } else {
                this.ajourhallareAndClosedIncluded = false;
            }
        });

        this.currentPage.subscribe(_ => {
            if (!this.valueChangeEnabled || !this.sortedReportGroupIds) return;

            // close all groups and items that isnt shown
            const currentlyDisplayedReports = this.sortedReportGroupIds.slice(
                (this.currentPage.value - 1) * this.pageSize,
                this.currentPage.value * this.pageSize
            );
            this.openedReportItems = this.openedReportItems.filter(a => currentlyDisplayedReports.includes(a));
            this.openedReportGroups = this.openedReportGroups.filter(a => currentlyDisplayedReports.includes(a));

            this.updateReportsToDisplay();
        });
    }

    public async refreshReportList() {
        this.loading = true;
        await this.reportService.refreshReports(this.ajourhallareAndClosedIncluded);
        this.loading = false;
    }

    public updateAllAvailableReports() {
        this.allReports = this.showUsersReportsElseAll
            ? this.reportService.reports.filter(a => this.reportGroupIds!.includes(a.id))
            : [...this.reportService.reports, ...this.reportService.otherUsersReports].filter(a => this.reportGroupIds!.includes(a.id));

        this.updateFilteredReportGroups();
    }

    ngOnChanges(changes: SimpleChanges) {
        // Check if the 'vectorLayer' input has changed
        if (changes["reportGroupIds"] && this.allReports.length == 0) {
            this.updateAllAvailableReports();
            this.updateFilteredReportGroups();
        }
    }

    updateFilteredReportGroups() {
        let filteredItemIds: number[] = [];
        this.filteredReportGroupIds = this.allReports
            .filter(group => {
                filteredItemIds = filteredItemIds.concat(
                    group.reportItems.filter(item => this.filterFunc(item.description, this.filterForm.value.description)).map(item => item.id)
                );

                const includesIdFilter = this.filterFunc(group.id, this.filterForm.value.id);
                const includesNameFilter = this.filterFunc(group.name, this.filterForm.value.name);
                const includesUsernameFilter = this.filterFunc(group.owner ?? externUserFormattedText, this.filterForm.value.username);
                const includesStatusFilter = this.filterFunc(group.status, this.filterForm.value.status?.map(status => status.statusEnum.toString()));

                const secondsIn24Hours = 86400
                const fromDate = this.filterForm.value.changedDate?.from ? DateTime.fromISO(this.filterForm.value.changedDate.from).toUnixInteger() : 0
                const toDate = this.filterForm.value.changedDate?.to ? DateTime.fromISO(this.filterForm.value.changedDate.to).toUnixInteger() + secondsIn24Hours : 10_000_000_000

                const includesDateFilter = fromDate < group.lastChanged!.toUnixInteger() && group.lastChanged!.toUnixInteger() < toDate

                return includesIdFilter && includesNameFilter && includesUsernameFilter && includesStatusFilter && includesDateFilter;
            })
            .map(group => group.id);

        this.filteredReportItemIds = filteredItemIds;
        this.sortTable(this.sortedColumnProperty, this.sortedColumnDirection);
    }

    public toggleDropdownGroup(groupId: number): void {
        if (!this.openedReportGroups.includes(groupId)) {
            this.openedReportGroups = [...this.openedReportGroups, groupId];

            const onMapLoadedForId = this.trvMapService.onMapLoaded("map-report" + groupId);
            onMapLoadedForId.subscribe(() => {
                this.addFeaturesToMap(groupId);
            });
        } else {
            this.openedReportGroups = this.openedReportGroups.filter(id => id !== groupId);
        }
    }

    public addFeaturesToMap(groupId: number) {
        let report = this.reportService.reports.find(a => a.id == groupId);
        if (!report) {
            report = this.reportService.otherUsersReports.find(a => a.id == groupId);
        }

        if (report) {
            const trvMap = this.trvMapService.getTrvMap("map-report" + groupId);
            if (trvMap) {
                trvMap.trvLayer.workingLayer?.getSource()?.clear();
                let extent = createEmpty();
                let zoomIn = report.reportItems.length > 0;
                let onlyOnefeature = undefined;
                report.reportItems.forEach(item => {
                    const feature = trvMap.trvLayer.getFeatureFromWkt(item.wkt);
                    trvMap.trvLayer.workingLayer?.getSource()?.addFeature(feature);
                    feature.setStyle(NvdbReportStyle);
                    if (report?.reportItems.length == 1) {
                        onlyOnefeature = feature;
                    }
                    extend(extent, feature.getGeometry()?.getExtent()!);
                    this.featureLookup[item.id] = feature;
                });

                if (onlyOnefeature != undefined) trvMap.zoomInOnFeature(onlyOnefeature, 0, [40, 40, 40, 40]);
                else if (zoomIn) trvMap.zoomInOnExtent(extent, 0, [40, 40, 40, 40])
            }
        }
    }

    public toggleDropdownItem(itemId: number): void {
        if (!this.openedReportItems.includes(itemId)) {
            this.openedReportItems = [...this.openedReportItems, itemId];
        } else {
            this.openedReportItems = this.openedReportItems.filter(id => id !== itemId);
        }
    }

    public zoomToReportItem(groupId: number, reportItem: ReportItem) {
        console.log("zoom!")
        const trvMap = this.trvMapService.getTrvMap("map-report" + groupId);
        if (trvMap && this.featureLookup[reportItem.id]) {
            trvMap.zoomInOnFeature(this.featureLookup[reportItem.id], 1000, [40, 40, 40, 40]);
        }
    }

    public mouseEnterReportItem(item: ReportItem) {
        if (this.featureLookup[item.id]) {
            this.featureLookup[item.id].setStyle(NvdbReportStyleHover);
        }
    }

    public mouseLeaveReportItem(item: ReportItem) {
        if (this.featureLookup[item.id]) {
            this.featureLookup[item.id].setStyle(NvdbReportStyle);
        }
    }

    onFileChange(event: any, reportEntity: FormGroup<ReportGroupForm> | FormGroup<ReportItemForm>) {
        const files: File[] = Array.from(event.target.files);

        reportEntity.controls.files.setValue(files);
    }

    addFile(reportItemForm: FormGroup<ReportItemForm>, $event: any) {
        reportItemForm.patchValue({
            files: [...reportItemForm.controls.files.value, ...$event.target.files],
        });
    }

    removeFile(reportItemForm: FormGroup<ReportItemForm>, file: File) {
        const old = reportItemForm.controls.files.value;
        const newa = [...reportItemForm.controls.files.value.filter(itemFile => itemFile.name !== file.name)];
        reportItemForm.patchValue({files: newa});
    }

    editGroup(group: Report, event: any) {
        event.stopPropagation();

        this.editableReportGroups = [...this.editableReportGroups, group.id];
    }

    async saveGroup(reportGroup: Report, event: any) {
        event.stopPropagation();

        const newName = this.reportService.pendingReportGroupNameChanges[reportGroup.id] ?? reportGroup.name;
        if (newName == "") {
            this.globalMessagesService.error("Ärendets namn får inte vara tomt.");
            return;
        }

        try {
            await this.reportService.createOrUpdateReport(newName, reportGroup.id);
            delete this.reportService.pendingReportGroupNameChanges[reportGroup.id];
            this.editableReportGroups = this.editableReportGroups.filter(id => id !== reportGroup.id);

            this.globalMessagesService.success("Ärendets namn uppdaterades.");
        } catch (e) {
            this.globalMessagesService.error("Ett fel uppstod när ärendets namn skulle uppdateras.");
        }
    }

    removeGroup(groupId: number, name: string, event: any) {
        event.stopPropagation();

        this.trvModalService
            .confirmDelete(`Bekräfta ta bort ärendet "${name}"`, "Alla tillagda förändringar kommer att tas bort.", "Ta bort", "Avbryt", {
                disposeOnBackdropClick: true,
            })
            .afterCloseWithType()
            .subscribe(async (event: any) => {
                if (event.closingEventType === "close") {
                    try {
                        await this.reportService.removeReportsById([groupId]);
                    } catch (e) {
                        this.globalMessagesService.error(`Ett fel uppstod när ärendet med namn "${name}" skulle tas bort.`);
                    }
                }
            });
    }

    cancelGroupEdit(reportGroup: Report, event: any) {
        event.stopPropagation();

        delete this.reportService.pendingReportGroupNameChanges[reportGroup.id];
        this.editableReportGroups = this.editableReportGroups.filter(id => id !== reportGroup.id);
        this.cdref.detectChanges();
    }

    editItem(itemId: number, event: any) {
        event.stopPropagation();

        this.editableReportItems = [...this.editableReportItems, itemId];
    }

    async saveItem(reportItemId: number, reportItemComponent: ReportItemComponent) {
        this.loadingIndividualItems.push(reportItemId);
        reportItemComponent.saveFileDescriptions(this.reportService.getItemById(reportItemId)!.form!.value.files!.map(a => a.idOrTempId))

        const successful = await reportItemComponent.save();
        this.loadingIndividualItems = this.loadingIndividualItems.filter(id => id != reportItemId);

        if (!successful) return;
        this.editableReportItems = this.editableReportItems.filter(item => item !== reportItemId);

        this.cdref.detectChanges();
    }

    async removeItem(groupId: number, itemId: number, itemDescription: string) {
        this.trvModalService
            .confirmDelete(
                `Bekräfta ta bort förändringen "${itemDescription}"`,
                "Är du säker att du vill ta bort förändringen?",
                "Ta bort",
                "Avbryt",
                {disposeOnBackdropClick: true}
            )
            .afterCloseWithType()
            .subscribe(async (event: any) => {
                if (event.closingEventType === "close") {
                    try {
                        await this.reportService.removeReportItemById(groupId, itemId);
                    } catch (e) {
                        this.globalMessagesService.error(`Ett fel uppstod när förändringen med beskrivning "${itemDescription}" skulle tas bort.`);
                    }
                }
            });

        this.cdref.detectChanges();
    }

    cancelEditItem(reportItemId: number) {
        this.reportService.resetItemForm(reportItemId);
        this.editableReportItems = this.editableReportItems.filter(itemId => itemId !== reportItemId);
    }

    showInMap() {
        console.log("TODO");
    }

    reportGroupChange(event: any, id: number) {
        const newValue = (event.target as HTMLInputElement).value;
        if (this.getReportGroup(id)?.name === newValue) {
            delete this.reportService.pendingReportGroupNameChanges[id];
        } else {
            this.reportService.pendingReportGroupNameChanges[id] = newValue;
        }
    }

    sortTable(property: keyof Report | "itemCount" | null, direction: SortDirection) {
        if (direction === SortDirection.none || property === null) {
            this.sortedReportGroupIds = [...this.filteredReportGroupIds];
        } else {
            this.sortedReportGroupIds = this.allReports
                .filter(group => this.filteredReportGroupIds.includes(group.id))
                .sort((a, b) => {
                    let valueA;
                    let valueB;

                    if (property === "status") {
                        valueA = this.FORMATTED_REPORT_STATUS.find(status => status.statusEnum === a.status)?.value;
                        valueB = this.FORMATTED_REPORT_STATUS.find(status => status.statusEnum === b.status)?.value;
                    } else if (property === "itemCount") {
                        valueA = a.reportItems.length;
                        valueB = b.reportItems.length;
                    } else {
                        valueA = a[property];
                        valueB = b[property];
                    }

                    if (typeof valueA === "number" || typeof valueB === "number") {
                        valueA = valueA ? valueA : -1;
                        valueB = valueB ? valueB : -1;
                    } else if (typeof valueA === "string" || typeof valueB === "string") {
                        valueA = valueA ? valueA : "";
                        valueB = valueB ? valueB : "";
                    } else if (valueA instanceof DateTime || valueB instanceof DateTime) {
                        valueA = valueA ? valueA : DateTime.fromMillis(0);
                        valueB = valueB ? valueB : DateTime.fromMillis(0);
                    } else if (!valueA && !valueB) {
                        return 1;
                    }

                    if (typeof valueA === "number" && typeof valueB === "number") {
                        return direction === SortDirection.asc ? valueA - valueB : valueB - valueA;
                    } else if (typeof valueA === "string" && typeof valueB === "string") {
                        return direction === SortDirection.asc ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
                    } else if (valueA instanceof DateTime && valueB instanceof DateTime) {
                        return direction === SortDirection.asc
                            ? valueA.toUnixInteger() - valueB.toUnixInteger()
                            : valueB.toUnixInteger() - valueA.toUnixInteger();
                    } else {
                        throw new Error("Invalid data type for sorting");
                    }
                })
                .map(group => group.id);
        }

        this.updateReportsToDisplay();
    }

    sortDirectionChange(propertyName: keyof Report | "itemCount") {
        let sortDirection = SortDirection.none;
        if (this.sortedColumnProperty != propertyName) {
            sortDirection = SortDirection.asc;
        } else if (this.sortedColumnDirection == SortDirection.asc) {
            sortDirection = SortDirection.desc;
        } else if (this.sortedColumnDirection == SortDirection.desc) {
            sortDirection = SortDirection.none;
        } else if (this.sortedColumnDirection == SortDirection.none) {
            sortDirection = SortDirection.asc;
        }

        this.sortedColumnProperty = propertyName;
        this.sortedColumnDirection = sortDirection;

        this.sortTable(this.sortedColumnProperty, this.sortedColumnDirection);
    }

    public updateReportsToDisplay() {
        this.reportsToDisplay = this.sortedReportGroupIds
            .slice((this.currentPage.value - 1) * this.pageSize, this.currentPage.value * this.pageSize)
            .map(a => ({...this.allReports.find(b => b.id == a)!}));
    }

    // UTILS
    protected readonly faSearch = faSearch;
    protected readonly svgTrash = svgTrash;
    protected readonly svgPencil = svgPencil;
    protected readonly svgInfo = svgInfo;
    protected readonly svgCross = svgCross;
    protected readonly svgCheck = svgCheck;
    protected readonly svgArrowUp = svgArrowUp;
    protected readonly svgArrowDown = svgArrowDown;

    protected readonly faMapMarkerAlt: IconProp = faMapMarkerAlt as IconProp;
    protected readonly faRoute: IconProp = faRoute as IconProp;
    protected readonly faDrawPolygon: IconProp = faDrawPolygon as IconProp;
    protected readonly faEllipsisV: IconProp = faEllipsisV as IconProp;

    protected readonly faChevronDown = faChevronDown;
    protected readonly faChevronUp = faChevronUp;
    protected readonly faArrowsRotate = faArrowsRotate;
    protected readonly faK = faK;
    protected readonly Object = Object;

    filterValues: any;

    ReportStatusesEnum = ReportStatusesEnum;
    ReportItem = ReportItem;
    SortDirection = SortDirection;

    FORMATTED_REPORT_STATUS = FORMATTED_REPORT_STATUS;

    private filterFunc(fieldValue: string | number | undefined | null, filterValue: string | string[] | undefined | null) {
        if (!filterValue || filterValue.length == 0) return true;

        const fieldMatchesFilter = (fieldValue: string | number | undefined | null, filterValue: string | undefined | null): boolean => {
            const regexPattern = (filterValue || "")
                .toString()
                .toLowerCase()
                .trim()
                .replace(/%/g, ".*") // % => .*
                .replace(/\*/g, ".*") // * => \w*
                .replace(/\?/g, "."); // ? => .

            const regex = new RegExp(regexPattern, "i");
            return regex.test(fieldValue?.toString().toLowerCase().trim() || "");
        };

        let matches = false;
        if (Array.isArray(filterValue)) {
            for (const string of filterValue) {
                if (fieldMatchesFilter(fieldValue, string)) {
                    matches = true;
                    break;
                }
            }
        } else {
            matches = fieldMatchesFilter(fieldValue, filterValue);
        }

        return matches;
    }

    groupHasChildrenWithChanges(reportGroup: Report) {
        return reportGroup.reportItems.some(item => this.reportService.reportItemsWithChanges.includes(item.id));
    }

    getReportGroup(reportGroupId: number) {
        return this.allReports?.find(group => group.id === reportGroupId);
    }

    public onSummaryChanged(): void {
        this.filterForm.setValue(this.filterValues);
    }

    public onClearFilter(): void {
        this.filterForm.reset();
    }

    displayDropdownValue = (object: any) => {
        return object.description ?? object.name ?? object.value;
    };

    async sendClarification(reportName: string, id: number, event: any) {
        event.stopPropagation();

        this.trvModalService
            .confirm(`Bekräfta komplettera ärende "${reportName}"`, "Är du säker att du vill skicka in kompletteringen?", "Skicka in", "Avbryt", {
                disposeOnBackdropClick: true,
            })
            .afterCloseWithType()
            .subscribe(async (event: any) => {
                if (event.closingEventType !== "close") return;
                try {
                    await this.reportService.sendReports([id]);
                    this.globalMessagesService.success(`Kompletteringen för ärende med namn "${reportName}" har skickats in.`);
                } catch (e) {
                    this.globalMessagesService.error(`Ett fel uppstod när ärende med namn "${reportName}" skulle kompletteras.`);
                }
            });
    }

    // is used to reset table state
    setTableState(newState: TableState) {
        this.valueChangeEnabled = false;

        this.currentPage.next(newState.page);
        this.filterForm.patchValue(newState.filters.value);

        this.openedReportGroups = newState.openedReports;
        this.openedReportItems = newState.openedReportItems;

        this.cdref.detectChanges();

        setTimeout(() => {
            this.valueChangeEnabled = true;
            window.scrollTo({top: newState.scroll, behavior: "instant"});
        }, 0);
    }


    public selectedReportIds: number[] = []

    showInViewMode() {
        this.router.navigateByUrl(`/map?viewmode=${this.selectedReportIds.join(",")}`)
    }

    checkboxChanged($event: any, id: number) {
        $event.stopPropagation();

        if ($event.target.checked) {
            this.selectedReportIds.push(id)
        } else {
            this.selectedReportIds = this.selectedReportIds.filter(a => a !== id)
        }
    }

    protected readonly faLocationPin = faLocationPin;
}

export interface FilterForm {
    id: FormControl<string | null>;
    name: FormControl<string | null>;
    username: FormControl<string | null>;
    description: FormControl<string | null>;
    changedDate: FormControl<DateRange>;
    // use the formattedStatusEnum table as source
    status: FormControl<{ statusEnum: number; value: string }[]>;
}

interface ReportItemForm {
    description: FormControl<string>;
    files: FormControl<File[]>;
    id: FormControl<number>;

    deliveryType: FormControl<DeliveryTypeRead>;
}

interface ReportGroupForm {
    files: FormControl<File[]>;
    id: FormControl<number>;
    reportItems: FormArray<FormGroup<ReportItemForm>>;

    // report ids to show after filter
    filteredReportItemsId: FormControl<string[]>;
}

enum SortDirection {
    asc,
    desc,
    none,
}

<div class="mb-4" [trv-loading]='!reportService.reportDataLoaded || loading'>
    @if (!isSendReportTable) {
        <div class="d-flex p-1" style="border-bottom: 1px solid #dee2e6;">
            <div class='d-flex align-items-center'>
                <button type='button' class='btn btn-link btn-update d-flex align-items-center' aria-label='Uppdatera'
                        (click)='refreshReportList()'>
                    <fa-icon [icon]='faArrowsRotate'></fa-icon>
                    Uppdatera
                </button>
                <b>{{ this.reportService.reportsLastRefreshed.toFormat("HH:mm") }}</b>
            </div>
            <button type='button' class='btn btn-link btn_goto_map' aria-label='Visa ärenden i kartan'
                    (click)='showInViewMode()'
                    [disabled]="selectedReportIds.length === 0"
            >
                <span>
                    <fa-icon [icon]='faLocationPin'></fa-icon>
                </span>
                Visa valda i kartan <b>({{ selectedReportIds.length }})</b>
            </button>
        </div>
    }
    <div>
        <form [formGroup]='filterForm' class='form-inline bg-white p-2 filter_container'>
            <div class='header_container'>
                <div class='search_values_container'>
                    <div class='trv-input-icon-append filter-form'>
                        <input formControlName='id' type='text' class='form-control' placeholder='Id'/>
                        <fa-icon class='icon' [icon]='faSearch'></fa-icon>
                    </div>
                    <div class='trv-input-icon-append filter-form'>
                        <input formControlName='name' type='text' class='form-control' placeholder='Ärendenamn'/>
                        <fa-icon class='icon' [icon]='faSearch'></fa-icon>
                    </div>
                    @if (ajourhallare) {
                        <div class='trv-input-icon-append filter-form'>
                            <input formControlName='username' type='text' class='form-control'
                                   placeholder='Användare'/>
                            <fa-icon class='icon' [icon]='faSearch'></fa-icon>
                        </div>
                    }
                    <div class='trv-input-icon-append filter-form'>
                        <input formControlName='description' type='text' class='form-control'
                               placeholder='Beskrivning'/>
                        <fa-icon class='icon' [icon]='faSearch'></fa-icon>
                    </div>

                    <trv-date-range formControlName="changedDate"></trv-date-range>

                    <trv-select
                        [multi]='true'
                        formControlName='status'
                        [source]='FORMATTED_REPORT_STATUS'
                        placeholder='Status'
                        valueKey='statusEnum'
                        [displayMethod]='displayDropdownValue'
                    ></trv-select>
                </div>
            </div>
        </form>
    </div>
    <trv-filter-summary [filter]='filterValues' (clear)='onClearFilter()'
                        (change)='onSummaryChanged()'></trv-filter-summary>
    <div class='table_headers_container'>
        <div class='table_header' style='pointer-events: none'></div>
        <div class='table_header' (click)="sortDirectionChange('id')">
            Id @if (sortedColumnProperty === 'id' && sortedColumnDirection === SortDirection.asc) {
            <app-svg-icon [svgIcon]='svgArrowDown'></app-svg-icon>
        }
            @if (sortedColumnProperty === 'id' && sortedColumnDirection === SortDirection.desc) {
                <app-svg-icon [svgIcon]='svgArrowUp'></app-svg-icon>
            }
        </div>

        <div class='table_header' (click)="sortDirectionChange('name')">
            Ärendenamn @if (sortedColumnProperty === 'name' && sortedColumnDirection === SortDirection.asc) {
            <app-svg-icon [svgIcon]='svgArrowDown'></app-svg-icon>
        }
            @if (sortedColumnProperty === 'name' && sortedColumnDirection === SortDirection.desc) {
                <app-svg-icon [svgIcon]='svgArrowUp'></app-svg-icon>
            }
        </div>

        <div class='table_header' (click)="sortDirectionChange('status')">
            Status @if (sortedColumnProperty === 'status' && sortedColumnDirection === SortDirection.asc) {
            <app-svg-icon [svgIcon]='svgArrowDown'></app-svg-icon>
        }
            @if (sortedColumnProperty === 'status' && sortedColumnDirection === SortDirection.desc) {
                <app-svg-icon [svgIcon]='svgArrowUp'></app-svg-icon>
            }
        </div>

        @if (ajourhallare) {
            <div class='table_header' (click)="sortDirectionChange('owner')">
                Användare @if (sortedColumnProperty === 'owner' && sortedColumnDirection === SortDirection.asc) {
                <app-svg-icon [svgIcon]='svgArrowDown'></app-svg-icon>
            }
                @if (sortedColumnProperty === 'owner' && sortedColumnDirection === SortDirection.desc) {
                    <app-svg-icon [svgIcon]='svgArrowUp'></app-svg-icon>
                }
            </div>
        }

        <div class='table_header' (click)="sortDirectionChange('lastChanged')">
            Senast
            ändrad @if (sortedColumnProperty === 'lastChanged' && sortedColumnDirection === SortDirection.asc) {
            <app-svg-icon [svgIcon]='svgArrowDown'></app-svg-icon>
        }
            @if (sortedColumnProperty === 'lastChanged' && sortedColumnDirection === SortDirection.desc) {
                <app-svg-icon [svgIcon]='svgArrowUp'></app-svg-icon>
            }
        </div>
        <div class='table_header' (click)="sortDirectionChange('itemCount')">
            Antal
            förändringar @if (sortedColumnProperty === 'itemCount' && sortedColumnDirection === SortDirection.asc) {
            <app-svg-icon [svgIcon]='svgArrowDown'></app-svg-icon>
        }
            @if (sortedColumnProperty === 'itemCount' && sortedColumnDirection === SortDirection.desc) {
                <app-svg-icon [svgIcon]='svgArrowUp'></app-svg-icon>
            }
        </div>
        <div class='table_header' style='pointer-events: none'></div>

    </div>

    @for (reportGroup of reportsToDisplay; track reportGroup) {
        <div class='group_container'>
            <div
                class='group_header'
                (click)='toggleDropdownGroup(reportGroup.id)'
                [class.group_open]='({ items: [reportGroup.id], list: openedReportGroups } | allItemsInList) || allGroupsOpened'
            >
                <div class='group_header_input'>
                    <div>
                        @if (!isSendReportTable) {
                            <input type='checkbox' class='form-check-input' #checkbox
                                   style="width:18px; height: 18px; cursor: pointer"
                                   (click)="checkboxChanged($event, reportGroup.id)"
                                   [id]='reportGroup.id'
                                   aria-label="Markera ärende"
                            />
                        }
                        <span role='button' class='btn btn-link' [class.hidden]='reportGroup.reportItems.length === 0'>
                    @if (({items: [reportGroup.id], list: openedReportGroups} | allItemsInList) || allGroupsOpened) {
                        <fa-icon [icon]='faChevronUp'></fa-icon>
                    } @else {
                        <fa-icon [icon]='faChevronDown'></fa-icon>
                    }
                </span>
                    </div>
                    <div style='display: flex; align-items: center; gap: 10px'>
                        @if (reportService.pendingReportGroupNameChanges[reportGroup.id] || groupHasChildrenWithChanges(reportGroup)) {
                            <div class='change_dot'></div>
                        }
                        {{ reportGroup.id }}
                    </div>
                    <div>
                        @if ({items: [reportGroup.id], list: editableReportGroups} | allItemsInList) {
                            <input
                                appAutofocus
                                type='text'
                                placeholder='Ärendenamn'
                                class='form-control group_name_input'
                                (input)='reportGroupChange($event, reportGroup.id)'
                                (click)='$event.stopPropagation()'
                                [value]='reportGroup.name'
                                [ngClass]="{ 'ng-invalid ng-touched ng-dirty': reportService.pendingReportGroupNameChanges[reportGroup.id] == '' }"
                            />
                            @if (reportService.pendingReportGroupNameChanges[reportGroup.id] == '') {
                                <div @showErrorMessage class='invalid-feedback'>Måste anges.</div>
                            }
                        } @else {
                            <div class='group_name_container'>
                                {{ reportGroup.name }}
                            </div>
                        }
                    </div>
                    <div>
                        {{
                            {
                                keyValue: reportGroup.status,
                                keyPropertyName: 'statusEnum',
                                valuePropertyName: 'value',
                                objectList: FORMATTED_REPORT_STATUS
                            } | valueFromObjectList
                        }}
                    </div>
                    @if (ajourhallare) {
                        <div>
                            {{ reportGroup.owner ?? externUserFormattedText }}
                        </div>
                    }
                    <div>
                        {{ reportGroup.lastChanged?.toFormat("yyyy-MM-dd HH:mm") }}
                    </div>
                    <div>
                        {{ reportGroup.reportItems.length }}
                    </div>
                    <div class='group_header_buttons'>
                        @if (ajourhallare) {
                            <button class='btn btn-primary' [routerLink]="'/handle-reports/' + reportGroup.id">
                                Hantera
                            </button>
                        } @else {
                            @if (!isSendReportTable) {
                                <button
                                    class='btn btn-primary btn-sm'
                                    [routerLink]="'/reports/' + reportGroup.id"
                                    [position]="['bottom']"
                                    trvTooltip='Gå till ärendesidan'
                                    aria-label="Gå till ärendesidan"
                                >
                                    <app-svg-icon [svgIcon]='svgInfo'></app-svg-icon>
                                </button>
                            }
                            @if ((reportGroup.status == ReportStatusesEnum.Draft || reportGroup.status == ReportStatusesEnum.OpenForClarification) && ({
                                items: [reportGroup.id], list: editableReportGroups
                            } | allItemsInList)) {
                                <button
                                    class='btn btn-secondary btn-sm'
                                    (click)='cancelGroupEdit(reportGroup, $event)'
                                    [position]="['bottom']"
                                    aria-label="Avbryt"
                                    trvTooltip='Avbryt'
                                >
                                    <app-svg-icon [svgIcon]='svgCross'></app-svg-icon>
                                </button>
                                <button
                                    class='btn btn-primary btn-sm'
                                    (click)='saveGroup(reportGroup, $event)'
                                    [position]="['bottom']"
                                    aria-label="Spara"
                                    trvTooltip='Spara'
                                >
                                    <app-svg-icon [svgIcon]='svgCheck'></app-svg-icon>
                                </button>
                            } @else {
                                @if (reportGroup.status == ReportStatusesEnum.Draft || reportGroup.status == ReportStatusesEnum.OpenForClarification) {
                                    <button
                                        class='btn btn-danger btn-sm'
                                        (click)='removeGroup(reportGroup.id, reportGroup.name, $event)'
                                        [position]="['bottom']"
                                        aria-label="Ta bort ärende"
                                        trvTooltip='Ta bort ärende'
                                    >
                                        <app-svg-icon [svgIcon]='svgTrash'></app-svg-icon>
                                    </button>
                                }

                                @if (reportGroup.status == ReportStatusesEnum.Draft) {
                                    <button
                                        class='btn btn-primary btn-sm'
                                        (click)='editGroup(reportGroup, $event)'
                                        [position]="['bottom']"
                                        aria-label="Uppdatera ärendets namn"
                                        trvTooltip='Uppdatera ärendets namn'
                                    >
                                        <app-svg-icon [svgIcon]='svgPencil'></app-svg-icon>
                                    </button>
                                }

                                @if (reportGroup.status == ReportStatusesEnum.OpenForClarification && !isSendReportTable) {
                                    <button
                                        class='btn btn-primary btn-sm'
                                        (click)='sendClarification(reportGroup.name, reportGroup.id, $event)'
                                        [position]="['bottom']"
                                        trvTooltip='Skicka in komplettering'
                                        aria-label="Skicka in komplettering"
                                        style='padding-left: 0.75rem; padding-right: 0.75rem;'
                                    >
                                        <fa-icon [icon]='faK'></fa-icon>
                                    </button>
                                }
                            }
                        }
                    </div>
                </div>
            </div>
            <div>
                @if (reportGroup.reportItems.length > 0) {
                    <div
                        [@groupExpand]="(({ items: [reportGroup.id], list: openedReportGroups } | allItemsInList) || allGroupsOpened) ? 'expanded' : 'collapsed'"
                        style='display: flex'
                    >
                        <div class='all_item_container'>
                            @for (reportItem of reportGroup.reportItems; track $index) {
                                @if (filteredReportItemIds.length ==
                                0 || filteredReportItemIds.includes(reportItem.id)) {

                                    <div class='item_container'
                                         [trv-loading]='loadingIndividualItems.includes(reportItem.id)'
                                         (mouseenter)='mouseEnterReportItem(reportItem)'
                                         (mouseleave)='mouseLeaveReportItem(reportItem)'
                                    >
                                        <app-report-item-expandable-header
                                            [open]='{ items: [reportItem.id], list: openedReportItems } | allItemsInList'
                                            [showChangeDot]='{ items: [reportItem.id], list: reportService.reportItemsWithChanges } | allItemsInList'
                                            (headerClicked)='toggleDropdownItem(reportItem.id)'
                                            (zoomClicked)='zoomToReportItem(reportGroup.id, reportItem)'
                                            [reportItem]='reportItem'
                                            [sequenceNumber]='$index+1'
                                        ></app-report-item-expandable-header>
                                        <div
                                            [@groupExpand]="({ items: [reportItem.id], list: openedReportItems } | allItemsInList) ? 'expanded' : 'collapsed'"
                                            class='report_item_edit_container'
                                        >
                                            <app-report-item
                                                [availableReports]='{reports: reportService.reports, statusesToInclude: [ReportStatusesEnum.Draft, ReportStatusesEnum.OpenForClarification]} | reportStatus'
                                                [isFromExternAnvandare]='reportGroup.owner == null'
                                                [reportItem]='reportItem'
                                                [isEditable]='({ items: [reportItem.id], list: editableReportItems } | allItemsInList) && !ajourhallare'
                                                #reportItemComponent
                                            ></app-report-item>
                                            <div class='item_footer_right'>
                                                @if (!ajourhallare) {
                                                    <div class='item_button_container'>
                                                        @if ({
                                                            items: [reportItem.id],
                                                            list: editableReportItems
                                                        } | allItemsInList) {
                                                            <div class='editable_buttons_container'>
                                                                <div>
                                                                    * är obligatorisk
                                                                </div>
                                                                <div>
                                                                    <button type='button'
                                                                            class='btn btn-secondary me-2'
                                                                            trvTooltip='Avbryt'
                                                                            aria-label="Avbryt"
                                                                            (click)='cancelEditItem(reportItem.id)'>
                                                                        Avbryt
                                                                    </button>
                                                                    <button
                                                                        type='button'
                                                                        class='btn btn-primary'
                                                                        (click)='saveItem(reportItem.id, reportItemComponent)'
                                                                        [disabled]='!({ items: [reportItem.id], list: reportService.reportItemsWithChanges } | allItemsInList)'
                                                                        trvTooltip='Spara ändringar'
                                                                        aria-label="Spara ändringar"
                                                                    >
                                                                        Spara
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        } @else {
                                                            @if (reportGroup.status === ReportStatusesEnum.Draft || reportGroup.status ===
                                                            ReportStatusesEnum.OpenForClarification) {
                                                                <button
                                                                    type='button'
                                                                    class='btn btn-danger'
                                                                    (click)='removeItem(reportGroup.id, reportItem.id, reportItem.description)'
                                                                    trvTooltip='Ta bort förändring'
                                                                    aria-label="Ta bort förändring"
                                                                >
                                                                    Ta bort
                                                                </button>
                                                                <button type='button' class='btn btn-primary'
                                                                        (click)='editItem(reportItem.id, $event)'
                                                                        aria-label="Ändra ärende"
                                                                        trvTooltip='Ändra ärende'>
                                                                    Ändra
                                                                </button>
                                                            }
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            }
                        </div>
                        <div
                            style='width: 1px; margin-left: 4px; margin-right: 4px; background-color: #d5d5d5'></div>
                        <div
                            style='height: 200px; width: 30%'
                        >
                            @if (({items: [reportGroup.id], list: openedReportGroups} | allItemsInList)) {
                                <lib-trv-ng-map
                                    [id]="'map-report' + reportGroup.id"
                                    [layerInput]='layerdata'
                                    [defaultMap]='false'
                                    [hideControls]='true'
                                ></lib-trv-ng-map>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    }

    <div class='p-2 pl-3 pagination-container'>
        <trv-pagination
            [pageSize]='pageSize'
            [page]='currentPage.value'
            [collectionSize]='sortedReportGroupIds.length'
            (pageChanged)='currentPage.next($event.page)'
            [hidePageSizeOptions]='true'
        ></trv-pagination>
    </div>
</div>



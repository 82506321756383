import {Component, Inject, Input, OnInit} from "@angular/core";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {faMagnifyingGlassPlus, faEdit, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {TrvNgMapService} from "@trafikverket/trv-ng-map";
import {ReportItem, ReportService} from "src/app/service/report.service";
import {TrvGlobalMessagesService, TrvModalService, TrvPopoverRef} from "trv-ng-common";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {NvdbNavigationService} from "@app/service/nvdb-navigation.service";

@Component({
    selector: "app-report-item-options-popover",
    templateUrl: "./report-item-options-popover.component.html",
    styleUrls: ["./report-item-options-popover.component.scss"],
    standalone: true,
    imports: [FontAwesomeModule],
})
export class ReportItemOptionsPopoverComponent implements OnInit {
    public reportItem!: ReportItem;

    public faMagnifyingGlassPlus: IconProp = faMagnifyingGlassPlus as IconProp;
    public faEdit: IconProp = faEdit as IconProp;
    public faTrashCan: IconProp = faTrashCan as IconProp;

    constructor(
        public nvdbNavigationService: NvdbNavigationService,
        private trvModalService: TrvModalService,
        private trvPopoverRef: TrvPopoverRef,
        @Inject(TrvNgMapService) private trvMapService: TrvNgMapService,
        @Inject(ReportService) private reportService: ReportService,
        @Inject(TrvGlobalMessagesService)
        private globalMessagesService: TrvGlobalMessagesService
    ) {
    }

    ngOnInit(): void {
    }

    public zoomTo() {
        if (!this.reportItem.feature) return;

        this.trvMapService.trvMap?.zoomInOnFeature(this.reportItem.feature, 1000, this.nvdbNavigationService.getPaddingForZoomToExtentOnMainMap(100))
        this.trvPopoverRef.close("");
    }

    public edit() {
        this.reportService.setActiveItemToCreateOrEdit(this.reportItem.id);
        this.trvPopoverRef.close("");
    }

    public remove() {
        this.trvModalService
            .confirmDelete("Bekräfta ta bort", "Är du säker att du vill ta bort geometrin?", "Ta bort", "Avbryt", {
                disposeOnBackdropClick: true,
            })
            .afterCloseWithType()
            .subscribe(async (event: any) => {
                if (event.closingEventType !== "close") return;

                try {
                    await this.reportService.removeReportItemById(this.reportItem.report.id, this.reportItem.id);
                    this.trvPopoverRef.close("");
                } catch (e) {
                    this.globalMessagesService.error(
                        `Ett fel uppstod när förändringen med beskrivning "${this.reportItem.description}" skulle tas bort.`
                    );
                }
            });
    }
}

/* tslint:disable */
/* eslint-disable */
export enum ReportStatusesEnum {
  Draft = 1,
  Sent = 2,
  ClarificationSent = 3,
  Received = 4,
  Reviewed = 5,
  OpenForClarification = 6,
  Processing = 7,
  Closed = 8
}

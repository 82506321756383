import { AfterViewInit, Component, Directive, ElementRef, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { svgCross } from "src/app/shared/utils/svg-helper-data";
import { Report, ReportService } from "src/app/service/report.service";
import { TrvFormUtilityService, TrvFormValidationErrorMap, TrvGlobalMessagesService, TrvPopoverRef } from "trv-ng-common";
import { SvgIcon } from "src/app/shared/utils/svg-helper";
import { ReportStatusesEnum } from '@_api/dataleverans/models'
import { ReportEditComponent } from "../../../reports/report-edit/report-edit.component";
import { SvgIconComponent } from "../../../utils/svg-icon/svg-icon.component";


@Component({
    selector: 'app-report-edit-popover',
    templateUrl: './report-edit-popover.component.html',
    styleUrls: ['./report-edit-popover.component.scss'],
    standalone: true,
    imports: [
    SvgIconComponent,
    ReportEditComponent
],
})
export class ReportEditPopoverComponent implements AfterViewInit {
    public iconX: SvgIcon = svgCross;

    // null if you want to create a new reportgroup
    public reportGroupToEdit: Report | null = null;

    constructor(
        private trvPopoverRef: TrvPopoverRef,
    ) {

    }

    ngAfterViewInit(): void {

    }


    public close() {
        this.trvPopoverRef.close("");
    }

    protected readonly ReportStatusesEnum = ReportStatusesEnum
}

<form [formGroup]='form'>
    <div class='report-item-control'>
        <label class='col-form-label form-field-label label-bold'>Typ av fel*</label>
        <span (click)='felTypInfo()' style='margin-left: 1rem; cursor: pointer; fill: #1e5c88'><app-svg-icon
            [svgIcon]='circleInfoIcon'></app-svg-icon></span>
        <div>
            <trv-select
                formControlName='deliveryTypes'
                placeholder='Välj ett objekt'
                [source]='reportAvikelseService.deliveryTypes'
                valueKey='deliveryType'
                sortKey='deliveryType'
                [enableSearch]='false'
                [displayMethod]='displayDeliveryTypeName'
                [disableClear]='true'
            ></trv-select>
        </div>
    </div>
    <div class='report-item-control'>
        <label class='col-form-label form-field-label label-bold'>Beskriv vad du upplever är fel i kartan*</label>
        <div>
            <textarea class='form-control' [formControl]='description' placeholder='Beskrivning' rows='3'></textarea>
            @if (form.controls.description.errors?.['required'] != null) {
                <div @showErrorMessage class='invalid-feedback'>Måste anges.</div>
            }
            @else if (form.controls.description.errors?.['maxlength'] != null) {
                <div @showErrorMessage class='invalid-feedback'>Max 600 tecken.</div>
            }
        </div>
    </div>

    <div class='mt-2'>
        * är obligatorisk
    </div>
</form>


import { Injectable } from "@angular/core";
import { Feature } from "ol";
import { Geometry, LineString, MultiLineString } from "ol/geom";
import { map, Observable } from "rxjs";
import { RouteApi } from "../_api/dataleverans/services/route-api";
import { RoutePoint, Route, RouteStops } from "../_api/dataleverans/models";

@Injectable({
    providedIn: "root",
})
export abstract class aRouteService {
    abstract getClosestPointsOnRoads(feature: Feature): RoutePoint[];
}

@Injectable({
    providedIn: "root",
})
export class RouteServiceMock implements aRouteService {
    public getClosestPointsOnRoads(feature: Feature): RoutePoint[] {
        throw new Error("Method not implemented.");
    }
}

@Injectable({
    providedIn: "root",
})
export class RouteService {
    // todo this has to be in the abstract class aswell
    public snapToNetType: { id: number; name: string } | false = false;

    constructor(private routeApiClient: RouteApi) {}

    public getClosestPointsOnRoads(feature: Feature): Observable<MultiLineString> {
        // todo depending on snapToNetType...

        const originalFlatCoordinates = (feature.getGeometry() as LineString).getFlatCoordinates();


        let points: RoutePoint[] = [];
        for (let i = 0; i < originalFlatCoordinates.length; i += 2) {
            const point: RoutePoint = {
                x: originalFlatCoordinates[i],
                y: originalFlatCoordinates[i + 1],
            };
            points.push(point);
        }

        let index = 0;

        const routeReq:Route = {
            stops: points.map(
                point =>
                    ({
                        name: index++ + "",
                        routeName: "routeName",
                        locationType: 0,
                        x: point.x,
                        y: point.y,
                    }) as RouteStops
            )
        }


        return this.routeApiClient
            .route({
                body:routeReq,
            })
            .pipe(
                map(
                    routes =>
                        routes?.segments?.flatMap(currentSegment =>
                            (currentSegment?.polyline || []).map(routePoints => routePoints.map(routePoint => [routePoint.x, routePoint.y]))
                        ) || []
                ),
                map(lines => new MultiLineString(lines))
            );
    }
}

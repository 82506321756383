<div class='content_container'>
    <div class='event_container'>
        <div (click)='toggleEventLog()' class='dataslag_header' [ngClass]='{ open: showEventLogg }'>
            @if (showEventLogg) {
                <fa-icon [icon]='faChevronUp'></fa-icon>
            } @else {
                <fa-icon [icon]='faChevronDown'></fa-icon>
            }
            <span class='c-report__list__group__header__name no-select'>Ärendelogg ({{ report.events.length }})</span>
        </div>
        <div [@groupExpand]="showEventLogg ? 'expanded' : 'collapsed'">
            <div class='event_body'>
                <table cdk-table [dataSource]='report.events.slice().reverse()' class='trv-table trv-table-hover'>
                    <ng-container cdkColumnDef='date'>
                        <th cdk-header-cell *cdkHeaderCellDef>Datum</th>
                        <td cdk-cell *cdkCellDef='let event'>
                            {{ DateTime.fromISO(event.created).toFormat("yyyy-MM-dd HH:mm") }}
                        </td>
                    </ng-container>

                    <ng-container cdkColumnDef='eventType'>
                        <th cdk-header-cell *cdkHeaderCellDef>Händelse</th>
                        <td cdk-cell *cdkCellDef='let event'>
                            {{ event.action }}
                        </td>
                    </ng-container>

                    <ng-container cdkColumnDef='user'>
                        <th cdk-header-cell *cdkHeaderCellDef>Användare</th>
                        <td cdk-cell *cdkCellDef='let event'>
                            {{ event.user }}
                        </td>
                    </ng-container>

                    <ng-container cdkColumnDef='comment'>
                        <th cdk-header-cell *cdkHeaderCellDef>Kommentar</th>
                        <td cdk-cell *cdkCellDef='let event'>
                            {{ event.comment }}
                        </td>
                    </ng-container>

                    <tr cdk-header-row *cdkHeaderRowDef='visibleColumnKeys'></tr>
                    <tr cdk-row *cdkRowDef='let event; columns: visibleColumnKeys'></tr>
                </table>
            </div>
        </div>
    </div>
    <div class='mt-3 mb-3'>
        <table class='info'>
            <tr>
                <th>Id</th>
                <td>{{ report.id }}</td>
            </tr>
            <tr>
                <th>UID</th>
                <td>{{ report.uid }}</td>
            </tr>
            <tr>
                <th>Ärende</th>
                <td>{{ report.name }}</td>
            </tr>
            <tr>
                <th>Status</th>
                <td> {{
                        {
                            keyValue: report.status,
                            keyPropertyName: 'statusEnum',
                            valuePropertyName: 'value',
                            objectList: FORMATTED_REPORT_STATUS
                        } | valueFromObjectList
                    }}
                </td>
            </tr>

            <tr>
                <th>Ändrad</th>
                <td>{{ report.lastChanged?.toFormat('yyyy-MM-dd HH:mm:ss') }}</td>
            </tr>

            @if (report.owner && report.owner != "") {
                <tr>
                    <th>Ägare</th>
                    <td>{{ report.owner }}</td>
                </tr>
                <tr>
                    <th>Email</th>
                    <td>{{ report.email }}</td>
                </tr>
            } @else {
                <tr>
                    <th>Inskickare</th>
                    <td>{{ report.author }}</td>
                </tr>
                <tr>
                    <th>Email</th>
                    <td>{{ report.email }}</td>
                </tr>
                <tr>
                    <th>Telefon</th>
                    <td>{{ report.phoneNumber }}</td>
                </tr>
            }

            <tr>
                <th>Upplåst
                </th>
                <td>{{ report.isUnlocked ? "Ja" : "Nej" }}
                    <span (click)='unlockedInfo()' style='margin-left: 0.5rem; cursor: pointer; fill: #1e5c88'><app-svg-icon
                        [svgIcon]='circleInfoIcon'></app-svg-icon></span>
                </td>
            </tr>
        </table>
    </div>


    <div class='event_container'>
        <div (click)='toggleMap()' class='dataslag_header' [ngClass]='{ open: showMap }'>
            @if (showMap) {
                <fa-icon [icon]='faChevronUp'></fa-icon>
            } @else {
                <fa-icon [icon]='faChevronDown'></fa-icon>
            }
            <span class='c-report__list__group__header__name no-select'>Karta</span>
            <span role='button' class='btn btn-link btn-zoom icon' (click)='handleZoomAll($event)'>
                <fa-icon [icon]='faMagnifyingGlassPlus'></fa-icon>
            </span>
        </div>
        <div [@groupExpand]="showMap ? 'expanded' : 'collapsed'">
            <div class='event_body'>
                <div style='width: 100%; height: 300px;'>
                    <lib-trv-ng-map [id]="'handle-report' + report.id" [layerInput]='layerdata' [defaultMap]='false'
                                    [hideControls]='false'></lib-trv-ng-map>
                </div>
            </div>
        </div>
    </div>

    <br/>

    @for (item of report.reportItems; track $index) {
        <div (mouseenter)='mouseEnterReportItem(item)' (mouseleave)='mouseLeaveReportItem(item)'>
            <app-report-item-expandable-header [reportItem]='item'
                                               [open]='{ items: [item.id], list: openedReportItems } | allItemsInList'
                                               (headerClicked)='toggleReportItemOpen(item.id)'
                                               (zoomClicked)='zoomToReportItem(item)'
                                               [sequenceNumber]="$index+1"
            >
            </app-report-item-expandable-header>
            <div
                [@groupExpand]="({ items: [item.id], list: openedReportItems } | allItemsInList) ? 'expanded' : 'collapsed'"
                class='report_item_edit_container'>
                @if (environment.application == "NvdbDataleverans") {
                    <app-report-item
                        [availableReports]='{reports: reportService.reports, statusesToInclude: [ReportStatusesEnum.Draft, ReportStatusesEnum.OpenForClarification]} | reportStatus'
                        [reportItem]='item' [isFromExternAnvandare]='report.owner == null'
                        [isEditable]='false'></app-report-item>
                } @else {
                    <div class='p-2'>
                        <b>Typ av fel:</b>
                        <div>{{ item.deliveryType.description }}</div>
                        <b>Beskriv vad du upplever är fel i kartan:</b>
                        <div>{{ item.description }}</div>
                    </div>
                }
            </div>
        </div>
    }
</div>

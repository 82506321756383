import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'valueFromObjectList',
    standalone: true
})
export class ValueFromObjectListPipe implements PipeTransform {
  transform(args: { keyValue: any; keyPropertyName: string; valuePropertyName: string; objectList: any[] }): string {
    return args.objectList.find(object => object[args.keyPropertyName] === args.keyValue)[args.valuePropertyName];
  }
}

@if (authenticationService.currentRole == 'Admin') {
<div>
    <div style="padding: 1rem; border-bottom: 1px solid #ced4da">
        <div style="font-size: 24px">Systeminställningar</div>
        <div style="font-size: 16px">Här ser du de systemparametrar som går att konfigurera.</div>
    </div>
    <trv-tabset>
        <trv-tab header="NVDB dataleverans" [icon]="faBroom">
            <div style="padding: 0rem 1rem 1rem 1rem; border-bottom: 1px solid #ced4da">
                <div>
                    <div [trv-loading]="loading">
                        <b class="d-inline-block">Meddelande från förvaltningen</b>
                        <br />
                        <i class="mb-2 d-inline-block">
                            Meddelandetext att visa i popup när sidan laddas.
                            <br />
                            Visas inte om den här texten är tom
                        </i>

                        <angular-editor [formControl]="message" [config]="editorConfig"></angular-editor>

                        <b class="mt-2 d-inline-block">Period då meddelandet visas</b>
                        <br />
                        <i class="mb-4 d-inline-block">
                            Den period under vilket meddelande från förvaltningen ska visas.
                            <br />
                            Första datumet är det datum texten ska börja visas. Sista datumet är det datum texten ska sluta visas. Om inget värde är
                            satt i sista rutan så visas meddelandet tills vidare.
                        </i>
                        <div class="date_pickers_container">
                            <div>
                                <b class="d-inline-block">Från</b>
                                <div class="d-flex">
                                    <trv-date-picker [formControl]="periodStart" [skipClear]="true"></trv-date-picker>
                                    <button
                                        class="btn btn-primary rounded-0"
                                        [ngClass]="periodStartIsTodaysDate() ? 'set_date_button_active' : 'set_date_button_inactive'"
                                        (click)="periodStart.setValue(DateTime.now().toISO())"
                                    >
                                        Idag
                                    </button>
                                </div>
                            </div>

                            <div>
                                <b class="d-inline-block">Till</b>
                                <div class="d-flex">
                                    <trv-date-picker [formControl]="periodEnd" [skipClear]="true" [minDate]="periodStart!.value!"></trv-date-picker>
                                    <button
                                        class="btn btn-primary rounded-0"
                                        [ngClass]="periodEnd.value == null ? 'set_date_button_active' : 'set_date_button_inactive'"
                                        (click)="periodEnd.setValue(null)"
                                    >
                                        Tillsvidare
                                    </button>
                                </div>
                            </div>
                        </div>

                        <button type="button" class="btn btn-primary me-2" (click)="previewMessageFromForvaltning()">Förhandsgranska</button>
                        <button type="button" class="btn btn-primary me-2" (click)="submitMessageFromForvaltning()">Spara</button>
                    </div>
                </div>
            </div>
        </trv-tab>
        <trv-tab header="NVDB på karta" [icon]="faBroom">
            <app-pa-karta-admin></app-pa-karta-admin>
        </trv-tab>
    </trv-tabset>
</div>
}

import {ElementRef, EventEmitter, Inject, Injectable, Renderer2} from "@angular/core";
import Feature from "ol/Feature";
import {BehaviorSubject, combineLatest, firstValueFrom, merge, of, take} from "rxjs";
import {TrvMapExtension} from "../trv-map-extension/trv-map-extension";
import WKT from "ol/format/WKT";
import {TrvNgMapService, generateGUID} from "@trafikverket/trv-ng-map";
import {NvdbNavigationService} from "./nvdb-navigation.service";
import {LocalStorageService} from "./local-storage.service";
import {TrvGlobalMessagesService} from "trv-ng-common";
import {NetDbApi, ReportAvikelseApi} from "../_api/pakarta/services";
import {DateTime} from "luxon";
import {DeliveryTypeRead, DeliveryTypeResponse, DeliveryTypesEnum, Report, ReportItem} from "../_api/pakarta/models";
import {
    NvdbReportStyle,
    NvdbReportStyleFocused,
    NvdbReportStyleHidden,
    NvdbReportStyleHover,
} from "@app/trv-map-extension/trv-map-extension-styles";
import {GeometryType} from "@shared/enums";
import {environment} from "src/environments/environment";
import {getUid, View} from "ol";
import {FOCUSED, HIDDEN, REPORT_ITEM_ID} from "@app/shared/constants";
import {FeatureToCreate} from '@app/service/report.service'
import {getCenter} from "ol/extent";
import {NetDbService} from "@app/service/net-db.service";

@Injectable({
    providedIn: "root",
})
export class ReportAvikelseService {
    // ReportItemWithFeature if editing existing item
    // Feature if creating a new item
    // null if hiding th emodal
    // OBS!!! always edit with setReportAvikelseItemToCreateOrEdit
    public reportAvikelseItemToCreateOrEdit: ReportItemWithFeature | FeatureToCreate | null = null;
    public reportAvikelseItemToCreateOrEditChanged = new EventEmitter<void>();
    // keep track of previous item so we can cleanup
    private _previousItemToCreateOrEdit: ReportItemWithFeature | FeatureToCreate | null = null;

    public updateReportAvikelseFormDescription = new EventEmitter<string>();

    public trvExtension?: TrvMapExtension;

    public unsentReportAvikelseItem: ReportItemWithFeature[] = [];

    public deliveryTypes: DeliveryTypeRead[] = [];
    public deliveryTypesLoaded = false;
    public deliveryTypesLoadedChanged = new EventEmitter();
    queOfItemsToCreate: FeatureToCreate[] = []
    totalQueLengthFromBeginning: number | null = null

    constructor(
        @Inject(TrvNgMapService) public trvMapService: TrvNgMapService,
        @Inject(NvdbNavigationService) public navigationService: NvdbNavigationService,
        public localStorageService: LocalStorageService,
        public messageService: TrvGlobalMessagesService,
        private reportAvikelseApiClient: ReportAvikelseApi,
        private netDbService: NetDbService
    ) {
        if (environment.application == "NvdbPåKarta") {
            try {
                this.reportAvikelseApiClient.getDeliveryTypes().subscribe((a: DeliveryTypeResponse) => {
                    this.deliveryTypes = a.deliveryTypes;
                    this.deliveryTypesLoaded = true;
                    this.deliveryTypesLoadedChanged.emit();

                    this.netDbService.getLastFeatureUpdateNVDB().pipe(take(1)).subscribe(lastFeatureUpdateNVDB => {
                        if (lastFeatureUpdateNVDB) this.navigationService.formattedLastFeatureUpdateNVDB = lastFeatureUpdateNVDB.split(" ")[0];
                    })
                });
            } catch (e) {
                this.messageService.error("Kunde inte hämta data från servern");
            }
        }
    }

    setReportAvikelseItemToCreateOrEdit(item: ReportItemWithFeature | FeatureToCreate | null, clearQue = true) {

        if (clearQue) {
            this.queOfItemsToCreate = []
            this.totalQueLengthFromBeginning = null
        }

        const previousItem = this._previousItemToCreateOrEdit;
        const previousItemWasCreatingAndWasCancelled =
            this.itemTypeofCreating(previousItem) && !this.unsentReportAvikelseItem.some(a => getUid(a.feature) === getUid(previousItem.feature));

        if (previousItemWasCreatingAndWasCancelled) {
            this.removeFeatureFromMap(previousItem.feature);
        }

        const previousFeature = previousItem?.feature
        if (previousFeature) {
            previousFeature.setStyle(NvdbReportStyle);
            previousFeature.set(FOCUSED, false);
        }

        this._previousItemToCreateOrEdit = item;
        this.navigationService.reportItemEditModalVisible = item !== null;
        this.reportAvikelseItemToCreateOrEdit = item;
        this.reportAvikelseItemToCreateOrEditChanged.emit();

        if (this.navigationService.isMobileDevice) {
            this.navigationService.currentMobileMenuState.setValue(item?.feature ? "Large" : "Medium")
        }

        if (item?.feature) {
            this.trvExtension!.resetAllReportFeatureStyle();
            item.feature.set(FOCUSED, true);
            item.feature.setStyle(NvdbReportStyleFocused)

            this.panToFeature(item.feature, 400)
        }
    }

    // pan to feature and focus, need timeout for the previous feature to be removed before pan
    panToFeature(feature: Feature, duration = 0) {
        const panPixel = this.navigationService.getViewableCenterPixelOnMainMap()
        // pan slightly lower for points since their image is above them
        if (feature.getGeometry()?.getType() == "Point") {
            panPixel[1] += 20
        }

        setTimeout(() => {
            this.trvMapService.trvMap!.panToFeatureOffsetLocalPixel(feature, panPixel, 400);
        }, 50);
    }

    // check if reportAvikelseItemToCreateOrEdit is currently creating an item or updating an existing item
    public itemTypeofCreating(item: ReportItemWithFeature | FeatureToCreate | null): item is FeatureToCreate {
        return item != null && !item.hasOwnProperty("deliveryType");
    }

    public itemTypeofEditing(item: ReportItemWithFeature | FeatureToCreate | null): item is ReportItemWithFeature {
        return item != null && item.hasOwnProperty("deliveryType");
    }

    public getReportAvikelse(UID: string) {
        return this.reportAvikelseApiClient.getDupReport({
            UID: UID,
        });
    }

    public createReportAvikelseItem(feature: Feature, description: string, deliveryType: DeliveryTypesEnum) {
        var newAvikelseItem: ReportItemWithFeature = {
            description: description,
            deliveryType: deliveryType,
            wkt: new WKT().writeFeature(feature),
            seqNr: 0,
            feature: feature,
            id: Math.floor(Math.random() * 1_000_000_000_000_000),
        };

        feature.set(REPORT_ITEM_ID, newAvikelseItem.id);
        this.unsentReportAvikelseItem.push(newAvikelseItem);
        this.localStorageService.setUnsentReportAvikelseItems(this.unsentReportAvikelseItem);
    }

    public editFeature(feature: Feature) {
        //Update wkt
        const reportItemId = feature.get(REPORT_ITEM_ID);
        const reportItem = this.unsentReportAvikelseItem.find(a => a.id === reportItemId);
        if (reportItem) {
            reportItem.wkt = new WKT().writeFeature(feature);
        }
        this.localStorageService.setUnsentReportAvikelseItems(this.unsentReportAvikelseItem);
    }

    public removeAvikelseItemWithFeature(reportAvikelseItemWithFeature: ReportItemWithFeature) {
        const feature = reportAvikelseItemWithFeature.feature;
        if (feature) {
            this.unsentReportAvikelseItem = this.unsentReportAvikelseItem.filter(item => item !== reportAvikelseItemWithFeature);
            this.removeFeatureFromMap(feature);
            this.localStorageService.setUnsentReportAvikelseItems(this.unsentReportAvikelseItem);
            //If we are editing this item, remove it
            if (this.reportAvikelseItemToCreateOrEdit == reportAvikelseItemWithFeature) {
                this.setReportAvikelseItemToCreateOrEdit(null);
            }
        }
    }

    public initLoadFromLocalStorage(reportAvikelseItems: ReportItem[]) {
        const itemIds = this.localStorageService.getHiddenReportItems();

        reportAvikelseItems.forEach(reportItem => {
            const feature = this.trvMapService.trvMap?.trvLayer.getFeatureFromWkt(reportItem.wkt);
            const reportAvikelseItemWithFeature: ReportItemWithFeature = {
                description: reportItem.description,
                deliveryType: reportItem.deliveryType,
                wkt: reportItem.wkt,
                seqNr: reportItem.seqNr,
                feature: feature!,
                id: reportItem.id,
            };

            if (itemIds.includes(reportAvikelseItemWithFeature.id!)) {
                feature!.set(HIDDEN, true);
                feature!.setStyle(NvdbReportStyleHidden);
            } else {
                feature!.setStyle(NvdbReportStyle);
            }

            feature!.set(REPORT_ITEM_ID, reportAvikelseItemWithFeature.id);
            this.addReportFeatureToMap(feature!)
            this.unsentReportAvikelseItem.push(reportAvikelseItemWithFeature);
        });
    }

    public removeFeatureFromMap(feature: Feature) {
        const layer = this.trvExtension!.reportLayer;
        const source = layer.getSource();
        source?.removeFeature(feature);
    }

    public highlightAvikelseItem(reportAvikelseItem: ReportItemWithFeature) {
        if (reportAvikelseItem.feature.get(HIDDEN) || reportAvikelseItem.feature.get(FOCUSED)) return;

        reportAvikelseItem.feature.setStyle(NvdbReportStyleHover);
    }

    public resetAllFeatureStyles() {
        this.trvExtension?.reportLayer
            .getSource()
            ?.getFeatures()
            .forEach(feature => {
                if (feature.get(HIDDEN)) feature.setStyle(NvdbReportStyleHidden);
                else if (feature.get(FOCUSED)) feature.setStyle(NvdbReportStyleFocused);
                else feature.setStyle(NvdbReportStyle);
            });
    }

    public toggleVisibility(event: any, avikelseItemWithFeature: ReportItemWithFeature) {
        event.stopPropagation()

        const feature = avikelseItemWithFeature.feature;
        if (feature) {
            if (feature.get(HIDDEN)) {
                //feature was visibly, make it invisible
                feature.setStyle(NvdbReportStyleHidden);
            } else if (feature.get(FOCUSED)) {
                feature.setStyle(NvdbReportStyleFocused);
            } else {
                feature.setStyle(NvdbReportStyle);
            }

            feature.set(HIDDEN, !feature.get(HIDDEN));
            const hiddenItems = this.unsentReportAvikelseItem.filter(a => a.feature.get(HIDDEN)).map(a => a.id!);
            this.resetAllFeatureStyles();

            this.localStorageService.setHiddenReportItems(hiddenItems);
        }
        avikelseItemWithFeature.feature = feature;
    }

    public sendInAvikelser(reportName: string, email: string, name: string, phoneNr: string, itemSeqNrToSend: number[]) {
        //Generate group
        let avikelseItems: ReportItem[] = [];
        this.unsentReportAvikelseItem.forEach((item, index) => {
            if (!itemSeqNrToSend.includes(index)) return;

            const avikelseItem: ReportItem = {
                description: item.description,
                deliveryType: item.deliveryType,
                wkt: item.wkt,
                seqNr: index,
            };
            avikelseItems.push(avikelseItem);
        });

        let avikelseGroup: Report = {
            name: reportName,
            date: DateTime.now().toISO()!,
            reportItems: avikelseItems,
            uid: generateGUID(),
            email: email,
            author: name,
            phoneNr: phoneNr,
        };

        this.reportAvikelseApiClient
            .reportAvikelse({
                body: avikelseGroup,
            })
            .pipe(take(1))
            .subscribe({
                next: () => {
                },
                error: error => {
                    console.error(error);
                    this.messageService.error("Ett fel uppstod när ärendet skulle skickas in: " + error);
                },
                complete: () => {
                    let seqNr = 0
                    for (const item of this.unsentReportAvikelseItem) {
                        if (itemSeqNrToSend.includes(seqNr)) this.removeAvikelseItemWithFeature(item);
                        seqNr++
                    }

                    this.messageService.success("Ärendet skickades in.");
                },
            });
    }

    public getGeometryTypeFromWkt(wkt: string): GeometryType | null {
        let wktFromat = new WKT();
        let feature = wktFromat.readFeature(wkt, {
            dataProjection: "EPSG:3006",
            featureProjection: "EPSG:3006",
        });

        return feature.getGeometry()!.getType() as GeometryType;
    }

    addReportFeatureToMap(feature: Feature) {
        if (feature.get(HIDDEN)) {
            feature.setStyle(NvdbReportStyleHidden);
        } else if (feature.get(FOCUSED)) {
            feature.setStyle(NvdbReportStyleFocused);
        } else {
            feature.setStyle(NvdbReportStyle);
        }

        this.trvExtension!.reportLayer.getSource()?.addFeature(feature!);
    }
}

export interface ReportItemWithFeature extends ReportItem {
    feature: Feature;
}

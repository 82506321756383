import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { catchError, combineLatest, from, lastValueFrom, map, mergeMap, Observable, of, startWith, switchMap, take, tap } from "rxjs";
import { inject } from "@angular/core";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { AuthenticationService } from "@app/service/authentication.service";

export function authenticationGuard(): CanActivateFn {
    return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        const oauthService: OidcSecurityService = inject(OidcSecurityService);
        const router: Router = inject(Router);
        const authService: AuthenticationService = inject(AuthenticationService);

        return oauthService.checkAuth().pipe(
            take(1),
            switchMap(({ isAuthenticated, accessToken }) => {
                if (!isAuthenticated) {
                    localStorage.setItem("returnUrl", state.url);
                    router.navigate(["/login"]);
                    return of(false);
                }

                if (state.url === "/login") {
                    router.navigateByUrl("/");
                    return of(false);
                } else {

                    try {
                        const buf = atob(accessToken);
                        const json = JSON.parse(buf);
                        if (json.access_token) {
                            let key = null;
                            for (let i = 0; i < sessionStorage.length; i++) {
                                key = sessionStorage.key(0);
                                if (key?.startsWith("0-")) {
                                    break;
                                }
                            }
                            if (key) {
                                const data = sessionStorage.getItem(key);
                                if (data) {
                                    let dataJson = JSON.parse(data);
                                    dataJson.authnResult.access_token = json.access_token;
                                    dataJson.authzData = json.access_token;
                                    sessionStorage.setItem(key, JSON.stringify(dataJson));
                                }
                            }
                        }
                    } catch {}

                    return oauthService.getPayloadFromAccessToken().pipe(
                        map(payload => {
                            let roles: string[] = [];
                            if (typeof payload.role === "string") {
                                roles = [payload.role];
                            } else if (Array.isArray(payload.role)) {
                                roles = payload.role;
                            }

                            //Check if user has any role, if length 0 -> not authorized
                            if (roles.length == 0) {
                                router.navigate(["/not-authorized"]);
                                return false;
                            }

                            return true;
                        })
                    );
                }
            })
        );
    };
}

export function authenticationGuardMinAjorhallare(): CanActivateFn {
    return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        const oauthService: OidcSecurityService = inject(OidcSecurityService);
        const router: Router = inject(Router);
        const authService: AuthenticationService = inject(AuthenticationService);

        return oauthService.checkAuth().pipe(
            take(1),
            switchMap(({ isAuthenticated, accessToken }) => {
                if (!isAuthenticated) {
                    localStorage.setItem("returnUrl", state.url);
                    router.navigate(["/login"]);
                    return of(false);
                }

                if (state.url === "/login") {
                    router.navigateByUrl("/");
                    return of(false);
                } else {
                    try {
                        const buf = atob(accessToken);
                        const json = JSON.parse(buf);
                        if (json.access_token) {
                            let key = null;
                            for (let i = 0; i < sessionStorage.length; i++) {
                                key = sessionStorage.key(0);
                                if (key?.startsWith("0-")) {
                                    break;
                                }
                            }
                            if (key) {
                                const data = sessionStorage.getItem(key);
                                if (data) {
                                    let dataJson = JSON.parse(data);
                                    dataJson.authnResult.access_token = json.access_token;
                                    dataJson.authzData = json.access_token;
                                    sessionStorage.setItem(key, JSON.stringify(dataJson));
                                }
                            }
                        }
                    } catch {}

                    return oauthService.getPayloadFromAccessToken().pipe(
                        map(payload => {
                            if (!authService.initialized) {
                                let roles: string[] = [];
                                if (typeof payload.role === "string") {
                                    roles = [payload.role];
                                } else if (Array.isArray(payload.role)) {
                                    roles = payload.role;
                                }

                                return roles.includes(authService.AJOURHALLARE_ROLE);
                            }

                            if (authService.isCurrentMinAjourhallare) {
                                return true;
                            } else {
                                router.navigate(["/home"]);
                                return false;
                            }
                        })
                    );
                }
            })
        );
    };
}

export function authenticationGuardMinAdmin(): CanActivateFn {
    return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        const oauthService: OidcSecurityService = inject(OidcSecurityService);
        const router: Router = inject(Router);
        const authService: AuthenticationService = inject(AuthenticationService);

        return oauthService.checkAuth().pipe(
            take(1),
            switchMap(({ isAuthenticated }) => {
                if (!isAuthenticated) {
                    localStorage.setItem("returnUrl", state.url);
                    router.navigate(["/login"]);
                    return of(false);
                }

                if (state.url === "/login") {
                    router.navigateByUrl("/");
                    return of(false);
                } else {
                    return oauthService.getPayloadFromAccessToken().pipe(
                        map(payload => {
                            if (!authService.initialized) {
                                let roles: string[] = [];
                                if (typeof payload.role === "string") {
                                    roles = [payload.role];
                                } else if (Array.isArray(payload.role)) {
                                    roles = payload.role;
                                }

                                return roles.includes(authService.ADMIN_ROLE);
                            }

                            if (authService.isCurrentMinAdmin) {
                                return true;
                            } else {
                                router.navigate(["/home"]);
                                return false;
                            }
                        })
                    );
                }
            })
        );
    };
}

import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, ViewChild } from '@angular/core'
import { TrvModalRef } from "trv-ng-common";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";


@Component({
    selector: 'app-map-export-preview-modal',
    templateUrl: './map-export-preview-modal.component.html',
    styleUrls: ['./map-export-preview-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [],
})
export class MapExportPreviewModalComponent {
    public src = "";
    public isPng = false;

    download: EventEmitter<null> = new EventEmitter<null>();

    constructor(
        private modalRef: TrvModalRef<MapExportPreviewModalComponent>,
        private sanitizer: DomSanitizer
    ) {}

    close(): void {
        this.modalRef.close();
    }

    dismiss(): void {
        this.modalRef.dismiss();
    }

    public getSafeUrl(url: string): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}

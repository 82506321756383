import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faHand } from "@fortawesome/free-solid-svg-icons";
import { MapTools, TrvNgMapService, MapToolsType } from "@trafikverket/trv-ng-map";

import {
    svgArrowLeft,
    svgEraser,
    svgGoogleMaps,
    svgHand,
    svgInfo,
    svgMagnifyingGlass,
    svgMagnifyingPlus,
    svgMagnifyingPlusFilled,
    svgPin,
    svgPrinter,
    svgRightArrow,
    svgRuler,
    svgSweden,
    svgTimeBack,
} from "src/app/shared/utils/svg-helper-data";
import { mapTo, take } from "rxjs";
import { NavigationState, NvdbNavigationService } from "src/app/service/nvdb-navigation.service";
import { SvgIcon } from "src/app/shared/utils/svg-helper";
import { SWEDEN_VIEW } from "src/app/shared/constants";
import { TrvPopoverRef, TrvTooltipPosition, TrvTooltipDirective, TrvPopoverDirective } from "trv-ng-common";
import { BetraktelseDatumComponent } from "../betraktelse-datum/betraktelse-datum.component";
import { TrvMapExtensionService } from "src/app/service/trv-map-extension.service";
import { DateTime } from "luxon";
import { environment } from "src/environments/environment";
import { MapButtonComponent } from "../map-button/map-button.component";

@Component({
    selector: "app-map-buttons",
    templateUrl: "./map-buttons.component.html",
    styleUrls: ["./map-buttons.component.scss"],
    standalone: true,
    imports: [MapButtonComponent, TrvTooltipDirective, TrvPopoverDirective],
})
export class MapButtonsComponent implements OnInit {
    public environment = environment;

    public faHand: IconProp = faHand as IconProp;

    public MapTools = MapTools;

    public firstMaptool: string = "-100";

    //Icons import
    public printerIcon: SvgIcon = svgPrinter;
    public googleMapsIcon: SvgIcon = svgGoogleMaps;
    public rulerIcon: SvgIcon = svgRuler;
    public pinIcon: SvgIcon = svgPin;
    public timeBackIcon: SvgIcon = svgTimeBack;
    public infoIcon: SvgIcon = svgInfo;
    public handIcon: SvgIcon = svgHand;
    public zoomIcon: SvgIcon = svgMagnifyingGlass;
    public zoomIconPlus: SvgIcon = svgMagnifyingPlusFilled;
    public eraserIcon: SvgIcon = svgEraser;
    public swedenIcon: SvgIcon = svgSweden;
    public svgRightArrow = svgRightArrow;
    public svgArrowLeft = svgArrowLeft;

    public prevInit = false;

    public toolTipPositions: TrvTooltipPosition[] = ["left", "bottom"];

    public initialized: boolean = false;

    public betraktelseComponent = BetraktelseDatumComponent;
    @ViewChild("datePopoverRef") datePopoverRef!: TrvPopoverDirective;

    constructor(
        @Inject(TrvNgMapService) public trvMapService: TrvNgMapService,
        @Inject(TrvMapExtensionService)
        public trvMapExtensionService: TrvMapExtensionService,
        @Inject(NvdbNavigationService)
        public nvdbNavigationService: NvdbNavigationService,
        private cdref: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.cdref.detectChanges();

        this.trvMapService.onMapLoaded().subscribe(() => {
            this.initialized = true;
        });

        //this.trvMapService.mapLoaded.pipe(take(1)).subscribe(_l => {
        //    this.initialized = true;
        //});
    }

    public getTool(): MapToolsType {
        if (this.trvMapService.trvMap) {
            return this.trvMapService.trvMap.activeMapTool?.value;
        }
        return 0;
    }

    public setMapTool(mapTool: MapToolsType) {
        if (!this.trvMapService.trvMap) return;

        if (this.trvMapService.trvMap.activeMapTool?.value == mapTool) mapTool = MapTools.Pointer;

        this.trvMapService.trvMap.changeActiveMapTool(mapTool);
    }

    public eraser() {
        this.trvMapService.trvMap?.trvInteractions.eraserTool();
    }

    public gotoSweden() {
        this.trvMapService.trvMap?.setMapStateWithCoord(SWEDEN_VIEW.centerCoordinate, SWEDEN_VIEW.zoom, undefined, undefined, 1000, true);
    }

    showPopover(event: Event) {
        if (this.datePopoverRef.active) this.datePopoverRef.hide();
        else this.datePopoverRef.show();

        this.cdref.detectChanges();
    }

    onShowComponent(ref: TrvPopoverRef<BetraktelseDatumComponent>): void {
        ref.componentInstance.closeEmitter.pipe(take(1)).subscribe(() => {
            this.datePopoverRef.hide();
            this.cdref.detectChanges();
        });
    }

    onAfterCloseWithType(event: any): void {
        this.cdref.detectChanges();
    }

    public showBetraktelseNotice() {
        let res = true;
        const trvExtenstion = this.trvMapExtensionService.trvMapExtension;
        if (trvExtenstion) {
            const today = DateTime.now();
            if (trvExtenstion.currentBetraktelsedatum.hasSame(today, "day")) res = false;
        }

        return res;
    }

    protected readonly NavigationState = NavigationState;

    changeNavigationState(newState: NavigationState) {
        if (newState == this.nvdbNavigationService.navigationState) {
            const checkInfo = newState == NavigationState.InfoClick && this.trvMapService.trvMap!.activeMapTool.value == MapTools.NvdbInfo;
            const checkExport = newState == NavigationState.PrintMap && this.trvMapService.trvMap!.activeMapTool.value == MapTools.ExportRectangle;
            
            if (checkInfo || checkExport) {
                this.setMapTool(MapTools.Pointer);
                if(checkExport){
                    this.nvdbNavigationService.navigationState = NavigationState.MainMenu;
                    this.nvdbNavigationService.navigationStateChanged.emit();
                }
                    
                return;
            }
        }
        this.nvdbNavigationService.navigationState = newState;
        this.nvdbNavigationService.navigationStateChanged.emit();
    }
}

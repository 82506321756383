<div class="c-login c-login--fullscreen">
    <div class="c-login__background"></div>
    <div class="c-login__brand-header">
        <a href="#">
            <img src="assets/svg/trv-logo--red-bg.svg" alt="" class="c-login__brand-header-logo" />
        </a>
    </div>
    <div class="c-login__form">
        <div class="c-login__form-inner">
            <div class="c-login__header">
                <h1 class="c-login__header__heading">
                    <span>Obehörig</span>
                    <span class="heading-divider">
                        <fa-icon [icon]="faCircle"></fa-icon>
                    </span>
                    <span>NVDB Dataleverans</span>
                </h1>
            </div>
            <div class="p-4">
                Du är inte behörig att komma åt NVDB dataleverans. <br> Ansök om behörighet här: <a href="https://arthur.trafikverket.local/arthur/ModAccnt.asp" target="_blank">Arthur</a>
            </div>
            <div class="ps-4 pe-4">
                <div class="externalLoginNavigation">
                    <a [routerLink]="['/login']" class="c-login__btn-link btn btn-dark">
                        Tillbaka till inloggningssidan
                        <span class="c-login__btn-link__icon">
                            <fa-icon [icon]="faRight"></fa-icon>
                        </span>
                    </a>
                </div>
            </div>
            <div class="c-login__form-footer">
                <h3>För support/behörighetsfrågor eller vid fel</h3>
                <p>
                    Trafikverket användarstöd IT Tel: 010-125 10 10
                    <br />
                    Mer om NVDB Dataleverans på
                    <a href="https://www.trafikverket.se" target="_blank">trafikverket.se</a>
                </p>
                <div class="">
                    <!--<small *ngIf="user">Inloggad som: <b>{{user.firstName}} {{user.lastName}}</b> ({{user.username}})</small>-->
                </div>
            </div>
        </div>
    </div>
</div>

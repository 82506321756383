import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { take } from "rxjs";

@Component({
    selector: "app-logout-page",
    templateUrl: "./logout-page.component.html",
    styleUrls: ["./logout-page.component.scss"],
    standalone: true,
})
export class LogoutPageComponent implements OnInit {
    constructor(
        private oidcService: OidcSecurityService,
        private router: Router
    ) {}


    ngOnInit(): void {
        this.oidcService
            .isAuthenticated()
            .pipe(take(1))
            .subscribe(isAuth => {
                if (!isAuth) {
                    this.router.navigateByUrl("/login");
                } else {
                    this.oidcService
                        .logoff()
                        .pipe(take(1))
                        .subscribe(() => {

                        });
                }
            });
    }
}


<div class="c-report__list__report" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()" (click)="reportAvikelseService.setReportAvikelseItemToCreateOrEdit(avikelseItemWithFeature);">
  @if ((avikelseItemWithFeature.feature | geometryType) == GeometryType.POINT) {
    <fa-icon [icon]="faMapMarkerAlt" class="me-2 geom-icon"></fa-icon>
  }
  @if (
    (avikelseItemWithFeature.feature | geometryType) == GeometryType.LINE_STRING || (avikelseItemWithFeature.feature | geometryType) == GeometryType.MULTI_LINE_STRING
    ) {
    <fa-icon
      [icon]="faRoute"
      class="me-2 geom-icon"
    ></fa-icon>
  }
  @if ((avikelseItemWithFeature.feature | geometryType) == GeometryType.POLYGON || (avikelseItemWithFeature.feature | geometryType) == GeometryType.MULTI_POLYGON) {
    <fa-icon
      [icon]="faVectorSquare"
      class="me-2 geom-icon"
    ></fa-icon>
  }
  <span class="c-report__list__report__name">
    {{ avikelseItemWithFeature.description }}
  </span>
  <div (click)="reportAvikelseService.toggleVisibility($event, this.avikelseItemWithFeature)" class="c-report__list__report__show" [ngClass]="{'hidden': !(avikelseItemWithFeature.feature | featureVisible)}">
    @if (avikelseItemWithFeature.feature | featureVisible) {
      <fa-icon [icon]="faEye"></fa-icon>
    }
    @if (!(avikelseItemWithFeature.feature | featureVisible)) {
      <fa-icon [icon]="faEyeSlash"></fa-icon>
    }
  </div>
  <div class="c-report__list__report__handle">
    <button
      class="btn btn-link c-report__list__report__name__menu"
      [trvPopover]="popoverComponent"
      [position]="popoverPosition"
      aria-label="Avvikelse alternativ"
      (shown)="onShowComponent($event)"
      (afterCloseWithType)="onAfterCloseWithType($event)"
      >
      <fa-icon [icon]="faEllipsisV"></fa-icon>
    </button>
  </div>
</div>


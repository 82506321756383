<div class="header">
    <div>Information från förvaltningen</div>
    <button class='btn btn-link' (click)='close()'
            aria-label="Stäng modal"
    >
        <app-svg-icon [svgIcon]='crossIcon'></app-svg-icon>
    </button>
</div>
<div [innerHtml]="content" class="body"></div>
<div class="footer">
    <div class="checkbox">
        <input type='checkbox' class='form-check-input' [formControl]='dontShowAgain'
               id='dontShowAgain'/>
        <label class='form-check-label' for='dontShowAgain'>Visa inte detta meddelande igen</label>
    </div>
    <button type="button" class="btn btn-link" (click)="close()">Stäng</button>
</div>

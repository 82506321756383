import { Component, Inject, OnInit } from "@angular/core";
import { NvdbNavigationService } from "src/app/service/nvdb-navigation.service";
import { RouterLink } from "@angular/router";
import { AuthenticationService } from "@app/service/authentication.service";
import { ReportService } from "@app/service/report.service";

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
    standalone: true,
    imports: [RouterLink],
})
export class HomePageComponent implements OnInit {
    ngOnInit(): void {}

    constructor(
        @Inject(NvdbNavigationService)
        public nvdbNavigation: NvdbNavigationService,
        @Inject(AuthenticationService)
        public authService: AuthenticationService,
        public reportService: ReportService
    ) {
        this.nvdbNavigation.hideHamburgerMenu = true;
        this.nvdbNavigation.backLink = "";
    }
}

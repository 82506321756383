import {Inject} from "@angular/core";
import {
    extendMapTools,
    MapTools,
    OlOverlayComponent,
    TrvInteractions,
    TrvMap,
    TrvNgMapOverlayService,
    TrvNgMapService,
    getExtentFromCoordinates,
    LayerListItem, bluePinStyle2,
} from "@trafikverket/trv-ng-map";

import {Collection, Feature, MapBrowserEvent} from "ol";
import {LineString, MultiLineString, MultiPoint, Point, Polygon} from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import ImageWMS from "ol/source/ImageWMS";
import {Icon, Text, Style, Fill, Stroke, RegularShape} from "ol/style";
import {Coordinate, add} from "ol/coordinate";
import {Draw, Modify, Select, Snap, Translate} from "ol/interaction";
import {DrawEvent} from "ol/interaction/Draw";
import {TranslateEvent} from "ol/interaction/Translate";
import {Pixel} from "ol/pixel";
import {Extent, getCenter, getTopLeft} from "ol/extent";
import {Report, ReportItem, ReportService} from "../service/report.service";
import {environment} from "src/environments/environment";
import {NetDbService} from "../service/net-db.service";
import {buffer as extentBuffer} from "ol/extent";
import {fromExtent} from "ol/geom/Polygon";
import {layer} from "@fortawesome/fontawesome-svg-core";
import CircleStyle from "ol/style/Circle";
import {finalize, firstValueFrom, fromEvent, Subject, take, takeUntil} from "rxjs";
import {TrvMapExtensionService} from "../service/trv-map-extension.service";
import {ROUTES} from "@angular/router";
import {RouteService} from "../service/route.service";
//import { ArcIndexes, GeometryObjectA, Properties } from "topojson-specification";
import {NavigationState, NvdbNavigationService} from "../service/nvdb-navigation.service";
import {TrvGlobalMessagesService, TrvModalService} from "trv-ng-common";
import {FeatureTypeRequestMultiple} from "../_api/dataleverans/models/feature-type-request-multiple";
import {ModifyEvent} from "ol/interaction/Modify";
import BaseEvent from "ol/events/Event";
import {DateTime} from "luxon";
import {faL} from "@fortawesome/free-solid-svg-icons";
import Geometry from "ol/geom/Geometry";
import {FeatureTypeRequest} from "../_api/dataleverans/models";
import {set} from "ol/transform";
import {ReportItemWithFeature, ReportAvikelseService} from "../service/report-avikelse.service";
import TileWMS from "ol/source/TileWMS";
import GeoJSON from "ol/format/GeoJSON";
import {get as getProjection, transform} from "ol/proj";
import {LoadingSpinnerComponent} from "@components/utils/loading-spinner/loading-spinner.component";
import {
    NvdbReportStyle,
    NvdbReportStyleFocused,
    NvdbReportStyleHidden,
    NvdbReportStyleHover,
    NvdbReportStyleLoadingSnapGrip,
} from "./trv-map-extension-styles";
import {FOCUSED, HIDDEN, REPORT_ITEM_ID} from "@shared/constants";
import {GeometryType} from "@shared/enums";

export class TrvMapExtension {
    public tempLayer?: VectorLayer<VectorSource>;
    public pinLayer?: VectorLayer<VectorSource>;
    public reportLayer!: VectorLayer<VectorSource>;
    public infoClickTempLayer!: VectorLayer<VectorSource>;
    public infoClickBgTempLayer!: VectorLayer<VectorSource>;

    public pointDraw!: Draw;
    public isDrawingPoint: boolean = false;

    public lineDraw!: Draw;
    public isDrawingLine: boolean = false;
    public numberOfLinesCurrentlyGettingDrawn: number = 0;

    public polygonDraw!: Draw;
    public isDrawingPolygon: boolean = false;

    public reportModify!: Modify;
    public reportTranslate!: Translate;
    public isTranslating: boolean = false;

    public reportTranslateFeaturesCollection = new Collection<Feature<Geometry>>();

    //Info
    public hasSearchedForInfoObjects = false

    public currentInfoObjects: any[] = [];
    public currentInfoObject: number = 0;
    public currentInfoObjectsChanged: Subject<void> = new Subject<void>();

    public currentInfoObjectsMultiple: any = {};
    public currentInfoLoading: boolean = false;

    public currentDataSlagLayer!: LayerListItem;

    public currentInfoClickName = "";
    public currentInfoClickMetaKey = "";
    public infoClickMetaKeys: string[] = [];
    public currentInfoclickDate = new Date();
    private unsubscribeInfoClick$ = new Subject<void>();
    public lastInfoClickCoordinate: Coordinate = [0, 0];
    public lastInfoClickExtent: Extent = [];
    public lastInfoClickWasInCoord: boolean = false;
    public showBackToInfoClickInCoord: boolean = false;

    public currentBetraktelsedatum: DateTime = DateTime.now();

    public infoClickFeatureStyle = new Style({
        stroke: new Stroke({
            color: "rgba(0,255,255, 0.4)",
            width: 18,
        }),
        image: new CircleStyle({
            radius: 16,
            stroke: new Stroke({
                color: "rgba(0,255,255, 0.4)",
                width: 7,
            }),
        }),
    });

    public infoClickFeatureStyle2 = new Style({
        fill: new Fill({
            color: "rgba(0,0,128, 0.5)",
        }),
        stroke: new Stroke({
            color: "rgba(0,0,128, 0.5)",
            width: 8,
        }),
        image: new CircleStyle({
            radius: 16,
            stroke: new Stroke({
                color: "rgba(0, 0, 128, 0.5)",
                width: 3,
            }),
        }),
    });

    public lineDrawStyle = new Style({
        stroke: new Stroke({
            color: "red",
            width: 3,
        }),
    });

    public lineDrawHoverStyle = new Style({
        stroke: new Stroke({
            color: "rgba(30, 92, 136, 1)",
            width: 6,
        }),
    });

    public polygonDrawStyle = new Style({
        fill: new Fill({
            color: "rgba(215, 0, 0, 0.2)",
        }),
        stroke: new Stroke({
            color: "rgba(215, 0, 0, 0.7)",
            width: 3,
        }),
    });

    public polygonDrawHoverStyle = new Style({
        fill: new Fill({
            color: "rgba(30, 92, 136, 0.4)",
        }),
        stroke: new Stroke({
            color: "rgba(30, 92, 136, 1)",
            width: 6,
        }),
    });

    public infoClickMouseStyle = new Style({
        image: new Icon({
            anchor: [-1, 0.5],
            anchorXUnits: "fraction",
            anchorYUnits: "fraction",
            scale: 0.5,
            src: "assets/img/info.png",
        }),
        text: new Text({
            font: "17px Calibri,sans-serif",
            fill: new Fill({color: "#000"}),
            text: "Info",
            stroke: new Stroke({
                color: "#fff",
                width: 2,
            }),
            offsetY: 3,
            offsetX: 40,

            textAlign: "left",
        }),
    });

    public removeReportMouseStyle = new Style({
        image: new Icon({
            anchor: [-1, 0.5],
            anchorXUnits: "fraction",
            anchorYUnits: "fraction",
            scale: 1,
            src: "assets/img/bin.png",
        }),
        text: new Text({
            font: "17px Calibri,sans-serif",
            fill: new Fill({color: "#000"}),
            text: "",
            stroke: new Stroke({
                color: "#fff",
                width: 2,
            }),
            offsetY: 3,
            offsetX: 40,

            textAlign: "left",
        }),
    });

    public editReportMouseStyle = new Style({
        image: new Icon({
            anchor: [-1, 0.5],
            anchorXUnits: "fraction",
            anchorYUnits: "fraction",
            scale: 1,
            src: "assets/img/edit.png",
        }),
    });

    constructor(
        @Inject(TrvNgMapService) private trvMapService: TrvNgMapService,
        @Inject(TrvNgMapOverlayService)
        private trvMapOverlayService: TrvNgMapOverlayService,
        @Inject(NvdbNavigationService)
        private nvdbNavigation: NvdbNavigationService,
        @Inject(ReportService) private reportService: ReportService,
        @Inject(ReportAvikelseService) private reportAvikelseService: ReportAvikelseService,
        @Inject(NetDbService) private netDbService: NetDbService,
        @Inject(TrvMapExtensionService)
        private trvMapExtensionService: TrvMapExtensionService,
        @Inject(RouteService) private routeService: RouteService,
        @Inject(TrvGlobalMessagesService) public globalMessagesService: TrvGlobalMessagesService,
        @Inject(TrvModalService) public trvModalService: TrvModalService
    ) {
        this.init();
    }

    public init() {
        //Test add more maptools
        this.initExtendMapTools();

        //Add layers
        this.tempLayer = new VectorLayer({
            source: new VectorSource({}),
        });

        this.pinLayer = new VectorLayer({
            source: new VectorSource({}),
        });

        this.reportLayer = new VectorLayer({
            source: new VectorSource({}),
        });

        this.infoClickBgTempLayer = new VectorLayer({
            source: new VectorSource({}),
        });

        this.infoClickTempLayer = new VectorLayer({
            source: new VectorSource({}),
        });

        this.trvMapService.trvMap?.trvLayer.addLayer(this.pinLayer);
        this.trvMapService.trvMap?.trvLayer.addLayer(this.reportLayer);
        this.trvMapService.trvMap?.trvLayer.addLayer(this.infoClickBgTempLayer);
        this.trvMapService.trvMap?.trvLayer.addLayer(this.infoClickTempLayer);
        this.trvMapService.trvMap?.trvLayer.addLayer(this.tempLayer, true);

        const trvInteractions: TrvInteractions = this.trvMapService.trvMap!.trvInteractions;
        trvInteractions.simpleRulerTool = false;

        trvInteractions.registerMapToolInteraction({
            mapTool: MapTools.NvdbInfo,
            initFunc: this.infoClickReset.bind(this),
            resetFunc: this.infoClickReset.bind(this),
            clickFunc: this.infoClick.bind(this),
            moveFunc: this.infoMove.bind(this),
        });

        //Interactions for dataleverans only
        if (environment.application == "NvdbDataleverans") {
            trvInteractions.registerMapToolInteraction({
                mapTool: MapTools.Pointer,
                clickFunc: this.reportClick.bind(this),
                moveFunc: this.reportMove.bind(this),
            });

            trvInteractions.registerMapToolInteraction({
                mapTool: MapTools.DataLeveransReportPoint,
                initFunc: this.reportDataLeveransPointInit.bind(this),
                resetFunc: this.reportReset.bind(this),
                clickFunc: this.reportClick.bind(this),
                moveFunc: this.reportMove.bind(this),
            });

            trvInteractions.registerInteractionStateForTool(MapTools.DataLeveransReportPoint, trvInteractions.DoubleClickZoom, false);

            trvInteractions.registerMapToolInteraction({
                mapTool: MapTools.DataLeveransReportLine,
                initFunc: this.reportDataLeveransLineInit.bind(this),
                resetFunc: this.reportReset.bind(this),
                clickFunc: this.reportClick.bind(this),
                moveFunc: this.reportMove.bind(this),
            });

            trvInteractions.registerMapToolInteraction({
                mapTool: MapTools.DataLeveransReportPolygon,
                initFunc: this.reportDataLeveransPolygonInit.bind(this),
                resetFunc: this.reportReset.bind(this),
                clickFunc: this.reportClick.bind(this),
                moveFunc: this.reportMove.bind(this),
            });

            trvInteractions.registerMapToolInteraction({
                mapTool: MapTools.DataLeveransReportTranslate,
                initFunc: this.reportDataLeveransTranslateInit.bind(this),
                resetFunc: this.reportDataLeveransTranslateReset.bind(this),
                moveFunc: this.reportDataLeveransTranslateMove.bind(this),
            });

            trvInteractions.registerMapToolInteraction({
                mapTool: MapTools.DataLeveransReportEdit,
                initFunc: this.reportDataLeveransEditInit.bind(this),
                resetFunc: this.reportDataLeveransEditReset.bind(this),
            });

            trvInteractions.registerMapToolInteraction({
                mapTool: MapTools.DataLeveransReportDelete,
                initFunc: this.reportDataLeveransDeleteInit.bind(this),
                resetFunc: this.reportDataLeveransDeleteReset.bind(this),
                clickFunc: this.reportDataLeveransDeleteClick.bind(this),
                moveFunc: this.reportDataLeveransDeleteMove.bind(this),
            });
        }

        //Interactions for dataleverans only
        if (environment.application == "NvdbPåKarta") {
            trvInteractions.registerMapToolInteraction({
                mapTool: MapTools.Pointer,
                clickFunc: this.reportClick.bind(this),
                moveFunc: this.reportMove.bind(this),
            });

            trvInteractions.registerMapToolInteraction({
                mapTool: MapTools.PaKartaReportPoint,
                initFunc: this.reportPaKartaPointInit.bind(this),
                resetFunc: this.reportReset.bind(this),
                clickFunc: this.reportClick.bind(this),
                moveFunc: this.reportMove.bind(this),
            });

            trvInteractions.registerMapToolInteraction({
                mapTool: MapTools.PaKartaReportLine,
                initFunc: this.reportPaKartaLineInit.bind(this),
                resetFunc: this.reportReset.bind(this),
                clickFunc: this.reportClick.bind(this),
                moveFunc: this.reportMove.bind(this),
            });

            trvInteractions.registerMapToolInteraction({
                mapTool: MapTools.PaKartaReportPolygon,
                initFunc: this.reportPaKartaPolygonInit.bind(this),
                resetFunc: this.reportReset.bind(this),
                clickFunc: this.reportClick.bind(this),
                moveFunc: this.reportMove.bind(this),
            });

            trvInteractions.registerMapToolInteraction({
                mapTool: MapTools.PaKartaReportTranslate,
                initFunc: this.reportPaKartaTranslateInit.bind(this),
                resetFunc: this.reportPaKartaTranslateReset.bind(this),
                moveFunc: this.reportPaKartaTranslateMove.bind(this),
            });

            trvInteractions.registerMapToolInteraction({
                mapTool: MapTools.PaKartaReportEdit,
                initFunc: this.reportPaKartaEditInit.bind(this),
                resetFunc: this.reportPaKartaEditReset.bind(this),
            });

            trvInteractions.registerMapToolInteraction({
                mapTool: MapTools.PaKartaReportDelete,
                initFunc: this.reportPaKartaDeleteInit.bind(this),
                resetFunc: this.reportPaKartaDeleteReset.bind(this),
                clickFunc: this.reportPaKartaDeleteClick.bind(this),
                moveFunc: this.reportPaKartaDeleteMove.bind(this),
            });
        }

        this.reportService.trvExtension = this;
        this.reportAvikelseService.trvExtension = this;
    }

    /**
     * Extend all custom map tools
     */
    private initExtendMapTools() {
        //Extend common tools
        extendMapTools("NvdbInfo");

        //Extend Dataleverans tools
        extendMapTools("DataLeveransReportPoint");
        extendMapTools("DataLeveransReportLine");
        extendMapTools("DataLeveransReportPolygon");

        extendMapTools("DataLeveransReportTranslate");
        extendMapTools("DataLeveransReportEdit");
        extendMapTools("DataLeveransReportDelete");

        //Extend på karta tools
        extendMapTools("PaKartaReportPoint");
        extendMapTools("PaKartaReportLine");
        extendMapTools("PaKartaReportPolygon");

        extendMapTools("PaKartaReportTranslate");
        extendMapTools("PaKartaReportEdit");
        extendMapTools("PaKartaReportDelete");
    }

    /**
     * Method  that resets all features on reportLayer to it's default styles
     */
    public resetAllReportFeatureStyle() {
        //Reset point layer
        for (let feature of this.reportLayer?.getSource()?.getFeatures()!) {
            if (feature.get(HIDDEN)) feature.setStyle(NvdbReportStyleHidden);
            else if (feature.get(FOCUSED) && this.nvdbNavigation.VIEWMODE && feature.getGeometry()?.getType() == GeometryType.POINT) {
                feature.setStyle(bluePinStyle2);
            } else if (feature.get(FOCUSED)) {
                feature.setStyle(NvdbReportStyleFocused);
            } else feature.setStyle(NvdbReportStyle);
        }
    }

    /**
     * Method that finds a report-feature that is being hovered \
     * Priority: Point > Line > Polygon \
     * The method also sets the style of the feature to its corresponding hover-style
     * @param pixel The pixel to check for a feature
     * @param setStyle If true, sets default hover style, false doesn't.
     *
     * @returns A feature, or undefined if no feature is present on the pixel
     */
    private getReportHoverFeatureAllTypes(trvMap: TrvMap, pixel: Pixel, pixelRadius = 10) {
        let pointOrPolygonHoverFeature = trvMap?.trvInteractions.getSmallestFeatureAtPixel(pixel, this.reportLayer);
        if (pointOrPolygonHoverFeature && !pointOrPolygonHoverFeature.get(HIDDEN)) {
            return this.setStyleHover(pointOrPolygonHoverFeature);
        }

        //If no point or polygon found, check of linefeatures
        let lineHoverFeature = this.getReportLineFeature(pixel);
        if (lineHoverFeature && !lineHoverFeature.get(HIDDEN)) {
            return this.setStyleHover(lineHoverFeature);
        }

        this.nvdbNavigation.currentlyHoveredReportItemInMap = null
        document.body.style.cursor = "default";
        return undefined;
    }

    public setStyleHover(feature: Feature) {
        if (this.nvdbNavigation.isMobileDevice) return feature

        this.nvdbNavigation.currentlyHoveredReportItemInMap = feature.get(REPORT_ITEM_ID)
        document.body.style.cursor = "pointer";
        if (!feature.get(HIDDEN) && !feature.get(FOCUSED)) feature.setStyle(NvdbReportStyleHover);
        return feature;
    }

    /**
     * Method that looks for a line feature within a specific radius.
     * @param pixel The evt.pixel to look at
     * @param pixel The radius around the pixel in pixels. Default is 5px
     * @returns
     */
    private getReportLineFeature(pixel: Pixel, radius: number = 5): Feature | undefined {
        let features = this.trvMapService.trvMap?.trvInteractions.getLineFeaturesWithinRadius(pixel, radius, this.reportLayer);
        let hoverFeature: Feature | undefined = undefined;
        if (features && features.length > 0) {
            hoverFeature = features[0];
        }
        return hoverFeature;
    }

    private swedishTranslationGeometryType(feature: Feature): string {
        let name = feature.getGeometry()!.getType();
        if (name == "Point") return "Punkt";

        if (name == "LineString" || name == "MultiLineString") return "Linje";

        if (name == "Polygon") return "Polygon";

        return "";
    }

    public reportRemoveMove(coord: Coordinate, text?: string) {
        this.tempLayer?.getSource()?.clear();

        const point = new Point(coord);
        const feature = new Feature(point);
        if (text) this.removeReportMouseStyle.getText()?.setText(text);
        else this.removeReportMouseStyle.getText()?.setText("");

        feature.setStyle(this.removeReportMouseStyle);

        this.tempLayer?.getSource()?.addFeature(feature);
    }

    //NVDB DATALEVERANS

    public reportDataLeveransPointInit(trvMap: TrvMap) {
        this.pointDraw = new Draw({
            source: this.reportLayer.getSource()!,
            type: "Point",
        });

        trvMap.map.addInteraction(this.pointDraw);

        //Event that triggers when drawing is starting
        this.pointDraw.on("drawstart", (event: DrawEvent) => {
            this.isDrawingPoint = true;
        });

        //Event that triggers when drawing is finished
        this.pointDraw.on("drawend", async (event: DrawEvent) => {
            if (this.routeService.snapToNetType) {
                const extent = event.feature.getGeometry()?.getExtent();
                if (extent) {
                    let loadingFeature = this.trvMapOverlayService.addOverlay(
                        trvMap,
                        LoadingSpinnerComponent,
                        extent,
                        [0, 0],
                        "center-center"
                    ) as OlOverlayComponent;

                    event.feature.setStyle(this.loadingLineSnapToGridStyle);

                    event.feature.setGeometry(
                        new LineString([
                            [extent[0], extent[1]],
                            [extent[0] + 1, extent[1] + 1],
                        ])
                    );

                    try {
                        const closestPoint = await firstValueFrom(this.routeService.getClosestPointsOnRoads(event.feature));
                        const firstCoordinate = closestPoint.getCoordinates()[0][0];
                        const resultingPoint = new Point(firstCoordinate);
                        event.feature.setGeometry(resultingPoint);
                        this.trvMapOverlayService.removeOverlay(trvMap, loadingFeature);
                    } catch (e) {
                        console.log(e);
                        this.globalMessagesService.error("Gick ej att fästa punkten mot nätet.");
                        this.trvMapOverlayService.removeOverlay(trvMap, loadingFeature);
                        this.isDrawingPoint = false;
                        this.reportAvikelseService.removeFeatureFromMap(event.feature);
                        return;
                    }
                }
            }

            //Wait .5 second before we set isDrawing to false.
            setTimeout(() => {
                this.isDrawingPoint = false;
            }, 500);
            // get the drawn feature

            const feature = event.feature;
            feature.setStyle(NvdbReportStyle);

            this.reportService.setActiveItemToCreateOrEdit({feature: feature});
        });
    }

    public reportClick(evt: MapBrowserEvent<any>, trvMap: TrvMap) {
        const pixel = trvMap.map.getEventPixel(evt.originalEvent);

        if (this.isDrawingLine || this.isDrawingPolygon) return;

        // return if desktop and isDrawingPoint. On mobile you cannot hover a point so it is always drawing (kind of)
        if (!this.nvdbNavigation.isMobileDevice && this.isDrawingPoint) return;

        let hoverFeature = this.getReportHoverFeatureAllTypes(trvMap, pixel);

        if (!hoverFeature?.get(REPORT_ITEM_ID)) return;
        const itemId = hoverFeature.get(REPORT_ITEM_ID);

        if (environment.application == "NvdbDataleverans") {
            const item = this.reportService.reports.map(a => a.reportItems.find(a => a.id === itemId))[0];
            if (item || hoverFeature.get(HIDDEN)) return;
            if (itemId == this.reportService.itemToCreateOrEdit) return; // ignore click if the item is already being edited
            this.reportService.setActiveItemToCreateOrEdit(itemId);
        } else {
            const item = this.reportAvikelseService.unsentReportAvikelseItem.find(a => a.id === itemId);
            if (!item || hoverFeature.get(HIDDEN)) return;
            if (itemId == this.reportAvikelseService.reportAvikelseItemToCreateOrEdit) return; // ignore click if the item is already being edited
            this.reportAvikelseService.setReportAvikelseItemToCreateOrEdit(item);
        }
    }

    public reportDataLeveransLineInit(trvMap: TrvMap) {
        this.lineDraw = new Draw({
            source: this.reportLayer.getSource()!,
            type: "LineString",
        });

        trvMap.map.addInteraction(this.lineDraw);

        //Event that triggers when drawing is starting
        this.lineDraw.on("drawstart", (event: DrawEvent) => {
            this.isDrawingLine = true;
            this.numberOfLinesCurrentlyGettingDrawn++;
        });
        //Event that triggers when drawing is finished
        this.lineDraw.on("drawend", async (event: DrawEvent) => {
            if (this.routeService.snapToNetType) {
                const centerRightOfExtent = getCenter(getExtentFromCoordinates((event.feature.getGeometry() as LineString).getCoordinates()));

                let loadingFeature = this.trvMapOverlayService.addOverlay(
                    trvMap,
                    LoadingSpinnerComponent,
                    centerRightOfExtent,
                    [0, 0],
                    "center-center"
                ) as OlOverlayComponent;

                event.feature.setStyle(NvdbReportStyleLoadingSnapGrip);

                try {
                    event.feature.setGeometry(await firstValueFrom(this.routeService.getClosestPointsOnRoads(event.feature)));
                    this.trvMapOverlayService.removeOverlay(trvMap, loadingFeature);
                } catch (e) {
                    console.log(e);
                    this.globalMessagesService.error("Gick ej att fästa linjen mot nätet.");
                    this.trvMapOverlayService.removeOverlay(trvMap, loadingFeature);
                    this.isDrawingPoint = false;
                    this.reportAvikelseService.removeFeatureFromMap(event.feature);
                    return;
                }
            }

            event.feature.setStyle(NvdbReportStyle);

            //Wait .5 second before we set isDrawing to false.
            setTimeout(() => {
                this.numberOfLinesCurrentlyGettingDrawn--;

                if (this.numberOfLinesCurrentlyGettingDrawn === 0) this.isDrawingLine = false;
            }, 500);

            // get the drawn feature
            const feature = event.feature;
            if (feature.get(HIDDEN)) return

            this.reportService.setActiveItemToCreateOrEdit({feature: feature});
        });
    }


    public reportDataLeveransPolygonInit(trvMap: TrvMap) {
        this.polygonDraw = new Draw({
            source: this.reportLayer.getSource()!,
            type: "Polygon",
        });

        trvMap.map.addInteraction(this.polygonDraw);

        //Event that triggers when drawing is starting
        this.polygonDraw.on("drawstart", (event: DrawEvent) => {
            this.isDrawingPolygon = true;
        });

        //Event that triggers when drawing is finished
        this.polygonDraw.on("drawend", (event: DrawEvent) => {
            //Wait .5 second before we set isDrawing to false.
            setTimeout(() => {
                this.isDrawingPolygon = false;
            }, 500);

            const feature = event.feature;

            if (feature.get(HIDDEN)) return
            feature.setStyle(NvdbReportStyle);
            // this.openReportOverlayFromHoverFeature(feature);
            this.reportService.setActiveItemToCreateOrEdit({feature: feature});
        });
    }


    public reportDataLeveransTranslateInit(trvMap: TrvMap) {
        let source = this.reportLayer.getSource();
        if (source) {
            this.reportTranslate = new Translate({
                features: this.reportTranslateFeaturesCollection,
            });

            trvMap.map.addInteraction(this.reportTranslate);

            this.reportTranslate.on("translatestart", (event: TranslateEvent) => {
                this.isTranslating = true;
            });

            this.reportTranslate.on("translateend", (event: TranslateEvent) => {
                this.isTranslating = false;
                var features = event.features.getArray();
                if (features.length > 0) {
                    this.reportService.moveFeature(features[0]);
                }
            });
        }
    }

    public reportDataLeveransTranslateReset(trvMap: TrvMap) {
        trvMap.map.removeInteraction(this.reportTranslate);
    }

    public reportDataLeveransTranslateMove(evt: MapBrowserEvent<any>, trvMap: TrvMap) {
        const pixel = trvMap.map.getEventPixel(evt.originalEvent);
        this.tempLayer?.getSource()?.clear();
        this.resetAllReportFeatureStyle();

        let hoverFeature = this.getReportHoverFeatureAllTypes(trvMap, pixel);

        if (this.isTranslating) return;

        this.reportTranslateFeaturesCollection.clear();
        if (hoverFeature) this.reportTranslateFeaturesCollection.push(hoverFeature);
    }

    public reportDataLeveransEditInit(trvMap: TrvMap) {
        let source = this.reportLayer.getSource();

        if (source) {
            this.reportModify = new Modify({
                source: source,
            });

            trvMap.map.addInteraction(this.reportModify);

            this.reportModify.on("modifyend", (event: ModifyEvent) => {
                this.reportService.moveFeature(event.features.getArray()[0]);
            });
        }
    }

    public reportDataLeveransEditReset(trvMap: TrvMap) {
        trvMap.map.removeInteraction(this.reportModify);
        this.tempLayer?.getSource()?.clear();
        this.resetAllReportFeatureStyle();
    }

    public reportDataLeveransDeleteInit(trvMap: TrvMap) {
        this.tempLayer?.getSource()?.clear();
        this.resetAllReportFeatureStyle();
    }

    public reportDataLeveransDeleteReset(trvMap: TrvMap) {
        this.tempLayer?.getSource()?.clear();
        this.resetAllReportFeatureStyle();
    }

    public reportDataLeveransDeleteClick(evt: MapBrowserEvent<any>, trvMap: TrvMap) {
        const pixel = trvMap.map.getEventPixel(evt.originalEvent);
        let hoverFeature = this.getReportHoverFeatureAllTypes(trvMap, pixel);
        if (hoverFeature) {
            const reportItem = this.reportService.getItemById(hoverFeature.get(REPORT_ITEM_ID))!;
            this.trvModalService
                .confirmDelete(
                    "Bekräfta ta bort förändringen",
                    `Är du säker att du vill ta bort förändringen med beskrivning "${reportItem.description}"?`,
                    "Ta bort",
                    "Avbryt",
                    {
                        disposeOnBackdropClick: true,
                    }
                )
                .afterCloseWithType()
                .subscribe(async (event: any) => {
                    if (event.closingEventType === "close") {
                        try {
                            await this.reportService.removeReportItemById(reportItem.report.id, reportItem.id);
                        } catch (e) {
                            this.globalMessagesService.error(
                                `Ett fel uppstod när förändringen med beskrivning "${reportItem.description}" skulle tas bort.`
                            );
                        }
                    }
                });
        }
    }

    public reportDataLeveransDeleteMove(evt: MapBrowserEvent<any>, trvMap: TrvMap) {
        this.resetAllReportFeatureStyle();
        let hoverFeature = this.getReportHoverFeatureAllTypes(trvMap, evt.pixel);
        let reportRemoveText = "";

        if (hoverFeature) {
            reportRemoveText = "Ta bort: " + this.swedishTranslationGeometryType(hoverFeature);
        }
        this.reportRemoveMove(evt.coordinate, reportRemoveText);
    }

    private reportFeatureEditReset(trvMap: TrvMap) {
        if (this.reportModify) trvMap.map.removeInteraction(this.reportModify);
    }

    //NVDB PÅ KARTA

    public reportPaKartaPointInit(trvMap: TrvMap) {
        this.pointDraw = new Draw({
            source: this.reportLayer.getSource()!,
            type: "Point",
        });

        trvMap.map.addInteraction(this.pointDraw);

        //Event that triggers when drawing is starting
        this.pointDraw.on("drawstart", (event: DrawEvent) => {
            this.isDrawingPoint = true;
        });
        //Event that triggers when drawing is finished
        this.pointDraw.on("drawend", async (event: DrawEvent) => {
            //Wait .5 second before we set isDrawing to false.
            setTimeout(() => {
                this.isDrawingPoint = false;
            }, 500);
            // get the drawn feature

            const feature = event.feature;
            feature.setStyle(NvdbReportStyle);

            setTimeout(() => {
                this.reportAvikelseService.setReportAvikelseItemToCreateOrEdit({feature});
            }, 1);
        });
    }

    public reportPaKartaLineInit(trvMap: TrvMap) {
        this.lineDraw = new Draw({
            source: this.reportLayer.getSource()!,
            type: "LineString",
        });

        trvMap.map.addInteraction(this.lineDraw);

        //Event that triggers when drawing is starting
        this.lineDraw.on("drawstart", (event: DrawEvent) => {
            this.isDrawingLine = true;
        });
        //Event that triggers when drawing is finished
        this.lineDraw.on("drawend", async (event: DrawEvent) => {
            setTimeout(() => {
                this.isDrawingLine = false;
            }, 500);

            const feature = event.feature;
            feature.setStyle(NvdbReportStyle);

            this.reportAvikelseService.setReportAvikelseItemToCreateOrEdit({feature});
        });
    }

    public reportPaKartaPolygonInit(trvMap: TrvMap) {
        this.polygonDraw = new Draw({
            source: this.reportLayer.getSource()!,
            type: "Polygon",
        });

        trvMap.map.addInteraction(this.polygonDraw);

        //Event that triggers when drawing is starting
        this.polygonDraw.on("drawstart", (event: DrawEvent) => {
            this.isDrawingPolygon = true;
        });
        //Event that triggers when drawing is finished
        this.polygonDraw.on("drawend", async (event: DrawEvent) => {
            setTimeout(() => {
                this.isDrawingPolygon = false;
            }, 500);

            const feature = event.feature;
            feature.setStyle(NvdbReportStyle);

            this.reportAvikelseService.setReportAvikelseItemToCreateOrEdit({feature});
        });
    }

    public reportReset(trvMap: TrvMap) {
        this.isDrawingPoint = false;
        this.isDrawingLine = false;
        this.isDrawingPolygon = false;
        if (this.pointDraw) trvMap.map.removeInteraction(this.pointDraw);
        if (this.lineDraw) trvMap.map.removeInteraction(this.lineDraw);
        if (this.polygonDraw) trvMap.map.removeInteraction(this.polygonDraw);

        this.reportFeatureEditReset(trvMap);
    }


    public reportMove(evt: MapBrowserEvent<any>, trvMap: TrvMap) {
        const pixel = trvMap.map.getEventPixel(evt.originalEvent);
        if (this.isDrawingPoint) return;
        if (this.isDrawingLine) return;
        if (this.isDrawingPolygon) return;

        this.resetAllReportFeatureStyle();

        let hoverFeature = this.getReportHoverFeatureAllTypes(trvMap, pixel);

        if (hoverFeature) {
            if (hoverFeature.get(HIDDEN)) return;

            if (this.pointDraw) this.pointDraw.setActive(false);
            if (this.lineDraw) this.lineDraw.setActive(false);
            if (this.polygonDraw) this.polygonDraw.setActive(false);
        } else {
            if (this.pointDraw) this.pointDraw.setActive(true);
            if (this.lineDraw) this.lineDraw.setActive(true);
            if (this.polygonDraw) this.polygonDraw.setActive(true);
        }
    }

    public reportPaKartaTranslateInit(trvMap: TrvMap) {
        let source = this.reportLayer.getSource();
        if (source) {
            this.reportTranslate = new Translate({
                features: this.reportTranslateFeaturesCollection,
            });

            trvMap.map.addInteraction(this.reportTranslate);

            this.reportTranslate.on("translatestart", (event: TranslateEvent) => {
                this.isTranslating = true;
            });

            this.reportTranslate.on("translateend", (event: TranslateEvent) => {
                this.isTranslating = false;
                var features = event.features.getArray();
                if (features.length > 0) {
                    this.reportAvikelseService.editFeature(features[0]);
                    this.globalMessagesService.success(`Geometrin flyttades.`);
                }
            });
        }
    }

    public reportPaKartaTranslateReset(trvMap: TrvMap) {
        trvMap.map.removeInteraction(this.reportTranslate);
    }

    public reportPaKartaTranslateMove(evt: MapBrowserEvent<any>, trvMap: TrvMap) {
        const pixel = trvMap.map.getEventPixel(evt.originalEvent);
        this.tempLayer?.getSource()?.clear();
        this.resetAllReportFeatureStyle();

        let hoverFeature = this.getReportHoverFeatureAllTypes(trvMap, pixel);

        if (this.isTranslating) return;

        this.reportTranslateFeaturesCollection.clear();
        if (hoverFeature) this.reportTranslateFeaturesCollection.push(hoverFeature);
    }

    public reportPaKartaEditInit(trvMap: TrvMap) {
        let source = this.reportLayer.getSource();

        if (source) {
            this.reportModify = new Modify({
                source: source,
            });

            trvMap.map.addInteraction(this.reportModify);

            this.reportModify.on("modifyend", (event: ModifyEvent) => {
                this.reportAvikelseService.editFeature(event.features.getArray()[0]);
            });
        }
    }

    public reportPaKartaEditReset(trvMap: TrvMap) {
        trvMap.map.removeInteraction(this.reportModify);
        this.tempLayer?.getSource()?.clear();
        this.resetAllReportFeatureStyle();
    }

    public reportPaKartaDeleteInit() {
        this.tempLayer?.getSource()?.clear();
        this.resetAllReportFeatureStyle();
    }

    public reportPaKartaDeleteReset() {
        this.tempLayer?.getSource()?.clear();
        this.resetAllReportFeatureStyle();
    }

    public reportPaKartaDeleteClick(evt: MapBrowserEvent<any>, trvMap: TrvMap) {
        const pixel = trvMap.map.getEventPixel(evt.originalEvent);
        let hoverFeature = this.getReportHoverFeatureAllTypes(trvMap, pixel);
        if (hoverFeature) {
            const itemId = hoverFeature.get(REPORT_ITEM_ID);
            const item = this.reportAvikelseService.unsentReportAvikelseItem.find(a => a.id === itemId);
            if (item) {
                this.trvModalService
                    .confirmDelete(`Bekräfta ta bort avvikelsen "${item.description}"`, "Är du säker att du vill ta bort avvikelsen?", "Ta bort", "Avbryt", {
                        disposeOnBackdropClick: true,
                    })
                    .afterCloseWithType()
                    .subscribe(async (event: any) => {
                        if (event.closingEventType !== "close") return;
                        this.reportAvikelseService.removeAvikelseItemWithFeature(item);
                        this.globalMessagesService.success(`Avvikelsen "${item.description}" togs bort.`)
                    })
            }
        }
    }

    public reportPaKartaDeleteMove(evt: MapBrowserEvent<any>, trvMap: TrvMap) {
        this.resetAllReportFeatureStyle();
        let hoverFeature = this.getReportHoverFeatureAllTypes(trvMap, evt.pixel);
        let reportRemoveText = "";

        if (hoverFeature) {
            reportRemoveText = "Ta bort: " + this.swedishTranslationGeometryType(hoverFeature);
        }
        this.reportRemoveMove(evt.coordinate, reportRemoveText);
    }

    public infoClickReset(trvMap: TrvMap) {
        this.infoClickBgTempLayer?.getSource()?.clear();
        this.infoClickTempLayer?.getSource()?.clear();
        this.tempLayer?.getSource()?.clear();
    }

    public infoClick(evt: MapBrowserEvent<any>, trvMap: TrvMap) {
        const clickedCoordinate = trvMap.map.getCoordinateFromPixel(evt.pixel);

        this.lastInfoClickCoordinate = clickedCoordinate;
        this.hasSearchedForInfoObjects = true
        this.nvdbNavigation.navigationState = NavigationState.InfoClick;
        this.nvdbNavigation.navigationStateChanged.emit();


        const pixelBuffer = 6; // Pixels around coordinate to info click with

        //const clickedCoordinate = trvMap.map.getCoordinateFromPixel(evt.pixel);
        const resolution = trvMap.map.getView().getResolution();
        const mapBuffer = pixelBuffer * resolution!;

        const extent: Extent = [
            clickedCoordinate[0] - mapBuffer,
            clickedCoordinate[1] - mapBuffer,
            clickedCoordinate[0] + mapBuffer,
            clickedCoordinate[1] + mapBuffer,
        ];

        this.lastInfoClickExtent = extent;

        this.infoClicked(extent, trvMap);
    }

    public infoClicked(extent: Extent, trvMap: TrvMap) {

        this.currentInfoLoading = true;
        const extentGeometry = fromExtent(extent);
        const feature = new Feature(extentGeometry);
        this.infoClickBgTempLayer.getSource()?.clear();
        this.infoClickTempLayer.getSource()?.clear();
        trvMap.trvLayer.addFeature(feature, this.infoClickTempLayer);

        //Get active layers that has trv:metaKey
        let dataslagLayers = trvMap.trvLayer.wmsLayerListItems!;
        dataslagLayers = dataslagLayers.filter((layer: {
            active: any;
            trvData: { hasOwnProperty: (arg0: string) => any }
        }) => {
            return layer.active && layer.trvData.hasOwnProperty("metaKey");
        });

        if (this.currentInfoLoading) {
            this.cancelInfoClickSubscription();
            this.currentInfoLoading = true;
        }

        if (dataslagLayers.length == 0) {
            this.currentInfoObjectsMultiple = {};
            this.currentInfoObjects = [];
            this.currentInfoObject = 0;
            this.currentInfoObjectsChanged.next()
            //Search in point
            var infoClickInCoordinateObj: FeatureTypeRequest = {
                metaKey: "",
                extent: extent,
                viewDate: this.currentBetraktelsedatum.toISO(),
            };

            this.netDbService
                .infoClickInCoordinate(infoClickInCoordinateObj)
                .pipe(take(1), takeUntil(this.unsubscribeInfoClick$))
                .pipe(
                    finalize(() => {
                        this.currentInfoLoading = false;
                    })
                )
                .subscribe({
                    next: (dataTables: string) => {


                        this.showBackToInfoClickInCoord = false;
                        this.lastInfoClickWasInCoord = true;


                        let dataObjects = JSON.parse(dataTables);
                        if (dataObjects.length == 0) return;
                        let keys = ["VeryUniqueKey:Utvalda värden i aktuell punkt"];
                        this.infoClickMetaKeys = keys;
                        this.currentInfoObjectsMultiple = {"VeryUniqueKey:Utvalda värden i aktuell punkt": dataObjects};
                        var firstKey = keys[0];
                        this.changeInfoObject(firstKey);
                    },
                    error: e => {
                    },
                    complete: () => {
                    },
                });
            return;
        }

        let metaKey: string = "";
        let metaKeys: string[] = [];
        if (dataslagLayers[0].trvData.hasOwnProperty("metaKey")) metaKey = (dataslagLayers[0].trvData as any).metaKey;

        if (metaKey.length == 0) {
            this.currentInfoObjectsMultiple = {};
            this.currentInfoObjects = [];
            this.currentInfoObjectsChanged.next()
            this.currentInfoObject = 0;
            this.currentInfoLoading = false;
            return;
        }

        dataslagLayers.forEach((dataslagLayer: { trvData: { hasOwnProperty: (arg0: string) => any } }) => {
            if (dataslagLayer.trvData.hasOwnProperty("metaKey")) metaKeys.push((dataslagLayer.trvData as any).metaKey);
        });

        var objMultiple: FeatureTypeRequestMultiple = {
            metaKeys: metaKeys,
            extent: extent,
            viewDate: this.currentBetraktelsedatum.toISO(),
        };

        this.netDbService
            .infoClickMultiple(objMultiple)
            .pipe(take(1), takeUntil(this.unsubscribeInfoClick$))
            .pipe(
                finalize(() => {
                    this.currentInfoLoading = false;
                })
            )
            .subscribe({
                next: (dataTables: string) => {

                    this.showBackToInfoClickInCoord = this.lastInfoClickWasInCoord
                    this.lastInfoClickWasInCoord = false;

                    let dataObjects = JSON.parse(dataTables);

                    let keys = Object.keys(dataObjects);
                    this.infoClickMetaKeys = keys;
                    this.currentInfoObjectsMultiple = dataObjects;
                    if (keys.length == 0) {
                        this.currentInfoObjects = [];
                        this.currentInfoObjectsChanged.next()
                        this.currentInfoObject = 0;
                        return;
                    }

                    var firstKey = keys[0];
                    this.changeInfoObject(firstKey);
                },
                error: e => {
                },
                complete: () => {
                },
            });
    }

    public changeInfoObject(metaKey: string) {
        const trvMap = this.trvMapService.getTrvMap()!;

        if (!this.currentInfoObjectsMultiple.hasOwnProperty(metaKey)) {
            console.error("Couldn't find meta key");
            return;
        }

        const firstValue = this.currentInfoObjectsMultiple[metaKey];

        if (Array.isArray(firstValue)) {
            this.currentInfoObjects = firstValue;
            this.currentInfoObjectsChanged.next()
            this.currentInfoObject = 0;

            this.currentInfoClickName = this.getLayerNameFromMetakey(metaKey)
            this.currentInfoClickMetaKey = metaKey;

            if (metaKey != "VeryUniqueKey:Utvalda värden i aktuell punkt") {
                this.currentDataSlagLayer = trvMap.trvLayer.wmsLayerListItems.find(
                    (layer: LayerListItem) => layer.trvData.hasOwnProperty("metaKey") && (layer.trvData as any).metaKey == metaKey
                )!;
                if (!this.currentDataSlagLayer) throw Error("Måste hitta ett valid dataslag");
            }
            this.setCurrentInfoObject(this.currentInfoObject, this.trvMapService.trvMap!);
        }
    }

    public getLayerNameFromMetakey(metaKey: string) {
        if (metaKey == "VeryUniqueKey:Utvalda värden i aktuell punkt") return "Utvalda värden i aktuell punkt"
        return this.trvMapService.trvMap!.trvLayer.wmsLayerListItems.find(a => this.trvMapService.trvMap!.trvLayer.getLayerData(a, "metaKey") == metaKey)!.name
    }

    public setCurrentInfoObject(index: number, trvMap: TrvMap) {
        if (index >= 0 && index < this.currentInfoObjects.length) {
            this.currentInfoObject = index;
            this.currentInfoObjectsChanged.next();
            let infoObj = this.currentInfoObjects[index];
            if (infoObj.hasOwnProperty("WKT")) {
                try {
                    this.infoClickBgTempLayer.getSource()?.clear();
                    this.infoClickTempLayer.getSource()?.clear();

                    let infoClickResultGeometries = JSON.parse(infoObj.WKT);
                    let wktString = infoClickResultGeometries.Geom;

                    if (infoClickResultGeometries.BackGeoms.length == 0) {
                        var feature = trvMap.trvLayer.getFeatureFromWkt(wktString);
                        feature.setStyle(this.infoClickFeatureStyle);

                        trvMap.trvLayer.addFeature(feature, this.infoClickTempLayer);
                    } else {
                        infoClickResultGeometries.BackGeoms.forEach((_wkt: string) => {
                            var feature = trvMap.trvLayer.getFeatureFromWkt(_wkt);
                            feature.setStyle(this.infoClickFeatureStyle);
                            trvMap.trvLayer.addFeature(feature, this.infoClickBgTempLayer);
                        });
                    }

                    var feature2 = trvMap.trvLayer.getFeatureFromWkt(wktString);
                    feature2.setStyle(this.infoClickFeatureStyle2);


                    trvMap.trvLayer.addFeature(feature2, this.infoClickTempLayer);

                    if (infoClickResultGeometries.HasDirection) {
                        var dir = infoClickResultGeometries.Direction;

                        var geom = feature2.getGeometry();
                        if (geom instanceof LineString) {
                            const coords = geom.getCoordinates();
                            if (coords.length > 2) {
                                let lastIndex = coords.length - 1;
                                var rotation = 0;

                                var lastCoord = coords[lastIndex];
                                var nextLastCoord = coords[lastIndex - 1];

                                if (dir == "2") {
                                    lastCoord = coords[0];
                                    nextLastCoord = coords[1];
                                }

                                var arrowPoint = new Point(lastCoord);
                                var arrowFeature = new Feature(arrowPoint);

                                var x1: number = lastCoord[0];
                                var y1: number = lastCoord[1];

                                var x2: number = nextLastCoord[0];
                                var y2: number = nextLastCoord[1];

                                const dx = x1 - x2;
                                const dy = y1 - y2;

                                rotation = Math.atan2(dy, dx) * -1 + Math.PI / 2;

                                const arrowSymbol = new Icon({
                                    anchor: [0.5, 0.4], // Center of the icon
                                    anchorXUnits: "fraction",
                                    anchorYUnits: "fraction",
                                    src: "assets/img/info-arrow.png", // Path to your custom arrow image
                                    rotation: rotation,
                                });

                                const infoClickArrowStyle = new Style({
                                    image: arrowSymbol,
                                });

                                arrowFeature.setStyle(infoClickArrowStyle);
                                trvMap.trvLayer.addFeature(arrowFeature, this.infoClickTempLayer);
                            }
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        } else {
            console.error("Tried to set an info object that doesn't exists");
        }
    }

    public infoMove(evt: MapBrowserEvent<any>, trvMap: TrvMap) {
        const pixel = trvMap.map.getEventPixel(evt.originalEvent);

        this.tempLayer?.getSource()?.clear();

        const point = new Point(evt.coordinate);
        const feature = new Feature(point);
        feature.setStyle(this.infoClickMouseStyle);

        this.tempLayer?.getSource()?.addFeature(feature);
    }

    loadingLineSnapToGridStyle = new Style({
        stroke: new Stroke({
            color: "rgba(255,0,0,0.3)",
            width: 3,
        }),
    });

    public updateBetraktelsedatum(date: DateTime) {
        if (this.currentBetraktelsedatum.toMillis() != date.toMillis()) {
            this.currentBetraktelsedatum = date;
            console.info("New betraktelsedatum set");
            //Test
            let trvMap = this.trvMapService.trvMap!;
            trvMap.trvLayer.wmsLayerListItems.forEach((layer: LayerListItem) => {
                let wms = layer.layer;
                const source: any = wms.getSource();

                if (source.updateParams) {
                    let viewDate = date.toLocaleString();
                    source.updateParams({TIME: viewDate});
                }
            });
        }
    }

    public cancelInfoClickSubscription() {
        this.unsubscribeInfoClick$.next();
    }
}

<div class='betraktelse-container'>
    <div class='betraktelse-header'>
        Välj betraktelsedatum
        <button type='button' aria-label='Stäng' class='btn btn-link' (click)='close()'>
            <svg
                _ngcontent-qoi-c18=''
                _ngcontent-app-c19=''
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='18'
                fill='currentColor'
                viewBox='0 0 16 16'
                class='bi bi-x-lg'
            >
                <path
                    _ngcontent-qoi-c18=''
                    _ngcontent-app-c19=''
                    d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z'
                    fill-rule=''
                    class='ng-star-inserted'
                ></path>
            </svg>
        </button>
    </div>
    <div class='betraktelse-body'>
        <trv-date-picker [skipClear]='true' [formControl]='datesForm'></trv-date-picker>
        <button class='btn btn-primary rounded-0'
                [ngClass]='datesFormIsTodaysDate() ? "set_date_button_active": "set_date_button_inactive"'
                (click)='datesForm.setValue(DateTime.now().toISO())'>Idag</button>
    </div>
</div>

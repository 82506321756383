/* tslint:disable */
/* eslint-disable */
export enum DeliveryTypesEnum {
  NyttBilnat = 1,
  NyttCykelnat = 2,
  GeometrirattaVagnat = 3,
  AvslutaEllerTaBortVagnat = 4,
  NyEllerFörändradEllerRattadForeteelse = 5,
  AvslutaEllerTaBortForeteelse = 6,
  LeveransViaUnderlag = 7,
  RapporteraFelEllerBrist = 8,
  AvikelseBilvagSaknas = 100,
  AvikelseCykelEllerGangSaknas = 101,
  AvikelseAnnat = 102
}

<div chart-header class="d-flex justify-content-between">
    <div>
        <h5 class="mb-1">
            Fördelning av ärende per system
            <span class="badge badge-phoenix badge-phoenix-warning rounded-pill fs-9 ms-2">
                <span class="badge-label">-6.8%</span>
            </span>
        </h5>
        <h6 class="text-body-tertiary">{{ statsService.dateRangeText }}</h6>
    </div>
    <h4>{{ statsService.allReports.length }}</h4>
</div>
<div>
    <div class="btn-group" role="group" aria-label="Basic example">
        <button
            type="button"
            [ngClass]="{
                active: currentChartType == 'bar'
            }"
            (click)="changeToBar()"
            class="btn btn-light btn-sm"
        >
            Stapeldiagram
        </button>
        <button
            type="button"
            [ngClass]="{
                active: currentChartType == 'pie'
            }"
            (click)="changeToPie()"
            class="btn btn-light btn-sm"
        >
            Tårtdiagram
        </button>
    </div>

    <br />
    <div style="max-height: 350px;display: flex;justify-content: center;">
        <canvas
            baseChart
            [data]="statsService.numReportsPerSourceChartData"
            [options]="statsService.numReportsPerSourceChartOptions"
            [type]="currentChartType"
        ></canvas>
    </div>
</div>


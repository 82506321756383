import { Injectable } from "@angular/core";
import { faHome, faMap, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { RouterNavigationItem } from "trv-ng-common";

@Injectable({
    providedIn: "root",
})
export class NavigationService {
    private startNavItem = new RouterNavigationItem({
        label: "Start",
        routerLink: "/",
        description: "Startsida",
        icon: faHome as IconDefinition,
    });
    private kartaNavItem = new RouterNavigationItem({
        label: "Karta",
        routerLink: "/map",
        description: "Karta",
        icon: faMap as IconDefinition,
    });

    constructor() {}

    sidebar() {
        return [this.startNavItem, this.kartaNavItem];
    }

    navBlocks() {
        return [this.kartaNavItem];
    }
}

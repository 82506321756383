import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { TrvNgMapService } from "@trafikverket/trv-ng-map";
import { take } from "rxjs";
import { SvgIcon } from "src/app/shared/utils/svg-helper";
import { SvgIconComponent } from "../../utils/svg-icon/svg-icon.component";
import { NgClass } from "@angular/common";
import {TrvTooltipDirective, TrvTooltipPosition} from "trv-ng-common";

@Component({
    selector: "app-map-button",
    templateUrl: "./map-button.component.html",
    styleUrls: ["./map-button.component.scss"],
    standalone: true,
    imports: [
        NgClass,
        SvgIconComponent,
        TrvTooltipDirective
    ],
})
export class MapButtonComponent {
    @Input() svgIcon!: SvgIcon;
    @Input() active: boolean = false;
    @Input() specialSvg?: boolean;
    @Input() disabled: boolean = false;
    @Input() notice: boolean = false;
    @Input() noticeText: string = "!";
    @Input() toolTip!: string;
    @Input() toolTipPosition!: TrvTooltipPosition[];

    constructor() {}
}

import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subject, takeUntil, tap } from "rxjs";
import { ReportAvikelseService } from "src/app/service/report-avikelse.service";
import { ReportItemAvikelseComponent } from "@components/reports-avikelse/report-item-avikelse/report-item-avikelse.component";
import { ReportItemAvikelseComponent as ReportItemAvikelseComponent_1 } from "../report-item-avikelse/report-item-avikelse.component";

import {TrvLoadingBundle, TrvLoadingDirective} from "trv-ng-common";
import { GeometryType } from "@app/shared/enums";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { faDrawPolygon, faMapMarkerAlt, faRoute } from "@fortawesome/free-solid-svg-icons";
import Feature from "ol/Feature";

@Component({
    selector: "app-report-avikelse-item-edit-modal",
    templateUrl: "./report-avikelse-item-edit-modal.component.html",
    styleUrls: ["./report-avikelse-item-edit-modal.component.scss"],
    standalone: true,
    imports: [TrvLoadingBundle, ReportItemAvikelseComponent_1, FaIconComponent, TrvLoadingDirective],
})
export class ReportAvikelseItemEditModalComponent implements OnDestroy {
    @ViewChild(ReportItemAvikelseComponent, { static: false }) reportItemComponent!: ReportItemAvikelseComponent;

    currentStatus: "EDITING_ITEM" | "CREATING_ITEM" | "CREATING_ITEM_FROM_QUE" = "CREATING_ITEM";

    private onDestroy$ = new Subject<void>();

    public loading: boolean = false;

    public geometryType = GeometryType.POINT;
    protected readonly GeometryType = GeometryType;

    protected readonly faRoute = faRoute;
    protected readonly faMapMarkerAlt = faMapMarkerAlt;
    protected readonly faDrawPolygon = faDrawPolygon;

    constructor(public reportAvikelseService: ReportAvikelseService) {
        this.setModalStatuses();
        this.reportAvikelseService.reportAvikelseItemToCreateOrEditChanged.subscribe(() => this.setModalStatuses());
    }

    setModalStatuses() {
        const item = this.reportAvikelseService.reportAvikelseItemToCreateOrEdit;
        if (this.reportAvikelseService.itemTypeofCreating(item) && this.reportAvikelseService.totalQueLengthFromBeginning) {
            this.currentStatus = "CREATING_ITEM_FROM_QUE";
        } else if (this.reportAvikelseService.itemTypeofCreating(item)) {
            this.currentStatus = "CREATING_ITEM";
        } else {
            this.currentStatus = "EDITING_ITEM";
        }
    }

    public async submit(reportItemComponent: ReportItemAvikelseComponent) {
        this.loading = true;
        if (await reportItemComponent.save()) {
            const firstItem = this.reportAvikelseService.queOfItemsToCreate.shift();
            if (firstItem) {
                this.reportAvikelseService.addReportFeatureToMap(firstItem.feature);
            } else {
                this.reportAvikelseService.totalQueLengthFromBeginning = null;
            }

            this.reportAvikelseService.setReportAvikelseItemToCreateOrEdit(firstItem ?? null, false);
        }
        this.loading = false;
    }

    public close() {
        const currentItem = this.reportAvikelseService.reportAvikelseItemToCreateOrEdit;
        if (this.reportAvikelseService.itemTypeofCreating(currentItem)) {
            this.reportAvikelseService.removeFeatureFromMap(currentItem.feature);
        }
        this.reportAvikelseService.setReportAvikelseItemToCreateOrEdit(null);
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}

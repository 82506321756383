<div class='c-popover-options'>
    <button class='c-popover-options__btn' (click)='zoomIn()'>
        <fa-icon [icon]='faMagnifyingGlassPlus'></fa-icon>
        Zooma till
    </button>

    @if (reportGroupItem.status === ReportStatusesEnum.Draft) {
        <button
            class='c-popover-options__btn'
            [trvPopover]='popoverComponent'
            [position]='popoverPosition'
            (shown)='onShowComponent($event)'
            (afterCloseWithType)='onAfterCloseWithType($event)'
        >
            <fa-icon [icon]='faEdit'></fa-icon>
            Redigera
        </button>
    }
    <button class='c-popover-options__btn' (click)='export()'>
        <fa-icon [icon]='faFileExport'></fa-icon>
        Exportera
    </button>

    @if (nvdbNavigationService.VIEWMODE) {
        <button class='c-popover-options__btn' (click)='goToViewModeReport()'>
            <fa-icon [icon]='faExternalLinkSquare'></fa-icon>
            Gå till ärendesida
        </button>
    }

    @if (!nvdbNavigationService.VIEWMODE) {
        <button class='c-popover-options__btn' (click)='remove()'>
            <fa-icon [icon]='faTrashCan'></fa-icon>
            Ta bort
        </button>
    }
</div>

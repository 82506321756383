import { Component, Input } from "@angular/core";
import { SvgIcon } from "src/app/shared/utils/svg-helper";
import { svgChevronUp, svgChevronDown } from "src/app/shared/utils/svg-helper-data";
import { SvgIconComponent } from "../../utils/svg-icon/svg-icon.component";
import { NgClass } from "@angular/common";

@Component({
    selector: "app-map-menu-item",
    templateUrl: "./map-menu-item.component.html",
    standalone: true,
    imports: [
    NgClass,
    SvgIconComponent
],
})
export class MapMenuItemComponent {
    @Input() public leftIcon!: SvgIcon;
    @Input() public itemName!: string;
    @Input() public open!: boolean;

    public downIcon: SvgIcon = svgChevronDown;
    public upIcon: SvgIcon = svgChevronUp;

    constructor() {}

    ngOnInit(): void {}
}

import {ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation} from "@angular/core";
import {TrvNgMapService} from "@trafikverket/trv-ng-map";
import {
    TrvGlobalMessagesService,
    TrvModalService,
    TrvPopoverPosition,
    TrvPopoverRef,
    TrvPopoverDirective, TrvSelectComponent
} from "trv-ng-common";
import {LocalStorageService, SavedView} from "src/app/service/local-storage.service";
import {SWEDEN_VIEW} from "src/app/shared/constants";
import {SaveViewComponent} from '@components/map-menu/saved-view-section/save-view/save-view.component'
import {AsyncPipe} from "@angular/common";
import {SavedViewItemComponent} from "./saved-view-item/saved-view-item.component";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {DateTime} from "luxon";
import {NvdbNavigationService, sortByOptions, SortingStrategies} from "@app/service/nvdb-navigation.service";

@Component({
    selector: 'app-saved-view-section',
    templateUrl: './saved-view-section.component.html',
    styleUrls: ['../../map-menu/map-menu.component.scss', './saved-view-section.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        TrvPopoverDirective,
        SavedViewItemComponent,
        AsyncPipe,
        TrvSelectComponent,
        ReactiveFormsModule
    ],
})
export class SavedViewSectionComponent {
    public popoverPosition: TrvPopoverPosition[] = ["right", "bottom-end", "bottom", "bottom-start", 'top-start', 'top-end'];
    public popoverComponent = SaveViewComponent;


    sortedSavedViews: SavedView[] = []

    constructor(
        public localStorageService: LocalStorageService,
        public nvdbNavigationService: NvdbNavigationService,
    ) {
        this.sortSavedViews()

        this.nvdbNavigationService.savedViewsSortBy.valueChanges.subscribe(() => this.sortSavedViews())
        this.localStorageService.savedViewsChanged.subscribe(() => this.sortSavedViews())
    }

    public sortSavedViews() {
        switch (this.nvdbNavigationService.savedViewsSortBy.value.strategy) {
            case SortingStrategies.ALPHA_A_TO_Z:
                this.sortedSavedViews = [...this.localStorageService.savedViews].sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                break;
            case SortingStrategies.ALPHA_Z_TO_A:
                this.sortedSavedViews = [...this.localStorageService.savedViews].sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1)
                break;
            case SortingStrategies.CREATED_NEW_FIRST:
                this.sortedSavedViews = [...this.localStorageService.savedViews].sort((a, b) => a.lastModifiedDate.diff(b.lastModifiedDate).toMillis() > 0 ? -1 : 1)
                break;
            case SortingStrategies.CREATED_OLD_FIRST:
                this.sortedSavedViews = [...this.localStorageService.savedViews].sort((a, b) => a.lastModifiedDate.diff(b.lastModifiedDate).toMillis() > 0 ? 1 : -1)
                break;
        }
    }

    public isDefaultView(savedView: SavedView): boolean {
        return this.localStorageService.defaultViewId === savedView?.id;
    }

    onShowComponent(ref: TrvPopoverRef<SaveViewComponent>): void {
    }

    protected readonly SWEDEN_VIEW = SWEDEN_VIEW;

    public displaySortByName(obj: { strategy: SortingStrategies, name: string }) {
        return obj.name
    };

    protected readonly sortByOptions = sortByOptions;
}



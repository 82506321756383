/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ActorDataslagByDeliveryType } from '../models/actor-dataslag-by-delivery-type';
import { getAllDataslagForActors } from '../fn/net-db/get-all-dataslag-for-actors';
import { GetAllDataslagForActors$Params } from '../fn/net-db/get-all-dataslag-for-actors';
import { getAllDataslagForActors$Plain } from '../fn/net-db/get-all-dataslag-for-actors-plain';
import { GetAllDataslagForActors$Plain$Params } from '../fn/net-db/get-all-dataslag-for-actors-plain';
import { getDescription } from '../fn/net-db/get-description';
import { GetDescription$Params } from '../fn/net-db/get-description';
import { getDescription$Plain } from '../fn/net-db/get-description-plain';
import { GetDescription$Plain$Params } from '../fn/net-db/get-description-plain';
import { getLastFeatureUpdateNVDB } from '../fn/net-db/get-last-feature-update-nvdb';
import { GetLastFeatureUpdateNVDB$Params } from '../fn/net-db/get-last-feature-update-nvdb';
import { getLastFeatureUpdateNVDB$Plain } from '../fn/net-db/get-last-feature-update-nvdb-plain';
import { GetLastFeatureUpdateNVDB$Plain$Params } from '../fn/net-db/get-last-feature-update-nvdb-plain';
import { infoClick } from '../fn/net-db/info-click';
import { InfoClick$Params } from '../fn/net-db/info-click';
import { infoClick$Plain } from '../fn/net-db/info-click-plain';
import { InfoClick$Plain$Params } from '../fn/net-db/info-click-plain';
import { infoClickInCoordinate } from '../fn/net-db/info-click-in-coordinate';
import { InfoClickInCoordinate$Params } from '../fn/net-db/info-click-in-coordinate';
import { infoClickInCoordinate$Plain } from '../fn/net-db/info-click-in-coordinate-plain';
import { InfoClickInCoordinate$Plain$Params } from '../fn/net-db/info-click-in-coordinate-plain';
import { infoClickMultiple } from '../fn/net-db/info-click-multiple';
import { InfoClickMultiple$Params } from '../fn/net-db/info-click-multiple';
import { infoClickMultiple$Plain } from '../fn/net-db/info-click-multiple-plain';
import { InfoClickMultiple$Plain$Params } from '../fn/net-db/info-click-multiple-plain';
import { searchAll } from '../fn/net-db/search-all';
import { SearchAll$Params } from '../fn/net-db/search-all';
import { searchAll$Plain } from '../fn/net-db/search-all-plain';
import { SearchAll$Plain$Params } from '../fn/net-db/search-all-plain';
import { searchKommunLan } from '../fn/net-db/search-kommun-lan';
import { SearchKommunLan } from '../models/search-kommun-lan';
import { SearchKommunLan$Params } from '../fn/net-db/search-kommun-lan';
import { searchKommunLan$Plain } from '../fn/net-db/search-kommun-lan-plain';
import { SearchKommunLan$Plain$Params } from '../fn/net-db/search-kommun-lan-plain';

@Injectable({ providedIn: 'root' })
export class NetDbApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `searchAllNetDb()` */
  static readonly SearchAllNetDbPath = '/NetDb/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchAll$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchAll$Plain$Response(params?: SearchAll$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return searchAll$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchAll$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchAll$Plain(params?: SearchAll$Plain$Params, context?: HttpContext): Observable<string> {
    return this.searchAll$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchAll()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchAll$Response(params?: SearchAll$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return searchAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchAll$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchAll(params?: SearchAll$Params, context?: HttpContext): Observable<string> {
    return this.searchAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `searchKommunLanNetDb()` */
  static readonly SearchKommunLanNetDbPath = '/NetDb/SearchKommunLan';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchKommunLan$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchKommunLan$Plain$Response(params?: SearchKommunLan$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchKommunLan>> {
    return searchKommunLan$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchKommunLan$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchKommunLan$Plain(params?: SearchKommunLan$Plain$Params, context?: HttpContext): Observable<SearchKommunLan> {
    return this.searchKommunLan$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchKommunLan>): SearchKommunLan => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchKommunLan()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchKommunLan$Response(params?: SearchKommunLan$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchKommunLan>> {
    return searchKommunLan(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchKommunLan$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchKommunLan(params?: SearchKommunLan$Params, context?: HttpContext): Observable<SearchKommunLan> {
    return this.searchKommunLan$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchKommunLan>): SearchKommunLan => r.body)
    );
  }

  /** Path part for operation `infoClickInCoordinateNetDb()` */
  static readonly InfoClickInCoordinateNetDbPath = '/NetDb/InfoClickInCoordinate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `infoClickInCoordinate$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  infoClickInCoordinate$Plain$Response(params?: InfoClickInCoordinate$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return infoClickInCoordinate$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `infoClickInCoordinate$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  infoClickInCoordinate$Plain(params?: InfoClickInCoordinate$Plain$Params, context?: HttpContext): Observable<string> {
    return this.infoClickInCoordinate$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `infoClickInCoordinate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  infoClickInCoordinate$Response(params?: InfoClickInCoordinate$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return infoClickInCoordinate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `infoClickInCoordinate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  infoClickInCoordinate(params?: InfoClickInCoordinate$Params, context?: HttpContext): Observable<string> {
    return this.infoClickInCoordinate$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `infoClickNetDb()` */
  static readonly InfoClickNetDbPath = '/NetDb/InfoClick';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `infoClick$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  infoClick$Plain$Response(params?: InfoClick$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return infoClick$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `infoClick$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  infoClick$Plain(params?: InfoClick$Plain$Params, context?: HttpContext): Observable<string> {
    return this.infoClick$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `infoClick()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  infoClick$Response(params?: InfoClick$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return infoClick(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `infoClick$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  infoClick(params?: InfoClick$Params, context?: HttpContext): Observable<string> {
    return this.infoClick$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `infoClickMultipleNetDb()` */
  static readonly InfoClickMultipleNetDbPath = '/NetDb/InfoClickMultiple';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `infoClickMultiple$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  infoClickMultiple$Plain$Response(params?: InfoClickMultiple$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return infoClickMultiple$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `infoClickMultiple$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  infoClickMultiple$Plain(params?: InfoClickMultiple$Plain$Params, context?: HttpContext): Observable<string> {
    return this.infoClickMultiple$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `infoClickMultiple()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  infoClickMultiple$Response(params?: InfoClickMultiple$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return infoClickMultiple(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `infoClickMultiple$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  infoClickMultiple(params?: InfoClickMultiple$Params, context?: HttpContext): Observable<string> {
    return this.infoClickMultiple$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getDescriptionNetDb()` */
  static readonly GetDescriptionNetDbPath = '/NetDb/GetDescription';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDescription$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDescription$Plain$Response(params?: GetDescription$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getDescription$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDescription$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDescription$Plain(params?: GetDescription$Plain$Params, context?: HttpContext): Observable<string> {
    return this.getDescription$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDescription()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDescription$Response(params?: GetDescription$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getDescription(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDescription$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDescription(params?: GetDescription$Params, context?: HttpContext): Observable<string> {
    return this.getDescription$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getLastFeatureUpdateNvdbNetDb()` */
  static readonly GetLastFeatureUpdateNvdbNetDbPath = '/NetDb/GetLastFeatureUpdateNVDB';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLastFeatureUpdateNVDB$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLastFeatureUpdateNVDB$Plain$Response(params?: GetLastFeatureUpdateNVDB$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getLastFeatureUpdateNVDB$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLastFeatureUpdateNVDB$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLastFeatureUpdateNVDB$Plain(params?: GetLastFeatureUpdateNVDB$Plain$Params, context?: HttpContext): Observable<string> {
    return this.getLastFeatureUpdateNVDB$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLastFeatureUpdateNVDB()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLastFeatureUpdateNVDB$Response(params?: GetLastFeatureUpdateNVDB$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getLastFeatureUpdateNVDB(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLastFeatureUpdateNVDB$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLastFeatureUpdateNVDB(params?: GetLastFeatureUpdateNVDB$Params, context?: HttpContext): Observable<string> {
    return this.getLastFeatureUpdateNVDB$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getAllDataslagForActorsNetDb()` */
  static readonly GetAllDataslagForActorsNetDbPath = '/NetDb/GetAllDataslagForActor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllDataslagForActors$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDataslagForActors$Plain$Response(params?: GetAllDataslagForActors$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ActorDataslagByDeliveryType>>> {
    return getAllDataslagForActors$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllDataslagForActors$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDataslagForActors$Plain(params?: GetAllDataslagForActors$Plain$Params, context?: HttpContext): Observable<Array<ActorDataslagByDeliveryType>> {
    return this.getAllDataslagForActors$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ActorDataslagByDeliveryType>>): Array<ActorDataslagByDeliveryType> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllDataslagForActors()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDataslagForActors$Response(params?: GetAllDataslagForActors$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ActorDataslagByDeliveryType>>> {
    return getAllDataslagForActors(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllDataslagForActors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDataslagForActors(params?: GetAllDataslagForActors$Params, context?: HttpContext): Observable<Array<ActorDataslagByDeliveryType>> {
    return this.getAllDataslagForActors$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ActorDataslagByDeliveryType>>): Array<ActorDataslagByDeliveryType> => r.body)
    );
  }

}

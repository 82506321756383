import {animate, state, style, transition, trigger} from "@angular/animations";
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject} from "@angular/core";
import {FormControl, FormGroup, Validators, ReactiveFormsModule} from "@angular/forms";
import WKT from "ol/format/WKT";
import {combineLatest} from "rxjs";
import {LocalStorageService} from "src/app/service/local-storage.service";
import {ReportAvikelseService, ReportItemWithFeature} from 'src/app/service/report-avikelse.service'
import {focusOnFirstInvalidControl} from "src/app/shared/utils/utils";
import {
    TrvFormUtilityService,
    TrvGlobalMessagesService,
    TrvUtilityService,
    TrvSelectComponent,
    TrvModalService
} from "trv-ng-common";
import {showErrorMessage} from '@shared/animations'
import {DeliveryTypeRead, DeliveryTypesEnum} from "@app/_api/pakarta/models";
import Feature from 'ol/Feature'
import {svgCircleInfo} from "@shared/utils/svg-helper-data";
import {SvgIconComponent} from "@components/utils/svg-icon/svg-icon.component";
import {felTyperInfoMessage, leveransInfoMessage} from "@shared/constants";
import {TrvModalConfig} from "trv-ng-common/lib/components/modal/modal-config";
import {NvdbNavigationService} from "@app/service/nvdb-navigation.service";


@Component({
    selector: 'app-report-item-avikelse',
    templateUrl: './report-item-avikelse.component.html',
    styleUrls: ['./report-item-avikelse.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    // TODO move this to utils folder...
    animations: [showErrorMessage],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        TrvSelectComponent,
        SvgIconComponent
    ],
})
export class ReportItemAvikelseComponent {
    public form: FormGroup<SingleSelectFormGroup>;

    protected readonly circleInfoIcon = svgCircleInfo;

    constructor(
        private nvdbNavigatorService: NvdbNavigationService,
        @Inject(TrvModalService) private trvModalService: TrvModalService,
        public reportAvikelseService: ReportAvikelseService,
        private formUtilityService: TrvFormUtilityService,
        private trvUtilityService: TrvUtilityService,
        private cdref: ChangeDetectorRef,
        private globalMessagesService: TrvGlobalMessagesService,
        private localStorageService: LocalStorageService,
    ) {
        this.form = new FormGroup<SingleSelectFormGroup>({
            deliveryTypes: new FormControl(this.reportAvikelseService.deliveryTypes[0], Validators.required),
            description: new FormControl(null, [Validators.required, Validators.maxLength(600)]),
        });
        this.initFormOnChange();

        this.reportAvikelseService.reportAvikelseItemToCreateOrEditChanged.subscribe(() => {
            if (this.reportAvikelseService.reportAvikelseItemToCreateOrEdit) {
                this.initFormOnChange();
            }
        })

        this.reportAvikelseService.updateReportAvikelseFormDescription.subscribe((description: string) => this.form.patchValue({description}))
    }

    private initFormOnChange() {
        let item = this.reportAvikelseService.reportAvikelseItemToCreateOrEdit
        if (this.reportAvikelseService.itemTypeofEditing(item)) {
            const deliveryTypesEnum = item.deliveryType
            const deliveryType = this.reportAvikelseService.deliveryTypes.find(type => type.deliveryType === deliveryTypesEnum)!;

            this.deliveryTypesControl.setValue(deliveryType);
            this.description.setValue(item.description);
        } else if (this.reportAvikelseService.itemTypeofCreating(item)) {
            if (item.defaultData?.deliveryType)
                this.deliveryTypesControl.setValue(item.defaultData.deliveryType);
            else
                this.deliveryTypesControl.setValue(this.reportAvikelseService.deliveryTypes[0]);

            if (item.defaultData?.description)
                this.description.setValue(item.defaultData.description);
            else
                this.description.setValue(null);
        }
    }

    get deliveryTypesControl() {
        return this.form.get("deliveryTypes") as FormControl;
    }

    get description() {
        return this.form.get("description") as FormControl;
    }

    public displayDeliveryTypeName(object: DeliveryTypeRead) {
        return object.description;
    }

    public async save(): Promise<boolean> {
        if (!this.form.valid) {
            this.formUtilityService.isValid(this.form);
            this.form.updateValueAndValidity();
            this.form.markAllAsTouched();
            this.form.markAsDirty();
            this.cdref.detectChanges();
            focusOnFirstInvalidControl();
            this.globalMessagesService.error("Var vänlig och korrigera felen, kan ej skapa avvikelsen");
            return false;
        }
        const values = this.form.getRawValue();

        const desc = values.description;
        const deliveryType = values.deliveryTypes!.deliveryType;

        const item = this.reportAvikelseService.reportAvikelseItemToCreateOrEdit

        if (this.reportAvikelseService.itemTypeofEditing(item)) {
            // Update
            (item as ReportItemWithFeature).description = desc ? desc : "";
            (item as ReportItemWithFeature).deliveryType = deliveryType;
            this.localStorageService.setUnsentReportAvikelseItems(this.reportAvikelseService.unsentReportAvikelseItem);
            this.globalMessagesService.success(`Uppdaterade avvikelsen "${values.description}".`)
        } else if (this.reportAvikelseService.itemTypeofCreating(item)) {
            //Create
            this.reportAvikelseService.createReportAvikelseItem(item.feature, desc ? desc : "", deliveryType);
            this.globalMessagesService.success(`Skapade avvikelsen "${values.description}".`)
        }
        return true;
    }

    felTypInfo() {
        const config: TrvModalConfig = {
            disposeOnBackdropClick: true,
        }
        if (this.nvdbNavigatorService.isMobileDevice) config.size = 'fullscreen'

        this.trvModalService.info("Information om typer av fel", felTyperInfoMessage, config);
    }
}

interface SingleSelectFormGroup {
    deliveryTypes: FormControl<{ deliveryType: DeliveryTypesEnum; description: string } | null>;
    description: FormControl<string | null>;
}

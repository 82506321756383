<div class='map-main'>
    <header class='map-main__header'>
        <div class='map-main__header__menu'>
            @if (nvdbNavigation.isMobileDevice) {
                <div class='map-main__header__logo'>
                    <img class="ms-3" style="height: 35px" src='assets/img/trafikverket_logo_symbol_only.png'
                         alt="Trafikverkets logotyp"/>
                </div>
                <span class="ms-3">{{ nvdbNavigation.siteTitle }}</span>
            } @else if (!nvdbNavigation.hideHamburgerMenu && !trvMapService.trvMap?.showStreetView) {
                <button class='btn btn-link' (click)='menuToggle()'>
                    <app-svg-icon [svgIcon]='hamburgerIcon'></app-svg-icon>
                    <span class='title--small'>Meny</span>
                    <span class='title--large'>{{ nvdbNavigation.siteTitle }}</span>
                </button>
            }
            @if (nvdbNavigation.hideHamburgerMenu) {
                <button class='btn btn-link' (click)='goToMap()'>
                    <app-svg-icon [svgIcon]='arrowLeft'></app-svg-icon>
                    <span class='ms-2'>{{ backText }}</span>
                </button>
            }
        </div>

        @if (!nvdbNavigation.isMobileDevice) {
            <div class='map-main__header__logo'>
                <app-svg-icon [svgIcon]='logoIconWithText'></app-svg-icon>
            </div>
        }
        <div class='map-main__header__right'>
            <a class='btn btn-link header-btn' (click)='showInfoComponent()'>
                <app-svg-icon [svgIcon]='infoCircleIcon'></app-svg-icon>
            </a>
            @if (environment.application == 'NvdbDataleverans') {
                <a class='btn btn-link header-btn' #myOptionsPopoverRef='trvPopover' (click)='showPopover($event)'
                   [trvPopover]='myOptionsComponent' (clickOutside)='clickOutside()'
                   (shown)='onShowMyOptionsComponent($event)' [position]='myOptionsPosition'
                   (afterCloseWithType)='onAfterCloseMyOptionsComponent($event)' [manualToggle]='true'>
                    <app-svg-icon [svgIcon]='personIcon'></app-svg-icon>
                </a>
            }
        </div>
    </header>
    <div style='flex: 1; margin-top:3.5rem'>
        <router-outlet></router-outlet>
    </div>
    @if (!nvdbNavigation.isMobileDevice) {
        <footer>
            <div class='center-items'
                 [style.margin-left.px]='(nvdbNavigation.currentPageIsMap && nvdbNavigation.sideBarVisible) ? nvdbNavigation.sideBarWidth: 0'>
                <div class='me-4 hidden-mobile'>Trafikverket</div>
                <div class='me-4 hidden-mobile'>781 89 Borlänge</div>
                <div class='me-4 hidden-mobile'>Tel 0771-921 921</div>
                <div class='me-4 hidden-mobile'>Org.nr 202100-6297</div>
                <div class='me-4 hidden-mobile'>
                    <a title='' [href]="'mailto:'+email" class='text-white'>
                        <fa-icon [icon]='faEnvelope' class='me-1'></fa-icon>
                        {{ email }}
                    </a>
                </div>
                <div class='me-4'>ver.{{ environment.buildVersion }}</div>
            </div>
            <div class='right-items'>
                <div class='me-4 hidden-mobile'>
                    <a title='Länk till NVDB.se' href='https://www.nvdb.se/sv' target='_blank'>
                        <img src='assets/img/NVDB_logo.svg' height='40' alt="Länk till NVDB.se">
                    </a>
                </div>
            </div>
        </footer>
    }
    @if (nvdbNavigation.currentPageIsMap) {
        <app-map-menu #appmenu></app-map-menu>
    }
    <!-- 52 is the height of the app-top-dashboard, and 26 the height of the map message in the bottom. -->
    <div class='report-edit-modal'
         [style.left.px]='(nvdbNavigation.currentPageIsMap && nvdbNavigation.sideBarVisible) ? nvdbNavigation.sideBarWidth + nvdbNavigation.reportItemEditModalMargin : nvdbNavigation.reportItemEditModalMargin'
    >
        @if (environment.application == "NvdbDataleverans" && reportService.itemToCreateOrEdit && !nvdbNavigation.isMobileDevice && trvMapService.isMapLoaded()) {
            <app-report-item-edit-modal #container style='height:100%; display:block'
                                        [style.width.px]='nvdbNavigation.reportItemEditModalWidth'></app-report-item-edit-modal>
            <div class='resize_handle' #resizeHandle (mousedown)='onMouseDown($event)'></div>
        } @else if (environment.application == "NvdbPåKarta" && reportAvikelseService.reportAvikelseItemToCreateOrEdit && !nvdbNavigation.isMobileDevice) {
            <app-report-avikelse-item-edit-modal #container style='height:100%; display:block'
                                                 [style.width.px]='nvdbNavigation.reportItemEditModalWidth'></app-report-avikelse-item-edit-modal>
            <div class='resize_handle' #resizeHandle (mousedown)='onMouseDown($event)'></div>
        }
    </div>
</div>

/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getDescription } from '../fn/net-db/get-description';
import { GetDescription$Params } from '../fn/net-db/get-description';
import { getDescription$Plain } from '../fn/net-db/get-description-plain';
import { GetDescription$Plain$Params } from '../fn/net-db/get-description-plain';
import { getLastFeatureUpdateNVDB } from '../fn/net-db/get-last-feature-update-nvdb';
import { GetLastFeatureUpdateNVDB$Params } from '../fn/net-db/get-last-feature-update-nvdb';
import { getLastFeatureUpdateNVDB$Plain } from '../fn/net-db/get-last-feature-update-nvdb-plain';
import { GetLastFeatureUpdateNVDB$Plain$Params } from '../fn/net-db/get-last-feature-update-nvdb-plain';
import { infoClick } from '../fn/net-db/info-click';
import { InfoClick$Params } from '../fn/net-db/info-click';
import { infoClick$Plain } from '../fn/net-db/info-click-plain';
import { InfoClick$Plain$Params } from '../fn/net-db/info-click-plain';
import { infoClickInCoordinate } from '../fn/net-db/info-click-in-coordinate';
import { InfoClickInCoordinate$Params } from '../fn/net-db/info-click-in-coordinate';
import { infoClickInCoordinate$Plain } from '../fn/net-db/info-click-in-coordinate-plain';
import { InfoClickInCoordinate$Plain$Params } from '../fn/net-db/info-click-in-coordinate-plain';
import { infoClickMultiple } from '../fn/net-db/info-click-multiple';
import { InfoClickMultiple$Params } from '../fn/net-db/info-click-multiple';
import { infoClickMultiple$Plain } from '../fn/net-db/info-click-multiple-plain';
import { InfoClickMultiple$Plain$Params } from '../fn/net-db/info-click-multiple-plain';
import { search } from '../fn/net-db/search';
import { Search$Params } from '../fn/net-db/search';
import { search$Plain } from '../fn/net-db/search-plain';
import { Search$Plain$Params } from '../fn/net-db/search-plain';

@Injectable({ providedIn: 'root' })
export class NetDbApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getLastFeatureUpdateNvdbNetDb()` */
  static readonly GetLastFeatureUpdateNvdbNetDbPath = '/NetDb/GetLastFeatureUpdateNVDB';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLastFeatureUpdateNVDB$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLastFeatureUpdateNVDB$Plain$Response(params?: GetLastFeatureUpdateNVDB$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getLastFeatureUpdateNVDB$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLastFeatureUpdateNVDB$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLastFeatureUpdateNVDB$Plain(params?: GetLastFeatureUpdateNVDB$Plain$Params, context?: HttpContext): Observable<string> {
    return this.getLastFeatureUpdateNVDB$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLastFeatureUpdateNVDB()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLastFeatureUpdateNVDB$Response(params?: GetLastFeatureUpdateNVDB$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getLastFeatureUpdateNVDB(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLastFeatureUpdateNVDB$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLastFeatureUpdateNVDB(params?: GetLastFeatureUpdateNVDB$Params, context?: HttpContext): Observable<string> {
    return this.getLastFeatureUpdateNVDB$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `searchNetDb()` */
  static readonly SearchNetDbPath = '/NetDb/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Plain$Response(params?: Search$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return search$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Plain(params?: Search$Plain$Params, context?: HttpContext): Observable<string> {
    return this.search$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Response(params?: Search$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return search(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search(params?: Search$Params, context?: HttpContext): Observable<string> {
    return this.search$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `infoClickInCoordinateNetDb()` */
  static readonly InfoClickInCoordinateNetDbPath = '/NetDb/InfoClickInCoordinate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `infoClickInCoordinate$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  infoClickInCoordinate$Plain$Response(params?: InfoClickInCoordinate$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return infoClickInCoordinate$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `infoClickInCoordinate$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  infoClickInCoordinate$Plain(params?: InfoClickInCoordinate$Plain$Params, context?: HttpContext): Observable<string> {
    return this.infoClickInCoordinate$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `infoClickInCoordinate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  infoClickInCoordinate$Response(params?: InfoClickInCoordinate$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return infoClickInCoordinate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `infoClickInCoordinate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  infoClickInCoordinate(params?: InfoClickInCoordinate$Params, context?: HttpContext): Observable<string> {
    return this.infoClickInCoordinate$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `infoClickNetDb()` */
  static readonly InfoClickNetDbPath = '/NetDb/InfoClick';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `infoClick$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  infoClick$Plain$Response(params?: InfoClick$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return infoClick$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `infoClick$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  infoClick$Plain(params?: InfoClick$Plain$Params, context?: HttpContext): Observable<string> {
    return this.infoClick$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `infoClick()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  infoClick$Response(params?: InfoClick$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return infoClick(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `infoClick$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  infoClick(params?: InfoClick$Params, context?: HttpContext): Observable<string> {
    return this.infoClick$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `infoClickMultipleNetDb()` */
  static readonly InfoClickMultipleNetDbPath = '/NetDb/InfoClickMultiple';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `infoClickMultiple$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  infoClickMultiple$Plain$Response(params?: InfoClickMultiple$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return infoClickMultiple$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `infoClickMultiple$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  infoClickMultiple$Plain(params?: InfoClickMultiple$Plain$Params, context?: HttpContext): Observable<string> {
    return this.infoClickMultiple$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `infoClickMultiple()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  infoClickMultiple$Response(params?: InfoClickMultiple$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return infoClickMultiple(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `infoClickMultiple$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  infoClickMultiple(params?: InfoClickMultiple$Params, context?: HttpContext): Observable<string> {
    return this.infoClickMultiple$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getDescriptionNetDb()` */
  static readonly GetDescriptionNetDbPath = '/NetDb/GetDescription';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDescription$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDescription$Plain$Response(params?: GetDescription$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getDescription$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDescription$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDescription$Plain(params?: GetDescription$Plain$Params, context?: HttpContext): Observable<string> {
    return this.getDescription$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDescription()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDescription$Response(params?: GetDescription$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getDescription(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDescription$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDescription(params?: GetDescription$Params, context?: HttpContext): Observable<string> {
    return this.getDescription$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}

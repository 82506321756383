import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";


import { importProvidersFrom } from "@angular/core";
import { AppComponent } from "./app/app.component";
import { CdkTableModule } from "@angular/cdk/table";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatSliderModule } from "@angular/material/slider";
import { BrowserAnimationsModule, NoopAnimationsModule, provideAnimations } from "@angular/platform-browser/animations";
import { ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { AppRoutingModule } from "./app/app-routing.module";
import { ApiModule as ApiModulePaKarta } from "./app/_api/pakarta/api.module";
import { ApiModule as ApiModuleDataleverans } from "./app/_api/dataleverans/api.module";
import { ApiModule as ApiModuleNjdb } from "./app/_api/njdbpawebb/api.module";
import { authConfig } from "./app/auth.config";
import { NetDbServiceDataleverans } from "./app/service/dataleverans/net-db-dataleverans.service";
import { NetDbServicePakarta } from "./app/service/pakarta/net-db-pakarta.service";
import { environment } from "src/environments/environment";
import { NetDbService, EmptyNetDbService } from "./app/service/net-db.service";
import { AuthInterceptor, AuthModule } from "angular-auth-oidc-client";
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from "@angular/common/http";
import { ReportService } from "./app/service/report.service";
import { AutofocusDirective } from '@app/directives/autofocus.directive'
import { NetDbNjdbpawebbService } from "@app/service/njdbpawebb/net-db-njdbpawebb.service";
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

bootstrapApplication(AppComponent, {
    providers: [
        provideCharts(withDefaultRegisterables()),
        importProvidersFrom(AuthModule.forRoot({ config: authConfig }),
        ApiModuleDataleverans.forRoot({ rootUrl: environment.baseApiUrl }), ApiModulePaKarta.forRoot({ rootUrl: environment.baseApiUrl }), ApiModuleNjdb.forRoot({ rootUrl: environment.baseApiUrl }),
        AppRoutingModule, BrowserModule, FontAwesomeModule, ReactiveFormsModule, MatSliderModule, BrowserAnimationsModule, NoopAnimationsModule),
        ReportService,
        AutofocusDirective,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        {
            provide: NetDbService,
            useClass: environment.application === "NvdbPåKarta"
                ? NetDbServicePakarta
                : environment.application === "NvdbDataleverans"
                    ? NetDbServiceDataleverans
                    : environment.application === "NjdbPåWebb"
                        ? NetDbNjdbpawebbService
                        : EmptyNetDbService,
        },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
    ]
})
    .catch(err => console.error(err));

import { Component, Inject, OnInit } from "@angular/core";
import { Report, ReportService } from "../../service/report.service";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import { NvdbNavigationService } from "../../service/nvdb-navigation.service";
import {TrvLoadingDirective, TrvModalService, TrvTooltipDirective, TrvTooltipPosition} from "trv-ng-common";
import { DisplayReportComponent } from "../../components/reports/display-report/display-report.component";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import {faFileCirclePlus, faLocationPin, faMap} from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "app-single-report-page",
    templateUrl: "./single-report-page.component.html",
    styleUrls: ["./single-report-page.component.scss"],
    standalone: true,
    imports: [RouterLink, DisplayReportComponent, FaIconComponent, TrvLoadingDirective, TrvTooltipDirective],
})
export class SingleReportPageComponent implements OnInit {
    unformattedReportId!: string;
    reportId!: number;
    invalidReportId = false;

    report: Report | null = null;
    loading = true;
    createGeopackageLoading = false

    protected readonly faLocationPin = faLocationPin;
    protected readonly faMap = faMap;
    protected readonly faFileCirclePlus = faFileCirclePlus;

    public toolTipPositions: TrvTooltipPosition[] = ["bottom", "right"];
    constructor(
        public route: ActivatedRoute,
        @Inject(NvdbNavigationService)
        public nvdbNavigation: NvdbNavigationService,
        @Inject(ReportService) public reportService: ReportService,
        private trvModalService: TrvModalService,
        public router: Router
    ) {
        this.nvdbNavigation.hideHamburgerMenu = true;
        this.nvdbNavigation.backLink = "/reports";
    }

    async ngOnInit() {
        this.unformattedReportId = this.route.snapshot.paramMap.get("reportId")!;

        const isReportIdValidNumber =/^\d+$/.test(this.unformattedReportId);

        if (isReportIdValidNumber){
            this.reportId = parseInt(this.unformattedReportId);
        } else {
            this.invalidReportId = true;
            this.loading = false
            return;
        }

        this.report = [...this.reportService.reports, ...this.reportService.otherUsersReports].find(a => a.id === this.reportId)!;
        if (this.reportService.reportDataLoaded) this.loading = false;
        this.reportService.reportsChanged.subscribe(() => {
            this.report = [...this.reportService.reports, ...this.reportService.otherUsersReports].find(a => a.id === this.reportId)!;
            this.loading = false;
        });
    }

    showInViewMode() {
        this.router.navigateByUrl(`/map?viewmode=${this.reportId}`)
    }

    async export() {
        this.createGeopackageLoading = true
        await this.reportService.exportReportToGeopackage(this.report!);
        this.createGeopackageLoading = false
    }

}

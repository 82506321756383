import { Component } from "@angular/core";
import { faCircle, faEnvelope, faPlug, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@Component({
    selector: "app-not-found-page",
    templateUrl: "./not-found-page.component.html",
    styleUrls: ["./not-found-page.component.scss"],
    standalone: true,
    imports: [FontAwesomeModule],
})
export class NotFoundPageComponent {
    faCircle = faCircle;
    faEnvelope = faEnvelope;
    faPlug = faPlug;
    faTimes = faTimes;
}

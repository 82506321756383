import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import { DateTime } from "luxon";
import { TrvMapExtensionService } from "src/app/service/trv-map-extension.service";
import { TrvDatePickerComponent } from "trv-ng-common";
import { NgClass } from '@angular/common'

@Component({
    selector: "app-betraktelse-datum",
    templateUrl: "./betraktelse-datum.component.html",
    styleUrls: ["./betraktelse-datum.component.scss"],
    standalone: true,
    imports: [ReactiveFormsModule, TrvDatePickerComponent, NgClass],
})
export class BetraktelseDatumComponent implements OnInit {
    public datesForm = new FormControl<string | null>(null);

    public closeEmitter: EventEmitter<void> = new EventEmitter<void>();

    constructor(public trvMapExtenstionService: TrvMapExtensionService) {}

    ngOnInit(): void {
        this.datesForm.setValue(this.trvMapExtenstionService.trvMapExtension.currentBetraktelsedatum.toISO());

        this.datesForm.valueChanges.subscribe(newDate => {
            if (newDate) this.trvMapExtenstionService.trvMapExtension.updateBetraktelsedatum(DateTime.fromISO(newDate));
        });
    }

    public close() {
        this.closeEmitter.emit();
    }

    datesFormIsTodaysDate() {
        return (this.datesForm.value?.split("T")[0] ?? undefined) == DateTime.now().toISODate();
    }

    protected readonly DateTime = DateTime;
}

/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { downloadFile } from '../fn/report/download-file';
import { DownloadFile$Params } from '../fn/report/download-file';
import { downloadFile$Plain } from '../fn/report/download-file-plain';
import { DownloadFile$Plain$Params } from '../fn/report/download-file-plain';
import { exportGeopackage } from '../fn/report/export-geopackage';
import { ExportGeopackage$Params } from '../fn/report/export-geopackage';
import { exportGeopackage$Plain } from '../fn/report/export-geopackage-plain';
import { ExportGeopackage$Plain$Params } from '../fn/report/export-geopackage-plain';
import { get } from '../fn/report/get';
import { Get$Params } from '../fn/report/get';
import { get$Plain } from '../fn/report/get-plain';
import { Get$Plain$Params } from '../fn/report/get-plain';
import { getOthers } from '../fn/report/get-others';
import { GetOthers$Params } from '../fn/report/get-others';
import { getOthers$Plain } from '../fn/report/get-others-plain';
import { GetOthers$Plain$Params } from '../fn/report/get-others-plain';
import { getOthersUnlocked } from '../fn/report/get-others-unlocked';
import { GetOthersUnlocked$Params } from '../fn/report/get-others-unlocked';
import { getOthersUnlocked$Plain } from '../fn/report/get-others-unlocked-plain';
import { GetOthersUnlocked$Plain$Params } from '../fn/report/get-others-unlocked-plain';
import { getSingle } from '../fn/report/get-single';
import { GetSingle$Params } from '../fn/report/get-single';
import { getSingle$Plain } from '../fn/report/get-single-plain';
import { GetSingle$Plain$Params } from '../fn/report/get-single-plain';
import { getSingleAny } from '../fn/report/get-single-any';
import { GetSingleAny$Params } from '../fn/report/get-single-any';
import { getSingleAny$Plain } from '../fn/report/get-single-any-plain';
import { GetSingleAny$Plain$Params } from '../fn/report/get-single-any-plain';
import { getSingleByUid } from '../fn/report/get-single-by-uid';
import { GetSingleByUid$Params } from '../fn/report/get-single-by-uid';
import { getSingleByUid$Plain } from '../fn/report/get-single-by-uid-plain';
import { GetSingleByUid$Plain$Params } from '../fn/report/get-single-by-uid-plain';
import { getSingleByUidAny } from '../fn/report/get-single-by-uid-any';
import { GetSingleByUidAny$Params } from '../fn/report/get-single-by-uid-any';
import { getSingleByUidAny$Plain } from '../fn/report/get-single-by-uid-any-plain';
import { GetSingleByUidAny$Plain$Params } from '../fn/report/get-single-by-uid-any-plain';
import { remove } from '../fn/report/remove';
import { Remove$Params } from '../fn/report/remove';
import { remove$Plain } from '../fn/report/remove-plain';
import { Remove$Plain$Params } from '../fn/report/remove-plain';
import { ReportItemRead } from '../models/report-item-read';
import { ReportRead } from '../models/report-read';
import { ReportReadBulk } from '../models/report-read-bulk';
import { saveBulk } from '../fn/report/save-bulk';
import { SaveBulk$Params } from '../fn/report/save-bulk';
import { saveBulk$Plain } from '../fn/report/save-bulk-plain';
import { SaveBulk$Plain$Params } from '../fn/report/save-bulk-plain';
import { saveReport } from '../fn/report/save-report';
import { SaveReport$Params } from '../fn/report/save-report';
import { saveReport$Plain } from '../fn/report/save-report-plain';
import { SaveReport$Plain$Params } from '../fn/report/save-report-plain';
import { saveReportItem } from '../fn/report/save-report-item';
import { SaveReportItem$Params } from '../fn/report/save-report-item';
import { saveReportItem$Plain } from '../fn/report/save-report-item-plain';
import { SaveReportItem$Plain$Params } from '../fn/report/save-report-item-plain';
import { send } from '../fn/report/send';
import { Send$Params } from '../fn/report/send';
import { unlockReport } from '../fn/report/unlock-report';
import { UnlockReport$Params } from '../fn/report/unlock-report';
import { unlockReport$Plain } from '../fn/report/unlock-report-plain';
import { UnlockReport$Plain$Params } from '../fn/report/unlock-report-plain';
import { uploadFile } from '../fn/report/upload-file';
import { UploadFile$Params } from '../fn/report/upload-file';
import { uploadFile$Plain } from '../fn/report/upload-file-plain';
import { UploadFile$Plain$Params } from '../fn/report/upload-file-plain';

@Injectable({ providedIn: 'root' })
export class ReportApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSingleReport()` */
  static readonly GetSingleReportPath = '/Report/Get/Single';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSingle$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingle$Plain$Response(params?: GetSingle$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportRead>> {
    return getSingle$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSingle$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingle$Plain(params?: GetSingle$Plain$Params, context?: HttpContext): Observable<ReportRead> {
    return this.getSingle$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportRead>): ReportRead => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSingle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingle$Response(params?: GetSingle$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportRead>> {
    return getSingle(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSingle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingle(params?: GetSingle$Params, context?: HttpContext): Observable<ReportRead> {
    return this.getSingle$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportRead>): ReportRead => r.body)
    );
  }

  /** Path part for operation `getSingleAnyReport()` */
  static readonly GetSingleAnyReportPath = '/Report/Get/SingleAny';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSingleAny$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleAny$Plain$Response(params?: GetSingleAny$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportRead>> {
    return getSingleAny$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSingleAny$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleAny$Plain(params?: GetSingleAny$Plain$Params, context?: HttpContext): Observable<ReportRead> {
    return this.getSingleAny$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportRead>): ReportRead => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSingleAny()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleAny$Response(params?: GetSingleAny$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportRead>> {
    return getSingleAny(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSingleAny$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleAny(params?: GetSingleAny$Params, context?: HttpContext): Observable<ReportRead> {
    return this.getSingleAny$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportRead>): ReportRead => r.body)
    );
  }

  /** Path part for operation `getSingleByUidReport()` */
  static readonly GetSingleByUidReportPath = '/Report/Get/SingleByUid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSingleByUid$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleByUid$Plain$Response(params?: GetSingleByUid$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportRead>> {
    return getSingleByUid$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSingleByUid$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleByUid$Plain(params?: GetSingleByUid$Plain$Params, context?: HttpContext): Observable<ReportRead> {
    return this.getSingleByUid$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportRead>): ReportRead => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSingleByUid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleByUid$Response(params?: GetSingleByUid$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportRead>> {
    return getSingleByUid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSingleByUid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleByUid(params?: GetSingleByUid$Params, context?: HttpContext): Observable<ReportRead> {
    return this.getSingleByUid$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportRead>): ReportRead => r.body)
    );
  }

  /** Path part for operation `getSingleByUidAnyReport()` */
  static readonly GetSingleByUidAnyReportPath = '/Report/Get/SingleByUidAny';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSingleByUidAny$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleByUidAny$Plain$Response(params?: GetSingleByUidAny$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportRead>> {
    return getSingleByUidAny$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSingleByUidAny$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleByUidAny$Plain(params?: GetSingleByUidAny$Plain$Params, context?: HttpContext): Observable<ReportRead> {
    return this.getSingleByUidAny$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportRead>): ReportRead => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSingleByUidAny()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleByUidAny$Response(params?: GetSingleByUidAny$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportRead>> {
    return getSingleByUidAny(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSingleByUidAny$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleByUidAny(params?: GetSingleByUidAny$Params, context?: HttpContext): Observable<ReportRead> {
    return this.getSingleByUidAny$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportRead>): ReportRead => r.body)
    );
  }

  /** Path part for operation `getReport()` */
  static readonly GetReportPath = '/Report/Get';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Plain$Response(params?: Get$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportReadBulk>> {
    return get$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `get$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Plain(params?: Get$Plain$Params, context?: HttpContext): Observable<ReportReadBulk> {
    return this.get$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportReadBulk>): ReportReadBulk => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Response(params?: Get$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportReadBulk>> {
    return get(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `get$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get(params?: Get$Params, context?: HttpContext): Observable<ReportReadBulk> {
    return this.get$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportReadBulk>): ReportReadBulk => r.body)
    );
  }

  /** Path part for operation `getOthersReport()` */
  static readonly GetOthersReportPath = '/Report/Get/Others';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOthers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOthers$Plain$Response(params?: GetOthers$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportReadBulk>> {
    return getOthers$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOthers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOthers$Plain(params?: GetOthers$Plain$Params, context?: HttpContext): Observable<ReportReadBulk> {
    return this.getOthers$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportReadBulk>): ReportReadBulk => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOthers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOthers$Response(params?: GetOthers$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportReadBulk>> {
    return getOthers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOthers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOthers(params?: GetOthers$Params, context?: HttpContext): Observable<ReportReadBulk> {
    return this.getOthers$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportReadBulk>): ReportReadBulk => r.body)
    );
  }

  /** Path part for operation `getOthersUnlockedReport()` */
  static readonly GetOthersUnlockedReportPath = '/Report/Get/OthersUnlocked';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOthersUnlocked$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOthersUnlocked$Plain$Response(params?: GetOthersUnlocked$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportReadBulk>> {
    return getOthersUnlocked$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOthersUnlocked$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOthersUnlocked$Plain(params?: GetOthersUnlocked$Plain$Params, context?: HttpContext): Observable<ReportReadBulk> {
    return this.getOthersUnlocked$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportReadBulk>): ReportReadBulk => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOthersUnlocked()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOthersUnlocked$Response(params?: GetOthersUnlocked$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportReadBulk>> {
    return getOthersUnlocked(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOthersUnlocked$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOthersUnlocked(params?: GetOthersUnlocked$Params, context?: HttpContext): Observable<ReportReadBulk> {
    return this.getOthersUnlocked$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportReadBulk>): ReportReadBulk => r.body)
    );
  }

  /** Path part for operation `unlockReportReport()` */
  static readonly UnlockReportReportPath = '/Report/UnlockReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unlockReport$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  unlockReport$Plain$Response(params?: UnlockReport$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return unlockReport$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unlockReport$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unlockReport$Plain(params?: UnlockReport$Plain$Params, context?: HttpContext): Observable<number> {
    return this.unlockReport$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unlockReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  unlockReport$Response(params?: UnlockReport$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return unlockReport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unlockReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unlockReport(params?: UnlockReport$Params, context?: HttpContext): Observable<number> {
    return this.unlockReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `saveBulkReport()` */
  static readonly SaveBulkReportPath = '/Report/SaveBulk';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveBulk$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveBulk$Plain$Response(params?: SaveBulk$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportReadBulk>> {
    return saveBulk$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveBulk$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveBulk$Plain(params?: SaveBulk$Plain$Params, context?: HttpContext): Observable<ReportReadBulk> {
    return this.saveBulk$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportReadBulk>): ReportReadBulk => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveBulk()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveBulk$Response(params?: SaveBulk$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportReadBulk>> {
    return saveBulk(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveBulk$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveBulk(params?: SaveBulk$Params, context?: HttpContext): Observable<ReportReadBulk> {
    return this.saveBulk$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportReadBulk>): ReportReadBulk => r.body)
    );
  }

  /** Path part for operation `saveReportReport()` */
  static readonly SaveReportReportPath = '/Report/SaveReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveReport$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveReport$Plain$Response(params?: SaveReport$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportRead>> {
    return saveReport$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveReport$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveReport$Plain(params?: SaveReport$Plain$Params, context?: HttpContext): Observable<ReportRead> {
    return this.saveReport$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportRead>): ReportRead => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveReport()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveReport$Response(params?: SaveReport$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportRead>> {
    return saveReport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveReport$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveReport(params?: SaveReport$Params, context?: HttpContext): Observable<ReportRead> {
    return this.saveReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportRead>): ReportRead => r.body)
    );
  }

  /** Path part for operation `saveReportItemReport()` */
  static readonly SaveReportItemReportPath = '/Report/SaveReportItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveReportItem$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveReportItem$Plain$Response(params?: SaveReportItem$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportItemRead>> {
    return saveReportItem$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveReportItem$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveReportItem$Plain(params?: SaveReportItem$Plain$Params, context?: HttpContext): Observable<ReportItemRead> {
    return this.saveReportItem$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportItemRead>): ReportItemRead => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveReportItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveReportItem$Response(params?: SaveReportItem$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportItemRead>> {
    return saveReportItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveReportItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveReportItem(params?: SaveReportItem$Params, context?: HttpContext): Observable<ReportItemRead> {
    return this.saveReportItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportItemRead>): ReportItemRead => r.body)
    );
  }

  /** Path part for operation `removeReport()` */
  static readonly RemoveReportPath = '/Report/Remove';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `remove$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  remove$Plain$Response(params?: Remove$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return remove$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `remove$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  remove$Plain(params?: Remove$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.remove$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `remove()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  remove$Response(params?: Remove$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return remove(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `remove$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  remove(params?: Remove$Params, context?: HttpContext): Observable<boolean> {
    return this.remove$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `sendReport()` */
  static readonly SendReportPath = '/Report/Send';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `send()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  send$Response(params?: Send$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return send(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `send$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  send(params?: Send$Params, context?: HttpContext): Observable<void> {
    return this.send$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `exportGeopackageReport()` */
  static readonly ExportGeopackageReportPath = '/Report/ExportGeopackage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportGeopackage$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportGeopackage$Plain$Response(params?: ExportGeopackage$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return exportGeopackage$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportGeopackage$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportGeopackage$Plain(params?: ExportGeopackage$Plain$Params, context?: HttpContext): Observable<Blob> {
    return this.exportGeopackage$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportGeopackage()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportGeopackage$Response(params?: ExportGeopackage$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return exportGeopackage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportGeopackage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportGeopackage(params?: ExportGeopackage$Params, context?: HttpContext): Observable<Blob> {
    return this.exportGeopackage$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `downloadFileReport()` */
  static readonly DownloadFileReportPath = '/Report/DownloadFile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadFile$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadFile$Plain$Response(params?: DownloadFile$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return downloadFile$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadFile$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadFile$Plain(params?: DownloadFile$Plain$Params, context?: HttpContext): Observable<Blob> {
    return this.downloadFile$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadFile$Response(params?: DownloadFile$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return downloadFile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadFile(params?: DownloadFile$Params, context?: HttpContext): Observable<Blob> {
    return this.downloadFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `uploadFileReport()` */
  static readonly UploadFileReportPath = '/Report/UploadFile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadFile$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFile$Plain$Response(params?: UploadFile$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return uploadFile$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadFile$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFile$Plain(params?: UploadFile$Plain$Params, context?: HttpContext): Observable<number> {
    return this.uploadFile$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFile$Response(params?: UploadFile$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return uploadFile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFile(params?: UploadFile$Params, context?: HttpContext): Observable<number> {
    return this.uploadFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

}

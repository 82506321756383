import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {FeatureTypeRequest} from "../../_api/njdbpawebb/models/feature-type-request";
import {FeatureTypeRequestMultiple} from "../../_api/njdbpawebb/models/feature-type-request-multiple";
import {NetDbApi} from "../../_api/njdbpawebb/services/net-db-api";
import {NetDbService} from "../net-db.service";
import {ActorTypesEnum, DataslagByDeliveryType, SearchRequest} from "src/app/_api/dataleverans/models";

@Injectable({
    providedIn: "root",
})
export class NetDbNjdbpawebbService implements NetDbService {

    constructor(private netDbApiClient: NetDbApi) {}

    public getLastFeatureUpdateNVDB(): Observable<string> {
      throw new Error("Method not implemented.");
    }

    public infoClickInCoordinate(featureTypeRequest: FeatureTypeRequest): Observable<string> {
      throw new Error("Method not implemented.");
    }    

    public infoClickMultiple(featureTypeRequestMultiple: FeatureTypeRequestMultiple): Observable<string> {
        return this.netDbApiClient.infoClickMultiple({
            body: featureTypeRequestMultiple,
        });
    }

    public searchAll(searchObj: SearchRequest): Observable<string> {
        return this.netDbApiClient.search(
            {
                body: searchObj
            }
        )
    }

    public getDescription(metaKey: string): Observable<string> {
        return this.netDbApiClient.getDescription({metaKey: metaKey});
    }


    public getAllDataslagForActor(actorType: ActorTypesEnum): Observable<DataslagByDeliveryType[]> {
        throw new Error("Method not implemented.");
    }
}

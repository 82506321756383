import {Component, Inject, Input, OnInit} from '@angular/core'
import {
    TrvFormComponent,
    TrvFormFieldComponent,
    TrvFormUtilityService,
    TrvFormValidationErrorMap,
    TrvGlobalMessagesService,
    TrvModalRef
} from 'trv-ng-common'
import {AjourhallningAction} from "@app/shared/enums";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms'
import {NgIf} from "@angular/common";
import {showErrorMessage} from '@shared/animations'
import {AutofocusDirective} from '@app/directives/autofocus.directive'
import {ReportService} from "@app/service/report.service";

@Component({
    selector: "app-ajourhallning-action-modal",
    standalone: true,
    imports: [FormsModule, NgIf, ReactiveFormsModule, AutofocusDirective, TrvFormFieldComponent, TrvFormComponent],
    templateUrl: "./ajourhallning-action-modal.component.html",
    styleUrl: "./ajourhallning-action-modal.component.scss",
    animations: [showErrorMessage],
})
export class AjourhallningActionModalComponent implements OnInit{
    @Input() action!: AjourhallningAction;
    @Input() reportId!: number;

    validationErrorMap: TrvFormValidationErrorMap = {
        email: {
            required: "Måste anges.",
            email: "Måste vara en godkänd E-post adress."
        },
        comment: {
            required: "Måste anges.",
        }
    };

    form!: FormGroup<any>
    constructor(
        public modalRef: TrvModalRef<AjourhallningActionModalComponent>,
        @Inject(TrvGlobalMessagesService)
        private globalMessagesService: TrvGlobalMessagesService,
        private formUtilityService: TrvFormUtilityService,
        private formBuilder: FormBuilder,
        private reportService: ReportService
    ) {}

    ngOnInit(){
        this.form = this.formBuilder.group({
            email: ['', [Validators.email]],
            comment: ['', this.action == AjourhallningAction.klart_for_beredning ? [] : [Validators.required]],
            sendEmail: [true],
        })

        if(this.action == AjourhallningAction.informera_kommunen || this.action == AjourhallningAction.informera_annan || this.action == AjourhallningAction.informera_kunden){
            this.email.addValidators([Validators.required])
        }

        if(this.action == AjourhallningAction.informera_kunden){
            this.email.patchValue(this.reportService.reports.concat(this.reportService.otherUsersReports).find(a => a.id === this.reportId)?.email ?? "")
        }
    }

    get email() {
        return this.form.get('email') as FormControl;
    }

    get comment() {
        return this.form.get('comment') as FormControl;
    }

    get sendEmail() {
        return this.form.get('sendEmail') as FormControl;
    }

    submit(): void {
        if (!this.form.valid) {
            this.formUtilityService.isValid(this.form);
            this.globalMessagesService.error("Var vänlig och korrigera felen, det gick ej att uppdatera ärendet.");
            return
        }

        this.modalRef.close({
            email: this.form.value.email,
            comment: this.form.value.comment,
            sendEmail: this.form.value.sendEmail
        });
    }

    protected readonly AjourhallningAction = AjourhallningAction;
}

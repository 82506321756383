<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Dela ärende</h4>

    <button type="button" class="btn-close" aria-describedby="modal-title" (click)="modalRef.dismiss()"></button>
</div>
<div class="modal-body">
    <div class="d-flex">
        <div class="link">
            <span class="link_text">
                <fa-icon [icon]='faLink'></fa-icon>
                {{ url }}
            </span>
        </div>
        <button class="btn btn-primary" style="border-radius: 0; text-wrap: nowrap"
                (click)="unlockReportAndCopyLink()"
        >
           Lås upp ärende och kopiera länk
        </button>

        @if (report.owner != null) {
            <div (click)='unlockReportInformation()' class='link_information'>
                <app-svg-icon
                    [svgIcon]='circleInfoIcon'></app-svg-icon>
            </div>
        }
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="modalRef.close()">Stäng</button>
</div>

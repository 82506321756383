import {AfterViewInit, Component, ElementRef, Inject, ViewChild} from '@angular/core'
import {ActivatedRoute, EventType, NavigationEnd, Router, RouterOutlet} from '@angular/router'
import {IconDefinition, IconProp} from '@fortawesome/fontawesome-svg-core'
import {
    faCaretDown,
    faChevronUp,
    faEnvelope,
    faHome,
    faInfoCircle,
    faLock,
    faMap,
    faUserCircle,
} from '@fortawesome/free-solid-svg-icons'
import { TrvNgMapService, TrvNgMapOverlayService, MapTools } from '@trafikverket/trv-ng-map'
import {
    NavigationItem,
    RouterNavigationItem,
    TrvModalService,
    TrvPopoverPosition,
    TrvPopoverRef,
    TrvPopoverDirective, TrvTooltipDirective,
} from 'trv-ng-common'
import {MapComponent} from '../components/map/map/map.component'
import {NavigationService} from '../service/navigation/navigation.service'
import {environment} from 'src/environments/environment'
import {Title} from '@angular/platform-browser'
import {combineLatest, firstValueFrom, forkJoin, of, take} from 'rxjs'
import {LocalStorageService} from '../service/local-storage.service'
import {Coordinate} from 'ol/coordinate'
import {SWEDEN_VIEW} from '../shared/constants'
import {SvgIcon} from '../shared/utils/svg-helper'
import {NvdbNavigationService} from '../service/nvdb-navigation.service'
import {
    svgPrinter,
    svgHamburger,
    svgArrowLeft,
    svgTrafikverketLogo,
    svgPerson,
    svgLocation,
    svgPlus,
    svgMinus,
    svgArrowDown,
    svgChevronDown,
    svgInfo,
    svgCircleInfo,
} from '../shared/utils/svg-helper-data'
import {OidcSecurityService} from 'angular-auth-oidc-client'
import {Report, ReportService} from '../service/report.service'
import {ReportAvikelseService} from '../service/report-avikelse.service'
import TileWMS from 'ol/source/TileWMS'
import ImageWMS from 'ol/source/ImageWMS'
import Source from 'ol/source/Source'
import {UserMenuComponent} from '../components/popover/user-menu/user-menu.component'
import {
    ApplicationInfoModalComponent,
} from '../components/modals/application-info-modal/application-info-modal.component'
import {AuthenticationService} from '../service/authentication.service'
import {MapMenuComponent} from '../components/map-menu/map-menu.component'
import {
    ReportAvikelseItemEditModalComponent,
} from '../components/reports-avikelse/report-avikelse-item-edit-modal/report-avikelse-item-edit-modal.component'
import {
    ReportItemEditModalComponent,
} from '../components/reports/report-item-edit-modal/report-item-edit-modal.component'
import {SvgIconComponent} from '../components/utils/svg-icon/svg-icon.component'
import {AsyncPipe, NgClass} from '@angular/common'
import {NetDbApi} from '@_api/dataleverans/services/net-db-api'
import {AjourApi} from '@_api/dataleverans/services/ajour-api'
import {ReportApi} from '@_api/dataleverans/services/report-api'
import {TypesApi} from '@_api/dataleverans/services/types-api'
import {FaIconComponent} from '@fortawesome/angular-fontawesome'
import {
    MapExportPreviewModalComponent
} from "@components/map-menu/print-map-tab/map-export-preview-modal/map-export-preview-modal.component";

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
    standalone: true,
    imports: [
        SvgIconComponent,
        TrvPopoverDirective,
        RouterOutlet,
        MapMenuComponent,
        ReportItemEditModalComponent,
        ReportAvikelseItemEditModalComponent,
        AsyncPipe,
        FaIconComponent,
        TrvTooltipDirective,
        NgClass,
    ],
})
export class DashboardComponent implements AfterViewInit {
    @ViewChild("container", {read: ElementRef, static: false}) container!: ElementRef;
    @ViewChild("resizeHandle", {read: ElementRef, static: false}) resizeHandle!: ElementRef;

    public mouseX = 0;

    public environment = environment;

    public googleMapsApiKey = environment.streetViewApiKey;

    public fasEnvelope: IconProp = faEnvelope as IconProp;
    public fasLock: IconProp = faLock as IconProp;
    public fasUser: IconProp = faUserCircle as IconProp;
    public fasInfo: IconProp = faInfoCircle as IconProp;
    public faCaretDown: IconProp = faCaretDown as IconProp;
    public faChevronUp = faChevronUp;
    public faEnvelope = faEnvelope;

    //Svg icons
    public printerIcon: SvgIcon = svgPrinter;
    public hamburgerIcon: SvgIcon = svgHamburger;
    public arrowLeft: SvgIcon = svgArrowLeft;
    public chevronDown: SvgIcon = svgChevronDown;
    public logoIconWithText: SvgIcon = svgTrafikverketLogo;
    public personIcon: SvgIcon = svgPerson;
    public infoIcon: SvgIcon = svgInfo;
    public infoCircleIcon: SvgIcon = svgCircleInfo;
    public locationIcon: SvgIcon = svgLocation;
    public plusIcon: SvgIcon = svgPlus;
    public minusIcon: SvgIcon = svgMinus;

    public email: string = "NVDBpakarta@trafikverket.se";

    public myOptionsComponent = UserMenuComponent;
    @ViewChild("myOptionsPopoverRef") myOptionsPopoverRef!: TrvPopoverDirective;

    public myOptionsPosition: TrvPopoverPosition[] = ["bottom", "bottom-end", "bottom-start", "left", "left-end"];
    public navItems: NavigationItem[];

    public title: string = "NVDB dataleverans";

    @ViewChild(MapMenuComponent) mapmenu!: MapMenuComponent;

    public buildVersion: string = "";


    backText: string = "Tillbaka";

    constructor(
        private route: ActivatedRoute,
        @Inject(Router) private router: Router,
        @Inject(ReportService) public reportService: ReportService,
        @Inject(ReportAvikelseService) public reportAvikelseService: ReportAvikelseService,
        @Inject(OidcSecurityService) private oidcService: OidcSecurityService,
        @Inject(NavigationService) private navigationService: NavigationService,
        @Inject(LocalStorageService)
        private localStorageService: LocalStorageService,
        @Inject(TrvNgMapService) public trvMapService: TrvNgMapService,
        @Inject(TrvNgMapOverlayService)
        private trvMapOverlayService: TrvNgMapOverlayService,
        @Inject(NvdbNavigationService)
        public nvdbNavigation: NvdbNavigationService,
        @Inject(TrvModalService) public trvModalService: TrvModalService,
        public authenticationService: AuthenticationService
    ) {
        this.buildVersion = environment.buildVersion;
        this.navItems = navigationService.sidebar();

        //Site specific variables,
        let title = "NVDB - Dataleverans";
        if (environment.application == "NvdbDataleverans") {
            title = "NVDB - Dataleverans";
            this.fetchApplicationData();
        } else if (environment.application == "NvdbPåKarta") {
            title = "NVDB på karta";
        } else if (environment.application == "NjdbPåWebb") {
            title = "NJDB på webb";
        }

        this.nvdbNavigation.siteTitle = title;

        this.setBackText(router.url)

        this.nvdbNavigation.currentPageIsMap = /^\/(map)?(\?.*)?$/.test(router.url)

        router.events.subscribe(navigationEvent => {
            if (navigationEvent.type !== EventType.NavigationEnd) return

            this.nvdbNavigation.currentPageIsMap = /^\/(map)?(\?.*)?$/.test(router.url)

            this.setBackText(navigationEvent.url)
        })
    }

    setBackText(url: string) {
        if (url.toString().match(/.*\/reports\/[0-9]+/) != null) {
            this.backText = "Mina ärenden"
        } else if (url.toString().match(/.*\/reports.*/) != null) {
            this.backText = "Min sida"
        } else if (url.toString().match(/.*home.*/) != null) {
            this.backText = "Karta"
        } else if (url.toString().match(/.*handle-reports\/[0-9]+.*/) != null) {
            this.backText = "Hantera ärenden"
        } else if (url.toString().match(/.*handle-reports.*/) != null) {
            this.backText = "Min sida"
        } else if (url.toString().match(/.*view-reports.*/) != null) {
            this.backText = "Min sida"
        } else if (url.toString().match(/.*stats.*/) != null) {
            this.backText = "Min sida"
        }
    }

    // fetches all data needed for the application
    async fetchApplicationData() {
        await this.authenticationService.init();
        this.reportService.initData();
    }

    /*
        async ngOnInit() {
    /!*
            if (environment.application == "NvdbDataleverans") {
                let dataslagForActors = await firstValueFrom(this.netDbApiClient.getAllDataslagForActors({ actorTypes: this.nvdbNavigation.reportAsList.map(a => a.id) }));
                for (const actorDataslag of dataslagForActors) {
                    for (const dataslagByDeliveryType of actorDataslag.dataslagByDeliveryTypes) {
                        this.reportService.allDataslag[actorDataslag.actorType][dataslagByDeliveryType.deliveryType] = dataslagByDeliveryType.dataslag;
                    }
                }
            }
    *!/
        }

    */

    ngAfterViewInit() {
        if (environment.application == "NvdbDataleverans" || (environment.local == "true")) {
            this.trvMapService.loadScriptApi(environment.streetViewApiKey).then(() => {
            });
        }

        this.trvMapService.onMapLoaded().subscribe(() => {
            //Order datalayers by name
            this.trvMapService.trvMap?.trvLayer.wmsLayerListItems.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
        });
    }

    onMouseDown = (event: MouseEvent) => {
        this.mouseX = event.clientX;

        document.body.style.cursor = "col-resize";
        this.container.nativeElement.style.userSelect = "none";
        this.container.nativeElement.style.pointerEvents = "none";
        this.resizeHandle.nativeElement.style.opacity = 100;

        this.resizeHandle.nativeElement.style.opacity = 100;

        document.addEventListener("mousemove", this.onMouseMove);
        document.addEventListener("mouseup", this.onMouseUp);
    };

    onMouseMove = (event: MouseEvent) => {
        event.preventDefault();

        const distanceMoved = event.clientX - this.mouseX;
        this.mouseX = event.clientX;

        // shouldnt resize if mouse is to far to the left or right
        const boundingBox = this.resizeHandle.nativeElement.getBoundingClientRect();
        const rightSideX = boundingBox.x + boundingBox.width;

        if ((event.clientX < rightSideX && distanceMoved > 0) || (rightSideX < event.clientX && distanceMoved < 0)) return;

        this.nvdbNavigation.updateReportEditModalWidth(distanceMoved);
    };

    onMouseUp = (event: MouseEvent) => {
        document.body.style.removeProperty("cursor");

        this.container.nativeElement.style.removeProperty("user-select");
        this.container.nativeElement.style.removeProperty("pointer-events");

        this.resizeHandle.nativeElement.style.opacity = 0;

        // Attach the listeners to document
        document.removeEventListener("mousemove", this.onMouseMove);
        document.removeEventListener("mouseup", this.onMouseUp);

        this.localStorageService.setReportItemEditModalWidth(this.nvdbNavigation.reportItemEditModalWidth);
    };

    public menuToggle(): void {
        this.nvdbNavigation.toggleSideBar();
    }

    public goToMap() {
        this.router.navigate([this.nvdbNavigation.backLink]);
    }

    public goToMyPages() {
        this.router.navigate(["/home"]);
    }

    public showPopover(event: Event) {
        //this.myOptionsPopoverRef.
        if (this.myOptionsPopoverRef.active) this.myOptionsPopoverRef.hide();
        else this.myOptionsPopoverRef.show();
    }

    public clickOutside() {
        if (!this.myOptionsPopoverRef.componentInstance.isSelectOpen()) this.myOptionsPopoverRef.hide();
    }

    public onShowMyOptionsComponent(ref: TrvPopoverRef<UserMenuComponent>): void {
    }

    public onAfterCloseMyOptionsComponent(event: any): void {
    }

    public showInfoComponent() {
        this.trvModalService.open(ApplicationInfoModalComponent, {
            disposeOnBackdropClick: true,
            size: this.nvdbNavigation.isMobileDevice ? "fullscreen" : "lg",
        });
    }
}

import { Component, ViewChild } from "@angular/core";
import { ChartConfiguration, ChartData, ChartEvent, ChartType, ChartTypeRegistry } from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import { MatButton } from "@angular/material/button";
import { MatDivider } from "@angular/material/divider";
import { NvdbNavigationService } from "@app/service/nvdb-navigation.service";
import { ChartContainerComponent } from "@app/components/stats/chart-container/chart-container.component";
import { NumReportsChartComponent } from "@app/components/stats/num-reports-chart/num-reports-chart.component";
import { NumReportsOverTimeChartComponent } from "@app/components/stats/num-reports-over-time-chart/num-reports-over-time-chart.component";
import { TrvDateRangeComponent, TrvFormBundle } from "trv-ng-common";
import { StatsService } from "@app/service/stats.service";
import { FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NumReportsPerSourceChartComponent } from "@app/components/stats/num-reports-per-source-chart/num-reports-per-source-chart.component";

@Component({
    selector: "app-statistics-page",
    standalone: true,
    imports: [BaseChartDirective, ChartContainerComponent, NumReportsChartComponent, NumReportsOverTimeChartComponent, ReactiveFormsModule ,TrvFormBundle,TrvDateRangeComponent, NumReportsPerSourceChartComponent],
    templateUrl: "./statistics-page.component.html",
    styleUrl: "./statistics-page.component.scss",
})
export class StatisticsPageComponent {

    firstCopy = false;

    
    constructor(public nvdbNavigation: NvdbNavigationService, public statsService: StatsService) {
        this.nvdbNavigation.hideHamburgerMenu = true;
        this.nvdbNavigation.backLink = "/home";
    }

    
}


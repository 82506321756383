import {EventEmitter, Inject, Injectable} from "@angular/core";
import {TrvNgMapService} from "@trafikverket/trv-ng-map";
import {SWEDEN_VIEW_ID} from "../shared/constants";
import {Coordinate} from "ol/coordinate";
import {ReportItemWithFeature} from "./report-avikelse.service";
import {TrvGlobalMessagesService} from 'trv-ng-common'
import {DateTime} from "luxon";
import {forEach} from "ol/geom/flat/segments";
import {SavedViewSectionComponent} from "@components/map-menu/saved-view-section/saved-view-section.component";

@Injectable({
    providedIn: "root",
})
/**
 * Service that handles saving and loading objects to local storage.
 */
export class LocalStorageService {
    private LOCALSTORAGE_DEFAULT_VIEW_KEY: string = "_defaultView";
    private LOCALSTORAGE_SAVED_VIEWS_KEY: string = "_savedViews";

    private LOCALSTORAGE_DEFAULT_REPORT_GROUP_ID: string = "_defaultReportGroupId";
    private LOCALSTORAGE_DEFAULT_AVIKELSE_GROUP_ID: string = "_defaultAvikelseReportGroupId";

    private LOCALSTORAGE_HIDDEN_REPORT_ITEMS: string = "_localstorageHiddenReportItems";

    private LOCALSTORAGE_SIDEBAR_WIDTH: string = "_localstorageSidebarWidth";
    private LOCALSTORAGE_REPORT_ITEM_EDIT_MODAL_WIDTH: string = "_localstorageReportItemEditModalWidth";

    private LOCALSTORAGE_DONT_SHOW_MESSAGE_AGAIN: string = "_localstoragedontShowMessage_";

    private DefaultSidebarWidth = 400;
    private DefaultReportItemEditModalWidth = 400;

    /**
     * The guid of the default view when opening the application
     */
    public defaultViewId = localStorage.getItem(this.LOCALSTORAGE_DEFAULT_VIEW_KEY) ?? SWEDEN_VIEW_ID
    public savedViews: SavedView[] = []
    public savedViewsChanged = new EventEmitter()

    constructor(@Inject(TrvNgMapService) private trvMapService: TrvNgMapService,
                @Inject(TrvGlobalMessagesService) private globalMessagesService: TrvGlobalMessagesService
    ) {

        var savedViews = JSON.parse(localStorage.getItem(this.LOCALSTORAGE_SAVED_VIEWS_KEY) ?? "[]")
        for (let view of savedViews) {
            // lastModifiedDate cannot be JSONParsed to DateTime so we have to do it manually
            var dateTime = DateTime.fromISO(view.lastModifiedDate as unknown as string)
            if (dateTime.isValid) {
                view.lastModifiedDate = dateTime
            } else {
                view.lastModifiedDate = DateTime.fromISO('2000-01-01')// just some random date long ago
            }
        }

        this.savedViews = savedViews
        this.savedViewsChanged.emit()
    }


    public setSavedViews(savedViews: SavedView[]) {
        // if the defaultviewid is no longer present, set the default view to sweden
        if (!savedViews.some(view => view.id === this.defaultViewId)) {
            this.setDefaultViewGuid(SWEDEN_VIEW_ID);
        }

        this.savedViews = savedViews

        localStorage.setItem(this.LOCALSTORAGE_SAVED_VIEWS_KEY, JSON.stringify(savedViews));
        this.savedViewsChanged.emit()
    }

    public setDefaultViewGuid(newDefaultGuid: string) {
        this.defaultViewId = newDefaultGuid;
        localStorage.setItem(this.LOCALSTORAGE_DEFAULT_VIEW_KEY, newDefaultGuid);
    }

    public getDefaultReportId() {
        return parseInt(localStorage.getItem(this.LOCALSTORAGE_DEFAULT_REPORT_GROUP_ID) ?? "-1");
    }

    public setDefaultReportGroupId(reportGroupId: string | number) {
        localStorage.setItem(this.LOCALSTORAGE_DEFAULT_REPORT_GROUP_ID, reportGroupId.toString());
    }

    public setUnsentReportAvikelseItems(reportAvikelseItemWithFeature: ReportItemWithFeature[]) {
        localStorage.setItem(this.LOCALSTORAGE_DEFAULT_AVIKELSE_GROUP_ID, JSON.stringify(reportAvikelseItemWithFeature));
    }

    public getUnsentReportAvikelseItems() {
        return JSON.parse(localStorage.getItem(this.LOCALSTORAGE_DEFAULT_AVIKELSE_GROUP_ID) ?? "[]");
    }

    public setHiddenReportItems(itemIds: number[]) {
        localStorage.setItem(this.LOCALSTORAGE_HIDDEN_REPORT_ITEMS, JSON.stringify(itemIds));
    }

    public getHiddenReportItems(): number[] {
        return JSON.parse(localStorage.getItem(this.LOCALSTORAGE_HIDDEN_REPORT_ITEMS) ?? "[]");
    }

    public setAvikelseReportName(reportName: string) {
        localStorage.setItem("nvdbpakartaReportName", reportName ?? "")
    }

    public clearAvikelseReportName() {
        localStorage.removeItem("nvdbpakartaReportName")
    }

    public getAvikelseReportName() {
        return localStorage.getItem("nvdbpakartaReportName") ?? ""
    }


    public setAvikelseContactInformation(email: string, name: string, phoneNr: string) {
        localStorage.setItem("nvdbpakartaEmail", email ?? "")
        localStorage.setItem("nvdbpakartaName", name ?? "")
        localStorage.setItem("nvdbpakartaPhone", phoneNr ?? "")
    }

    public clearAvikelseContactInformation() {
        localStorage.removeItem("nvdbpakartaEmail")
        localStorage.removeItem("nvdbpakartaName")
        localStorage.removeItem("nvdbpakartaPhone")
    }

    public getAvikelseEmail() {
        return localStorage.getItem("nvdbpakartaEmail") ?? ""
    }

    public getAvikelseName() {
        return localStorage.getItem("nvdbpakartaName") ?? ""
    }

    public getAvikelsePhone() {
        return localStorage.getItem("nvdbpakartaPhone") ?? ""
    }

    public setSidepanelWidth(sideBarWidth: number) {
        localStorage.setItem(this.LOCALSTORAGE_SIDEBAR_WIDTH, JSON.stringify(sideBarWidth));
    }

    public getSidepanelWidth(): number {
        const value = localStorage.getItem(this.LOCALSTORAGE_SIDEBAR_WIDTH);
        return value ? parseInt(JSON.parse(value)) : this.DefaultSidebarWidth;
    }

    public setReportItemEditModalWidth(reportItemEditModalWidth: number) {
        localStorage.setItem(this.LOCALSTORAGE_REPORT_ITEM_EDIT_MODAL_WIDTH, JSON.stringify(reportItemEditModalWidth));
    }

    public getReportItemEditModalWidth(): number {
        const value = localStorage.getItem(this.LOCALSTORAGE_REPORT_ITEM_EDIT_MODAL_WIDTH);
        return value ? parseInt(JSON.parse(value)) : this.DefaultReportItemEditModalWidth;
    }

    // dontShowMessage is null if inactive
    // if selected the string is the messageFromForvaltning on påkarta or dateTime for dataleverans.
    // these are used to check if the message has been updated since dontShowMessage was set.
    public getdontShowMessage(): string | null {
        return localStorage.getItem(this.LOCALSTORAGE_DONT_SHOW_MESSAGE_AGAIN)
    }

    // onKarta we store the message instead of the time. So when we try to detect changes we compare the messages instead of the updateTime
    // provide the messageFromForvaltning only for paKarta
    public setdontShowMessage(dontShowMessage: boolean, messageForOnKarta?: string) {
        if (dontShowMessage) localStorage.setItem(this.LOCALSTORAGE_DONT_SHOW_MESSAGE_AGAIN, messageForOnKarta ? messageForOnKarta : DateTime.now().toString())
        else localStorage.removeItem(this.LOCALSTORAGE_DONT_SHOW_MESSAGE_AGAIN)
    }
}

/**
 * Method that generates a GUID.
 * @returns GUID
 */
export function generateGUID(): string {
    let d = new Date().getTime();
    const guid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return guid;
}

export interface SavedView {
    id: string;
    name: string;
    backgroundLayerId: string | undefined;
    dataLayersId: string[] | undefined;
    // should these always default to true for all savedviews, or should
    // they be different for different views?
    saveBackground: boolean;
    saveDataLayers: boolean;

    extent: number[];
    zoom: number;
    centerCoordinate: Coordinate;

    lastModifiedDate: DateTime;
}

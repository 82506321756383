import { Component, OnInit } from "@angular/core";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { Router } from "@angular/router";
import { faCheckCircle, faCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { from, map, of, switchMap, take, tap } from "rxjs";
import { NvdbNavigationService } from "src/app/service/nvdb-navigation.service";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AuthenticationService } from '@app/service/authentication.service'
import { TrvLoadingBundle } from "trv-ng-common";

@Component({
    selector: "app-login-page",
    templateUrl: "./login-page.component.html",
    styleUrls: ["./login-page.component.scss"],
    standalone: true,
    imports: [FontAwesomeModule, TrvLoadingBundle],
})
export class LoginPageComponent implements OnInit {
    public faCircle = faCircle;
    public faCheck = faCheckCircle;

    constructor(
        private oidcService: OidcSecurityService,
        public authService: AuthenticationService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.oidcService
        .checkAuth()
        .subscribe(o => {
            if (o.isAuthenticated) {
                const storedUrl = localStorage.getItem("returnUrl");
                localStorage.removeItem("returnUrl");
                this.router.navigateByUrl(storedUrl ?? "/");
            }
        })

    }

    public login(): void {
        this.oidcService.authorize();
    }
}

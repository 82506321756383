/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CaseItem } from '../models/case-item';
import { downloadFile } from '../fn/command/download-file';
import { DownloadFile$Params } from '../fn/command/download-file';
import { downloadFile$Plain } from '../fn/command/download-file-plain';
import { DownloadFile$Plain$Params } from '../fn/command/download-file-plain';
import { filesInReport } from '../fn/command/files-in-report';
import { FilesInReport$Params } from '../fn/command/files-in-report';
import { filesInReport$Plain } from '../fn/command/files-in-report-plain';
import { FilesInReport$Plain$Params } from '../fn/command/files-in-report-plain';
import { getFileName } from '../fn/command/get-file-name';
import { GetFileName$Params } from '../fn/command/get-file-name';
import { getFileName$Plain } from '../fn/command/get-file-name-plain';
import { GetFileName$Plain$Params } from '../fn/command/get-file-name-plain';
import { reportCaseUpdated } from '../fn/command/report-case-updated';
import { ReportCaseUpdated$Params } from '../fn/command/report-case-updated';
import { reportsToCase } from '../fn/command/reports-to-case';
import { ReportsToCase$Params } from '../fn/command/reports-to-case';
import { reportsToCase$Plain } from '../fn/command/reports-to-case-plain';
import { ReportsToCase$Plain$Params } from '../fn/command/reports-to-case-plain';
import { reportsToSync } from '../fn/command/reports-to-sync';
import { ReportsToSync$Params } from '../fn/command/reports-to-sync';
import { reportsToSync$Plain } from '../fn/command/reports-to-sync-plain';
import { ReportsToSync$Plain$Params } from '../fn/command/reports-to-sync-plain';
import { ReportWithStatus } from '../models/report-with-status';
import { sendReport } from '../fn/command/send-report';
import { SendReport$Params } from '../fn/command/send-report';
import { syncedReports } from '../fn/command/synced-reports';
import { SyncedReports$Params } from '../fn/command/synced-reports';

@Injectable({ providedIn: 'root' })
export class CommandApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sendReportCommand()` */
  static readonly SendReportCommandPath = '/Command/SendReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendReport()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendReport$Response(params?: SendReport$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sendReport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendReport$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendReport(params?: SendReport$Params, context?: HttpContext): Observable<void> {
    return this.sendReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `reportsToSyncCommand()` */
  static readonly ReportsToSyncCommandPath = '/Command/ReportsToSync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsToSync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsToSync$Plain$Response(params?: ReportsToSync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ReportWithStatus>>> {
    return reportsToSync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsToSync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsToSync$Plain(params?: ReportsToSync$Plain$Params, context?: HttpContext): Observable<Array<ReportWithStatus>> {
    return this.reportsToSync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ReportWithStatus>>): Array<ReportWithStatus> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsToSync()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsToSync$Response(params?: ReportsToSync$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ReportWithStatus>>> {
    return reportsToSync(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsToSync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsToSync(params?: ReportsToSync$Params, context?: HttpContext): Observable<Array<ReportWithStatus>> {
    return this.reportsToSync$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ReportWithStatus>>): Array<ReportWithStatus> => r.body)
    );
  }

  /** Path part for operation `syncedReportsCommand()` */
  static readonly SyncedReportsCommandPath = '/Command/SyncedReports';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `syncedReports()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  syncedReports$Response(params?: SyncedReports$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return syncedReports(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `syncedReports$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  syncedReports(params?: SyncedReports$Params, context?: HttpContext): Observable<void> {
    return this.syncedReports$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `filesInReportCommand()` */
  static readonly FilesInReportCommandPath = '/Command/FilesInReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesInReport$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  filesInReport$Plain$Response(params?: FilesInReport$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<number>>> {
    return filesInReport$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filesInReport$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  filesInReport$Plain(params?: FilesInReport$Plain$Params, context?: HttpContext): Observable<Array<number>> {
    return this.filesInReport$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<number>>): Array<number> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesInReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  filesInReport$Response(params?: FilesInReport$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<number>>> {
    return filesInReport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filesInReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  filesInReport(params?: FilesInReport$Params, context?: HttpContext): Observable<Array<number>> {
    return this.filesInReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<number>>): Array<number> => r.body)
    );
  }

  /** Path part for operation `downloadFileCommand()` */
  static readonly DownloadFileCommandPath = '/Command/DownloadFile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadFile$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadFile$Plain$Response(params?: DownloadFile$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return downloadFile$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadFile$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadFile$Plain(params?: DownloadFile$Plain$Params, context?: HttpContext): Observable<Blob> {
    return this.downloadFile$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadFile$Response(params?: DownloadFile$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return downloadFile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadFile(params?: DownloadFile$Params, context?: HttpContext): Observable<Blob> {
    return this.downloadFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `getFileNameCommand()` */
  static readonly GetFileNameCommandPath = '/Command/GetFileName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFileName$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFileName$Plain$Response(params?: GetFileName$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return getFileName$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFileName$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFileName$Plain(params?: GetFileName$Plain$Params, context?: HttpContext): Observable<Blob> {
    return this.getFileName$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFileName()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFileName$Response(params?: GetFileName$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return getFileName(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFileName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFileName(params?: GetFileName$Params, context?: HttpContext): Observable<Blob> {
    return this.getFileName$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `reportsToCaseCommand()` */
  static readonly ReportsToCaseCommandPath = '/Command/ReportsToCase';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsToCase$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsToCase$Plain$Response(params?: ReportsToCase$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CaseItem>>> {
    return reportsToCase$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsToCase$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsToCase$Plain(params?: ReportsToCase$Plain$Params, context?: HttpContext): Observable<Array<CaseItem>> {
    return this.reportsToCase$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CaseItem>>): Array<CaseItem> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsToCase()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsToCase$Response(params?: ReportsToCase$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CaseItem>>> {
    return reportsToCase(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsToCase$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsToCase(params?: ReportsToCase$Params, context?: HttpContext): Observable<Array<CaseItem>> {
    return this.reportsToCase$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CaseItem>>): Array<CaseItem> => r.body)
    );
  }

  /** Path part for operation `reportCaseUpdatedCommand()` */
  static readonly ReportCaseUpdatedCommandPath = '/Command/ReportCaseUpdated';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportCaseUpdated()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reportCaseUpdated$Response(params?: ReportCaseUpdated$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return reportCaseUpdated(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportCaseUpdated$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reportCaseUpdated(params?: ReportCaseUpdated$Params, context?: HttpContext): Observable<void> {
    return this.reportCaseUpdated$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

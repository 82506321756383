import { Component, Inject, Input } from "@angular/core";
import { FaIconComponent } from '@fortawesome/angular-fontawesome'
import { AjourhallningAction } from "@app/shared/enums";
import { Report, ReportService } from '@app/service/report.service'
import {
    AjourhallningActionModalComponent
} from '@components/modals/ajourhallning-action-modal/ajourhallning-action-modal.component'
import { TrvGlobalMessagesService, TrvModalService, TrvPopoverRef } from 'trv-ng-common'
import { ReportStatusesEnum } from '@_api/dataleverans/models'
import {
    faCircleCheck,
    faCircleInfo,
    faFileCirclePlus,
    faLockOpen,
    faSquareXmark,
} from '@fortawesome/free-solid-svg-icons'
import {NvdbNavigationService} from "@app/service/nvdb-navigation.service";

@Component({
    selector: "app-ajourhallare-handle-popover",
    standalone: true,
    imports: [FaIconComponent],
    templateUrl: "./ajourhallare-handle-popover.component.html",
    styleUrl: "./ajourhallare-handle-popover.component.scss",
})
export class AjourhallareHandlePopoverComponent {
    @Input() report!: Report;


    // ENUMS
    protected readonly ReportStatusesEnum = ReportStatusesEnum
    protected readonly AjourhallningActions = AjourhallningAction;

    // ICONS
    protected readonly faSquareXmark = faSquareXmark
    protected readonly faLockOpen = faLockOpen
    protected readonly faCircleCheck = faCircleCheck
    protected readonly faCircleInfo = faCircleInfo
    protected readonly faFileCirclePlus = faFileCirclePlus


    constructor(
        private nvdbNavigationService: NvdbNavigationService,
        @Inject(ReportService) public reportService: ReportService,
        @Inject(TrvGlobalMessagesService)
        private globalMessagesService: TrvGlobalMessagesService,
        @Inject(TrvModalService) public trvModalService: TrvModalService,
        private trvPopoverRef: TrvPopoverRef
    ) {}

    async handle(action: AjourhallningAction) {
        this.trvPopoverRef.close('')

        if (action === AjourhallningAction.skapa_geopackage) {
            await this.reportService.exportReportToGeopackage(this.report);
        }
        else {
            const modal = this.trvModalService.open(AjourhallningActionModalComponent, {
                disposeOnBackdropClick: true,
                size: this.nvdbNavigationService.isMobileDevice ? "fullscreen" : "lg",
            });
            const component = modal.componentInstance;
            component.action = action;
            component.reportId = this.report.id;

            modal.afterCloseWithType().subscribe(closeEvent => {
                if (closeEvent.closingEventType === "close") {

                    console.log(closeEvent.result.sendEmail)
                    let sendEmail = true;
                    if(action == AjourhallningAction.avsluta_arende) {
                        sendEmail = closeEvent.result.sendEmail
                    }


                    this.reportService.handleReportAction(this.report.id, action, closeEvent.result.comment, closeEvent.result.email, sendEmail);

                }
            });
        }
    }
}

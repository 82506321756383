export enum GeometryType {
    POINT = 'Point',
    LINE_STRING = 'LineString',
    LINEAR_RING = 'LinearRing',
    POLYGON = 'Polygon',
    MULTI_POINT = 'MultiPoint',
    MULTI_LINE_STRING = 'MultiLineString',
    MULTI_POLYGON = 'MultiPolygon',
    GEOMETRY_COLLECTION = 'GeometryCollection',
    CIRCLE = 'Circle',
}


export enum AjourhallningAction {
    klart_for_beredning="Klart för beredning",
    skapa_geopackage="Skapa geopackage-fil",
    las_upp="Öppna för komplettering",
    avsluta_arende="Avsluta ärende",
    informera_kunden="Informera kunden",
    informera_kommunen="Informera kommunen",
    informera_annan="Informera annan",
    mottaget_for_utredning="Mottaget för utredning"
}

import { AfterViewInit, Component, ElementRef, Inject, inject, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
    hoverPolygon,
    infoPointerStyle,
    LEKSAND_COORD,
    MapToolsType,
    redPinStyle,
    resolutions,
    STOCKHOLM_COORD,
    SWEREF_99_TM,
    TrvNgMapComponent,
    TrvNgMapOverlayService,
    TrvNgMapService,
    TrvMap,
    LayerListItem,
    MapTools,
} from "@trafikverket/trv-ng-map";
import { ChangeDetectorRef } from "@angular/core";
import { Feature, MapBrowserEvent, View } from "ol";
import { faEraser, faHand, faLocation, faMagnifyingGlass, faMapPin, faRuler, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { IconProp, layer } from "@fortawesome/fontawesome-svg-core";
import { Circle, Point } from "ol/geom";
import { TrvMapExtension } from "src/app/trv-map-extension/trv-map-extension";
import { environment } from "src/environments/environment";
import { Report, ReportItem, ReportService } from "src/app/service/report.service";
import { NetDbService } from "src/app/service/net-db.service";
import { TrvMapExtensionService } from "src/app/service/trv-map-extension.service";
import { ROUTES } from "@angular/router";
import { RouteService } from "../../../service/route.service";
import { LocalStorageService, SavedView } from "../../../service/local-storage.service";
import { SWEDEN_VIEW_ID, SWEDEN_VIEW } from "../../../shared/constants";
import { catchError, combineLatest, first, firstValueFrom, interval, merge, of, switchMap, take, takeWhile, tap, timeout, timeoutWith } from "rxjs";
import {
    MESSAGE_FROM_FORVALTNING_PERIOD_END_UID_NVDB_PA_KARTA,
    MESSAGE_FROM_FORVALTNING_PERIOD_START_UID_NVDB_PA_KARTA,
    MESSAGE_FROM_FORVALTNING_UID_NVDB_PA_KARTA,
    NvdbNavigationService,
} from "src/app/service/nvdb-navigation.service";
import { TrvGlobalMessagesService, TrvModalService } from "trv-ng-common";

import _nvdbDataleveransLayers from "../../../../assets/data/layer-data/nvdb-layers.json";
import _nvdbPaKartaLayers from "../../../../assets/data/layer-data/nvdb-layers-pakarta.json";
import _njdbPaWebbLayers from "../../../../assets/data/layer-data/njdb-layers.json";
import _smallLayers from "../../../../assets/data/layer-data/nvdb-layers-downscaled.json";
import { ReportAvikelseService } from "src/app/service/report-avikelse.service";
import { filter } from "rxjs/operators";
import { svgMagnifyingPlus } from "src/app/shared/utils/svg-helper-data";
import { SvgIcon } from "src/app/shared/utils/svg-helper";
import { AdminTypeEnum } from "@_api/dataleverans/models/admin-type-enum";
import { DateTime } from "luxon";
import { InfoFromForvaltningModalComponent } from "@components/modals/info-from-forvaltning-modal/info-from-forvaltning-modal.component";

@Component({
    selector: "app-map",
    templateUrl: "./map.component.html",
    styleUrls: ["./map.component.scss"],
    standalone: true,
    imports: [TrvNgMapComponent],
})
export class MapComponent implements AfterViewInit, OnDestroy {
    @ViewChild(TrvNgMapComponent) trvNgMap!: TrvNgMapComponent;

    @Input() east: number | null = null;
    @Input() north: number | null = null;
    @Input() scale: number | null = null;

    public loading: boolean = true;
    public MapTools = MapTools;
    public nvdbLayerData: any;

    public mapHeight!: string;

    constructor(
        private cdref: ChangeDetectorRef,
        @Inject(TrvNgMapService) private trvMapService: TrvNgMapService,
        @Inject(TrvNgMapOverlayService)
        private trvMapOverlayService: TrvNgMapOverlayService,
        @Inject(NvdbNavigationService)
        public nvdbNavigation: NvdbNavigationService,
        @Inject(ReportService) private reportService: ReportService,
        @Inject(ReportAvikelseService) private reportAvikelseService: ReportAvikelseService,
        @Inject(LocalStorageService)
        private localStorageService: LocalStorageService,
        @Inject(RouteService) private routeService: RouteService,
        @Inject(NetDbService) private netDbService: NetDbService,
        @Inject(TrvMapExtensionService)
        private trvMapExtensionService: TrvMapExtensionService,
        @Inject(TrvGlobalMessagesService)
        private globalMessagesService: TrvGlobalMessagesService,
        @Inject(TrvModalService) public trvModalService: TrvModalService
    ) {
        this.mapHeight = `calc(100svh - 50px - ${this.nvdbNavigation.isMobileDevice ? "0px" : "53px"}`;
    }

    async ngOnInit() {
        if (environment.application == "NvdbDataleverans") {
            this.nvdbLayerData = _nvdbDataleveransLayers;
        } else if (environment.application == "NvdbPåKarta") {
            this.nvdbLayerData = _nvdbPaKartaLayers;
        } else if (environment.application == "NjdbPåWebb") {
            this.nvdbLayerData = _njdbPaWebbLayers;
        }else {
            this.nvdbLayerData = _smallLayers;
        }

        //Alter nvdbdata with baseApiUrl;
        this.nvdbLayerData.Layers.LayerList.forEach((layer: any) => {
            if (layer.hasOwnProperty("Url")) {
                let url: string = layer.Url;
                layer.Url = url.replace("{api}", environment.baseApiUrl);
            }
        });

        this.nvdbLayerData.BackgroundLayer.LayerList.forEach((layer: any) => {
            if (layer.hasOwnProperty("Url")) {
                let url: string = layer.Url;
                layer.Url = url.replace("{api}", environment.baseApiUrl);
            }
        });

        //Remove local on på karta if dmz
        if (environment.application == "NvdbPåKarta" && environment.local != "true") {
            //trv:onlyLocal = true
            this.nvdbLayerData.Layers.LayerList = this.nvdbLayerData.Layers.LayerList.filter(
                (layer: any) => !(layer.hasOwnProperty("trv:onlyLocal") && layer["trv:onlyLocal"] == true)
            );
            this.nvdbLayerData.BackgroundLayer.LayerList = this.nvdbLayerData.BackgroundLayer.LayerList.filter(
                (layer: any) => !(layer.hasOwnProperty("trv:onlyLocal") && layer["trv:onlyLocal"] == true)
            );
        }

        this.loading = false;
        this.cdref.detectChanges();

        if (environment.application == "NvdbDataleverans") {
            await this.nvdbNavigation.loadAdminConfiguration();
            await this.nvdbNavigation.loadDupAdminConfiguration();
            const configurationValues = this.nvdbNavigation.adminConfiguration!.values!;

            const message = configurationValues.find(a => a.type === AdminTypeEnum.MessageFromForvaltningen)!.value!;

            const periodStart = configurationValues.find(a => a.type === AdminTypeEnum.MessagePeriodStart)!.value!;
            const periodEnd = configurationValues.find(a => a.type === AdminTypeEnum.MessagePeriodEnd)?.value ?? "9999-99-99";
            const today = DateTime.now().toString().split("T")[0];

            const messageLastUpdated = DateTime.fromISO(configurationValues.find(a => a.type === AdminTypeEnum.MessageFromForvaltningen)!.updated!);
            const dontShowMessageDate = this.localStorageService.getdontShowMessage();

            if (
                periodStart <= today &&
                today <= periodEnd &&
                message &&
                message != "" &&
                (!dontShowMessageDate || DateTime.fromISO(dontShowMessageDate) < messageLastUpdated)
            ) {
                this.localStorageService.setdontShowMessage(false);
                const modal = this.trvModalService.open(InfoFromForvaltningModalComponent, {
                    disposeOnBackdropClick: true,
                    size: this.nvdbNavigation.isMobileDevice ? "fullscreen" : "lg",
                });
                modal.componentInstance.content = message;
            }
        } else if (environment.application == "NvdbPåKarta") {
            await this.nvdbNavigation.loadDupAdminConfigurationPaKarta();

            let messageFromForvaltning = '<h3>Välkommen till NVDB på karta!</h3> Vid frågor eller synpunkter skicka e-post till <a href="mailto:NVDBpakarta@trafikverket.se">NVDBpakarta@trafikverket.se</a>'
            let periodStart = "";
            let periodEnd = "9999-99-99";
            let messageUpdated = DateTime.now();

            let adminValues = this.nvdbNavigation.adminConfigurationDup;
            if (adminValues == null) adminValues = [];

            const _messageFromForvaltning = adminValues.find(adminValue => adminValue.uid === MESSAGE_FROM_FORVALTNING_UID_NVDB_PA_KARTA);
            if (_messageFromForvaltning) {
                messageFromForvaltning = _messageFromForvaltning.value ?? "";
                messageUpdated = _messageFromForvaltning.updated ? DateTime.fromISO(_messageFromForvaltning.updated) : DateTime.now()
            }

            const _messageFromForvaltningPeriodStart = adminValues.find(
                adminValue => adminValue.uid === MESSAGE_FROM_FORVALTNING_PERIOD_START_UID_NVDB_PA_KARTA
            );
            if (_messageFromForvaltningPeriodStart) {
                periodStart = _messageFromForvaltningPeriodStart.value ?? ""
            }
            const _messageFromForvaltningPeriodEnd = adminValues.find(
                adminValue => adminValue.uid === MESSAGE_FROM_FORVALTNING_PERIOD_END_UID_NVDB_PA_KARTA
            );
            if (_messageFromForvaltningPeriodEnd) {
                periodEnd = _messageFromForvaltningPeriodEnd.value ?? "9999-99-99"
            }

            const today = DateTime.now().toString().split("T")[0];
            const messageLastUpdated = messageUpdated;
            const dontShowMessageDate = this.localStorageService.getdontShowMessage();

            if (
                periodStart <= today &&
                today <= periodEnd &&
                messageFromForvaltning &&
                messageFromForvaltning != "" &&
                (!dontShowMessageDate || DateTime.fromISO(dontShowMessageDate) < messageLastUpdated)
            ) {
                this.localStorageService.setdontShowMessage(false);
                const modal = this.trvModalService.open(InfoFromForvaltningModalComponent, {
                    disposeOnBackdropClick: true,
                    size: this.nvdbNavigation.isMobileDevice ? "fullscreen" : "lg",
                });
                modal.componentInstance.content = messageFromForvaltning;
            }
        }
    }

    ngAfterViewInit() {
        // set map extensions
        const trvMapExtension = new TrvMapExtension(
            this.trvMapService,
            this.trvMapOverlayService,
            this.nvdbNavigation,
            this.reportService,
            this.reportAvikelseService,
            this.netDbService,
            this.trvMapExtensionService,
            this.routeService,
            this.globalMessagesService,
            this.trvModalService
        );
        this.trvMapExtensionService.setTrvMapExtension(trvMapExtension);

        // go to previous view if it exists, else the default view
        let previousState = this.nvdbNavigation.previousMapState;

        const east = this.east;
        const north = this.north;
        const scale = this.scale;

        if (this.nvdbNavigation.VIEWMODE) {
            // DO NOTHING
        } else if (east && north && scale) {
            const zoom = this.trvMapService.trvMap?.getZoomForScale(scale);
            this.trvMapService.trvMap?.setMapStateWithCoord([east, north], zoom);
        } else if (previousState) {
            this.trvMapService.trvMap?.setMapStateWithCoord(
                previousState!.centerCoordinate,
                previousState!.zoom,
                previousState!.backgroundLayerId,
                previousState!.dataLayersId
            );

            this.trvMapService.trvMap!.viewHistory = previousState!.history;
            this.trvMapService.trvMap!.currentHistoryIndex = previousState!.currentHistoryIndex;
            this.trvMapService.trvMap!.hasMovedSinceLastSavepoint = previousState!.hasMovedSinceLastSavepoint;
        } else {
            const viewToGoto = this.localStorageService.savedViews.find(view => view.id === this.localStorageService.defaultViewId) ?? SWEDEN_VIEW;
            this.trvMapService.trvMap?.setMapStateWithCoord(
                viewToGoto.centerCoordinate,
                viewToGoto.zoom,
                viewToGoto.backgroundLayerId,
                viewToGoto.dataLayersId,
                2000
            );
            this.trvMapService.trvMap?.savePosition(viewToGoto.centerCoordinate, viewToGoto.zoom);
        }

        if (environment.application == "NvdbPåKarta") {
            this.reportAvikelseService.initLoadFromLocalStorage(this.localStorageService.getUnsentReportAvikelseItems());
        }

        if (this.trvMapService.isMapLoaded()) this.reportService.reloadFeatures();
    }

    public displayWmsLegend(): boolean {
        return this.trvMapService.isMapLoaded() && this.getTrvMap()!.trvLayer.getVisibleWmsLayer().length > 0;
        //return this.trvMapService.mapLoaded.value && this.getTrvMap()!.trvLayer.getVisibleWmsLayer().length > 0;
    }

    public getTrvMap(): TrvMap | undefined {
        return this.trvMapService.trvMap;
    }

    public getTool(): MapToolsType {
        if (this.trvMapService.trvMap) {
            return this.trvMapService.trvMap.activeMapTool?.value;
        }
        return MapTools.None;
    }

    public changeLayer(layerItem: LayerListItem): void {
        this.trvMapService.trvMap!.trvLayer.activateBackgroundLayer(layerItem);
    }

    public mouseDown() {
        //console.log("mouse down")
    }

    public mouseUp() {
        //console.log("mouse up")
    }

    public setMapTool(mapTool: MapToolsType) {
        if (!this.trvMapService.trvMap) return;

        this.trvMapService.trvMap.changeActiveMapTool(mapTool);
    }

    ngOnDestroy() {
        this.reportService.setActiveItemToCreateOrEdit(null);
    }
}
